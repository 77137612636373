import { makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useAuth } from '../../../../contexts/Auth';
import cashImage from '../../assets/cash.png';
import { Entry, LeaderboardEntry } from '../../types';
import { addSuffixToNumber } from '../../utils/utils';
import Pagination from '../common/leaderboard/Pagination';
import TiedText from '../common/leaderboard/TiedText';
import TopTitles from '../common/leaderboard/TopTitles';
import LeaderboardEntryItem from './LeaderboardEntryItem';

const styles = makeStyles((theme: Theme) => ({
    userData: {
        borderRadius: 7,
        background: theme.palette.background.default,
        padding: '10px',
        marginBottom: 20,
    },
    overallUserRow: {
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px',
        fontSize: 12,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left',
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        },
    },
    overallUserScores: {
        gridTemplateColumns: '50px 1fr 50px',
        alignItems: 'center',
        display: 'grid',
        fontWeight: 900,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left',
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        },
    },
    userRow: {
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px 90px',
        fontSize: 12,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left',
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        },
    },
    userScores: {
        gridTemplateColumns: '50px 1fr 50px 90px',
        alignItems: 'center',
        display: 'grid',
        fontWeight: 900,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left',
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        },
    },
    pos: {
        fontSize: 14,
        fontWeight: 400,
    },
    userName: {
        fontSize: 14,
        fontWeight: 400,
        overflow: 'hidden',
        paddingRight: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    points: {
        fontSize: 14,
    },
    winning: {
        fontSize: 14,
        paddingLeft: 30,
        position: 'relative',
    },
    winningType: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
    row: {
        display: 'flex',
        padding: '10px 0 0',
    },
    scores: {
        fontWeight: 400,
    },
    rank: {
        fontWeight: 700,
    },
    listWrapper: {},
    cashWinning: {
        color: '#6BFE00',
    },
    ticketWinning: {
        color: '#FFC700',
    },
}));

interface Props {
    entry: Entry | undefined | null;
    isAnyTie: boolean;
    newList: (LeaderboardEntry & { tied: boolean })[];
    type: 'OVERALL' | 'LADBROKES';
    isResulted: boolean;
}

const Leaderboard = (props: Props) => {
    const classes = styles();
    const { entry, isAnyTie, newList, type } = props;
    const [page, setPage] = React.useState(1);

    const { username } = useAuth();

    const pageSize = 20;
    const paginationPages = Math.ceil(newList.length / pageSize);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const v = value ? value : 1;
        setPage(v);
    };

    return (
        <>
            {entry ? (
                <div className={classes.userData}>
                    <div className={type === 'OVERALL' ? classes.overallUserRow : classes.userRow}>
                        <span>POS</span>
                        <span>Player</span>
                        <span>Pts</span>
                        {type !== 'OVERALL' ? <span>Bonus Cash</span> : null}
                    </div>

                    <div className={type === 'OVERALL' ? classes.overallUserScores : classes.userScores}>
                        <span className={classes.pos}>
                            {addSuffixToNumber(type === 'OVERALL' ? entry.nationalRank : entry.rank)}
                        </span>
                        <span className={classNames(classes.userName)}>{username}</span>
                        <span className={classes.points}>{entry.points}</span>
                        {type !== 'OVERALL' ? (
                            <span className={classNames(classes.winning)}>
                                <span className={classes.winningType}>
                                    <img src={cashImage} alt="winning-type-icon" />
                                </span>
                                <span style={{ color: '#6BFE00' }}>{`$${entry.winnings}`}</span>
                                { props.isResulted ? null : <span style={{ fontSize: 11, verticalAlign: 'super' }}>&nbsp;*</span>}
                            </span>
                        ) : null}
                    </div>

                    <div className={classes.row}>
                        <span className={classes.scores}>
                            <span className={classes.rank}>
                                {addSuffixToNumber(type === 'OVERALL' ? entry.nationalRank : entry.rank)}
                            </span>{' '}
                            of {newList.length}
                        </span>
                    </div>
                </div>
            ) : null}

            {isAnyTie ? <TiedText /> : null}

            <TopTitles type={type} />

            <div className={classes.listWrapper}>
                {newList.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize).map((entry, i: number) => {
                    return (
                        <LeaderboardEntryItem
                            key={i}
                            entry={entry}
                            username={username}
                            userScore={false}
                            winningStyle={type === 'OVERALL' ? classes.ticketWinning : classes.cashWinning}
                            type={type}
                            isResulted={props.isResulted}
                        />
                    );
                })}
            </div>

            {newList.length > pageSize && (
                <Pagination paginationPages={paginationPages} handlePagination={handleChange} />
            )}
        </>
    );
};

export default Leaderboard;
