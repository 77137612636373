import React, {useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import FixtureCardTopPart from '../shared/fixture_card/FixtureCardTopPart';
import FixtureButtons from '../shared/fixture_card/FixtureButtons';
import {Contest} from "../../../apollo/cricket/queries/GetContests";
import PlayButton from "../cricket/PlayButton";
import LeaderboardButton from "../cricket/LeaderboardButton";
import ResultsButton from "../cricket/ResultsButton";


const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 5,
        padding: '10px 20px',
        display: 'grid',
        gridTemplateColumns: '1fr 1.2fr 1fr',
        alignItems: 'center',
        background: theme.palette.background.default,
        position: 'relative'
    },
    wrapper: {
        paddingBottom: 10,
        '@media only screen and (min-width: 1025px)': {
            paddingBottom: 40
        },
        '@media only screen and (min-width: 1025px) and (max-height: 700px)': {
            paddingBottom: 10
        }
    },
}));


interface Props {
    contest: Contest
    entryId: number | undefined
    setPrevPageForLeaderboard: (p: string) => void
    leaderboardButtonClickFunc?: any
    playButtonClickFunc?: any
    resultButtonClickFunc?: any
    isLocked?: boolean
    setIsLocked?: (l: boolean) => void
    leaderBoardButtonWasClicked?: boolean
    resultButtonWasClicked?: boolean
    playButtonWasClicked?: boolean
}


const CricketFixtureCardWrapper = (props: Props) => {
    const classes = styles();
    const {
        contest, setPrevPageForLeaderboard, entryId, leaderBoardButtonWasClicked, resultButtonWasClicked, playButtonWasClicked,
        leaderboardButtonClickFunc, playButtonClickFunc, resultButtonClickFunc,
        isLocked, setIsLocked
    } = props;

    const [rainDelayed, setRainDelayed] = useState(false);
    const [status, setStatus] = useState<string | null>(null);

    const playButton = (
        <PlayButton entryId={entryId} isLocked={isLocked! || rainDelayed} buttonClickFunc={playButtonClickFunc}
                    buttonWasClicked={playButtonWasClicked!}/>
    );

    const resultButton = (
        <ResultsButton buttonWasClicked={resultButtonWasClicked!} buttonClickFunc={resultButtonClickFunc}/>
    );

    const leaderboardButton = (
        <LeaderboardButton buttonClickFunc={leaderboardButtonClickFunc}
                           buttonWasClicked={leaderBoardButtonWasClicked!}/>
    );


    return (
        <div className={classNames(classes.wrapper)}>
            <div className={classes.root}>
                <FixtureCardTopPart contest={contest}
                                    entryId={contest.entry ? contest.entry.id : undefined}
                                    setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                    setIsLocked={setIsLocked!} setRainDelayed={setRainDelayed} isLocked={isLocked!}
                                    setStatus={setStatus} status={status} color={0}/>
            </div>

            <FixtureButtons contestId={contest.id}
                            entryId={contest.entry ? contest.entry.id : undefined}
                            status={status}
                            playButton={playButton}
                            resultButton={resultButton}
                            leaderboardButton={leaderboardButton}
            />
        </div>
    )
};

export default CricketFixtureCardWrapper;