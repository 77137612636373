import React from 'react';
import classNames from "classnames";
import {addSuffixToNumber} from "../../../utils";
import TiedText from '../shared/TiedText';
import {AflNrlEntry} from "../../../../../shared/types";
import {makeStyles, Theme} from "@material-ui/core";
import {useAuth} from "../../../contexts/Auth";
import TopTitles from '../shared/TopTitles';
import LeaderboardEntry from "./LeaderboardEntry";
import PaginationComponent from './PaginationComponent';

const styles = makeStyles((theme: Theme) => ({
    userData: {
        borderRadius: 7,
        background: theme.palette.background.default,
        padding: '10px',
        marginBottom: 20
    },
    userRow: {
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px 90px',
        fontSize: 12,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left'
        }
    },
    userScores: {
        gridTemplateColumns: '50px 1fr 50px 90px',
        alignItems: 'center',
        display: 'grid',
        fontWeight: 900,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left'
        }
    },
    pos: {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'left'
    },
    userName: {
        fontSize: 14,
    },
    points: {
        fontSize: 14,
        textAlign: 'right',
    },
    money: {
        textAlign: 'right',
        fontSize: 14,
        color: '#6BFE00'
    },
    grey: {
        color: theme.palette.grey[300],
    },
    row: {
        display: 'flex',
        padding: '10px 0 0'
    },
    scores: {
        fontWeight: 700,
        paddingLeft: 5
    },
    listWrapper: {
    },
    overRank: {
        color: '#6BFE00'
    },
}));

interface Props {
    entry: AflNrlEntry | null
    isAnyTie: boolean
    newList: (AflNrlEntry & {tied: boolean})[]
}

const Leaderboard = (props: Props) => {
    const classes = styles();
    const {entry, isAnyTie, newList} = props;
    const [page, setPage] = React.useState(1);

    const {username} = useAuth();

    const pageSize = 20;
    const paginationPages = Math.ceil(newList.length / pageSize);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const v = value ? value : 1;
        setPage(v);
    };

    return (
        <>
            {entry ?
                <div className={classes.userData}>
                    <div className={classes.userRow}>
                        <span>RANK</span>
                        <span>USERNAME</span>
                        <span>SCORE</span>
                        <span>BONUS CASH</span>
                    </div>

                    <div className={classes.userScores}>
                        <span className={classes.pos}>{addSuffixToNumber(entry.rank)}</span>
                        <span className={classNames(classes.userName)}>{username}</span>
                        <span className={classes.points}>{entry.points}</span>
                        <span className={classNames(classes.money)}>${entry.winnings}</span>
                    </div>

                    <div className={classes.row}>
                        <div className={classes.grey}>Your Place</div>
                        <span className={classes.scores}> {entry.rank} / {newList.length}</span>

                    </div>
                </div> : null
            }

            {isAnyTie ?
                <TiedText/>
                : null}

            <TopTitles/>

            <div className={classes.listWrapper}>
                {newList
                    .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
                    .map((entry, i: number) => {
                        return (
                            <LeaderboardEntry key={i} entry={entry} username={username}
                                              userScore={false}
                                              cashColor={classes.overRank}/>)
                    })}
            </div>

            {newList.length > pageSize &&
            <PaginationComponent paginationPages={paginationPages}
                                 handlePagination={handleChange}/>}
        </>
    )
};

export default Leaderboard;