import {gql} from '@apollo/client';

export const NBA_IS_WON_CHECK = gql`
    query ifNbaWonCheck($blockId: Int!) {
        nba {
           leaderboard(blockId: $blockId) {
                blockIndex
                entries {
                    id
                    username
                    settlementStatus
                }
           }
        }
    }
`;