import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";

type Props<T extends string> = {
  onTabClick: (tabName: T) => void;
  selectedTab: T | undefined;
  tabNames: T[];
};

const Tabs = <T extends string>({
  onTabClick,
  selectedTab,
  tabNames,
}: Props<T>) => {
  const classes = styles();

  return (
    <div className={classes.tabs}>
      {tabNames.map((tabName) => (
        <div
          key={tabName}
          className={classNames(
            classes.btn,
            selectedTab === tabName ? classes.active : null
          )}
          onClick={() => onTabClick(tabName)}
        >
          {tabName}
        </div>
      ))}
    </div>
  );
};

const styles = makeStyles((theme: Theme) => ({
  tabs: {
    background: theme.palette.background.default,
    borderRadius: 20,
    display: "flex",
    margin: `15px auto 30px`,
    padding: 3,
  },
  btn: {
    borderRadius: 20,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 400,
    padding: 5,
    textTransform: "capitalize",
    width: "50%",
  },
  active: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.background.default,
  },
}));

export default Tabs;
