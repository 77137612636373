import {gql} from '@apollo/client';


export const LEADERBOARD_QUERY = gql`
    query leaderboard($contestId: Int!) {
        leaderboard(contestId: $contestId) {
            bonusBetCutOffPoints
            topPoints
            bottomPoints
            entryCount
            scores {
                username
                rank
                winnings
                points
            }
        }
    }
`;



