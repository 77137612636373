import React, {useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import Button from "../../common/UI/SquareButton";
import {useHistory} from "react-router";
import BackButton from '../../common/UI/BackButton';
import emptyleaderboard from '../../../assets/emptyleaderboard.png';


const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.contrastText,
        paddingTop: 50
    },
    inner: {},
    titleImg: {
        margin: 'auto',
        display: 'block',
        '@media only screen and (max-width: 1024px)': {
            width: 250
        },
        '@media only screen and (min-width: 1025px)': {
            width: '80%',
            maxWidth: 515,
            paddingBottom: 20,
            display: 'none'
        }
    },
    title: {
        fontSize: 30,
        fontWeight: 800,
        textAlign: 'center',
        paddingTop: 20
    },
    text: {
        width: '90%',
        margin: 'auto',
        padding: '20px 0 0',
        textAlign: 'center',
        fontSize: 18,
        '@media only screen and (max-width: 1024px)': {
            width: '70%',
        }
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    mobTitle: {
        display: 'none',
        '@media only screen and (max-width: 1024px)': {
            display: 'block',
            fontSize: 21,
            fontWeight: 700,
            padding: '10px 0 20px',
            position: 'relative',
            zIndex: 2
        }
    },
}));

interface Props {
    backToGame: () => void
    prevPageForLeaderboard: string
    children: React.ReactNode
    titleText: string
}

const EmptyLeaderboard = (props: Props) => {
    const classes = styles();
    const {backToGame, prevPageForLeaderboard, children, titleText} = props;
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const history = useHistory();

    return (
        <div className={classes.root}>
            <div className={classes.inner}>

                {children}

                <div className={classes.title}>{titleText}</div>

                <div className={classes.text}>Nobody entered this contest. Check out the next contests available for
                    your chance to win bonus cash.
                </div>

                <div className={classes.buttonWrapper}>
                    <Button width='162px' buttonWasClicked={buttonWasClicked}
                            onClick={(e: any) => {
                                if (!buttonWasClicked) {
                                    setButtonWasClicked(true);
                                    e.preventDefault();

                                    history.push(`/` + history.location.search)
                                }
                            }}>
                        Home
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default EmptyLeaderboard;