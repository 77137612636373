import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import BackButton from '../../common/UI/BackButton';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        height: '100vh',
        '@media only screen and (min-width: 1025px)': {
            backgroundImage: 'url(/BACKGROUND_DESKTOP.png)',
            backgroundSize: 'cover',
        },
        '@media only screen and (max-width: 1024px)': {
            backgroundSize: 'cover',
            backgroundImage: `url(/BackgroundGreen.jpg)`,
        },
    },
    inner: {
        height: '100%',
        overflow: 'scroll',
        '@media only screen and (min-width: 1025px)': {
            padding: '0px 50px 50px'
        },
        '@media only screen and (max-width: 1024px)': {
            padding: '0px 20px 30px'
        },
    },
    title: {
        fontWeight: 800,
        color: theme.palette.primary.contrastText,
        '@media only screen and (max-width: 1024px)': {
            fontSize: 24,
        },
        '@media only screen and (min-width: 1025px)': {
            fontSize: 32,
        },
    },
    mainListWrapper: {
        counterReset: 'main-counter',
        listStyle: 'none',
        paddingLeft: 40,
        fontSize: 14,
        textAlign: 'justify',
        '& > $mainList': {
            counterIncrement: 'main-counter',
            position: 'relative'
        },
        '@media only screen and (max-width: 1024px)': {
            paddingLeft: 20
        }
    },
    mainList: {
        paddingBottom: 10,
        '&:before': {
            content: 'counter(main-counter)',
            color: theme.palette.primary.contrastText,
            position: 'absolute',
            left: -20,
            width: 20,
            height: 20,
            top: 0,
        },
        '& > a': {
            color: theme.palette.primary.contrastText,
        }
    },
    secondaryListWrapper: {
        counterReset: 'secondary-counter',
        listStyle: 'none',
        paddingLeft: 40,
        fontSize: 14,
        '& > $secondaryList': {
            counterIncrement: 'secondary-counter',
            position: 'relative'
        },
        '@media only screen and (max-width: 1024px)': {
            paddingLeft: 20
        }
    },
    secondaryList: {
        paddingBottom: 10,
        paddingTop: 10,
        '&:before': {
            content: 'counter(secondary-counter, lower-latin)',
            color: theme.palette.primary.contrastText,
            position: 'absolute',
            left: -20,
            width: 20,
            height: 20,
            top: 10,
        },
    },
    thirdlyListWrapper: {
        counterReset: 'thirdly-counter',
        listStyle: 'none',
        paddingLeft: 40,
        fontSize: 14,
        '& > $thirdlyList': {
            counterIncrement: 'thirdly-counter',
            position: 'relative'
        },
        '@media only screen and (max-width: 1024px)': {
            paddingLeft: 20
        }
    },
    thirdlyList: {
        paddingBottom: 10,
        paddingTop: 10,
        '&:before': {
            content: 'counter(thirdly-counter, lower-roman)',
            color: theme.palette.primary.contrastText,
            position: 'absolute',
            left: -20,
            width: 20,
            height: 20,
            top: 10,
        },
    },
    boldRow: {
        fontWeight: 700,
        paddingBottom: 10,
        paddingTop: 10
    },
    regularRow: {
        paddingBottom: 10,
    },
}));

const Index = () => {
    const classes = styles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <BackButton onClick={() => history.goBack()}>Back</BackButton>

                <div className={classes.title}>Terms and Conditions</div>

                <ol className={classes.mainListWrapper}>
                    <li className={classes.mainList}>
                        The promoter is Entain Group Pty Ltd (ABN 25 151 956 768) of Level
                        2,
                        25 Montpelier Rd Bowen Hills QLD 4006 phone 1300 523 276 <b>(Promoter)</b>. The Promoter and its
                        related
                        companies are referred to as the <b>Promoter Group</b>.
                    </li>

                    <li className={classes.mainList}>Instructions and information on how to enter the Promotion form
                        part of these terms and conditions,
                        and entry into the Promotion is deemed acceptance of these terms and conditions and all of the
                        Promoter’s terms and conditions. The Promoter’s Responsible Gambling Policy (located at <a
                            href='https://responsiblegambling.entaingroup.com.au/'
                            target='_blank'>
                            https://responsiblegambling.entaingroup.com.au/</a>) will apply to entrants entering this
                        Promotion.
                    </li>

                    <li className={classes.mainList}>The following individuals are eligible to enter this Promotion:
                        <ol className={classes.secondaryListWrapper}>
                            <li className={classes.secondaryList}>residents of QLD, NSW, VIC, SA, ACT, TAS, WA and NT
                                who are 18 years of age or
                                older, who have a Ladbrokes account with the Promoter; and
                            </li>
                            <li className={classes.secondaryList}>who have a verified Ladbrokes account with the
                                Promoter and are classed as a
                                “recreational customer” as classified by the Promoter. For the avoidance of doubt,
                                accounts that have been restricted in any way, or accounts that the Promoter deems
                                ineligible for any reason are ineligible to enter the promotion.
                            </li>
                        </ol>
                    </li>


                    <li className={classes.mainList}>Entry to the promotion is free. To enter, you must access the Couch
                        Cricket free to play game in the Ladbrokes platform. Once you have agreed to the Couch Cricket
                        terms and conditions and entered the game, you are required to make various selections relating
                        to a Big Bash League (BBL) cricket match. Entrants will be asked to:
                        <ol className={classes.secondaryListWrapper}>
                            <li className={classes.secondaryList}>Choose a batter from the two batsmen who are currently
                                batting. As the entrant’s chosen batter scores runs, the entrant accumulates points. If
                                the entrant’s chosen batter gets out, the entrant is required to choose a new batter;
                                and
                            </li>
                            <li className={classes.secondaryList}>Answer questions relating to the BBL match that is
                                underway as well as general cricket trivia questions. The entrant will accumulate points
                                for each correct answer.
                            </li>
                        </ol>
                    </li>

                    <li className={classes.mainList}>Entry to each promotion is open on the dates listed in the below
                        table from approximately two (2) minutes prior to the match commencing:

                        <div className={classes.boldRow}>Promotion number | Date | Match | Scheduled match commencement
                            time (AEDT)</div>

                        <div className={classes.regularRow}>1 | Sun Dec 5 | Sixers vs Stars | 7:15pm</div>
                        <div className={classes.regularRow}>2 | Mon Dec 6 | Thunder vs Heat | 7:15pm</div>
                        <div className={classes.regularRow}>3 | Tue Dec 7 | Renegades vs Strikers | 7:15pm</div>
                        <div className={classes.regularRow}>4 | Wed Dec 8 | Hurricanes vs Sixers | 6:35pm</div>
                        <div className={classes.regularRow}>5 | Wed Dec 8 | Scorchers vs Heat | 9:35pm</div>
                        <div className={classes.regularRow}>6 | Thu Dec 9 | Strikers vs Renegades | 7:15pm</div>
                        <div className={classes.regularRow}>7 | Fri Dec 10 | Stars vs Thunder | 7:15pm</div>
                        <div className={classes.regularRow}>8 | Sat Dec 11 | Sixers vs Hurricanes | 6:35pm</div>
                        <div className={classes.regularRow}>9 | Sat Dec 11 | Scorchers vs Strikers | 9:30pm</div>
                        <div className={classes.regularRow}>10 | Sun Dec 12 | Thunder vs Stars | 7:15pm</div>
                        <div className={classes.regularRow}>11 | Mon Dec 13 | Heat vs Renegades | 7:15pm</div>
                        <div className={classes.regularRow}>12 | Tue Dec 14 | Hurricanes vs Scorchers | 7:15pm</div>
                        <div className={classes.regularRow}>13 | Wed Dec 15 | Stars vs Sixers | 7:15pm</div>
                        <div className={classes.regularRow}>14 | Sun Dec 19 | Heat vs Thunder | 7:40pm</div>
                        <div className={classes.regularRow}>15 | Mon Dec 20 | Scorchers vs Hurricanes | 7:15pm</div>
                        <div className={classes.regularRow}>16 | Tue Dec 21 | Sixers vs Strikers | 7:15pm</div>
                        <div className={classes.regularRow}>17 | Wed Dec 22 | Renegades vs Scorchers | 7:15pm</div>
                        <div className={classes.regularRow}>18 | Thu Dec 23 | Strikers vs Heat | 7:15pm</div>
                        <div className={classes.regularRow}>19 | Fri Dec 24 | Hurricanes vs Stars | 3:30pm</div>
                        <div className={classes.regularRow}>20 | Sun Dec 26 | Thunder vs Sixers | 6:05pm</div>
                        <div className={classes.regularRow}>21 | Sun Dec 26 | Scorchers vs Renegades | 9:15pm</div>
                        <div className={classes.regularRow}>22 | Mon Dec 27 | Hurricanes vs Strikers | 6:05pm</div>
                        <div className={classes.regularRow}>23 | Mon Dec 27 | Heat vs Stars| 9:05pm</div>
                        <div className={classes.regularRow}>24 | Tue Dec 28 | Thunder vs Scorchers | 7:15pm</div>
                        <div className={classes.regularRow}>25 | Wed Dec 29 | Sixers vs Heat | 6:05pm</div>
                        <div className={classes.regularRow}>26 | Wed Dec 29 | Renegades vs Hurricanes | 7:15pm</div>
                        <div className={classes.regularRow}>27 | Thu Dec 30 | Scorchers vs Stars | 7:15pm</div>
                        <div className={classes.regularRow}>28 | Fri Dec 31 | Strikers vs Thunder | 7:15pm</div>
                        <div className={classes.regularRow}>29 | Sat Jan 1 | Hurricanes vs Heat | 4pm</div>
                        <div className={classes.regularRow}>30 | Sat Jan 1 | Sixers vs Renegades | 7:30pm</div>
                        <div className={classes.regularRow}>31 | Sun Jan 2 | Stars vs Scorchers | 3:30pm</div>
                        <div className={classes.regularRow}>32 | Sun Jan 2 | Thunder vs Strikers | 7:15pm</div>
                        <div className={classes.regularRow}>33 | Mon Jan 3 | Stars vs Renegades | 7:15pm</div>
                        <div className={classes.regularRow}>34 | Tue Jan 4 | Heat vs Sixers | 7:15pm</div>
                        <div className={classes.regularRow}>35 | Wed Jan 5 | Strikers vs Hurricanes | 6:05pm</div>
                        <div className={classes.regularRow}>36 | Wed Jan 5 | Scorchers vs Thunder | 9:15pm</div>
                        <div className={classes.regularRow}>37 | Thu Jan 6 | Renegades vs Heat | 6:05pm</div>
                        <div className={classes.regularRow}>38 | Thu Jan 6 | Scorchers vs Sixers | 9:15pm</div>
                        <div className={classes.regularRow}>39 | Fri Jan 7 | Strikers vs Stars | 7:15pm</div>
                        <div className={classes.regularRow}>40 | Sat Jan 8 | Renegades vs Thunder | 6:05pm</div>
                        <div className={classes.regularRow}>41 | Sat Jan 8 | Heat vs Hurricanes | 8:30pm</div>
                        <div className={classes.regularRow}>42 | Sun Jan 9 | Sixers vs Scorchers | 7:15pm</div>
                        <div className={classes.regularRow}>43 | Mon Jan 10 | Stars vs Strikers | 2:10pm</div>
                        <div className={classes.regularRow}>44 | Mon Jan 10 | Hurricanes vs Thunder | 7:15pm</div>
                        <div className={classes.regularRow}>45 | Tue Jan 11 | Renegades vs Sixers | 7:15pm</div>
                        <div className={classes.regularRow}>46 | Wed Jan 12 | Heat vs Strikers | 7:15pm</div>
                        <div className={classes.regularRow}>47 | Thu Jan 13 | Thunder vs Hurricanes | 4pm</div>
                        <div className={classes.regularRow}>48 | Thu Jan 13 | Renegades vs Stars | 7:30pm</div>
                        <div className={classes.regularRow}>49 | Fri Jan 14 | Strikers vs Scorchers | 8:15pm</div>
                        <div className={classes.regularRow}>50 | Sat Jan 15 | Sixers vs Thunder | 7:40pm</div>
                        <div className={classes.regularRow}>51 | Sun Jan 16 | Stars vs Heat | 7:40pm</div>
                        <div className={classes.regularRow}>52 | Mon Jan 17 | Hurricanes vs Renegades | 7:40pm</div>
                        <div className={classes.regularRow}>53 | Tue Jan 18 | Strikers vs Sixers | 7:15pm</div>
                        <div className={classes.regularRow}>54 | Wed Jan 19 | Heat vs Scorchers | 1:30pm</div>
                        <div className={classes.regularRow}>55 | Wed Jan 19 | Thunder vs Renegades | 4:40pm</div>
                        <div className={classes.regularRow}>56 | Wed Jan 19 | Stars vs Hurricanes | 7:50pm</div>
                        <div className={classes.regularRow}>57 | Fri Jan 21 | The Eliminator | TBC</div>
                        <div className={classes.regularRow}>58 | Sat Jan 22 | The Qualifier | TBC</div>
                        <div className={classes.regularRow}>59 | Sun Jan 23 | The Knockout | TBC</div>
                        <div className={classes.regularRow}>60 | Wed Jan 26 | The Challenger | TBC</div>
                        <div className={classes.regularRow}>61 | Fri Jan 28 | The Final | TBC</div>
                    </li>

                    <li className={classes.mainList}>Entry to each promotion is open for new entrants up until 90
                        minutes after the match commences. Entrants who have entered before this time will be able to
                        continue playing until the match is complete.
                    </li>

                    <li className={classes.mainList}>Directors, managers, agents, employees of the Promoter Group and
                        their immediate families are not eligible to win any prizes resulting from this promotion.
                    </li>

                    <li className={classes.mainList}>There are 61 promotion periods. The prizes for each promotion
                        period are a share of $1000 in Bonus Cash, distributed as follows:
                        <ol className={classes.secondaryListWrapper}>
                            <li className={classes.secondaryList}>1<sup>st</sup> and 2<sup>nd</sup> place - $100 in
                                Bonus
                                Cash each
                            </li>
                            <li className={classes.secondaryList}>3<sup>rd</sup> to 6<sup>th</sup> place - $75 in Bonus
                                Cash
                                each
                            </li>
                            <li className={classes.secondaryList}>7<sup>th</sup> – 12<sup>th</sup> place - $50 in Bonus
                                Cash
                                each
                            </li>
                            <li className={classes.secondaryList}>13<sup>th</sup> – 20<sup>th</sup> - $25 in Bonus Cash
                                each
                            </li>
                        </ol>
                    </li>

                    <li className={classes.mainList}>The following conditions apply to each prize:
                        <ol className={classes.secondaryListWrapper}>
                            <li className={classes.secondaryList}>The prize is limited to the items listed in clause 8,
                                and any additional costs or
                                charges must be paid by the winner;
                            </li>
                            <li className={classes.secondaryList}>The prize may only be claimed by persons who are 18
                                years of age or older;
                            </li>
                            <li className={classes.secondaryList}>The prize winner must do everything required of them
                                to accept and take delivery
                                of the prize within a reasonable period of time;
                            </li>
                            <li className={classes.secondaryList}>If the prize winner fails to comply with clause 9(c),
                                their right to the prize will be
                                forfeited and they will not be compensated in any way;
                            </li>
                            <li className={classes.secondaryList}>If the prize (or a component of it) is not available
                                for any reason (including due to
                                COVID-19 restrictions or other impacts), we reserve the right to substitute the prize
                                with another of similar value, or at our discretion, with a cash sum equivalent to the
                                retail value of that component of the prize; and
                            </li>
                            <li className={classes.secondaryList}>The right to the prize is not transferable or
                                assignable to another person, or redeemable for cash (other than in accordance with
                                clause 9(e)).
                            </li>
                        </ol>
                    </li>

                    <li className={classes.mainList}>Points will be accumulated in the following three ways:
                        <ol className={classes.secondaryListWrapper}>
                            <li className={classes.secondaryList}>Each run scored by your selected batter after they
                                have been selected by you accumulates 15 points;
                            </li>
                            <li className={classes.secondaryList}>Questions answered correctly accumulates the following
                                points:
                                <ol className={classes.thirdlyListWrapper}>
                                    <li className={classes.thirdlyList}>Question with 2 answer choices – 50 points</li>
                                    <li className={classes.thirdlyList}>Question with 3 answer choices – 100 points</li>
                                    <li className={classes.thirdlyList}>Questions with 4 answer choices – 150 points
                                    </li>
                                </ol>

                            </li>
                            <li className={classes.secondaryList}>Your selected batter getting out is a deduction of 100
                                points.
                            </li>
                        </ol>
                    </li>

                    <li className={classes.mainList}>The winning entry for each promotion period will be decided at the
                        completion of each match outlined in clause 4 above. The top 20 place getters will each win a
                        prize. In the event of a tied position, an entrant’s finishing position will be determined by
                        their average time to answer each question, with the entrant with the lower average time taking
                        the higher position in the leader board.
                    </li>

                    <li className={classes.mainList}>The prizes will be deposited into the prize winner’s Ladbrokes
                        account within fourteen (14) days of the promotion period. The Promoter’s decision as to the
                        result of the prizes is final and no correspondence will be entered into.
                    </li>

                    <li className={classes.mainList}>The retail value for each promotion period is $1,000. The retail
                        value for the whole promotion period is $61,000.
                    </li>

                    <li className={classes.mainList}>Where a match is cancelled or abandoned prior to it commencing or
                        where a match commences but is latter cancelled or abandoned and there is no match winner, the
                        competition for that Promotion Period will also be cancelled and there will be no prize winners.
                        Where a match is affected by weather (such as reduced overs), the top 20 place getters will be
                        determined at the completion of the match. For the avoidance of doubt a match that is affected
                        by weather (but where there is still a match winner) shall not affect any of the rules as set
                        out in these terms and conditions.
                    </li>

                    <li className={classes.mainList}>The Promoter will attempt to notify the prize winner via email
                        immediately after each match and their names will be published at ladbrokes.com.au within 48
                        hours after the draw time/date. The Promoter may also announce or publish the first initial,
                        surname and suburb or town of the prize winners in any media, including social media.
                    </li>

                    <li className={classes.mainList}>The prizes are subject to Ladbrokes terms and conditions including
                        the claiming and use of any Bonus Cash coupons. If required, the Promoter may determine such
                        further prize winners at the same place as the original determination as and when required, in
                        order to distribute any unclaimed prizes as determined by the Promoter in its absolute
                        discretion, subject to any directions given by a responsible authority under any relevant
                        regulation. The Promoter will publish the winner of any such unclaimed prize determination 48
                        hours after the original determination.
                    </li>

                    <li className={classes.mainList}>The Promoter reserves the right, at any time, to verify the
                        validity of any entry or disqualify any entrant who submits an entry that is not in accordance
                        with these terms and conditions or who tampers with the entry process. The decision to verify,
                        validate or disqualify an entry is in the Promoter’s absolute discretion.
                    </li>

                    <li className={classes.mainList}>If the Promoter reasonably considers that a change to these terms
                        and conditions is likely to benefit entrants or be immaterial detriment to entrants, then the
                        Promoter can make the change immediately and does not need to notify entrants. Alternatively,
                        the Promoter may vary these terms and conditions by placing a notification at all places where
                        these terms and conditions are made available to entrants.
                    </li>

                    <li className={classes.mainList}>The Promoter accepts no responsibility for late, lost or
                        misdirected entries. The Promoter is not responsible for any problems or technical malfunction
                        of any computer system, software or internet difficulties, including any damage to an entrant’s
                        device, resulting from participating in the promotion. Any cost associated with participating in
                        the promotion are the entrant’s responsibility.
                    </li>

                    <li className={classes.mainList}>The Promoter will not be liable for any loss or damage or personal
                        injury suffered or sustained in relation to the promotion or a prize except for any liability
                        which cannot be excluded by law.
                    </li>

                    <li className={classes.mainList}>If the Promoter is prevented from or delayed in performing an
                        obligation by Force Majeure then the obligation is suspended during, but for no longer than, the
                        period the Force Majeure continues and any further period that is reasonable in the
                        circumstances. ‘Force Majeure’ means any event beyond the reasonable control of the Promoter,
                        which occurs without the fault or negligence of the Promoter.
                    </li>

                    <li className={classes.mainList}>Subject to obtaining the approval from the regulator (if
                        applicable), the Promoter reserves the right to delay or modify any aspect of this promotion if
                        for any reason it is not capable or running as planned (including without limitation, by reason
                        of infection by computer virus, bugs, tampering, fraud, technical failures, power failures or
                        due to any other causes unforeseen or beyond the reasonable control of the Promoter which
                        corrupt or affect the administration, security, fairness, integrity or proper conduct of the
                        promotion) but only to the extent necessary to overcome the issue that is affecting the running
                        of the promotion as planned.
                    </li>

                    <li className={classes.mainList}>By participating in the promotion, an entrant authorises the
                        Promoter Group , contractors and agents of the Promoter Group, to access, collect and use
                        personal information about the entrant, and disclose personal information about the entrant, to
                        the Promoter Group, Pickfans Pty Ltd, their contractors and agents for the purposes of (i)
                        conducting the promotion, (ii) providing the entrant with information and marketing material
                        about products and services of the Promoter Group, and (iii) making arrangements for a prize
                        winner to be notified and receive their prize. Such information may be provided electronically,
                        or by contacting the entrant by telephone or other means, until such time as the entrant asks
                        the Promoter not to provide further information. The Promoter’s Privacy Policy (available at
                        www.ladbrokes.com.au/terms-and-conditions/) contains information about how to access, correct or
                        make a complaint about the Promoter’s handling of personal information. A prize winner may be
                        asked by the Promoter to participate in publicity associated with the promotion. The prize
                        winner will have the right to elect whether or not to participate in that publicity.
                    </li>

                    <li className={classes.mainList}>If an entrant does not provide the personal information requested,
                        the entrant cannot participate in the promotion and will not receive information about products
                        and services of the Promoter Group. An entrant can request access to information held about them
                        by contacting the Promoter at privacy@entaingroup.com.au.
                    </li>

                    <li className={classes.mainList}>All references to time in these terms and conditions are a
                        reference to local Queensland time, unless expressly stated otherwise.
                    </li>

                    <li className={classes.mainList}>We have obtained the following permits for the promotion: NSW
                        permit No. NTP/02989 and ACT permit No. 21/02096.1.
                    </li>
                </ol>
            </div>
        </div>
    )
};

export default Index;