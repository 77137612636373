import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import LeaderboardEntry from "../shared/LeaderboardEntry";
import {useEntry} from "../../../contexts/cricket/Entry";
import {makeNameString} from '../../../utils';
import {Score} from "../../../../../shared/types";
import {useAuth} from "../../../contexts/Auth";
import {usePrevious} from "../../../hooks";
import AnimatedCell from '../../common/AnimatedCell';
import Abandoned from '../../abandoned';
import {useGameStatus} from "../../../contexts/cricket/GameStatus";
import EmptyLeaderboard from '../shared/EmptyLeaderboard';
import emptyleaderboard from "../../../assets/emptyleaderboard.png";
import TiedText from '../shared/TiedText';
import TopTitles from '../shared/TopTitles';
import BackComponent from './BackComponent';

const styles = makeStyles((theme: Theme) => ({
    userDataWrapper: {
        margin: '50px auto 0',
        width: '100%',
        position: 'relative',
        zIndex: 1,
        '@media only screen and (min-width: 1025px)': {
            padding: 0,
            margin: '0px auto',
        }
    },
    userData: {
        width: '100%',
        padding: '15px 10px 10px',
        background: theme.palette.primary.main,
        '@media only screen and (min-width: 1025px)': {
            borderRadius: 0
        }
    },
    justifyRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    flex: {
        display: 'flex',
        overflow: 'visible',
        '& > div': {
            overflow: 'visible',
        }
    },
    points: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.grey[400]
    },
    ballsWrapper: {
        '&>img': {
            width: 15,
            height: 15,
            marginRight: 5
        },
    },
    regular: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.primary.contrastText
    },
    scoreRow: {
        width: '100%',
        padding: '5px 20px',
        display: 'flex',
        justifyContent: 'space-around'
    },
    column: {},
    rank: {},
    title: {
        fontSize: 14,
        width: 120,
        textAlign: 'left'
    },
    starWrapper: {
        position: 'relative'
    },
    ratingStar: {
        display: 'block',
        width: 25,
        height: 25
    },
    rating: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scale(1)',
        fontSize: 13,
        fontWeight: 800,
        textShadow: '0 3px 6px #000',
    },
    scores: {
        fontSize: 17,
        fontWeight: 'bold',
        height: 25,
        color: theme.palette.grey[400]
    },
    bonus: {
        fontSize: 17,
        fontWeight: 'bold',
        height: 25
    },
    mainWrapper: {
        maxWidth: 550,
        margin: 'auto',
        width: '100%',
        padding: '10px 15px 15px',
        position: 'relative',
        zIndex: 1
    },
    divider: {
        width: '100%',
        height: 1,
        background: 'rgba(255, 255, 255, 0.2)',
        margin: '6px 0'
    },
    subTitle: {
        fontSize: 14,
        textAlign: 'left',
        paddingBottom: 10
    },
    winnerDivider: {
        width: '100%',
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center'
    },
    line: {
        width: '100%',
        height: 2,
        background: theme.palette.primary.contrastText
    },
    winnerTitle: {
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
        padding: '0 5px',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
    },
    batsmanName: {
        display: 'block',
        fontWeight: 700,
        textAlign: 'left',
        paddingBottom: 5
    },
    topTitlesTop: {
        display: 'grid',
        gridTemplateColumns: '1fr 70px 70px',
        fontSize: 9,
        color: theme.palette.primary.contrastText,
        textTransform: 'uppercase',
        '& > span:nth-child(1)': {
            textAlign: 'left'
        },
        '& > span:nth-child(2)': {
            textAlign: 'right',
            paddingRight: 10
        },
        '& > span:nth-child(3)': {
            textAlign: 'right'
        },

    },
    userScores: {
        display: 'grid',
        gridTemplateColumns: '1fr 70px 70px',
        fontSize: 12,
        '& > span:nth-child(1)': {
            textAlign: 'left'
        },
        '& > div:nth-child(2)': {
            textAlign: 'right'
        },
        '& > span:nth-child(3)': {
            textAlign: 'right'
        },
    },
    username: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
        paddingRight: 5
    },
    userTitles: {
        '& > $topTitles': {
            gridTemplateColumns: '50px 144px 50px 70px',
            paddingLeft: 0,
            '& > span': {
                textAlign: 'left'
            }
        }
    },
    userRank: {
        fontWeight: 700,
    },
    userSpan: {
        textAlign: 'left',
    },
    wrapper: {
        position: 'relative',
        height: '100vh',
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    topWrapper: {
        background: theme.palette.primary.main,
    },
    loadingPart: {
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    currentPoints: {
        marginLeft: 5,
        fontWeight: 'bold',
        fontSize: 13,
        opacity: 0,
        width: 25
    },
    positive: {
        color: theme.palette.grey[100]
    },
    negative: {
        color: theme.palette.grey[200]
    },
    show: {
        animation: `$show 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    '@keyframes show': {
        '0%': {
            opacity: 0,
            transform: 'scale(1)',

        },
        '50%': {
            opacity: 1,
            transform: 'scale(1.7)',
        },
        '100%': {
            opacity: 1,
            transform: 'scale(1.1)',
        },
    },
    '@keyframes hide': {
        '0%': {
            opacity: 1,
            transform: 'scale(1.1)',

        },
        '100%': {
            opacity: 0,
            transform: 'scale(1)',
        },
    },
    hide: {
        animation: `$hide 0.2s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    poinWrapper: {
        position: 'relative'
    },
    padding: {
        paddingTop: 70
    },
    rankRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: 10,
        fontSize: 14,
        '& > span:last-child': {
            fontWeight: 700,
            fontSize: 14,
            cursor: 'pointer'
        },
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    rankPaddings: {
        padding: '0 3px'
    },
    lockout: {
        display: 'block',
        width: 180,
        height: 180,
        margin: '40px auto',
        '@media only screen and (max-width: 1024px)': {
            width: 170,
            height: 170,
            margin: '20px auto',
        }
    },
}));

interface Props {
    prevPageForLeaderboard: string
    backToGame: () => void
    setShowLeaderboard: (s: boolean) => void
    list: Score[]
    entryCount: number
    isAnyTie: boolean
    entryId: number
}

const Leaderboard = (props: Props) => {
    const classes = styles();
    const {prevPageForLeaderboard, backToGame, setShowLeaderboard, list, entryCount, isAnyTie, entryId} = props;
    const [showDifference, setShowDifference] = useState(false);
    const [savedDifference, setSavedDifference] = useState(0);

    const {entry, isEntryLoaded} = useEntry();
    let timeout: any = null;

    const {currentBatsman} = entry;

    const {score} = entry;
    const {quizPoints, runPoints, rank, points, wicketPoints, winnings, difference} = score;

    const prevPoints = usePrevious(points);
    const prevRank = usePrevious(rank);
    const prevQuizPoints = usePrevious(quizPoints);
    const prevRunPoints = usePrevious(runPoints);
    const prevWicketPoints = usePrevious(wicketPoints);
    const prevWinnings = usePrevious(winnings);

    const {username} = useAuth();
    const {gameStatus} = useGameStatus();

    const [abandonedGame, setAbandonedGame] = useState(false);

    useEffect(() => {
        setAbandonedGame(gameStatus.contestStatus === 'ABANDONED')
    }, [gameStatus]);

    useEffect(() => {
        if (difference && difference !== 0) {
            setShowDifference(true);
            setSavedDifference(difference);

            timeout = setTimeout(() => {
                setShowDifference(false);
            }, 2000);
        }
    }, [difference]);

    return (

        <>
            {abandonedGame ?
                <div className={classes.wrapper}>
                    <Abandoned isAbsolute={true} isLimitedWidth={false}/>
                </div> : null}

            {list.length > 0 ?
                <>
                    <div className={classes.topWrapper}>
                        <BackComponent backToGame={backToGame}/>

                        {isEntryLoaded ?
                            <div className={classes.userDataWrapper}>
                                <div className={classes.userData}>

                                    <div className={classes.rankRow}>
                                            <span>RANK:
                                                <AnimatedCell
                                                    valueOne={prevRank}
                                                    valueTwo={rank}
                                                    classname={classes.rankPaddings}
                                                >
                                                    {rank}
                                            </AnimatedCell>
                                                of {entryCount}</span>

                                        {entryId != -1 ?
                                            <span onClick={() => setShowLeaderboard(false)}>View Answered</span>
                                            : null}
                                    </div>

                                    <div className={classes.userTitles}>
                                        <div className={classes.topTitlesTop}>
                                            <span>username</span>
                                            <span>score</span>
                                            <span>bonus cash</span>
                                        </div>
                                    </div>

                                    <div className={classes.divider}/>

                                    <div className={classes.userScores}>
                                        <span className={classes.username}>{username}</span>

                                        <div className={classes.poinWrapper}>
                                            <span className={classNames(classes.userSpan)}>{points}</span>

                                            <span
                                                className={classNames(classes.currentPoints, savedDifference! > 0 ?
                                                    classes.positive : classes.negative,
                                                    showDifference ? classes.show : classes.hide)}>
                                                    {difference! > 0 ? '+' + savedDifference : savedDifference}
                                                </span>
                                        </div>

                                        <AnimatedCell
                                            valueOne={prevWinnings}
                                            valueTwo={winnings}
                                            classname={classNames(classes.userSpan)}
                                        >
                                            ${winnings}
                                        </AnimatedCell>
                                    </div>

                                    <div className={classes.divider}/>


                                    <div className={classes.justifyRow}>
                                        <div className={classes.flex}>
                                            <span className={classes.title}>Quiz Points&nbsp;</span>
                                            <AnimatedCell
                                                valueOne={prevQuizPoints}
                                                valueTwo={quizPoints}
                                                classname={classNames(classes.regular)}
                                            >
                                                {quizPoints}
                                            </AnimatedCell>
                                        </div>
                                    </div>
                                    <div className={classes.justifyRow}>
                                        <div className={classes.flex}>
                                            <span className={classes.title}>Runs Points&nbsp;</span>
                                            <AnimatedCell
                                                valueOne={prevRunPoints}
                                                valueTwo={runPoints}
                                                classname={classNames(classes.regular)}
                                            >
                                                {runPoints}
                                            </AnimatedCell>
                                        </div>
                                    </div>
                                    <div className={classes.justifyRow}>
                                        <div className={classes.flex}>
                                            <span className={classes.title}>Wickets Points&nbsp;</span>
                                            <AnimatedCell
                                                valueOne={prevWicketPoints}
                                                valueTwo={wicketPoints}
                                                classname={classNames(classes.regular)}
                                            >
                                                {wicketPoints}
                                            </AnimatedCell>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                    </div>

                    <div className={classNames(classes.mainWrapper, !isEntryLoaded ? classes.padding : null)}>

                        {list.length > 0 &&
                        <>
                            <div className={classes.winnerDivider}>
                                <span className={classes.line}/>
                                <span
                                    className={classes.winnerTitle}>
                                        {gameStatus.contestStatus === 'COMPLETED' ? 'WINNERS' : 'Leading Players'}
                                    </span>
                                <span className={classes.line}/>
                            </div>

                            {isAnyTie ?
                                <TiedText/>
                                : null}

                            <TopTitles/>


                            {list.map((score: Score, i: number) => (
                                <LeaderboardEntry key={i} score={score} username={username} userScore={false}/>
                            ))}
                        </>
                        }
                    </div>
                </>
                :
                <>
                    <BackComponent backToGame={backToGame}/>
                    <EmptyLeaderboard backToGame={backToGame} prevPageForLeaderboard={prevPageForLeaderboard}
                                      titleText='No Entries'>
                        <img src={emptyleaderboard} alt="empty" className={classes.lockout}/>
                    </EmptyLeaderboard>
                </>
            }
        </>


    )

};

export default Leaderboard;