import {gql} from '@apollo/client';

export const NRL_ENTAIN_ADMIN = gql`
    query getEntainList {
        nrl {
           previous {
               id
               status
               startTime
               fixture {
                    id
                    homeTeam {
                        id
                        externalId
                        name
                    }
                    awayTeam {
                        id
                        externalId
                        name
                    }
               }
           }
        }
    }
`;