import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import NotifyMeButton from '../../../common/UI/NotifyMeButton';
import NotifiedText from '../../../common/UI/NotifiedText';
import clock from "../../../../assets/Clock.png";
import {AFL_HAS_REQUESTED_NOTIFICATION} from "../../../../apollo/afl/queries/HasRequestedNotification";
import {NRL_HAS_REQUESTED_NOTIFICATION} from "../../../../apollo/nrl/queries/HasRequestedNotification";
import {useQuery} from "@apollo/client";
import {AflNrlGameState} from "../../../../../../shared/types";

const styles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: 188
    },
    title: {
        fontSize: 14,
        fontWeight: 900,
        textAlign: 'center'
    },
    progress: {
        textAlign: 'center',
        lineHeight: '55px',
        fontSize: 35,
        animationDirection: 'reverse',
        position: 'relative',
        borderRadius: 6,
        backgroundColor: theme.palette.primary.dark,
        boxShadow: '0 1px 3px ' + theme.palette.primary.light,
        overflow: 'hidden',
        height: 10,
        top: 0,
        left: -1,
    },
    progressBar: {
        width: '100%',
        transitionDuration: '0.5s',
        transitionProperty: 'background-color',
        background: '#00FF6C',
        height: '100%',
        borderRadius: 6,
    },
    time: {
        fontSize: 16,
        fontWeight: 900,
        padding: '20px 0 10px',
        textAlign: 'center'
    },
    clock: {
        display: 'block',
        width: 20,
        height: 20
    },
    progressWrapper: {
        display: 'grid',
        gridTemplateColumns: '35px 1fr',
        alignItems: 'center'
    },
}));

interface Props {
    sport: string
    gameState: AflNrlGameState
    showNotifyMeButton: boolean
}

let seconds = 0;
const cacheTimer: any = {};

interface MyTime {
    initialMinutes: number,
    countdownSeconds: number
}

const GameInProgress = (props: Props) => {
    const classes = styles();
    const {gameState, showNotifyMeButton} = props;
    const [pressedNotifiedBtn, setPressedNotifiedBtn] = useState(false);
    const [progress, setProgress] = React.useState(0);
    const [minutesLeft, setMinutesLeft] = React.useState(0);
    const [isBtnStatusLoaded, setIsBtnStatusLoaded] = useState(false);

    let myTime: MyTime | undefined;


    const query = props.sport === 'AFL' ? AFL_HAS_REQUESTED_NOTIFICATION : NRL_HAS_REQUESTED_NOTIFICATION;

    const {loading, error, data} = useQuery(query, {
        variables: {
            blockId: gameState.currentBlock,
            blockNumber: gameState.period + 1,
        }
    });

    useEffect(() => {
        if (data && !loading && !error) {
            const way = props.sport === 'AFL' ? data.afl.hasRequestedNotification : data.nrl.hasRequestedNotification;
            setPressedNotifiedBtn(way);
            setIsBtnStatusLoaded(true)
        }
    }, [data]);

    useEffect(() => {
        if (gameState) {
            seconds = gameState.timeTillNextBlock;
            if (!cacheTimer[gameState.currentBlock]) {

                cacheTimer[gameState.currentBlock] = {
                    initialMinutes: seconds * 1000,
                    countdownSeconds: seconds * 1000,
                }
            }
            myTime = cacheTimer[gameState?.currentBlock]

            const minutesTillNextBlock = Math.floor(seconds / 60);

            if (myTime!.initialMinutes !== -1) {
                setMinutesLeft(minutesTillNextBlock);
            }

            const timer = setInterval(() => {
                if (myTime!.countdownSeconds > 0) {
                    myTime!.countdownSeconds = myTime!.countdownSeconds - 10;

                    const countedProgress = Math.abs((((myTime!.countdownSeconds / myTime!.initialMinutes)) * 100));
                    setProgress(countedProgress > 0 ? countedProgress : 0);
                } else {
                    clearInterval(timer);
                }
            }, 10);


            return () => clearInterval(timer);
        }
    }, [gameState]);

    const pluralSingular = (m: number) => {
        return `${m + (m === 1 ? ' Minute' : ' Minutes')}`
    }

    const notifyClick = () => {
        setPressedNotifiedBtn(true)
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>This round is finished.<br/>New questions in:</div>

            <div className={classes.time}>Est. {pluralSingular(minutesLeft)}</div>

            <div className={classes.progressWrapper}>
                <img src={clock} alt="clock" className={classes.clock}/>
                <div className={classes.progress}>
                    <div className={classes.progressBar} id='progress-bar'
                         style={{width: progress + '%', backgroundColor: '#00FF6C'}}/>
                </div>
            </div>

            {
                pressedNotifiedBtn ?
                    <NotifiedText/>
                    :
                    showNotifyMeButton ?
                        <NotifyMeButton click={notifyClick} blockId={gameState.currentBlock}
                                        blockNumber={gameState.period + 1} isListPage={false}/>
                        : null
            }
        </div>
    )
};

export default GameInProgress;