import {gql} from '@apollo/client';

export const QUESTION_QUERY = gql`
    query Questions($contestId: Int!) {
        questions(contestId: $contestId) {
            id
            text
            outcomes {
                id
                count
                text
                selected
            }
            start
            end
            isAnswered
        }
        
    }
`;