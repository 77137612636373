import React, {useCallback, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import FixtureCardTopPart from './FixtureCardTopPart';
import FixtureButtons from './FixtureButtons';
import PlayButton from './PlayButton';
import LeaderboardButton from './LeaderboardButton';
import ResultButton from './ResultButton';
import {NbaContest} from "../../../apollo/nba/queries/GetContests";
import { useTracking } from '../../../entain/hooks/useTracking';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 17,
        padding: '10px 10px 5px',
        display: 'grid',
        gridTemplateColumns: '1fr 1.2fr 1fr',
        alignItems: 'center',
        background: theme.palette.background.default,
        position: 'relative'
    },
    buttonsWrapper: {
        gridColumnStart: 1,
        gridColumnEnd: 4,
        paddingTop: 5,
    }
}));


interface Props {
    contest: NbaContest
    setPrevPageForLeaderboard: (p: string) => void
    leaderboardButtonClickFunc?: any
    playButtonClickFunc?: any
    resultButtonClickFunc?: any
    setIsLocked?: (l: boolean) => void
    leaderBoardButtonWasClicked?: boolean
    resultButtonWasClicked?: boolean
    playButtonWasClicked?: boolean
    currentBlock?: number
}

const FixtureCardWrapper = (props: Props) => {
    const classes = styles();
    const {
        contest,
        setPrevPageForLeaderboard,
        leaderBoardButtonWasClicked,
        resultButtonWasClicked,
        playButtonWasClicked,
        leaderboardButtonClickFunc,
        playButtonClickFunc,
        resultButtonClickFunc,
    } = props;
    const tracking = useTracking();

    const buttonClickFunc = useCallback((e: any) => {
        playButtonClickFunc(e);

        tracking.event('play button clicked',{
            contestId: contest?.id,
            blockId: contest?.currentBlock,
            fixtureId: contest?.fixture?.id,
            contestName: `${contest?.fixture?.homeTeam?.name} v ${contest?.fixture?.awayTeam?.name}`,
            sport: process.env.REACT_APP_BUILD_TARGET,
        });
    }, [playButtonClickFunc, contest])

    const [status, setStatus] = useState<string | null>(null);

    const playButton = (
        <PlayButton buttonClickFunc={buttonClickFunc} buttonWasClicked={playButtonWasClicked!}/>
    );

    const resultButton = (
        <ResultButton buttonWasClicked={resultButtonWasClicked!} buttonClickFunc={resultButtonClickFunc}/>
    );

    const leaderboardButton = (
        <LeaderboardButton buttonClickFunc={leaderboardButtonClickFunc}
                           buttonWasClicked={leaderBoardButtonWasClicked!}/>
    );

    return (
        <div className={classes.root}>
            <FixtureCardTopPart contest={contest}
                                entryId={undefined}
                                setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                setStatus={setStatus} status={status} color={1}/>

            <div className={classes.buttonsWrapper}>

                <FixtureButtons contestId={contest.id}
                                hasEntered={contest.hasEntered}
                                entryId={undefined}
                                status={status}
                                playButton={playButton}
                                resultButton={resultButton}
                                leaderboardButton={leaderboardButton}
                />
            </div>
        </div>
    )
};

export default FixtureCardWrapper;