import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import scoreIcon from '../../../assets/ScoreIcon.png';
import star from '../../../assets/Rank.png';
import classNames from "classnames";
import {useHistory, useParams} from "react-router";
import {useNrlAflEntry} from "../../../contexts/nrl_afl/Entry";
import {useQuestion} from "../../../contexts/nrl_afl/Question";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '5px 10px',
        boxShadow: '0 3px 6px ' + theme.palette.primary.light,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        overflow: 'visible',
        display: 'grid',
        gridTemplateColumns: '1fr 140px',
        '@media only screen and (max-width: 400px)': {
            maxWidth: 400,
            margin: 'auto',
        },
        position: 'relative',
        zIndex: 20,
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    leftPart: {
        borderRight: '2px solid #2B2C3A',
        padding: '5px 0',
        '& > $row:first-child': {
            paddingBottom: 5
        }
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
    },
    scoreImg: {
        display: 'block',
        width: 22,
        height: 19,
        marginRight: 10
    },
    starImg:{
        display: 'block',
        width: 19,
        height: 19,
        marginRight: 13
    },
    total: {
        textAlign: 'right',
        fontWeight: 700,
        paddingLeft: 5
    },
    rightPart: {
        padding: '5px 0 5px 10px',
        '& > $row': {
            justifyContent: 'flex-end'
        }
    },
    grey: {
        color: theme.palette.grey[300],
        paddingRight: 5
    },
    link: {
        fontSize: 14,
        fontWeight: 700,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    chevron: {
        width: 7,
        height: 7,
        borderBottom: '1px solid ' + theme.palette.primary.contrastText,
        borderRight: '1px solid ' + theme.palette.primary.contrastText,
        transform: 'rotate(-45deg)',
        display: 'block',
        marginLeft: 5,
        marginTop: 2,
        marginRight: 1
    }
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void
}

const TopBar = (props: Props) => {
    const classes = styles();
    const {setPrevPageForLeaderboard} = props;
    const {entry} = useNrlAflEntry();
    const {questionsLeft} = useQuestion()
    const history = useHistory();
    const {contest_id} = useParams<{ contest_id: string }>();

    let points = 0;
    let rank = 1;

    if (entry) {
        rank = entry.rank;
        points = entry.points;
    }

    let timeout: any = null;

    useEffect(() => {
        return () => {
            clearTimeout(timeout)
        }
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.leftPart}>
                <div className={classes.row}>
                    <img src={scoreIcon} alt="score" className={classes.scoreImg}/>
                    Score
                    <span className={classNames(classes.total)}>{points}</span>
                </div>

                <div className={classes.row}>
                    <img src={star} alt="star" className={classes.starImg}/>
                    Rank
                    <span className={classNames(classes.total)}>{rank}</span>
                </div>
            </div>
            <div className={classes.rightPart}>
                <div className={classes.row}>
                    <span className={classes.grey}> Questions Left</span>
                    <span className={classNames(classes.total)}>{questionsLeft >= 0 ? questionsLeft : 0}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.link} onClick={(e) => {
                        e.preventDefault();
                        setPrevPageForLeaderboard('question');

                        history.push(`/${contest_id}/leaderboard` + history.location.search)
                    }}>Leaderboard
                    <span className={classes.chevron}/></span>
                </div>
            </div>
        </div>
    )
};

export default TopBar;