import {gql} from '@apollo/client';

export const GAME_STATUS_SUBSCRIPTION = gql`
    subscription gameStatus($contestId: Int!) {
        gameStatus(contestId: $contestId) {
            contestStatus
            inningStatus
        }
    }
`;

