import {gql} from '@apollo/client';

export const NBA_GET_BLOCK_ID = gql`
    query getNbaContest($id: Int!) {
        nba {
           contest(id: $id) {
               id
               currentBlock
               status
           }
        }
    }
`;