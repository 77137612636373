import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {SelectedCricketer} from '../../../../../../shared/types'

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        maxWidth: 350,
        margin: 'auto',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 8,
        padding: '10px 6px 10px 12px',
        gridTemplateColumns: '50px 1fr',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        position: 'relative'
    },
    active: {
        '&::before': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            background: '#fff',
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: 100,
            opacity: 0.1,
            borderRadius: 8,
            left: 0
        }
    },
    helmet: {
        width: 40,
        display: 'block',
        marginRight: 10
    },
    mainWrapper: {
        width: '100%'
    },
    nameSize: {
        fontSize: 12,
        fontWeight: 700,
    },
    points: {
        fontSize: 12,
        color: '#b1b1b1',
        '& > span': {
            color: theme.palette.primary.contrastText,
            fontWeight: 700,
            paddingLeft: 5
        }
    }
}));

interface Props {
    batsman: SelectedCricketer
}

const YourBatsmanCard = (props: Props) => {
    const classes = styles();
    const {batsman} = props;

    const url = `/helmets/${batsman.teamKey}_left.png`;

    return (
        <div className={classNames(classes.root, (batsman.isOut || batsman.isComplete) ? null : classes.active)}>

            {batsman ?
                <>
                    <img src={url} alt="helmet" className={classNames(classes.helmet)}/>
                    <div className={classes.mainWrapper}>
                        <div className={classes.nameSize}>{batsman.name}</div>
                        <span className={classes.points}>Points <span>{batsman.points}</span></span>
                    </div>
                </>
                : null}
        </div>
    )

};

export default YourBatsmanCard;