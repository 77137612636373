import React, {useEffect, useState} from 'react';
import {makeStyles, Theme, useMediaQuery} from "@material-ui/core";
import AnimatedPlayerImage from '../../../common/AnimatedPlayerImage';
import lost from '../../../../assets/NO-WIN.png';
import {useEntry} from "../../../../contexts/cricket/Entry";
import {useHistory} from "react-router";
import Button from "../../../common/UI/SquareButton";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        position: 'relative',
        textAlign: 'center',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'hidden'
        },
        '& > a': {
            textDecoration: 'none'
        }
    },
    rootInner: {
        padding: '35px 15px',
        maxWidth: 500,
        margin: 'auto',
        position: 'relative',
        zIndex: 9,
        '@media only screen and (max-height: 630px)': {
            padding: '5px 15px',
        }
    },
    avoidClicking:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 425,
        zIndex: 10
    },
    text: {
        maxWidth: '80%',
        fontSize: 24,
        margin: 'auto',
        paddingTop: 20,
        fontWeight: 700,
        marginBottom: 25
    },
    lost: {
        display: 'block',
        width: '90%',
        margin: '50px auto 0'
    }
}));

interface Props {
}

const BrickedBlock = (props: Props) => {
    const classes = styles();
    const {entry} = useEntry()
    const history = useHistory();
    const [buttonWasClicked, setButtonWasClicked] = useState(false);

    return (
        <div className={classes.root}>
            <div className={classes.rootInner}>
                <div className={classes.avoidClicking}/>
                <AnimatedPlayerImage isUserWin={false}/>

                <img src={lost} alt="lost" className={classes.lost}/>

                <div className={classes.text}>There's always<br/> next time</div>

                <Button width='162px' buttonWasClicked={buttonWasClicked}
                        onClick={(e: any) => {
                            if (!buttonWasClicked) {
                                setButtonWasClicked(true);
                                e.preventDefault();

                                history.push(`/` + history.location.search)
                            }
                        }}>
                    Home
                </Button>
            </div>
        </div>
    )
};

export default BrickedBlock;