import { makeStyles, Theme } from '@material-ui/core';

interface Props {
    type: 'OVERALL' | 'LADBROKES'
}

const LeaderboardTopTitles = ({ type }: Props) => {
    const classes = styles();

    return (
        <>
            <div className={classes.topTitles}>
                <span>POS</span>
                <span>Player</span>
                <span>Pts</span>
                <span>{type === 'OVERALL' ? 'Tickets' : 'Bonus Cash'}</span>
            </div>
            <div className={classes.titlesDivider}/>
        </>
    )
};

const styles = makeStyles((theme: Theme) => ({
    topTitles: {
        color: theme.palette.primary.contrastText,
        display: 'grid',
        fontSize: 12,
        gridTemplateColumns: '50px 1fr 70px 90px',
        padding: '0 15px',
        '& > span': {
            textAlign: 'left',
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        },
    },
    titlesDivider: {
        background: '#FFFFFF2B',
        height: 1,
        margin: '5px 0 10px',
        width: '100%',
    },
}));

export default LeaderboardTopTitles;