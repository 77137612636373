import { makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import cashImage from '../../assets/cash.png';
import { LeaderboardEntry } from '../../types';
import { addSuffixToNumber } from '../../utils/utils';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 70px 90px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43,
    },
    overallRoot: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 70px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43,
    },
    pos: {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'left',
    },
    nameWrapper: {
        textAlign: 'left',
        fontSize: 14,
        wordBreak: 'break-all',
    },
    points: {
        fontSize: 14,
        textAlign: 'left',
    },
    winning: {
        fontSize: 14,
        fontWeight: 700,
        paddingLeft: 30,
        position: 'relative',
        textAlign: 'left',
    },
    cashWinning: {
        color: '#6BFE00',
    },
    ticketWinning: {
        color: '#FFC700',
    },
    winningType: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
}));

interface Props {
    entry: LeaderboardEntry & { tied: boolean };
    type: 'OVERALL' | 'LADBROKES';
}

const LeaderboardEntryItem = (props: Props) => {
    const classes = styles();
    const { entry, type } = props;

    return (
        <div className={type === 'OVERALL' ? classes.overallRoot : classes.root}>
            <span className={classes.pos}>
                {addSuffixToNumber(type === 'OVERALL' ? entry.nationalRank : entry.rank)}
            </span>

            <div className={classes.nameWrapper}>{entry.username}</div>

            <span className={classes.points}>
                {entry.tied ? <span>&#65121;</span> : ''}
                {entry.points}
            </span>

            {type !== 'OVERALL' ? (
                <span className={classNames(classes.winning, classes.cashWinning)}>
                    <span className={classes.winningType}>
                        <img src={cashImage} alt="winning-type-icon" />
                    </span>
                    <span>{`$${entry.winnings}`}</span>
                </span>
            ) : null}
        </div>
    );
};

export default LeaderboardEntryItem;
