import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  GetContestDocument,
  GetContestQuery,
  GetContestQueryVariables,
} from "../gql/operations.generated";
import { Contest } from "../types";

export const ContestContext = createContext<{
  contest: Contest | undefined | null;
  isContestLoaded: boolean;
}>({
  contest: null,
  isContestLoaded: false,
});

interface Props {
  children: React.ReactNode;
}

const ContestProvider = ({ children }: Props) => {
  const { contest_id: contestId } = useParams<{ contest_id: string }>();
  const [contest, setContest] = useState<Contest | undefined | null>(null);
  const [isContestLoaded, setIsContestLoaded] = useState<boolean>(false);

  const { data, error, loading } = useQuery<
    GetContestQuery,
    GetContestQueryVariables
  >(GetContestDocument, {
    variables: {
      id: contestId,
    },
  });

  useEffect(() => {
    if (data?.afl && !error && !loading) {
      setContest(data.afl.contest);
      setIsContestLoaded(true);
    }
  }, [data?.afl, error, loading]);

  return (
    <ContestContext.Provider value={{ contest, isContestLoaded }}>
      {children}
    </ContestContext.Provider>
  );
};

const useContest = () => {
  const context = useContext(ContestContext);
  if (context === undefined) {
    throw new Error("useContest must be used within a ContestProvider");
  }

  return context;
};

export { ContestProvider, useContest };
