import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    progress: {
        textAlign: 'center',
        lineHeight: '55px',
        fontSize: 35,
        animationDirection: 'reverse',
        position: 'relative',
        borderRadius: 7,
        backgroundColor: theme.palette.primary.dark,
        boxShadow: '0 1px 3px ' + theme.palette.primary.light,
        overflow: 'hidden',
        width: '100%',
        height: 12,
        top: 0,
        left: 0,
    },
    shrink: {
        background: theme.palette.grey[900],
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        borderRadius: 7,
    },
    questionProgressBarWrapper: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 50px',
        alignItems: 'center',
        paddingBottom: 5
    },
    progressPercentage: {
        fontSize: 16,
        fontWeight: 700,
        textAlign: 'right'
    },
}));

interface Props {
    percentage: number
}

const AnsweredProgressBar = (props: Props) => {
    const classes = styles();

    return (
        <div className={classes.questionProgressBarWrapper}>
            <div className={classes.progress}>
                <div className={classes.shrink} style={{width: props.percentage + '%'}}/>
            </div>
            <div className={classes.progressPercentage}>{props.percentage}%</div>
        </div>
    )
};

export default AnsweredProgressBar;