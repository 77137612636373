import { useEffect, useState } from 'react';
import { SportingPopularSameGameMulti, SportingPopularSameGameMultiPrice } from '../types';
import {
    addMessageListener,
    IncomingMessageTopic,
    OutgoingMessageTopic,
    postMessage,
} from '../utils/react-native-bridge';

export type CompetitionName = 'AFL' | 'NBA' | 'NRL';

export const isOfCompetitionName = (value: string): value is CompetitionName => {
    return ['AFL', 'NBA', 'NRL'].includes(value);
};

export type PSGMsData = {
    psgmPrices: SportingPopularSameGameMultiPrice[];
    psgms: SportingPopularSameGameMulti[];
};

export const useFetchPSGMs = (competitionName: CompetitionName, count: number = 6, pollInterval: number = 60000) => {
    const [data, setData] = useState<PSGMsData>({
        psgmPrices: [],
        psgms: [],
    });
    const [error, setError] = useState<string>('');

    // Add listener for PSGMs response event triggered by React Native
    useEffect(() => {
        const listener = (data: PSGMsData, err?: string) => {
            setData(data);
            if (err) setError(err);
        };

        const removeMessageHandler = addMessageListener(IncomingMessageTopic.FetchPSGMs, listener);

        return () => removeMessageHandler?.();
    }, []);

    // Post message to React Native to fetch PSGMs at the given interval
    useEffect(() => {
        const fetchPSGMs = () =>
            postMessage({
                topic: OutgoingMessageTopic.FetchPSGMs,
                data: { competitionName, count },
            });
        const intervalId = setInterval(fetchPSGMs, pollInterval);
        fetchPSGMs();

        return () => clearInterval(intervalId);
    }, [competitionName, count, pollInterval]);

    return {
        data,
        error,
    };
};
