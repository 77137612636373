import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import OutcomeButton from "./OutcomeButton";
import {useMutation} from "@apollo/client";
import {ANSWER_QUESTION} from "../../../../../apollo/cricket/mutations/AnswerQuestion";
import {useEntry} from "../../../../../contexts/cricket/Entry";
import {Question, Outcome} from '../../../../../../../shared/types';

const styles = makeStyles((theme: Theme) => ({
    root: {
    },
    flexRoot: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 5,
        flexDirection: 'column',
        overflow: 'visible',
        position: 'relative'
    },
    thirdButton: {
        '& > div': {
            marginLeft: 'calc(calc(100% - calc(50% - 10px))/2) !important'
        }
    },
    correctText: {
        color: theme.palette.grey[400],
        fontWeight: 'bold',
        textAlign: 'center'
    },
    incorrectText: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    preventClicksWhileAnimation: {
        position: 'absolute',
        zIndex: 1000,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }
}));

interface Props {
    question: Question
    timeExpired: boolean
    buttonWasClicked: boolean
    setButtonWasClicked: (b: boolean) => void
    setLessThanTenSecs: (l: boolean) => void
    setTimeExpired: (t: boolean) => void
    percentage: number[]
}

const OutcomeBlock = (props: Props) => {
    const classes = styles();
    const {question, timeExpired, buttonWasClicked, setButtonWasClicked, setLessThanTenSecs, setTimeExpired, percentage} = props;
    const [clickedButton, setClickedButton] = useState<number>(-1);

    const {entry} = useEntry();

    const [answerQuestion, {data, loading, error}] = useMutation(ANSWER_QUESTION);

    useEffect(() => {
        return() => {
            setButtonWasClicked(false);
            setLessThanTenSecs(false);
            setTimeExpired(false);
        }
    }, []);

    const onClick = async (id: number, outcomeIdx: number) => {
        if (!buttonWasClicked && !timeExpired && !question.isAnswered) {
            setButtonWasClicked(true);

            setClickedButton(outcomeIdx);
            await answerQuestion({variables: {entryId: entry.id, outcomeId: id}});

        }
    };



    return (
        <div className={classes.flexRoot}>
            {question.outcomes.map((outcome: Outcome, idx: number) => {

                return <OutcomeButton key={outcome.id} onClick={async () => await onClick(outcome.id, idx)}
                                      buttonId={idx}
                                      clickedButtonId={clickedButton}
                                      timeExpired={timeExpired}
                                      outcome={outcome}
                                      percentage={percentage}
                >
                    {outcome.text}
                </OutcomeButton>
            })}

        </div>
    )
};

export default OutcomeBlock;