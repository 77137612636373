import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {NbaEntry} from "../../../../../../shared/types";
import {addSuffixToNumber} from '../../../../utils';
import cashImage from '../../../../assets/nba-cash.png';
import ticketImage from '../../../../assets/nba-ticket.png';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 70px 90px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43
    },
    pos: {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'left'
    },
    nameWrapper: {
        textAlign: 'left',
        fontSize: 14,
        wordBreak: 'break-all'
    },
    points: {
        fontSize: 14,
        textAlign: 'left',
    },
    winning: {
        fontSize: 14,
        fontWeight: 700,
        paddingLeft: 30,
        position: 'relative',
        textAlign: 'left',
    },
    cashWinning: {
        color: '#6BFE00'
    },
    ticketWinning: {
        color: '#FFC700'
    },
    winningType: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
}));

interface Props {
    entry: NbaEntry & { tied: boolean },
    type: 'OVERALL' | 'LADBROKES'
}

const LeaderboardEntry = (props: Props) => {
    const classes = styles();
    const { entry, type } = props;

    return (
        <div className={classes.root}>
            <span className={classes.pos}>{addSuffixToNumber(type === 'OVERALL' ? entry.nationalRank : entry.rank)}</span>

            <div className={classes.nameWrapper}>{entry.username}</div>

            <span className={classes.points}>
                {entry.tied ? <span>&#65121;</span> : ''}
                {entry.points}
            </span>

            <span className={classNames(classes.winning, type === 'OVERALL' ? classes.ticketWinning : classes.cashWinning)}>
                <span className={classes.winningType}><img src={type === 'OVERALL' ? ticketImage : cashImage } alt="winning-type-icon" /></span>
                <span>{type === 'OVERALL' ? entry.nationalWinnings : `$${entry.winnings}`}</span>
            </span>
        </div>
    )
};

export default LeaderboardEntry;
