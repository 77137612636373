import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from 'react';
import { useAuth } from "../../../../contexts/Auth";
import { useTimeDrift } from "../../../../contexts/TimeDrift";
import {
  GetWonCheckDocument,
  GetWonCheckQuery,
  GetWonCheckQueryVariables,
} from "../../gql/operations.generated";
import { Entry } from "../../types";
import Results from "./answered-questions/Results";
import Leaderboards from "./Leaderboards";

interface Props {
  setIsWon: (b: boolean | null) => void;
  activeBlockId: string;
  tab: number;
  isWon: boolean | null;
  activeEntry: Entry | undefined | null;
}

const WinLossCheck = (props: Props) => {
  const { setIsWon, activeBlockId, tab, isWon, activeEntry } = props;
  const { data } = useQuery<GetWonCheckQuery, GetWonCheckQueryVariables>(
    GetWonCheckDocument,
    {
      variables: {
        blockId: activeBlockId,
      },
      pollInterval: 3200,
    }
  );

  const { username } = useAuth();
  const diff = useTimeDrift();
  const now = new Date().getTime() + diff;

  useEffect(() => {
    if (data) {
      const entries = data.afl.leaderboard.entries || [];

      const isUserEntered = entries.find((e) => {
        return e?.username === username;
      });

      if (isUserEntered && isUserEntered.settlementStatus === "WON") {
        setIsWon(true);
      } else if (isUserEntered && isUserEntered.settlementStatus === "LOSS") {
        setIsWon(false);
      } else {
        setIsWon(null);
      }
    }
  }, [setIsWon, username, data]);

  return (
    <>
      {tab === 0 && (
        <Leaderboards
          activeBlockId={activeBlockId}
          activeEntry={activeEntry}
          isWon={isWon}
        />
      )}

      {tab === 1 && (
        <Results
          blockId={activeBlockId}
          points={activeEntry?.points}
          activeEntry={!!activeEntry}
          now={now}
        />
      )}
    </>
  );
};

export default WinLossCheck;
