import {gql} from '@apollo/client';

export const PREVIOUS_CONTESTS = gql`
  query getContests {
    previous {
        id
        status
        estimatedStartTime
        lockOutTime
        fixture {
            id
            venue {
               id
               key
               name
               city
            }
            teamA {
                id
                key
                name
            }
            teamB {
                id
                key
                name
            }
            venue {
               id
               name
            }
     }
        entry {
            id
        }
    }
  }
`;