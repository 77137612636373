import {gql} from '@apollo/client';

export const GET_CONTESTS = gql`
    query getNrlContests {
        nrl {
            contests {
                id
                currentBlock
                status
                startTime
                isLocked
                fixtureId
                hasEntered
                fixture {
                    id
                    externalId
                    status
                    homeTeam {
                        id
                        externalId
                        name
                    }
                    awayTeam {
                        id
                        externalId
                        name
                    }
                    venue {
                        id
                        externalId
                        name
                    }
                }
            }
        }
    }
`;

export interface NrlContest {
    id: number
    currentBlock: number
    hasEntered: boolean
    status: string
    startTime: string
    isLocked: boolean
    fixtureId: number
    fixture: NrlFixture
    __typename: string
}

interface NrlFixture {
    id: number
    externalId: string
    status: string
    homeTeam: NrlTeam
    awayTeam: NrlTeam
    venue?: NrlVenue
    __typename: string
}

interface NrlTeam {
    id: number
    externalId: string
    name: string
    __typename: string
}

interface NrlVenue {
    id: number
    externalId: string
    name: string
    __typename: string
}

