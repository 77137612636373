import { useQuery } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import { useHistory } from "react-router";
import { useAuth } from "../../../../contexts/Auth";
import {
  GetPreviousContestsDocument,
  GetPreviousContestsQuery,
  GetPreviousContestsQueryVariables,
} from "../../gql/operations.generated";
import BackButton from "../common/BackButton";
import FixtureCard from "../common/fixture-card/FixtureCard";
import Layout from "../common/Layout";
import LoadingScreenSpinner from "../common/LoadingScreenSpinner";
import NoEnteredContests from "./NoEnteredContests";

const styles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 16,
    display: "block",
    textAlign: "center",
    justifySelf: "center",
    position: "relative",
    top: 12,
  },
  back: {
    height: 50,
    position: "absolute",
    "@media only screen and (min-width: 1025px)": {
      top: 5,
      left: 0,
      width: "100%",
    },
    "@media only screen and (max-width: 1024px)": {
      top: 0,
      left: 0,
      width: "100%",
    },
  },
  inner: {
    padding: "60px 30px 40px",
    position: "relative",
    overflow: "visible",
    "@media only screen and (min-width: 1025px)": {
      overflow: "auto",
    },
    "& > $title": {
      top: -48,
    },
  },
}));

interface Props {
  setPrevPageForLeaderboard: (p: string) => void;
}

function Index(props: Props) {
  const classes = styles();
  const { isAuth } = useAuth();
  const { setPrevPageForLeaderboard } = props;
  const history = useHistory();

  const { loading, error, data } = useQuery<
    GetPreviousContestsQuery,
    GetPreviousContestsQueryVariables
  >(GetPreviousContestsDocument, {
    skip: !isAuth,
  });

  if (!loading && !error && data) {
    const dataReceived = data.afl.previous;

    return (
      <Layout>
        <div className={classes.inner}>
          <div className={classes.back}>
            <BackButton
              onClick={() => history.push(`/` + history.location.search)}
            >
              Back
            </BackButton>
          </div>
          <div className={classes.title}>Previous Contests</div>

          {dataReceived.length > 0 ? (
            <>
              {dataReceived.map((contest) => (
                <FixtureCard
                  contest={contest}
                  key={contest.id}
                  setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                />
              ))}
            </>
          ) : (
            <NoEnteredContests />
          )}
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={classes.back}>
        <BackButton onClick={() => history.push(`/` + history.location.search)}>
          Back
        </BackButton>
      </div>
      <div className={classes.title}>Previous Contests</div>
      <LoadingScreenSpinner />
    </Layout>
  );
}

export default Index;
