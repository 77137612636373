import React, {useEffect, useState} from 'react';
import BackButton from '../../common/UI/BackButton';
import {makeStyles, Theme} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {ANSWERED_QUESTIONS} from "../../../apollo/cricket/queries/AnsweredQuestions";
import LoadingScreenBall from '../../common/cricketLoaders/LoadingScreeenBall'
import classNames from "classnames";
import NoAnsweredQuestions from './NoAnsweredQuestions';

const styles = makeStyles((theme: Theme) => ({
    mobTitle: {
        display: 'none',
        '@media only screen and (max-width: 1024px)': {
            display: 'block',
            fontSize: 18,
            fontWeight: 700,
            padding: '0px 0 10px',
            boxShadow: '0 3px 6px ' + theme.palette.primary.light,
            position: 'relative',
            zIndex: 2,
            top: -38
        }
    },
    back: {
        position: 'absolute',
        width: '100%',
        background: theme.palette.primary.main,
        height: 50
    },
    wrapperForBall: {
        position: 'relative',
        paddingTop: 50
    },
    eachQuestion: {
        width: '100%',
        borderRadius: 10,
        background: theme.palette.primary.main,
        padding: '7px 15px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 15,
        minHeight: 43
    },
    wrapper: {
        padding: '70px 12px 0'
    },
    questionText: {
        textAlign: 'left',
        fontSize: 14,
        width: '100%',
        fontWeight: 700,
        paddingBottom: 5
    },
    bottomRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14
    },
    answer: {
        '& > span:last-child': {
            fontWeight: 700
        }
    },
    points: {
        fontWeight: 700,
        color: '#a8a8a8'
    },
    correct: {
        color: theme.palette.grey[100]
    },
    incorrect: {
        color: '#ff6072'
    },
    questionTime: {
        textAlign: 'left',
        marginBottom: 5,
        fontSize: 12,
        color: '#B1B1B1'
    }
}));

interface Props {
    backToGame: () => void
    contestId: number
    entryId: number
}

const AnsweredQuestions = (props: Props) => {
    const {backToGame, contestId, entryId} = props;
    const classes = styles();

    const {data, loading, error, startPolling, stopPolling} = useQuery(ANSWERED_QUESTIONS, {
        variables: {
            contestId,
            entryId
        },
        fetchPolicy: "cache-and-network"
    });

    const convertUnixTimestampToString = (time: string) => {
        const datetime = new Date(Number(time))

        return `${datetime.toTimeString().split(' ')[0]}`
    }

    useEffect(() => {
        startPolling(5000);
        return () => {
            stopPolling();
        }
    }, [startPolling, stopPolling]);

    if (data) {
        return (
            <>
                <div className={classes.back}>
                    <BackButton onClick={() => backToGame()}
                                hideOnDesk={true}>Back</BackButton>

                    <div className={classes.mobTitle}>Answered Questions</div>
                </div>

                <div className={classes.wrapper}>

                    {data.previousQuestions.length > 1 ?

                        data.previousQuestions.map((q: any) => {
                            const selectedOutcome = q.outcomes.filter((o: any) => o.selected);
                            const outcomeText = selectedOutcome[0].text;
                            const isPending = selectedOutcome[0].correct === null;
                            const isCorrect = selectedOutcome[0].correct;

                            return <div key={q.id} className={classes.eachQuestion}>

                                <div className={classes.questionTime}>Question asked: {convertUnixTimestampToString(q.start)}</div>
                                <div className={classes.questionText}>{q.text}</div>

                                <div className={classes.bottomRow}>
                                    <div className={classes.answer}>
                                        <span>Your Answer: </span>
                                        <span>{outcomeText}</span>
                                    </div>

                                    {!isPending ?
                                        isCorrect ?
                                            <div className={classNames(classes.points, classes.correct)}>+ 50
                                                points</div>
                                            : <div className={classNames(classes.points, classes.incorrect)}>No Points</div>
                                        : <div className={classes.points}>Pending</div>
                                    }

                                </div>
                            </div>
                        })

                        : <NoAnsweredQuestions/>
                    }
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className={classes.back}>
                    <BackButton onClick={() => backToGame()}
                                hideOnDesk={true}>Back</BackButton>
                    <div className={classes.mobTitle}>Answered Questions</div>
                </div>

                <div className={classes.wrapperForBall}>
                    <LoadingScreenBall/>
                </div>
            </>
        )
    }
};

export default AnsweredQuestions;