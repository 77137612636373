import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import {
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from "react";
import { Spinner } from "react-activity";

const styles = makeStyles<Theme, { small: boolean; width?: string }>(
  (theme: Theme) => ({
    defaultButton: {
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      boxShadow: "#00000029 0 3px 6px",
      color: "#232323",
      cursor: "pointer",
      display: "inline-block",
      fontSize: ({ small }) => (small ? "12px" : "18px"),
      fontWeight: 700,
      padding: ({ small }) => (small ? "6px 25px" : "6px 35px"),
      position: "relative",
      textAlign: "center",
      width: ({ width }) => (width ? width : "auto"),
    },
    redButton: {
      backgroundColor: "#EB3638",
      color: "#FFFFFF",
    },
    blueButton: {
      backgroundColor: "#0072e3",
      color: "#FFFFFF",
    },
    greyButton: {
      backgroundColor: "#858695",
      color: "#000000",
    },
    disabledButton: {
      backgroundColor: "#7F8090",
      cursor: "not-allowed",
    },
    spinner: {
      overflow: "visible",
      position: "absolute",
      right: ({ small }) => (small ? "5px" : "10px"),
      top: "50%",
      transform: "translateY(-50%)",
    },
  })
);

type Props = {
  color?: "red" | "blue" | "grey" | "default";
  disabled?: boolean;
  onClick?: MouseEventHandler;
  showLoader?: boolean;
  small?: boolean; // text size, default = 18px, small = 12px
  width?: string;
} & PropsWithChildren<ReactNode>;

const GenericButton = ({
  children,
  color = "default",
  disabled,
  onClick,
  showLoader = false,
  small = false,
  width,
}: Props) => {
  const classes = styles({ small, width });
  const buttonClassName = useMemo(() => {
    switch (color) {
      case "red":
        return classes.redButton;
      case "blue":
        return classes.blueButton;
      case "grey":
        return classes.greyButton;
      case "default":
      default:
        return;
    }
  }, [classes, color]);

  return (
    <div
      className={classNames(
        classes.defaultButton,
        buttonClassName,
        disabled ? classes.disabledButton : undefined
      )}
      onClick={disabled ? undefined : onClick}
    >
      {children}
      {showLoader ? <Spinner className={classes.spinner} size={10} /> : null}
    </div>
  );
};

export default GenericButton;
