import { OutgoingMessageTopic, postMessage } from '../utils/react-native-bridge';

export type TrackingEvent = {
    eventName: string;
    extraData?: Record<string, unknown>;
};

export interface Tracking {
    event: (eventAction: string, extraData?: Record<string, unknown>) => void;
}

export const useTracking = (): Tracking => {
    const event = (eventAction: string, extraData?: Record<string, unknown>) => {
        if (!eventAction) {
            return;
        }

        postMessage<TrackingEvent>({
            topic: OutgoingMessageTopic.Tracking,
            data: {
                eventName: 'free_to_play',
                extraData: {
                    event_action: eventAction,
                    event_category: 'couch_quiz',
                    ...extraData
                }
            }
        });
    };

    return {
        event,
    };
};
