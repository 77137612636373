import {gql} from '@apollo/client';

export const AFL_GET_BLOCK_ID = gql`
    query getAflContest($id: Int!) {
        afl {
           contest(id: $id) {
               id
               currentBlock
               status
           }
        }
    }
`;