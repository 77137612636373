import React from 'react';
import BackButton from '../../common/UI/BackButton';
import {makeStyles, Theme} from "@material-ui/core";

interface Props {
    backToGame: () => void
}

const styles = makeStyles((theme: Theme) => ({
    back: {
        position: 'absolute',
        width: '100%',
        height: 50
    },
    mobTitle: {
        display: 'none',
        '@media only screen and (max-width: 1024px)': {
            display: 'block',
            fontSize: 18,
            fontWeight: 700,
            padding: '0px 0 10px',
            boxShadow: '0 3px 6px ' + theme.palette.primary.light,
            // background: theme.palette.primary.main,
            position: 'relative',
            zIndex: 2,
            top: -38
        }
    },
}));

const BackComponent = (props: Props) => {
    const classes = styles();

    return (
        <div className={classes.back}>
            <BackButton onClick={() => props.backToGame()}
                        hideOnDesk={true}>Back</BackButton>

            <div className={classes.mobTitle}>Leaderboard</div>
        </div>
    )
};

export default BackComponent;