import React, {useState} from 'react';
import classNames from "classnames";
import {makeStyles, Theme} from "@material-ui/core";

const progressBarGradientYellow = 'linear-gradient(98deg, rgba(255, 201, 18, 1) 0%, rgba(255, 233, 159, 1) 50%, rgba(255, 201, 18, 1) 100%)';
const progressBarGradientRed = 'linear-gradient(98deg, rgba(255, 0, 49, 1) 0%, rgba(231, 160, 174, 1) 50%, rgba(255, 0, 49, 1) 100%)';

const styles = makeStyles<Theme, Props>(theme => ({
    progressBar: {
        height: 7,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        overflow: 'visible'
    },
    shrinker: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 1,
        borderRadius: 20,
        zIndex: 1
    },
}));

interface Props {
    lessThanTenSecs?: boolean
    seconds: number
    addClass: boolean
    width: string
}

const ProgressBar = (props: Props) => {
    const classes = styles(props);
    const {lessThanTenSecs, seconds, addClass, width} = props;

    let [kick, setKick] = useState(true)

    setTimeout(() => {
        setKick(false)
    }, 500)

    return (
        <div className={classNames(classes.progressBar, addClass ? classes.shrinker : null)}
             style={{
                 transition: 'width ' + seconds + 's linear',
                 background: lessThanTenSecs ? `${progressBarGradientRed}` : `${progressBarGradientYellow}`,
                 width: kick ? width : '0%'
             }}/>
    )
};

export default ProgressBar;