import { makeStyles, Theme } from "@material-ui/core";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const styles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    // zIndex: 20
  },
}));

const LottieAnimation = (props: { lotti: any; width: any; height: any }) => {
  const classes = styles();

  const lottieRef = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      animationData: JSON.parse(JSON.stringify(props.lotti)),
      loop: true,
      renderer: "svg",
      container: lottieRef.current!,
      autoplay: true,
    });

    return () => {
      anim.stop();
      anim.destroy();
      lottie.stop();
      lottie.destroy();
    };
  }, []);

  return (
    <div className={classes.root}>
      <div
        ref={lottieRef}
        style={{ width: props.width + "%", height: props.height }}
      />
    </div>
  );
};

export default LottieAnimation;
