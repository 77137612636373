import { colors as genericColors } from './colors';

// Ladbrokes theme colours taken from the Sketh library and the Entain React Native application code

// Colors from Sketch library
const COLORS = {
    siteBg: '#f7f7f7',

    redBase: '#d32123',
    redBright: '#eb3638',
    redBrighter: '#fa6365',
    redDark: '#a50d0c',

    greyBase: '#232323',
    greyBright: '#d5d5d5',
    greyBrighter: '#f7f7f7',
    greyDark: '#131313',
    greyDarkExtra: '#232323',

    greyBlueBase: '#9196a1',
    greyBlueDark: '#464e5b',
    greyBlueBright: '#dedfe2',

    greyBoulder: '#7a7a7a',

    greenBase: '#5ab708',
    greenBright: '#7bc936',
    greenDark: '#418b00',

    greySilver: 'rgba(151, 151, 151, 0.15)',

    blueBase: '#0066cc',
    blueBright: '#0072e3',
    blueDark: '#194ca8',

    yellowBase: '#ffaf23',
    yellowBright: '#ffd33d',

    // Card colors
    cardGreyBaseFill: '#ececec',
    cardGreyBaseStroke: '#e1e1e1',
    cardGreyDarkFill: '#333333',
    cardOffWhiteBaseFill: '#fbfbfb',
    cardOffWhiteBaseStroke: '#e8e8e8',
    cardWhiteBaseFill: '#ffffff',
    cardWhiteBaseStroke: '#e8e8e8',

    // Button colors
    btnPrimaryActive: '#131313',
    btnPrimaryBase: '#232323',
    btnPrimaryHover: '#363535',

    btnSecondaryActive: '#a50d0c',
    btnSecondaryBase: '#d32123',
    btnSecondaryHover: '#eb3638',

    btnSubtleActive: '#47494d',
    btnSubtleBase: '#6d7076',
    btnSubtleHover: '#7c8088',

    divider: '#d7dbe0',
};

const bettingInsightsColors = {
    bettingInsightsBlueGrey: '#2e3642',
    bettingInsightsDarkBlue: '#181f2a',
    bettingInsightsLightBlue: '#fbfdff',
    bettingInsightsMediumGrey: '#dedfe2',
    bettingInsightsLightGrey: '#d4d4d4',
    bettingInsightsRed: '#de2736',
    bettingInsightsLightRed: '#EC5051',
    bettingInsightsDarkRed: '#d32123',
    bettingInsightsGreen: 'rgb(239, 255, 227)',
};

const priceButtonColors = {
    priceButtonIncreaseBackgroundColor: '#eaffd7',
    priceButtonDecreaseBackgroundColor: '#ffd9d9',
    priceButtonBorderColor: '#eceff1',
    priceButtonIncreaseBorderColor: '#c4f09d',
    priceButtonDecreaseBorderColor: '#f9b2b3',
    priceButtonShadowColor: '#babfca',
    priceButtonSelectedShadowColor: '#c0181a',
};

// Short Form colors
const shortFormColors = {
    shortFormGreyBright: '#D5D5D5',
    shortFormGreyLighter: '#A4A4A4',
    shortFormGreyLight: '#47494D',
    shortFormGrey: '#454B51',
    shortFormGreyMedium: '#363535',
    shortFormGreyDark: '#0A0909',
    shortFormGreen: '#90EB41',
    shortFormOffWhite: '#FBFBFB',
};

const {
    siteBg,
    redBase,
    redBright,
    redBrighter,
    redDark,
    greyBase,
    greyBright,
    greyDark,
    greyBlueBase,
    greyBlueDark,
    greyBlueBright,
    greenBase,
    greenBright,
    greenDark,
    blueBase,
    blueBright,
    blueDark,
    yellowBright,
    cardGreyBaseStroke,
} = COLORS;

const betslipColors = {
    betslipHeaderDark: greyDark,
    betslipPendingBetsGrey: greyBlueDark,
    accountSwitcherBackground: '#48566C',
};

const myBetsColors = {
    myBetsLightGrey: genericColors.greyMediumDark,
    myBetsGreyBlueDark: greyBlueDark,
    myBetsPriceBoostInfoElement: blueBright,
};

const searchColors = {
    searchBright: blueBright,
};

const betSharingColors = {
    betSharingReturnColor: genericColors.greenBright,
    betSharingNoReturnColor: redBrighter,
    betSharingDividerColor: cardGreyBaseStroke,
    betSharingTicketTopPendingColor: '#321B00',
    betSharingTicketTopReturnColor: '#1D2C11',
    betSharingTicketTopNoReturnColor: '#2D323A',
    betSharingTicketTopPendingBackgroundColor: '#FFF73B',
    betSharingTicketTopReturnBackgroundColor: '#98ED4E',
    betSharingTicketTopNoReturnBackgroundColor: '#C6CCD7',
    betSharingSelectionWinnerColor: '#C4F09D',
};

const activityStatementV2Colors = {
    activityStatementV2Background: genericColors.greyBrighter,
    activityStatementV2TableHeader: '#ECEEF2',
    activityStatementV2TableCell: genericColors.white,
    activityStatementV2Divider: genericColors.greyLighter,
    activityStatementDisabled: '#A4A4A4',
    activityStatementText: '#232323',
};

const statusIconColors = {
    statusIconSucceeded: greenBase,
    statusIconFailed: redBrighter,
    statusIconPending: greyBlueBright,
};

export const colors = {
    ...genericColors,
    ...COLORS,
    ...betslipColors,
    ...bettingInsightsColors,
    ...myBetsColors,
    ...searchColors,
    ...shortFormColors,
    ...betSharingColors,
    ...statusIconColors,
    ...priceButtonColors,
    ...activityStatementV2Colors,
    siteBg,

    primary: redBase,
    primaryBright: redBright,
    primaryBrighter: redBrighter,
    primaryDark: redDark,

    secondary: greyBase,
    secondaryBright: greyBright,
    secondaryBrighter: greyBlueBright,
    secondaryDark: greyDark,

    // TODO bad naming - need to be descriptive
    greyBlue: greyBlueBase,
    greyBlueBright,
    greyBlueDark,

    highlight: yellowBright,

    info: blueBase,
    infoBright: blueBright,
    infoDark: blueDark,
    infoBg: '#fafeff',

    success: greenBase,
    successBright: greenBright,
    successDark: greenDark,
    successBg: '#f3ffe2',

    // Not sure if colors below are correct
    danger: '#b12a2c',
    dangerBright: '#c94b4c',
    dangerDark: '#8c0a0c',
    dangerBg: '#fff9f9',

    warning: '#ffd283',
    warningBright: '#ffebc7',
    warningDark: '#b26200',
    warningBg: '#fffdf6',

    red: '#e53636',
};
