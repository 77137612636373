import React, {useState} from 'react';
import CricketButton from "../../common/UI/SquareButton";
import {makeStyles, Theme} from "@material-ui/core";

interface Props {
    buttonWasClicked: boolean
    buttonClickFunc: any
}

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        width: '100%',
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    }
}));

const LeaderboardButton = (props: Props) => {
    const classes = styles();
    const {buttonWasClicked, buttonClickFunc} = props;

    return (
        <div className={classes.wrapper}>
            <CricketButton width='100%' buttonWasClicked={buttonWasClicked} notDefaultColors='blue' small={true}
                           onClick={(e: any) => buttonClickFunc(e)}>
                Leaderboard
            </CricketButton>
        </div>
    )
};

export default LeaderboardButton;