import {gql} from '@apollo/client';

export const NRL_CONTEST_SUBSCRIPTION = gql`
  subscription nrlContest($id: Int!) {
          nrlContest(id: $id) {
            id
            status
            startTime
            isLocked
     }
  }
`;

