import { makeStyles, Theme } from '@material-ui/core';

interface Props {
    type: 'OVERALL' | 'LADBROKES';
}

const TopTitles = ({ type }: Props) => {
    const classes = styles();

    return (
        <>
            <div className={type === 'OVERALL' ? classes.overallTopTitles : classes.topTitles}>
                <span>POS</span>
                <span>Player</span>
                <span>Pts</span>
                {type !== 'OVERALL' ? <span>Bonus Cash</span> : null}
            </div>
            <div className={classes.titlesDivider} />
        </>
    );
};

const styles = makeStyles((theme: Theme) => ({
    topTitles: {
        color: theme.palette.primary.contrastText,
        display: 'grid',
        fontSize: 12,
        gridTemplateColumns: '50px 1fr 70px 90px',
        padding: '0 15px',
        '& > span': {
            textAlign: 'left',
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        },
    },
    overallTopTitles: {
        color: theme.palette.primary.contrastText,
        display: 'grid',
        fontSize: 12,
        gridTemplateColumns: '50px 1fr 50px',
        padding: '0 15px',
        '& > span': {
            textAlign: 'left',
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        },
    },
    titlesDivider: {
        background: '#FFFFFF2B',
        height: 1,
        margin: '5px 0 10px',
        width: '100%',
    },
}));

export default TopTitles;
