import {gql} from '@apollo/client';

export const NBA_ENTAIN_QUESTIONS_LIST = gql`
    query getEntainQuestionsList($id: Int!) {
        nba {
           contest(id: $id) {
                id
                questionResults {
                    blockNumber
                    questionNumber
                    text
                    messages
                    outcomes {
                        id
                        questionId
                        correct
                        text
                        points
                    }
                }
           }
        }
    }
`;