import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {Batsman} from "../../../../../shared/types";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: 'calc(100% - 40px)',
        position: 'absolute',
        left: 40,
        background: theme.palette.background.default,
        borderRadius: 8,
        padding: '5px 6px 6px 12px',
        gridTemplateColumns: '35px 1fr',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        border: '2px solid transparent',
        '@media only screen and (max-width: 1024px)': {
            left: 30,
            width: 'calc(100% - 30px)'
        },
    },
    checked:{
        border: '2px solid ' + theme.palette.primary.contrastText
    },
    outPlayer: {
        display: 'block',
        borderRadius: '100%',
        width: 36,
        height: 36,
        marginRight: 10
    },
    mainWrapper: {
        color: theme.palette.primary.contrastText,
        width: '100%'
    },
    topRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 2
    },
    nameSize: {
        fontSize: 14,
        fontWeight: 700,
        '@media only screen and (max-width: 1024px)': {
            fontSize: 12
        }
    },
    bottomRow: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridGap: 10,
        '& > div':{
            textAlign: 'center'
        }
    },
    points: {
        fontSize: 18,
        fontWeight: 500,
        '@media only screen and (max-width: 1024px)': {
            fontSize: 16
        }
    },
}));

interface Props {
    checked: boolean
    cricketer: Batsman
}

const BatsmenToChoose = (props: Props) => {
    const classes = styles();

    const {cricketer} = props;
    const url = `/helmets/${cricketer.teamKey}_left.png`;

    return (
        <div className={classNames(classes.root, props.checked ? classes.checked : null)}>
            <img src={url} alt="player" className={classNames(classes.outPlayer)}/>
            <div className={classes.mainWrapper}>
                <div className={classes.topRow}>
                    <span className={classes.nameSize}>{cricketer.name}</span>
                </div>
                <div className={classes.bottomRow}>
                    <div>
                        <div className={classes.nameSize}>RUNS</div>
                        <div className={classes.points}>{cricketer.runs}</div>
                    </div>
                    <div>
                        <div className={classes.nameSize}>BALLS</div>
                        <div className={classes.points}>{cricketer.balls}</div>
                    </div>
                    {/*<div>
                        <div className={classes.nameSize}>MATCHES</div>
                        <div className={classes.points}>-1</div>
                    </div>
                    <div>
                        <div className={classes.nameSize}>AVG</div>
                        <div className={classes.points}>-1</div>
                    </div>*/}

                </div>
            </div>
        </div>
    )
};

export default BatsmenToChoose;