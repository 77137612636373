import { makeStyles, Theme } from "@material-ui/core";
import { MouseEvent, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useTracking } from "../../../../../entain/hooks/useTracking";
import GenericButton from "../../../../common/components/GenericButton";
import { ContestListContest, PreviousContestListContest } from "../../../types";
import FixtureButtons from "./FixtureButtons";
import FixtureCardTopPart from "./FixtureCardTopPart";

const styles = makeStyles((theme: Theme) => ({
  fixtureCard: {
    alignItems: "center",
    background: theme.palette.background.default,
    borderRadius: 17,
    display: "grid",
    gridTemplateColumns: "1fr 1.2fr 1fr",
    marginBottom: 10,
    padding: "10px 10px 5px",
    position: "relative",
    width: "100%",
    "@media only screen and (min-width: 1025px)": {
      marginBottom: 40,
    },
    "@media only screen and (min-width: 1025px) and (max-height: 700px)": {
      marginBottom: 10,
    },
  },
  fixtureButtonsContainer: {
    gridColumnEnd: 4,
    gridColumnStart: 1,
    paddingTop: 5,
  },
}));

interface Props {
  contest: ContestListContest | PreviousContestListContest;
  setPrevPageForLeaderboard: (p: string) => void;
}

const FixtureCard = (props: Props) => {
  const { contest, setPrevPageForLeaderboard } = props;
  const classes = styles();
  const tracking = useTracking();
  const history = useHistory();
  const [status, setStatus] = useState<string | null>(null);
  const [isPlayButtonLoading, setIsPlayButtonLoading] = useState(false);
  const [isLeaderboardButtonLoading, setIsLeaderboardButtonLoading] =
    useState(false);
  const path = history.location.pathname;

  const onPlayButtonClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      setIsPlayButtonLoading(true);
      history.push(`${contest.id}/home` + history.location.search);
      tracking.event("play button clicked", {
        contestId: contest?.id,
        blockId: contest?.currentBlock,
        fixtureId: contest?.fixture?.id,
        contestName: `${contest?.fixture?.homeTeam?.name} v ${contest?.fixture?.awayTeam?.name}`,
        sport: process.env.REACT_APP_BUILD_TARGET,
      });
    },
    [contest, history, tracking]
  );

  const onLeaderboardButtonClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      setIsLeaderboardButtonLoading(true);
      setPrevPageForLeaderboard(
        path.indexOf("previous_results") !== -1 ? "prev_results" : "homepage"
      );
      history.push(`/${contest.id}/leaderboard` + history.location.search);
    },
    [contest.id, history, path, setPrevPageForLeaderboard]
  );

  const playButton = (
    <GenericButton
      color="red"
      disabled={isPlayButtonLoading}
      onClick={onPlayButtonClick}
      showLoader={isPlayButtonLoading}
      small
      width="140px"
    >
      Play
    </GenericButton>
  );

  const resultButton = (
    <GenericButton
      color="blue"
      disabled={isLeaderboardButtonLoading}
      onClick={onLeaderboardButtonClick}
      showLoader={isLeaderboardButtonLoading}
      small
      width="140px"
    >
      Results
    </GenericButton>
  );

  const leaderboardButton = (
    <GenericButton
      disabled={isLeaderboardButtonLoading}
      onClick={onLeaderboardButtonClick}
      showLoader={isLeaderboardButtonLoading}
      small
      width="140px"
    >
      Leaderboard
    </GenericButton>
  );

  return (
    <div className={classes.fixtureCard}>
      <FixtureCardTopPart
        contest={contest}
        entryId={undefined}
        setPrevPageForLeaderboard={setPrevPageForLeaderboard}
        setStatus={setStatus}
        status={status}
        color={1}
      />

      <div className={classes.fixtureButtonsContainer}>
        <FixtureButtons
          contestId={contest.id}
          hasEntered={contest.hasEntered}
          entryId={undefined}
          status={status}
          playButton={playButton}
          resultButton={resultButton}
          leaderboardButton={leaderboardButton}
        />
      </div>
    </div>
  );
};

export default FixtureCard;
