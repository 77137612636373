import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTimeDrift } from "../../../../../contexts/TimeDrift";
import { QuestionListQuestion } from "../../../types";

const styles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "44px 1fr",
    alignItems: "center",
    width: "100%",
  },
  seconds: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 900,
    zIndex: 2,
    color: theme.palette.primary.contrastText,
    borderRadius: "100%",
    border: "5px solid " + theme.palette.primary.dark,
    transitionDuration: "0.5s",
    transitionProperty: "border-color",
    height: 44,
  },
  progress: {
    textAlign: "center",
    lineHeight: "55px",
    fontSize: 35,
    animationDirection: "reverse",
    position: "relative",
    borderRadius: "0 6px 6px 0",
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "0 1px 3px " + theme.palette.primary.light,
    overflow: "hidden",
    height: 12,
    top: 0,
    left: -1,
  },
  progressBar: {
    width: "100%",
    transitionDuration: "0.5s",
    transitionProperty: "background-color",
    background: theme.palette.primary.dark,
    height: "100%",
    borderRadius: "0 6px 6px 0",
  },
}));

interface Props {
  clickedButton: number;
  question: QuestionListQuestion;
  seen: Date | null;
  setLessThanTenSecs?: (l: boolean) => void;
  setTimeExpired: (t: boolean) => void;
}

const ProgressBar = (props: Props) => {
  const classes = styles();
  const { clickedButton, question, seen, setLessThanTenSecs, setTimeExpired } =
    props;

  const [style, setStyles] = useState({});
  const [borderColor, setBorderColor] = useState({});

  const [time, setTime] = useState<string | number>("");
  const diff = useTimeDrift();

  useEffect(() => {
    // Don't start the interval for countdown when question is answered
    if (clickedButton !== -1) return;

    if (question && seen) {
      const dateFormat = new Date(seen);
      const seenDate = dateFormat.getTime();

      const progressBarWidthDenominator = question.durationSeconds * 1000;
      const progressBarWidthNumerator = question.durationSeconds * 1000;
      let currentProgressBarWidth =
        progressBarWidthNumerator / progressBarWidthDenominator;

      const progressBarInterval = setInterval(() => {
        const now = new Date().getTime() + diff;

        const difference = seenDate + question.durationSeconds * 1000 - now;

        let inSecs = Math.floor(difference / 1000);

        if (inSecs >= 60) {
          const minutes = Math.floor(inSecs / 60);
          let secs: string | number = inSecs - minutes * 60;
          secs = secs < 10 ? "0" + secs : secs;

          setTime(minutes + ":" + secs);
        } else {
          let secs: string | number =
            inSecs < 10 && inSecs > 0
              ? "0" + inSecs
              : inSecs <= 0
              ? "00"
              : inSecs;
          setTime(secs);
        }

        if (difference <= 0) {
          clearInterval(progressBarInterval);
          setStyles({ width: "0%", backgroundColor: "#00FF6C" });

          if (setLessThanTenSecs) setLessThanTenSecs(false);
          setTimeExpired(true);
        } else {
          currentProgressBarWidth = difference / progressBarWidthDenominator;
          if (inSecs <= 10) {
            if (setLessThanTenSecs) setLessThanTenSecs(true);

            setStyles({
              width: 100 * currentProgressBarWidth + "%",
              backgroundColor: "#C1272D",
            });
            setBorderColor({ borderColor: "#C1272D" });
          } else {
            setStyles({
              width: 100 * currentProgressBarWidth + "%",
              backgroundColor: "#00FF6C",
            });
            setBorderColor({ borderColor: "#00FF6C" });
          }
        }
      }, 10);

      return () => clearInterval(progressBarInterval);
    }
  }, [clickedButton, question, seen]);

  return (
    <div className={classes.root}>
      <div className={classNames(classes.seconds)} style={borderColor}>
        {time}
      </div>

      <div className={classes.progress}>
        <div className={classes.progressBar} id="progress-bar" style={style} />
      </div>
    </div>
  );
};

export default ProgressBar;
