import {gql} from "@apollo/client";

export const NRL_GAME_STATE_SUBSCRIPTION = gql`
    subscription gameState($contestId: Int!) {
           nrlGameState(contestId: $contestId) {
                currentBlock
                isBlockLocked
                timeTillNextBlock
                allBlocks {
                    id
                    status 
                    blockNumber 
                }
                homeTeamScore
                homeTeam {
                    id
                    externalId
                    name
                }
                awayTeamScore
                awayTeam {
                    id
                    externalId
                    name
                }
                period
                clockInSeconds
           }
    }
`;