import {gql} from '@apollo/client';

export const NBA_CONTEST = gql`
    query getNbaContest($id: Int!) {
        nba {
           contest(id: $id) {
                id
                currentBlock
                status
                startTime
                isLocked
                hasEntered
                fixture {
                    id
                    externalId
                    status
                    homeTeam {
                        id
                        externalId
                        name
                    }
                    awayTeam {
                        id
                        externalId
                        name
                    }
                    venue {
                        id
                        externalId
                        name
                    }
               }
           }
        }
    }
`;