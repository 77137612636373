import React, {useState} from 'react';
import classNames from "classnames";
import {makeStyles, Theme} from "@material-ui/core";
import {useHistory} from "react-router";

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        paddingBottom: 10,
        '@media only screen and (min-width: 1025px)': {
            paddingBottom: 40
        },
        '@media only screen and (min-width: 1025px) and (max-height: 700px)': {
            paddingBottom: 10
        }
    },
}));

interface ChildProps {
    leaderboardButtonClickFunc?: (e: any) => void;
    playButtonClickFunc?: (e: any) => void;
    resultButtonClickFunc?: (e: any) => void;
    playButtonWasClicked?: boolean;
    resultButtonWasClicked?: boolean;
    leaderBoardButtonWasClicked?: boolean;
    setIsLocked?: (l: boolean) => void;
    isLocked?: boolean;
}

interface Props {
    children: React.ReactElement<ChildProps>
    setPrevPageForLeaderboard: (p: string) => void
    contestId: number
    entryId?: number
}

const FixtureCard = (props: Props) => {
    const classes = styles();
    const {children, setPrevPageForLeaderboard, contestId, entryId} = props;

    const history = useHistory();
    const path = history.location.pathname;

    const [isLocked, setIsLocked] = useState(false);
    const [leaderBoardButtonWasClicked, setLeaderboardButtonWasClicked] = useState(false);
    const [playButtonWasClicked, setPlayButtonWasClicked] = useState(false);
    const [resultButtonWasClicked, setResultButtonWasClicked] = useState(false);

    const leaderboardButtonClickFunc = (e: any) => {
        if (!leaderBoardButtonWasClicked) {
            setLeaderboardButtonWasClicked(true);
            e.preventDefault();

            setPrevPageForLeaderboard(path.indexOf('previous_results') !== -1 ? 'prev_results' : 'homepage');
            history.push(`/${contestId}/leaderboard` + history.location.search)
        }
    };

    const playButtonClickFunc = (e: any) => {
        if (!playButtonWasClicked && !isLocked) {
            setPlayButtonWasClicked(true);
            e.preventDefault();

            let path = !entryId ? 'home' : 'question_page';
            history.push(`${contestId}/${path}` + history.location.search)
        }
    };

    const resultButtonClickFunc = (e: any) => {
        if (!resultButtonWasClicked) {
            setResultButtonWasClicked(true);
            e.preventDefault();

            setPrevPageForLeaderboard(path.indexOf('previous_results') !== -1 ? 'prev_results' : 'homepage');
            history.push(`/${contestId}/leaderboard` + history.location.search)
        }
    };

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {

            return React.cloneElement(child, {
                leaderboardButtonClickFunc: (e: any) => leaderboardButtonClickFunc(e),
                playButtonClickFunc: (e: any) => playButtonClickFunc(e),
                resultButtonClickFunc: (e: any) => resultButtonClickFunc(e),
                playButtonWasClicked: playButtonWasClicked,
                resultButtonWasClicked: resultButtonWasClicked,
                leaderBoardButtonWasClicked: leaderBoardButtonWasClicked,
                setIsLocked: (l: boolean) => setIsLocked(l),
                isLocked: isLocked
            });
        }

        return child;
    });

    return (
        <div className={classNames(classes.wrapper)}>
            {childrenWithProps}
        </div>
    )
};

export default FixtureCard;