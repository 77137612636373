import React from 'react';
import CricketButton from "../../common/UI/SquareButton";
import lock from '../../../assets/lock.png'
import {makeStyles, Theme} from "@material-ui/core";

interface Props {
    buttonWasClicked: boolean
    isLocked: boolean
    buttonClickFunc: any
    entryId: number | undefined
}

const styles = makeStyles((theme: Theme) => ({
    locked: {
        display: 'block',
        width: 20,
        margin: 'auto'
    }
}));

const PlayButton = (props: Props) => {
    const classes = styles();
    const {buttonWasClicked, isLocked, buttonClickFunc, entryId} = props;

    return (
        <CricketButton width='100%' buttonWasClicked={buttonWasClicked} small={true} notDefaultColors='red'
                       disabled={isLocked}
                       onClick={(e: any) => buttonClickFunc(e)}>
            {isLocked ?
                <img src={lock} alt="locked" className={classes.locked}/>

                : !entryId ? 'Play' : 'Resume'
            }

        </CricketButton>
    )
};

export default PlayButton;