import React, {useEffect, useRef} from "react";
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import winAnimation from '../../assets/animation/win.json';
import noWinAnimation from '../../assets/animation/no-win.json';
import lottie from 'lottie-web';

interface Props {
    isUserWin: boolean
}

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        width: 250,
        height: 250,
        margin: 'auto',
        borderRadius: '100%',
        overflow: 'hidden',
        '@media only screen and (max-height: 630px)': {
            width: 200,
            height: 200,
        }
    },
    win: {
        border: '10px solid ' + theme.palette.grey[400],
        background: 'rgba(62, 206, 128, 0.6)'
    },
    lost: {
        border: '10px solid ' + theme.palette.info.light,
        background: 'rgba(193, 39, 45, 0.6)'
    },
    image: {
        display: 'block',
        height: '100%'
    },
    loading: {
        with: 350,
        height: 350,
        background: '#fff'
    }
}));


const AnimatedPlayerImage = (props: Props) => {
    const classes = styles();
    const lottieRef = useRef(null);

    useEffect(() => {
        const anim = lottie.loadAnimation({
            animationData: props.isUserWin ? JSON.parse(JSON.stringify(winAnimation)) : JSON.parse(JSON.stringify(noWinAnimation)),
            loop: true,
            renderer: 'svg',
            container: lottieRef.current!,
            autoplay: true,
        });

        return () => {
            anim.stop();
            anim.destroy();
            lottie.stop();
            lottie.destroy();
        }
    }, []);

    return (
        <div className={classNames(classes.wrapper, props.isUserWin ? classes.win : classes.lost)}>
            <div ref={lottieRef} style={{height: 300, width: 300, margin: '-50px 0 0 -30px'}}/>
        </div>
    )
};

export default AnimatedPlayerImage;