import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {useGameState} from "../../../contexts/nrl_afl/GameState";
import {Team} from "../../../../../shared/types";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '10px 15px',
        boxShadow: '0 3px 6px ' + theme.palette.primary.light,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        minHeight: 60,
        overflow: 'visible',
        position: 'relative',
        zIndex: 10,
    },
    rootInner: {
        maxWidth: 250,
        margin: 'auto',
        display: 'grid',
        gridTemplateColumns: '35px 1fr 35px',
        alignItems: 'flex-end',
        // justifyContent: 'space-between'
    },
    flag: {
        display: 'block',
        width: 30,
        height: 30
    },
    end: {
        justifySelf: 'end'
    },
    period: {
        textAlign: 'center',
        color: theme.palette.grey[300],
        fontSize: 10,
        fontWeight: 700,
        paddingBottom: 5
    },
    pointsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '0 10px'
    },
    alias: {
        fontSize: 14
    },
    points: {
        fontSize: 14,
        fontWeight: 700,
    },
    time: {
        fontSize: 12,
        fontWeight: 700,
        padding: '0 3px 1px'
    }
}));

interface Props {
    sport: string
}

const BottomBar = (props: Props) => {
    const classes = styles();
    const {sport} = props;
    const {gameState} = useGameState();

    let homeTeamScore = 0;
    let awayTeamScore = 0;
    let period = 0;
    let clockInSeconds = 0;
    let homeTeam:Team = {id: -1, externalId: '', name: ''};
    let awayTeam:Team = {id: -1, externalId: '', name: ''};

    const [time, setCorrectTime] = useState('00:00');

    if (gameState) {
        homeTeamScore = gameState.homeTeamScore;
        awayTeamScore = gameState.awayTeamScore;
        period = gameState.period;
        clockInSeconds = gameState.clockInSeconds;
        homeTeam = gameState.homeTeam;
        awayTeam = gameState.awayTeam
    }

    useEffect(() => {
        let mins: number | string = Math.floor(clockInSeconds / 60);
        let secs: number | string = clockInSeconds - mins * 60;

        if (sport === 'NRL') {
            if (period === 2) {
                mins = mins + 40

            }
        } else if (sport === 'AFL') {
            if (period > 1) {
                mins = mins // + (20 * (period -1))
            }
        }

        mins = mins < 10 ? '0' + mins : mins;
        secs = secs < 10 ? '0' + secs : secs;

        setCorrectTime(`${mins}:${secs}`)

        return () => setCorrectTime('00:00')
    }, [clockInSeconds]);

    const getFirstThreeLetters = (teamName: string) => {
        return teamName.substr(0, 3).toUpperCase()
    }

    const correctPeriodNaming = (period: number) => {
        let ending = 'HALF';
        if (sport === 'AFL') ending = 'QTR';

        const s = ["TH", "ST", "ND", "RD"],
            v = period % 100;
        return `${period + (s[(v - 20) % 10] || s[v] || s[0]) + ' ' + ending}`;
    }

    const homeTeamImage = `/assets/images/${sport.toLowerCase()}/${homeTeam.externalId}.png`;
    const awayTeamImage = `/assets/images/${sport.toLowerCase()}/${awayTeam.externalId}.png`;

    return (
        <div className={classes.root}>
            <div className={classes.rootInner}>
                {period !== 0 &&
                    <>
                        <img src={homeTeamImage} alt="flag" className={classes.flag}/>
                        <div>
                            <div className={classes.period}>{correctPeriodNaming(period)}</div>
                            <div className={classes.pointsWrapper}>
                                <span className={classes.alias}>{getFirstThreeLetters(homeTeam.name)}</span>
                                <span className={classes.points}>{homeTeamScore}</span>
                                <span className={classes.time}>{time}</span>
                                <span className={classes.points}>{awayTeamScore}</span>
                                <span className={classes.alias}>{getFirstThreeLetters(awayTeam.name)}</span>
                            </div>
                        </div>
                        <img src={awayTeamImage} alt="flag" className={classNames(classes.flag, classes.end)}/>
                    </>
                }
            </div>
        </div>
    )
};

export default BottomBar;