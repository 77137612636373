import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import Countdown from "../Countdown";
import {Contest} from "../../../../apollo/cricket/queries/GetContests";
import {useQuery, useSubscription} from "@apollo/client";
import {CONTESTS} from "../../../../apollo/cricket/subscriptions/Contests";
import {getDateAndTime} from '../../../../utils';
import {CONTEST} from "../../../../apollo/cricket/queries/Contest";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 5,
        padding: '10px 20px',
        display: 'grid',
        gridTemplateColumns: '1fr 1.2fr 1fr',
        alignItems: 'center',
        background: theme.palette.background.default,
        position: 'relative'
    },
    wrapper: {
        paddingBottom: 10,
        '@media only screen and (min-width: 1025px)': {
            paddingBottom: 40
        },
        '@media only screen and (min-width: 1025px) and (max-height: 700px)': {
            paddingBottom: 10
        }
    },
    helmet: {
        width: '60%'
    },
    teamName: {
        fontSize: 12,
        fontWeight: 700,
        width: '100%',
        textAlign: 'center',
        paddingTop: 10
    },
    columnWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.primary.contrastText,
    },
    venue: {
        fontSize: 10,
        fontWeight: 500,
        paddingBottom: 3,
        textAlign: 'center'
    },
    date: {
        fontSize: 10,
        fontWeight: 700,
        paddingBottom: 3,
    },
    time: {
        fontSize: 14,
        fontWeight: 700,
    },
    whiteText: {
        fontWeight: 700,
        fontSize: 12,
        paddingTop: 10,
        textAlign: 'center'
    },
    redText: {
        fontWeight: 700,
        fontSize: 12,
        paddingTop: 10,
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
    },
    live: {
        fontWeight: 700,
        fontSize: 12,
        paddingTop: 10,
        textAlign: 'center',
        color: theme.palette.grey[500],
    }
}));

interface Props {
    contest: Contest
    entryId: number | undefined
    setPrevPageForLeaderboard: (p: string) => void
    setRainDelayed: (r: boolean) => void
    setIsLocked: (l: boolean) => void
    isLocked: boolean
    status: string | null
    setStatus: (s: string) => void
    color: number //0: white, 1: green
}

const FixtureCardTopPart = (props: Props) => {
    const classes = styles();
    const {contest, setRainDelayed, setIsLocked, isLocked, status, setStatus, color} = props;

    const [estimatedStartTime, setEstimatedStartTime] = useState<number>(0);
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    let timeout: any = null;

    useEffect(() => {
        setStatus(contest.status);
        setEstimatedStartTime(Number(contest.estimatedStartTime));

        const getDate = getDateAndTime(Number(contest.estimatedStartTime));

        setStartDate(getDate.startDate);
        setStartTime(getDate.startTime);
    }, []);

    const {loading: contestLoading, error: contestError, data: contestData} =
        useQuery(CONTEST, {
            variables: {
                id: contest.id
            }
        });

    const {data, loading, error} = useSubscription(CONTESTS, {
        variables: {id: contest.id},
        skip: contest.status === 'COMPLETED'
    });

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (!loading && !error && data) {
            if (data.contest) {
                setStatus(data.contest.status);
                setRainDelayed(data.contest.status === 'RAIN_DELAY')
            }
        }
    }, [data]);

    useEffect(() => {
        if (!contestLoading && !contestError && contestData) {
            timeout = setInterval(function () {
                const now = new Date();
                const lockout = new Date(Number(contestData.contest.lockOutTime) + 1000);

                if (lockout < now) {
                    clearTimeout(timeout);

                    if (!contestData.contest.entry) {
                        setIsLocked(true)
                    }
                }
            }, 1000);
        }

    }, [contestData]);

    const leftHelmet = `/helmets/${contest.fixture.teamA.key}_left.png`;
    const rightHelmet = `/helmets/${contest.fixture.teamB.key}_right.png`;

    return (
        <>
            <div className={classes.columnWrapper}>
                <img src={leftHelmet} alt="helmet" className={classes.helmet}/>
                <div className={classes.teamName}>{contest.fixture.teamA.name}</div>

            </div>

            <div className={classes.columnWrapper}>
                {contest.fixture.venue && contest.fixture.venue.name !== 'TBC' ?
                    <div className={classes.venue}>{contest.fixture.venue.name}</div>
                    : null}

                <div className={classes.date}>{startDate}</div>
                <div className={classes.time}>{startTime}</div>

                {status === 'INPROGRESS' && !isLocked ?

                    <div className={classes.live}>Live</div>

                    : status === 'INPROGRESS' && isLocked ?
                        <div className={classes.whiteText}>Locked Out</div>

                        : status === 'COMPLETED' ?
                            <div className={classes.whiteText}>Completed</div>

                            : status === 'ABANDONED' ?
                                <div className={classes.redText}>Match Abandoned</div>

                                : status === 'RAIN_DELAY' ?
                                    <div className={classes.redText}>Start Delayed</div>

                                    : <Countdown estimatedStartTime={estimatedStartTime} onTop={false} color={color}/>
                }

            </div>

            <div className={classes.columnWrapper}>
                <img src={rightHelmet} alt="helmet" className={classes.helmet}/>
                <div className={classes.teamName}>{contest.fixture.teamB.name}</div>

            </div>
        </>
    )
};

export default FixtureCardTopPart;