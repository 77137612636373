import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Icon } from '../core/Icon';

type SportsPSGMCardInfoProps = {
    betCount: number;
    legCount: number;
};

export const SportsPSGMCardInfo = ({ betCount, legCount }: SportsPSGMCardInfoProps) => {
    const classes = styles();

    return (
        <div className={classes.mainContainer}>
            <div className={classes.info}>
                <Icon id="same-game-multi" type="product" color="#232323" width={16} height={16} />
                <span className={classes.legCountText}>{`${legCount} leg${legCount > 1 ? 's' : ''}`}</span>
            </div>
            <div className={classes.info}>
                <Icon id="people" type="misc" color="#232323" width={16} height={16} />
                <span className={classes.betCountText}>{`${betCount} bet${betCount > 1 ? 's' : ''}`}</span>
            </div>
        </div>
    );
};

const styles = makeStyles(() => ({
    mainContainer: {
        alignItems: 'flex-end',
        borderBottom: '1px solid #E8E8E8',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        paddingBottom: 5,
    },
    info: {
        alignItems: 'center',
        display: 'flex',
    },
    legCountText: {
        fontSize: 14,
        fontWeight: 600,
        marginLeft: 5,
    },
    betCountText: {
        fontSize: 12,
        fontWeight: 600,
        marginLeft: 5,
    },
}));