import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTimeDrift } from "../../../../../contexts/TimeDrift";
import greenLck from "../../../assets/green-lock.png";
import lock from "../../../assets/lock.png";
import { differenceInUnits } from "../../../utils/utils";

const styles = makeStyles((theme: Theme) => ({
  wrap: {
    paddingTop: 10,
    position: "relative",
    zIndex: 20,
    fontWeight: 700,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& > img": {
      marginTop: 2,
      "@media only screen and (max-width: 1024px)": {
        width: 15,
      },
      "@media only screen and (min-width: 1025px)": {
        width: 20,
      },
    },
  },
  biggerText: {
    fontSize: 14,
    paddingTop: 0,
    "& > $wrapperWidth": {
      width: 70,
    },
  },
  wrapperWidth: {
    minWidth: 50,
  },
  green: {
    color: theme.palette.grey[500],
  },
}));

const DEFAULT_COUNTDOWN_TO_DISPLAY = "00:00:00";

interface Props {
  estimatedStartTime: number;
  onTop: boolean;
  color: number;
}

const Countdown = (props: Props) => {
  const classes = styles();
  const { estimatedStartTime, onTop, color } = props;
  const diff = useTimeDrift();

  const [countdownToDisplay, setCountdownToDisplay] = useState(
    DEFAULT_COUNTDOWN_TO_DISPLAY
  );
  const [hasElapsedStartTime, setHasElapsedStartTime] = useState(false);

  useEffect(() => {
    const now = new Date().getTime() + diff;

    if (estimatedStartTime - now > 100) {
      const countdown = setInterval(() => {
        const getCountdownUnits = differenceInUnits(estimatedStartTime, diff);
        setCountdownToDisplay(`${getCountdownUnits.countdownTime}`);

        if (getCountdownUnits.distance <= 0) {
          setHasElapsedStartTime(true);
          setCountdownToDisplay(DEFAULT_COUNTDOWN_TO_DISPLAY);
          clearInterval(countdown);
        }
      }, 1000);

      return () => clearInterval(countdown);
    } else {
      setHasElapsedStartTime(true);
    }
  }, [estimatedStartTime, diff]);

  return (
    <div
      className={classNames(classes.wrap, onTop ? classes.biggerText : null)}
    >
      {!onTop && !hasElapsedStartTime && (
        <img src={color ? greenLck : lock} alt="lock" />
      )}
      <span
        className={classNames(classes.wrapperWidth, color ? classes.green : "")}
      >
        {" "}
        {hasElapsedStartTime ? "Starting Soon" : countdownToDisplay}
      </span>
    </div>
  );
};

export default Countdown;
