import { useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
    GetEntryDocument,
    GetEntryQuery,
    GetEntryQueryVariables,
    SubscribeEntryDocument,
    SubscribeEntrySubscription,
    SubscribeEntrySubscriptionVariables,
} from '../gql/operations.generated';
import { Entry } from '../types';

const initialState: Entry = {
    id: '',
    points: 0,
    blockId: 0,
    rank: 0,
    winnings: 0,
    nationalRank: 0,
    nationalWinnings: 0,
    settlementStatus: 'NOT_SETTLED',
};

export const EntryContext = createContext<{
    isEntryLoaded: boolean;
    entry: Entry | undefined | null;
    loadEntry: (entry: Entry) => void;
}>({
    isEntryLoaded: false,
    entry: initialState,
    loadEntry: (entry: Entry) => {},
});

interface Props {
    contestId?: string;
    children: React.ReactNode;
}

const EntryProvider = (props: Props) => {
    const [entry, setEntry] = useState<Entry | undefined | null>(initialState);
    const [isEntryLoaded, setIsEntryLoaded] = useState<boolean>(false);
    const { contest_id } = useParams<{ contest_id: string }>();
    const { contestId, children } = props;
    const finalContestId = typeof contestId === 'string' ? contestId : contest_id ?? '';

    const { data, error, loading, subscribeToMore } = useQuery<GetEntryQuery, GetEntryQueryVariables>(
        GetEntryDocument,
        {
            variables: {
                contestId: finalContestId,
            },
            pollInterval: 10000,
        }
    );

    useEffect(() => {
        if (data?.afl && !error && !loading) {
            setEntry(data.afl.entry);
            setIsEntryLoaded(true);
        }
    }, [data?.afl, error, loading]);

    useEffect(() => {
        if (loading || !entry?.id || !subscribeToMore) return;

        const unsubscribe = subscribeToMore<SubscribeEntrySubscription, SubscribeEntrySubscriptionVariables>({
            document: SubscribeEntryDocument,
            variables: {
                entryId: entry.id,
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev;
                }

                setEntry(subscriptionData.data.aflEntry);

                return {
                    afl: {
                        entry: subscriptionData.data.aflEntry,
                    },
                };
            },
        });

        return () => unsubscribe?.();
    }, [entry?.id, loading, subscribeToMore]);

    const loadEntry = (entry: Entry) => {
        setEntry(entry);
        setIsEntryLoaded(true);
    };

    return <EntryContext.Provider value={{ isEntryLoaded, entry, loadEntry }}>{children}</EntryContext.Provider>;
};

const useEntry = () => {
    const context = useContext(EntryContext);
    if (context === undefined) {
        throw new Error('useEntry must be used within a EntryProvider');
    }

    return context;
};

export { EntryProvider, useEntry };
