import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import Countdown from "../shared/Countdown";
import {useQuery, useSubscription} from "@apollo/client";
import {AFL_CONTEST_SUBSCRIPTION} from '../../../apollo/afl/subscriptions/Contest';
import {NRL_CONTEST_SUBSCRIPTION} from '../../../apollo/nrl/subscriptions/Contest';
import {getDateAndTime} from '../../../utils';
import {AflContest} from "../../../apollo/afl/queries/GetContests";
import {NrlContest} from "../../../apollo/nrl/queries/GetContests";
import {AFL_CONTEST} from "../../../apollo/afl/queries/Contest";
import {NRL_CONTEST} from "../../../apollo/nrl/queries/Contest";

const styles = makeStyles((theme: Theme) => ({
    helmet: {
        width: '60%'
    },
    teamName: {
        fontSize: 12,
        fontWeight: 700,
        width: '100%',
        textAlign: 'center',
        paddingTop: 10
    },
    columnWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.primary.contrastText,
    },
    venue: {
        fontSize: 10,
        fontWeight: 500,
        paddingBottom: 3,
        textAlign: 'center'
    },
    date: {
        fontSize: 10,
        fontWeight: 700,
        paddingBottom: 3,
    },
    time: {
        fontSize: 14,
        fontWeight: 700,
    },
    whiteText: {
        fontWeight: 700,
        fontSize: 12,
        paddingTop: 10,
        textAlign: 'center'
    },
    live: {
        fontWeight: 700,
        fontSize: 12,
        paddingTop: 10,
        textAlign: 'center',
        color: theme.palette.grey[500],
    }
}));

interface Props {
    contest: AflContest | NrlContest
    entryId: number | undefined
    setPrevPageForLeaderboard: (p: string) => void
    status: string | null
    setStatus: (s: string) => void
    color: number //0: white, 1: green
    sport: string
}

const FixtureCardTopPart = (props: Props) => {
    const classes = styles();
    const {contest, status, setStatus, color, sport} = props;

    const [estimatedStartTime, setEstimatedStartTime] = useState<number>(0);
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    let timeout: any = null;

    const query = sport === 'AFL' ? AFL_CONTEST : NRL_CONTEST;
    const subscription = sport === 'AFL' ? AFL_CONTEST_SUBSCRIPTION : NRL_CONTEST_SUBSCRIPTION;

    useEffect(() => {
        setStatus(contest.status);
        setEstimatedStartTime(Number(contest.startTime));

        const getDate = getDateAndTime(Number(contest.startTime));

        setStartDate(getDate.startDate);
        setStartTime(getDate.startTime);
    }, []);

    const {loading: contestLoading, error: contestError, data: contestData} =
        useQuery(query, {
            variables: {
                id: contest.id
            }
        });

    const {data, loading, error} = useSubscription(subscription, {
        variables: {id: contest.id},
        skip: contest.status === 'COMPLETED'
    });

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (!loading && !error && data) {
            if (sport === 'NRL') {
                if (data.nrlContest) {
                    setStatus(data.nrlContest.status);
                }
            } else if (sport === 'AFL') {
                if (data.aflContest) {
                    setStatus(data.aflContest.status);
                }
            }

        }
    }, [data]);

    const homeTeamImage = `/assets/images/${sport.toLowerCase()}/${contest.fixture.homeTeam.externalId}.png`;
    const awayTeamImage = `/assets/images/${sport.toLowerCase()}/${contest.fixture.awayTeam.externalId}.png`;

    return (
        <>
            <div className={classes.columnWrapper}>
                <img src={homeTeamImage} alt="helmet" className={classes.helmet}/>
                <div className={classes.teamName}>{contest.fixture.homeTeam.name}</div>

            </div>

            <div className={classes.columnWrapper}>
                {contest.fixture.venue && contest.fixture.venue.name !== 'TBC' ?
                    <div className={classes.venue}>{contest.fixture.venue.name}</div>
                    : null}

                <div className={classes.date}>{startDate}</div>
                <div className={classes.time}>{startTime}</div>

                {status === 'INPROGRESS' ?
                    <div className={classes.live}>Live</div>
                    : status === 'COMPLETED' ?
                        <div className={classes.whiteText}>Completed</div>
                        : <Countdown estimatedStartTime={estimatedStartTime} onTop={false} color={color}/>
                }
            </div>

            <div className={classes.columnWrapper}>
                <img src={awayTeamImage} alt="helmet" className={classes.helmet}/>
                <div className={classes.teamName}>{contest.fixture.awayTeam.name}</div>

            </div>
        </>
    )
};

export default FixtureCardTopPart;