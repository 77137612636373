import { useEntain } from './useEntain';
import { useMemo } from 'react';
import { EntainConfig } from '../components/core/EntainProvider';

export const useFeatureFlag = (flag: keyof EntainConfig) => {
    const { config, insideWebView } = useEntain();

    return useMemo(() => {
        return insideWebView && config[flag];
    }, [insideWebView, config?.[flag]]);
};
