import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {useMutation} from "@apollo/client";
import {useNrlAflEntry} from "../../../../contexts/nrl_afl/Entry";
import {AflNrlQuestion, AflNrlOutcome} from '../../../../../../shared/types';
import OutcomeButton from './OutcomeButton';
import {ANSWER_QUESTION_AFL} from "../../../../apollo/afl/mutations/AnswerQuestion";
import {ANSWER_QUESTION_NRL} from "../../../../apollo/nrl/mutations/AnswerQuestion";
import {useQuestion} from "../../../../contexts/nrl_afl/Question";
import {useTimeDrift} from "../../../../contexts/TimeDrift";

const sport = process.env.REACT_APP_BUILD_TARGET;

const styles = makeStyles((theme: Theme) => ({
    flexRoot: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 5,
        flexDirection: 'column',
        overflow: 'visible',
        position: 'relative',
        paddingTop: 10
    },
}));

interface Props {
    question: AflNrlQuestion
    timeExpired: boolean
    setButtonWasClicked: ((b: boolean) => void) | undefined
    setLessThanTenSecs: ((l: boolean) => void) | undefined
    setTimeExpired: (t: boolean) => void
    clickedButton: number
    setClickedButton: (b: number) => void
    setOpenSnackbar: (s: boolean) => void
    trackAnswer: (outcome: AflNrlOutcome, timestamp: string, err?: unknown) => void
}

const Outcomes = (props: Props) => {
    const classes = styles();
    const {
        question,
        timeExpired,
        setButtonWasClicked,
        setLessThanTenSecs,
        setTimeExpired,
        clickedButton,
        setClickedButton,
        setOpenSnackbar,
        trackAnswer
    } = props;

    const {entry} = useNrlAflEntry();
    const {questionWasAnswered} = useQuestion();
    const mutation = sport === 'afl' ? ANSWER_QUESTION_AFL : ANSWER_QUESTION_NRL;
    const diff = useTimeDrift();

    const [errors, setError] = useState()

    const [answerQuestion, {data, loading, error}] = useMutation(mutation, {context: {timeout: 5000}}); //5 secs timeout for answer mutation, then cancel it


    useEffect(() => {
        if (!loading && !error && data) {
            // questionWasAnswered(question.id)
        } else if (!loading && error) {
            setOpenSnackbar(true)
            setButtonWasClicked && setButtonWasClicked(false)
            setClickedButton(-1);
        }
    }, [loading, data, error]);


    useEffect(() => {
        setOpenSnackbar(false)
        return () => {
            setButtonWasClicked && setButtonWasClicked(false)
            setLessThanTenSecs && setLessThanTenSecs(false);
            setTimeExpired(false);
            setOpenSnackbar(false)
        }
    }, []);

    const onClickGenerator = useCallback((outcome: AflNrlOutcome, outcomeIdx: number) => {
        return async () => {
            // if (!buttonWasClicked && !timeExpired && !question.isAnswered) { //TODO TIME EXPIRED BROKEN FOR NOW
            if (clickedButton === -1 && !question.isAnswered) {
                setButtonWasClicked && setButtonWasClicked(true)
                setClickedButton(outcomeIdx);
                const dateWithDiff = new Date().getTime() + diff;
                const timestamp = new Date(dateWithDiff).toISOString();

                await answerQuestion({variables: {entryId: entry.id, outcomeId: outcome.id, timestamp: timestamp}})
                    .then((data) => {
                        const path = sport === 'afl' ? data.data.afl.selectOutcome : data.data.nrl.selectOutcome;

                        if (path.success && !path.errorMessage) {
                            questionWasAnswered(question.id)
                            trackAnswer(outcome, timestamp);
                        } else {
                            setOpenSnackbar(true)
                            setButtonWasClicked && setButtonWasClicked(false)
                            setClickedButton(-1);
                        }
                    })
                    .catch((e) => {
                        setOpenSnackbar(true)
                        setButtonWasClicked && setButtonWasClicked(false)
                        setClickedButton(-1);
                        setError(e)
                        trackAnswer(outcome, timestamp, e);
                    });
            }
        }
    }, [clickedButton, question.isAnswered, setButtonWasClicked, setClickedButton, entry.id, setOpenSnackbar, setError, questionWasAnswered, sport]);


    return (
        <div className={classes.flexRoot}>
            {question.outcomes.map((outcome: AflNrlOutcome, idx: number) => {
                return <OutcomeButton key={outcome.id} onClick={onClickGenerator(outcome, idx)}
                                      buttonId={idx}
                                      clickedButtonId={clickedButton}
                                      timeExpired={timeExpired}
                                      selected={outcome.isSelected}
                                      setClickedButton={setClickedButton}
                >
                    {outcome.text}
                </OutcomeButton>
            })}
        </div>
    )
};

export default Outcomes;