import React, { useState } from 'react';
import {ThemeProvider} from '@material-ui/styles';
import { getThemeByName } from './themes';

interface Props {
    theme: string
    children: React.ReactNode
}

const CustomThemeProvider = (props: Props) => {
    const [themeName, _setThemeName] = useState(props.theme);
    const theme = getThemeByName(themeName);

    return (
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    );
};


export default CustomThemeProvider;