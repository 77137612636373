import React, {useState, useEffect} from 'react';
import QuestionBlock from './question_block/QuestionBlock';
import NoQuestions from './NoQuestions';
import {makeStyles, Theme} from "@material-ui/core";
import {useQuestion} from '../../../contexts/nrl_afl/Question';
import QuestionBlockWrapper from '../shared/QuestionBlockWrapper';
import BottomBar from './BottomBar';
import CompletedBlock from './completed/CompletedBlock';
import {useGameState} from "../../../contexts/nrl_afl/GameState";
import {useHistory, useParams} from "react-router";
import {useNrlAflEntry} from "../../../contexts/nrl_afl/Entry";
import {useNetwork} from "../../../hooks";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {useTimeDrift} from "../../../contexts/TimeDrift";

const styles = makeStyles((theme: Theme) => ({
    mainWrapper: {
        padding: '45px 15px 10px',
    },
    additionalClass: {
        background: theme.palette.background.default,
        margin: '0px auto',
        padding: '10px 15px 0',
    },
    backToList: {
        display: 'inline-flex',
        marginTop: 20,
        fontWeight: 700,
        cursor: 'pointer'
    },
    chevron: {
        width: 7,
        height: 7,
        borderBottom: '2px solid ' + theme.palette.primary.contrastText,
        borderRight: '2px solid ' + theme.palette.primary.contrastText,
        transform: 'rotate(135deg)',
        display: 'block',
        margin: '6px 10px 0 5px'
    }
}));

interface Props {
    sport: string
    setPrevPageForLeaderboard: (p: string) => void
}

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const QuestionsWrapper = (props: Props) => {
    const classes = styles();
    const {contest_id} = useParams<{ contest_id: string }>();
    const [timeExpired, setTimeExpired] = useState(false);

    const {currentQuestion, questionsLeft} = useQuestion();
    const {gameState} = useGameState();
    const {entry} = useNrlAflEntry();

    const now = new Date();
    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const network = useNetwork();
    const diff = useTimeDrift();

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openInternetConnectionSnackbar, setOpenInternetConnectionSnackbar] = React.useState(false);


    useEffect(() => {
        setOpenInternetConnectionSnackbar(!network)
    }, [network])

    useEffect(() => {
        if (currentQuestion && currentQuestion.end.getTime() > (now.getTime() + diff)) {
            const timeout = setTimeout(() => {
                forceUpdate()
            }, currentQuestion.end.getTime() - (now.getTime() + diff))

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [currentQuestion]);

    useEffect(() => {
        const periodsCount = props.sport === 'NRL' ? 2 : 4;

        if (gameState && entry) {
            if (gameState.period === periodsCount && (entry.settlementStatus === 'WON' || entry.settlementStatus === 'LOSS')) {
                history.push(`/${contest_id}/leaderboard` + history.location.search)
            }
        }

    }, [gameState, entry])

    const finishedBlock = ((questionsLeft === 0 && gameState && gameState.clockInSeconds > 180) || gameState?.isBlockLocked);

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleCloseInternetConnection = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenInternetConnectionSnackbar(false);
    };

    return (
        <>
            <BottomBar sport={props.sport}/>
            <div className={classes.mainWrapper}>

                {gameState ?
                    finishedBlock ?
                        <CompletedBlock sport={props.sport}
                                        setPrevPageForLeaderboard={props.setPrevPageForLeaderboard}/>
                        : currentQuestion ?
                            <>
                                <QuestionBlockWrapper
                                    additionalClass={classes.additionalClass}
                                    question={currentQuestion}
                                    setTimeExpired={setTimeExpired}
                                    timeExpired={timeExpired}>
                                    <QuestionBlock setOpenSnackbar={setOpenSnackbar}/>

                                </QuestionBlockWrapper>
                                <span className={classes.backToList} onClick={() => {
                                    history.push(`/` + history.location.search)
                                }}>
                                <span className={classes.chevron}/>All Games
                                </span>
                            </>
                            :
                            <NoQuestions/>
                    : <NoQuestions/>
                }
            </div>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    An error occurred. Please try again.
                </Alert>
            </Snackbar>

            <Snackbar open={openInternetConnectionSnackbar} autoHideDuration={6000}
                      onClose={handleCloseInternetConnection}>
                <Alert onClose={handleCloseInternetConnection} severity="warning">
                    Internet connection lost.
                </Alert>
            </Snackbar>
        </>
    );
};

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default QuestionsWrapper;