import {gql} from '@apollo/client';

export const NRL_CONTEST = gql`
    query getNrlContest($id: Int!) {
        nrl {
           contest(id: $id) {
               id
               currentBlock
               status
               startTime
               isLocked
               fixture {
                    id
                    homeTeam {
                        id
                        externalId
                        name
                    }
                    awayTeam {
                        id
                        externalId
                        name
                    }
               }
           }
        }
    }
`;