import React, {createContext, useContext, useEffect, useState} from 'react';
import {useMutation} from "@apollo/client";
import {GENERATE_AUTH} from "../apollo/cricket/mutations/GenerateAuth";
import { useLocation} from 'react-router-dom'


const defaultValue: {isAuth: boolean, username: null | string, national: boolean, fromSA: boolean} = {
    isAuth: false,
    username: '',
    national: false,
    fromSA: false,
}

const Auth = createContext(defaultValue)

interface Props {
    children: React.ReactNode
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const TOKEN_KEY = 'tokenv9'
export const USERNAME_KEY = 'usernamev2'
export const NATIONAL_KEY = 'nationalv1'
export const FROM_SA_KEY = 'fromsav1';

const AuthProvider = ({children}: Props) => {
    const query = useQuery();
    const [isAuth, setAuth] = useState(false)
    const [username, setUsername] = useState<string|null>(null)
    const [isNational, setIsNational] = useState(false);
    const [isFromSA, setIsFromSA] = useState(false);
    // delete old tokens
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith('tokenv') && key !== TOKEN_KEY) {
            localStorage.removeItem(key);
        }
    }
    const token = sessionStorage.getItem(TOKEN_KEY);
    const storedUsername = sessionStorage.getItem(USERNAME_KEY);
    const storedNational = Boolean(sessionStorage.getItem(NATIONAL_KEY))

    let injectedUsername = query.get("username");
    let externalId = query.get("clientid");
    const nobonus = query.get("nobonus") === 'true';
    const fromSA = query.get("fromSA") === 'true';
    const national = Boolean(query.get("national"));

    if (!externalId) {
        throw Error('Auth failed 1')
    }

    if (!injectedUsername) {
        throw Error('Auth failed 2')
    }

    const [generateAuthMutation, {data, loading, error}] = useMutation(GENERATE_AUTH, {
        variables: {
            externalId,
            username: injectedUsername,
            nobonus,
            national,
            fromSA,
        }
    });

    useEffect(() => {
        if (token && storedUsername && storedNational) {
            setAuth(true)
            setUsername(storedUsername)
            setIsNational(storedNational)
        } else {
            if (!loading && !error && injectedUsername) {
                if (data) {
                    localStorage.setItem(TOKEN_KEY, data.generateAuth.token);
                    localStorage.setItem(USERNAME_KEY, data.generateAuth.username);
                    localStorage.setItem(NATIONAL_KEY, data.generateAuth.national);
                    localStorage.setItem(FROM_SA_KEY, data.generateAuth.fromSA);

                    setAuth(true)
                    setUsername(data.generateAuth.username);
                    setIsNational(data.generateAuth.national);
                    setIsFromSA(data.generateAuth.fromSA);
                } else {
                    generateAuthMutation()
                }
            } else if (error) {
                console.error(error)
            } else if (!injectedUsername) {
                console.error('Username not given')
            }
        }
    }, [data, loading, error])



    return (
        <Auth.Provider value={{isAuth, username, national: isNational, fromSA: isFromSA}}>
            {children}
        </Auth.Provider>
    )
}

const useAuth = () => {
    const context = useContext(Auth)
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }

    return context
}

export {AuthProvider, useAuth}

