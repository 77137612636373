import { useMutation } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useEntain } from "../../../../entain/hooks/useEntain";
import { useTracking } from "../../../../entain/hooks/useTracking";
import notify from "../../assets/notify.png";
import { MAX_PERIOD } from "../../constants";
import {
  NotifyMeDocument,
  NotifyMeForContestDocument,
  NotifyMeForContestMutation,
  NotifyMeForContestMutationVariables,
  NotifyMeMutation,
  NotifyMeMutationVariables,
  RnNotifyMeDocument,
  RnNotifyMeMutation,
  RnNotifyMeMutationVariables,
} from "../../gql/operations.generated";

const styles = makeStyles((theme: Theme) => ({
  button: {
    background: theme.palette.info.light,
    minWidth: 100,
    fontSize: 12,
    height: 30,
    fontWeight: 700,
    textAlign: "center",
    margin: "40px auto auto",
    position: "relative",
    cursor: "pointer",
    paddingLeft: 25,
    paddingRight: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 0,
    borderRadius: 5,
  },
  notify: {
    display: "block",
    width: 20,
    height: 22,
    position: "absolute",
    left: 3,
  },
}));

interface Props {
  click: () => void;
  blockId: string;
  blockNumber: number;
  isListPage: boolean;
  contestId?: string;
}

const NotifyMeButton = (props: Props) => {
  const classes = styles();
  const [buttonWasClicked, setButtonWasClicked] = useState(false);
  const tracking = useTracking();
  const { insideCouchQuizApp, couchQuizAppPushToken } = useEntain();

  const [notifyMe, { error: notifyMeError }] = useMutation<
    NotifyMeMutation,
    NotifyMeMutationVariables
  >(NotifyMeDocument);
  const [rnNotifyMe, { error: rnNotifyMeError }] = useMutation<
    RnNotifyMeMutation,
    RnNotifyMeMutationVariables
  >(RnNotifyMeDocument);
  const [notifyMeForContest, { error: notifyMeForContestError }] = useMutation<
    NotifyMeForContestMutation,
    NotifyMeForContestMutationVariables
  >(NotifyMeForContestDocument);

  const text =
    props.blockNumber > MAX_PERIOD ? "Notify Me Next Game" : "Notify Me";

  useEffect(() => {
    const e =
      notifyMeError?.message ||
      notifyMeForContestError?.message ||
      rnNotifyMeError?.message;
    if (e) {
      tracking.event("notify clicked error", {
        event_label: props.contestId,
        contestId: props.contestId,
        blockId: props.blockId,
        blockNumber: props.blockNumber,
        sport: process.env.REACT_APP_BUILD_TARGET,
        error: e,
      });
    }
  }, [
    notifyMeError?.message,
    notifyMeForContestError?.message,
    props,
    rnNotifyMeError?.message,
    tracking,
  ]);

  const onClick = useCallback(() => {
    if (!buttonWasClicked) {
      setButtonWasClicked(true);

      if (props.isListPage) {
        notifyMeForContest({ variables: { contestId: props.contestId! } });
      } else if (insideCouchQuizApp) {
        rnNotifyMe({
          variables: {
            blockId: props.blockId,
            blockNumber: props.blockNumber,
            pushToken: couchQuizAppPushToken ?? "",
          },
        });
      } else {
        notifyMe({
          variables: { blockId: props.blockId, blockNumber: props.blockNumber },
        });
      }

      tracking.event("notify clicked", {
        event_label: props.contestId,
        contestId: props.contestId,
        blockId: props.blockId,
        blockNumber: props.blockNumber,
        sport: process.env.REACT_APP_BUILD_TARGET,
      });
      props.click();
    }
  }, [
    buttonWasClicked,
    couchQuizAppPushToken,
    insideCouchQuizApp,
    notifyMe,
    notifyMeForContest,
    props,
    rnNotifyMe,
    tracking,
  ]);

  return (
    <div className={classes.button} onClick={onClick}>
      <img src={notify} alt="bell" className={classes.notify} />
      {text}
    </div>
  );
};

export default NotifyMeButton;
