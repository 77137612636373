import React from 'react';
import classNames from "classnames";
import {makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'auto',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'auto'
        },
        '& > a': {
            textDecoration: 'none'
        },
        background: `url('/nba-background.png')`,
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        '@media only screen and (orientation: landscape)': {
            backgroundImage: `url('/nba-background-landscape.jpg')`
        }
    },
    rootInner: {
        maxWidth: '100%',
        margin: 'auto',
        position: 'relative',
        height: '100%',
        '@media only screen and (max-width: 650px)': {
            maxWidth: 650,
        },
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
        },
    },
    wrapperForBall: {
        position: 'relative',
        paddingTop: 50
    }
}));

interface Props {
    children: React.ReactNode
    loader: React.ReactNode
}

const LoadingScreen = (props: Props) => {
    const classes = styles();

    return (
        <div
            className={classNames(classes.root)}>
            <div className={classes.rootInner}>
                {props.children}
                <div className={classes.wrapperForBall}>
                    {props.loader}
                </div>

            </div>
        </div>
    )
};

export default LoadingScreen;