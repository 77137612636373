import { useEffect, useMemo, useState } from 'react';
import { formatting } from '../utils/formatting';

export const useCountDown = (startDate: string) => {
    const startDateInSeconds = useMemo(() => (new Date(startDate)).getTime() / 1000, [startDate]);
    const [display, setDisplay] = useState('0s');

    useEffect(() => {
        const interval = setInterval(() => {
            const secondsToStart = startDateInSeconds - Math.floor(Date.now() / 1000);

            // prevent count down to go negative and clear the interval
            if (secondsToStart < 0) {
                setDisplay('0s');
                clearInterval(interval);
                return;
            }

            const newDisplay = formatting.COUNTDOWN(secondsToStart);
            setDisplay(newDisplay);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [startDateInSeconds]);

    return { display };
};