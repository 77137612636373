import React, {useEffect, useRef, useState} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import loader from '../../../../assets/animation/Loading-spinner.json';
import lottie from "lottie-web";

const styles = makeStyles((theme: Theme) => ({
    root: {
        margin: 'auto auto 15px',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 16,
        fontWeight: 700,
        borderRadius: 4,
        textAlign: 'center',
        cursor: 'pointer',
        height: 40,
        overflow: 'hidden',
        width: '80%',
        backgroundColor: '#FBFBFC',
        color: '#000',
        '@media only screen and (max-width: 1024px)': {
            width: '100%',
        },
    },
    button: {
        width: '100%',
        position: 'relative',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000',

    },
    answerText: {
        color: '#000',
        position: 'relative',
        zIndex: 2
    },
    selectedAnswer: {
        border: '2px solid #7BD2FA',
        borderRadius: 4,
        background: '#7BD2FA',
    },
    greyBg: {
        background: '#7BD2FA',
    },
    spinner: {
        display: 'block',
        width: 45,
        height: 45,
        position: 'absolute',
        right: -3,
        top: -13,
        zIndex: 10
    },
}));

interface Props {
    children: React.ReactNode
    onClick: any
    buttonId: number
    clickedButtonId: number
    timeExpired: boolean
    selected: boolean | null
    shadow?: boolean
    setClickedButton: (b: number) => void
}

const OutcomeButton = (props: Props) => {
    const classes = styles();
    const {children, onClick, buttonId, clickedButtonId, timeExpired, selected, shadow, setClickedButton} = props;
    const lottieRef = useRef(null);

    useEffect(() => {
        if (selected) setClickedButton(buttonId)

        const anim = lottie.loadAnimation({
            animationData: JSON.parse(JSON.stringify(loader)),
            loop: true,
            renderer: 'svg',
            container: lottieRef.current!,
            autoplay: true,
        });

        return () => {
            anim.stop();
            anim.destroy();
            lottie.stop();
            lottie.destroy();
        }

    }, [clickedButtonId]);

    return (
        <div
            style={{boxShadow: shadow ? '#000000CE 0 3px 6px' : ''}}
            className={classNames(classes.root,
                (!timeExpired && (clickedButtonId !== -1 && clickedButtonId === buttonId))
                    ? classes.greyBg : null)}
            onClick={() => {
                onClick()
            }}
        >
            <div className={classNames(classes.button, (clickedButtonId === buttonId) ? classes.selectedAnswer : null)}>
                <span className={classNames(classes.answerText)}>
                    {children}
                </span>

                {clickedButtonId === buttonId &&
                    <div ref={lottieRef} style={{height: 65, width: 65, margin: 'auto'}} className={classes.spinner}/>
                }
            </div>
        </div>
    )
};

export default OutcomeButton;