import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {AFL_ENTAIN_QUESTIONS_LIST} from "../../apollo/afl/queries/EntainAdminQuestionsList";
import {NRL_ENTAIN_QUESTIONS_LIST} from "../../apollo/nrl/queries/EntainAdminQuestionsList";
import {useQuery} from "@apollo/client";
import {transformAdminQuestions} from '../../utils';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Outcome, Question} from "./types";
import classNames from "classnames";
import {useHistory} from "react-router";

const styles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        fontSize: 18,
        paddingBottom: 30
    },
    accDetails: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    accordion: {
        background: theme.palette.secondary.dark,
        color: theme.palette.primary.contrastText,
        width: '100%'
    },
    eachLine: {
        flexWrap: 'wrap'
    },
    questionRow: {
        paddingBottom: 10,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        color: theme.palette.info.contrastText,
    },
    questionText: {
        width: '100%',
        paddingBottom: 5,
        fontWeight: 'bold'
    },
    questionNumber: {
        paddingRight: 10
    },
    outcomesRow: {
        width: '100%',
        paddingBottom: 5,
        display: 'flex',
    },
    eachOutcome: {
        paddingRight: 15
    },
    correct: {
        color: '#1a772d',
        fontWeight: 'bold'
    },
    expandIcon: {
        color: theme.palette.info.contrastText,
    },
    titleGradingText: {
        fontWeight: 'bold',
        paddingBottom: 5,
        width: '100%',
    },
    eachGrading: {
        width: '100%',
        paddingBottom: 5
    },
    blockTitle: {
        fontWeight: 'bold',
        color: theme.palette.info.contrastText,
    },
    leaderboardLink: {
        paddingBottom: 10,
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    },
    linkIcon: {
        width: 15,
        marginLeft: 5
    }
}))

interface Props {
    contestId: number
    sport: string
}

const AccordionDetailsComponent = (props: Props) => {
    const classes = styles();
    const {contestId, sport} = props;
    const history = useHistory();

    const query = sport === 'AFL' ? AFL_ENTAIN_QUESTIONS_LIST : NRL_ENTAIN_QUESTIONS_LIST;

    const {data, loading, error} = useQuery(query, {
        variables: {
            id: contestId
        }
    });

    const [expanded, setExpanded] = React.useState<number | false>(false);

    const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const goToLeaderborad = () => {
        history.push(`/entain-admin/${contestId}/leaderboard`)
    }

    if (data && !loading && !error) {
        const path = sport === 'AFL' ? data.afl.contest.questionResults : data.nrl.contest.questionResults;
        const questionsList = transformAdminQuestions(path);

        return (
            <AccordionDetails className={classes.accDetails}>

                <div className={classes.leaderboardLink} onClick={() => goToLeaderborad()}>
                    Go to leaderboard <ArrowForwardIosIcon
                    className={classes.linkIcon}/>
                </div>

                {Object.keys(questionsList).map((r: string, j: number) => {

                    return (
                        <Accordion expanded={expanded === j} onChange={handleChange(j)} className={classes.accordion}
                                   TransitionProps={{unmountOnExit: true}} key={j}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.expandIcon}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <span className={classes.blockTitle}>Block {r}</span>
                            </AccordionSummary>

                            <AccordionDetails className={classes.eachLine}>
                                {questionsList[r].map((q: Question, j: number) => {

                                    return (
                                        <div key={j} className={classes.questionRow}>

                                            <div className={classes.questionText}>
                                                <span className={classes.questionNumber}>Q#{q.questionNumber}</span>
                                                {q.text}
                                            </div>

                                            <div className={classes.outcomesRow}>
                                                {q.outcomes.map((o: Outcome, i: number) => {
                                                    return (
                                                        <span key={i} className={classNames(classes.eachOutcome,
                                                            o.correct === true ? classes.correct : '')}>
                                                        A{i + 1}: {o.text}</span>
                                                    )
                                                })}
                                            </div>

                                            {q.messages.length > 0 &&
                                                <>
                                                    <div className={classes.titleGradingText}>Grading Notes:</div>
                                                    <ul>
                                                        {q.messages.map((m: string, k: number) => {
                                                            return (
                                                                <li key={k} className={classes.eachGrading}>{m}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            }
                                        </div>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </AccordionDetails>
        )
    } else {
        return <div className={classes.title}> LOADING....</div>
    }
};

export default AccordionDetailsComponent;