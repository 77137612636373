import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useEntry } from "../../contexts/Entry";
import { useGameState } from "../../contexts/GameState";
import GameStats from "../common/GameStats";
import Layout from "../common/Layout";
import LoadingScreenSpinner from "../common/LoadingScreenSpinner";
import QuestionsWrapper from "./QuestionsWrapper";

interface Props {
  setPrevPageForLeaderboard: (p: string) => void;
}

const Question = (props: Props) => {
  const { setPrevPageForLeaderboard } = props;
  const history = useHistory();
  const { entry, isEntryLoaded } = useEntry();
  const { gameState } = useGameState();
  const { contest_id } = useParams<{ contest_id: string }>();

  useEffect(() => {
    if (gameState?.contestStatus === "COMPLETED") {
      history.push(`/${contest_id}/leaderboard` + history.location.search);
    }
  }, [contest_id, gameState?.contestStatus, history]);

  const renderContent = () => {
    if (isEntryLoaded && !entry) {
      return <LoadingScreenSpinner>Invalid Entry</LoadingScreenSpinner>;
    }

    switch (gameState?.questionStatus) {
      case "WAITING":
      case "INPROGRESS":
      case "COMPLETED":
        return (
          <>
            <GameStats showCountdownOnly={true} />
            <QuestionsWrapper
              setPrevPageForLeaderboard={setPrevPageForLeaderboard}
            />
          </>
        );
      case undefined:
        return <LoadingScreenSpinner />;

      default:
        return <LoadingScreenSpinner>Error..........</LoadingScreenSpinner>;
    }
  };

  return (
    <AnimatePresence>
      <Layout>{renderContent()}</Layout>
    </AnimatePresence>
  );
};

export default Question;
