// Shared colours - taken from the Entain React Native application code
export const colors = {
    white: '#ffffff',
    offWhite: '#fbfbfb',
    primaryContrast: '#ffffff',
    secondaryContrast: '#ffffff',
    transparentWhite: 'rgba(255, 255, 255, 0.9)',

    success: '#5ab708',
    successBright: '#7bc936',
    successDark: '#418b00',
    successBg: '#ffffff',

    info: '#0066cc',
    infoBright: '#0072e3',
    infoDark: '#194ca8',
    infoBg: '#f0f9fc',
    infoAlert: '#EBF5FF',

    warning: '#ffd283',
    warningBright: '#ffebc7',
    warningDark: '#b26200',
    warningBg: '#ffffff',

    danger: '#b12a2c',
    dangerBright: '#c94b4c',
    dangerDark: '#8c0a0c',
    dangerBg: '#ffffff',

    greenBright: '#449100',

    yellowBase: '#ffd200',
    yellowBright: '#ffd33d',
    yellowDark: '#ff9d00',

    black: '#000',
    greyDarkest: '#2a2b2c',
    greyDarker: '#30363a',
    greyDark: '#454545',
    greyLightDark: '#393939',
    greyBase: '#4e4e4e',
    greyMedium: '#9196a1',
    greyMediumDark: '#525865',
    greyLight: '#e4e4e4',
    greyLighter: '#e8e8e8',
    greyLightest: '#f2f2f2',
    greyBright: '#D5D5D5',
    greyBrighter: '#F7F7F7',
    greyBrightest: '#F8F8F8',
    greyPlatinum: '#E9E9E9',
    greyCultured: '#EFEFEF',

    orange: '#FFA800',
    lightOrange: '#FFFAEF',
    brightOrange: '#FCC462',

    subtleText: '#787675',
    darkGrey: '#A4A4A4',
    veryLightGrey: '#CCCCCC',
    aliceBlue: '#F1F3F5',
    avatarBlue: '#3E66FB',
    brightBlue: '#7DD0FF',
    skyBlue: '#0F9EF4',

    lightPink: '#FFE1E1',

    whiteSmoke: '#f5f5f5',
    skeleton: '#d6d6d6',

    transparent: 'transparent',

    betMapsColors: {
        entrant: [
            '#ff6770', // red
            '#10D99C', // green
            '#5484ff', // blue
        ],
        heatMap: [
            'rgba(0, 0, 255, 0)',
            'hsla(225, 73%, 57%, 0.5)',
            'hsla(180, 100%, 50%, 0.5)',
            'hsla(158, 100%, 67%, 0.5)',
            'hsla(105, 100%, 58%, 0.6)',
        ],
        navy: '#2d3642',
        white: '#ffffff',
        darkGrey: '#232323',
        deselectedPolygonWash: 'rgba(40, 40, 40, 0.4)',
        fillOutlineColor: 'rgba(0, 0, 0, 0.25)',
        newFeatureBubble: '#2c3540',
    },
    betMapsShadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,

        elevation: 3,
    },
};
