import React, {useEffect, useState} from 'react';
import Selection from '../../selection'
import GameWrapper from './GameWrapper';
import WonBlock from "./live/WonBlock";
import BrickedBlock from "./live/BrickedBlock";
import {AnimatePresence} from 'framer-motion';
import {useParams} from "react-router";
import {useCricketState} from "../../../contexts/cricket/CricketState";
import {Batsman} from "../../../../../shared/types";
import {useEntry} from "../../../contexts/cricket/Entry";
import Live from "./live";
import OutAnnouncement from "../../selection/OutAnnouncement";
import {useGameStatus} from "../../../contexts/cricket/GameStatus";
import {makeStyles, Theme} from "@material-ui/core";
import LoadingScreenBall from "../../common/cricketLoaders/LoadingScreeenBall";
import PlayerLayer from '../../common/UI/PlayerLayer';
import ResultsPending from './live/ResultsPending';
import Abandoned from '../../abandoned';
import Delayed from '../../delayed';

const styles = makeStyles((theme: Theme) => ({
    waiting: {
        display: 'block',
        width: '100%',
        marginBottom: 10
    }
}));

interface Props {
    agreedRules: boolean
    setPrevPageForLeaderboard: (p: string) => void
}

export type InternalGameState =
    'loading'
    | 'first-inning-selection'
    | 'abandoned'
    | 'questions'
    | 'out-selection'
    | 'innings-break'
    | 'second-inning-selection'
    | 'pending-results'
    | 'won'
    | 'loss'
    | 'rain-delay'

const QuestionPageIndex = (props: Props) => {
    const classes = styles();
    const {setPrevPageForLeaderboard} = props;
    const {contest_id} = useParams<{ contest_id: string }>();
    const contestId = parseInt(contest_id, 10);
    const {entry, isEntryLoaded} = useEntry();
    const {gameStatus, isStatusLoaded} = useGameStatus();

    const [selectionFade, setSelectionFade] = useState<boolean>(false);
    const [openBatsmenList, setOpenBatsmenList] = useState(false);

    const [selectedBatsman, setSelectedBatsman] = useState<null | Batsman>(null);
    const [internalGameState, setInternalGameState] = useState<InternalGameState>('loading');
    const {cricketState} = useCricketState();

    useEffect(() => {
        if (isEntryLoaded && isStatusLoaded) {
            if (gameStatus.contestStatus === 'ABANDONED') {
                setInternalGameState('abandoned');
            } else if (entry.settlementStatus === 'WON') {
                setInternalGameState('won');
            } else if (entry.settlementStatus === 'LOSS') {
                setInternalGameState('loss');
            } else if (gameStatus.inningStatus === 'RAIN_DELAY') {
                setInternalGameState('rain-delay');
            } else if (gameStatus.contestStatus === 'COMPLETED' && entry.settlementStatus === 'NOT_SETTLED') {
                setInternalGameState('pending-results');
            } else if (!entry.currentBatsman && gameStatus.inningStatus === "FIRST" && !entry.hasSelectedFirst && cricketState && cricketState.batsmans.length >= 2) {
                setInternalGameState('first-inning-selection');
            } else if (!entry.currentBatsman && gameStatus.inningStatus === "PREGAME" && !entry.hasSelectedFirst && cricketState && cricketState.batsmans.length >= 2) {
                setInternalGameState('first-inning-selection');
            } else if (!entry.currentBatsman && gameStatus.inningStatus === "SECOND" && !entry.hasSelectedSecond && cricketState && cricketState.batsmans.length >= 2) {
                setInternalGameState('second-inning-selection');
            } else if (((!entry.currentBatsman && !entry.isFirstComplete) || (!entry.currentBatsman && gameStatus.inningStatus === "SECOND")) && cricketState && cricketState.batsmans.length >= 2) {
                setInternalGameState('out-selection');
            } else {
                setInternalGameState('questions');
            }
        }
    }, [entry, isEntryLoaded, gameStatus, isStatusLoaded]);


    useEffect(() => {
        setSelectionFade(selectedBatsman !== null);
    }, [selectedBatsman]);

    return (
        <AnimatePresence>

            {internalGameState === 'first-inning-selection'
            || internalGameState === 'second-inning-selection' ?
                <Selection fade={selectionFade} setSelectedBatsman={setSelectedBatsman}/>

                : internalGameState === 'loading' ?
                    <PlayerLayer>
                        <LoadingScreenBall>
                            Just a minute, <br/>we are just warming up.
                        </LoadingScreenBall>
                    </PlayerLayer>

                    : internalGameState === 'questions' || internalGameState === 'out-selection'
                    || internalGameState === 'pending-results' || internalGameState === 'won'
                    || internalGameState === 'loss' || internalGameState === 'rain-delay'
                    || internalGameState === 'abandoned' ?

                        <GameWrapper openBatsmenList={openBatsmenList}
                                     setOpenBatsmenList={setOpenBatsmenList}
                        >
                            {internalGameState === 'questions' ?
                                <Live setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                      setOpenBatsmenList={setOpenBatsmenList}
                                />

                                : internalGameState === 'out-selection' ?
                                    <OutAnnouncement/>

                                    : internalGameState === 'pending-results' ?
                                        <ResultsPending setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                                        contestId={contestId}/>

                                        : internalGameState === 'abandoned' ?
                                            <Abandoned isAbsolute={false} isLimitedWidth={false}/>

                                            : internalGameState === 'rain-delay' ?
                                                <Delayed/>


                                                : internalGameState === 'won' ?
                                                    <WonBlock/>

                                                    : internalGameState === 'loss' ?
                                                        <BrickedBlock/>

                                                        : null
                            }
                        </GameWrapper>

                        : <PlayerLayer>
                            <LoadingScreenBall>
                                Error..........
                            </LoadingScreenBall>
                        </PlayerLayer>
            }

        </AnimatePresence>

    );
};

export default QuestionPageIndex;