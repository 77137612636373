import React from "react";
import {GET_CONTESTS, NrlContest} from '../../../apollo/nrl/queries/GetContests';
import {useQuery} from "@apollo/client";
import {makeStyles, Theme} from "@material-ui/core";
import NrlAflLayout from '../../common/UI/NrlAflLayout';
import {useAuth} from "../../../contexts/Auth";
import LoadingScreenSpinner from '../../common/nrlLoaders/LoadingScreenSpinner';
import logo from "../../../assets/CQ-logo.png";

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: '0px 30px 20px',
    },
    logo:{
        display: 'block',
        width: 250,
        height: 140,
        margin: 'auto'
    }
}));

interface ChildProps {
    sorted?: NrlContest[];
}

interface Props {
    sport: string
    children: React.ReactElement<ChildProps>
}

function Homepage(props: Props) {
    const classes = styles();
    const {isAuth} = useAuth();

    const {loading, error, data} = useQuery(GET_CONTESTS, {
        skip: !isAuth
    });

    if (!loading && !error && data) {
        let sorted = [...data.nrl.contests].sort((a: NrlContest, b: NrlContest) => {
            if (a.startTime > b.startTime) {
                if (a.status === 'COMPLETED' && b.status === 'COMPLETED') {
                    return 0
                } else if (a.status === 'COMPLETED' && b.status !== 'COMPLETED') {
                    return 1
                } else if (a.status !== 'COMPLETED' && b.status === 'COMPLETED') {
                    return -1
                }
                return 1
            }

            return -1
        });

        const childrenWithProps = React.Children.map(props.children, child => {
            if (React.isValidElement(child)) {

                return React.cloneElement(child, {
                    sorted: sorted
                });
            }

            return child;
        });

        return <>{childrenWithProps}</>;
    }

    return (
        <NrlAflLayout>
            <div className={classes.wrapper}>
                <img src={logo} alt="logo" className={classes.logo}/>
                <LoadingScreenSpinner/>
            </div>
        </NrlAflLayout>
    )
}

export default Homepage;