import {createTheme} from '@material-ui/core/styles';

export const cricketTheme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
            'sans-serif'
        ].join(','),
    },
    palette: {
        primary: {
            main: '#42434A',
            contrastText: '#fff',
            light: '#00000029',// shadow color
            dark: '#000' // text color
        },
        secondary: {
            main: '#0000004D',
            contrastText: '#3E4347', //tab button
            light: '#17305A',
            dark: '#B1B1B1'
        },
        info: {
            main: '#00000080',
            contrastText: '#2D2F3195', //divider color
            light: '#eb3638',
            dark: '#a50d0c'
        },
        background: {
            default: '#FFFFFF21',
            paper: '#FBD93B'
        },
        grey: {
            100: '#8CC63F',
            200: '#FF0159',
            300: '#00DBB6',
            400: '#3ECE80',
            500: '#FFFFFFC3',
            600: '#0000004E', //card shadow
            700: '#7EAEFF',
            800: '#FA4F59',
            900: '#c8c64c'
        }
    },
});