import React, {useEffect} from "react";
import noWin from "../../../assets/NO-WIN.png";
import niceOne from '../../../assets/NICE-ONE.png';
import {GET_CONTESTS, Contest} from '../../../apollo/cricket/queries/GetContests';
import {useQuery} from "@apollo/client";
import titleImg from "../../../assets/CC-Logo.png";
import {makeStyles, Theme} from "@material-ui/core";
import CricketFixtureCardWrapper from '../cricket/CricketFixtureCardWrapper';
import fireball from '../../../assets/animation/Fireball.gif';
import NoContests from "../shared/NoContests";
import PlayerLayer from '../../common/UI/PlayerLayer';
import {useAuth} from "../../../contexts/Auth";
import LoadingScreenBat from '../../common/cricketLoaders/LoadingScreenBat';
import {useHistory} from "react-router-dom";
import spinner from '../../../assets/animation/spinner.gif';
import lockout from '../../../assets/sadface.png';
import rain from '../../../assets/rain.png';
import emptyleaderboard from '../../../assets/emptyleaderboard.png';
import FixtureCard from '../shared/fixture_card/FixtureCard';

const styles = makeStyles((theme: Theme) => ({
    title: {
        fontWeight: 800,
        fontSize: 25,
        paddingBottom: 15,
        display: 'block',
        textAlign: 'center'
    },
    titleImg: {
        '@media only screen and (max-width: 1024px)': {
            width: 250
        },
        '@media only screen and (min-width: 1025px)': {
            width: '80%',
            maxWidth: 515,
            paddingBottom: 20
        },
        '@media only screen and (max-height: 690px)': {
            width: 180
        }
    },
    comebackText: {
        padding: '0 0 20px',
        width: '80%',
        margin: 'auto',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 14,
        '@media only screen and (max-height: 690px)': {
            display: 'none'
        }
    },
    prevContests: {
        fontSize: 13,
        cursor: 'pointer',
        textAlign: 'center',
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
        '& > $chevron': {
            width: 7,
            height: 7,
            borderBottom: '1px solid ' + theme.palette.primary.contrastText,
            borderRight: '1px solid ' + theme.palette.primary.contrastText,
            transform: 'rotate(-45deg)',
            display: 'block',
            marginLeft: 5,
            marginTop: 6
        },
    },
    chevron: {}
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void
}

function Homepage(props: Props) {
    const allImages = [
        '/BackgroundGreen.jpg',
        '/Home_Background.jpg',
        '/BACKGROUND_DESKTOP.png',
        '/QUESTION-BACKGROUND.jpg',
        '/Background-questions.jpg',
        niceOne,
        noWin,
        fireball,
        spinner,
        lockout,
        rain,
        emptyleaderboard
    ];
    let timeout1: any = null;
    const classes = styles();
    const {isAuth} = useAuth();
    const history = useHistory();

    const {loading, error, data} = useQuery(GET_CONTESTS, {
        skip: !isAuth
    });

    useEffect(() => {
        return () => {
            clearTimeout(timeout1);
        }
    }, []);

    const cacheImages = async (srcArray: string[]) => {
        const promises = await srcArray.map((src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();

                img.src = src;
                img.onload = resolve;
                img.onerror = reject
            })
        });

        await Promise.all(promises)
    };
    timeout1 = setTimeout(() => {
        cacheImages(allImages).catch(e => console.warn(`caught on main: ${e}`))
    }, 0);

    if (!loading && !error && data) {
        let sorted = [...data.contests].sort((a: Contest, b: Contest) => {
            if (a.estimatedStartTime > b.estimatedStartTime) {
                if (a.status === 'COMPLETED' && b.status === 'COMPLETED') {
                    return 0
                } else if (a.status === 'COMPLETED' && b.status !== 'COMPLETED') {
                    return 1
                } else if (a.status !== 'COMPLETED' && b.status === 'COMPLETED') {
                    return -1
                }
                return 1
            }

            return -1
        });

        return (
            <PlayerLayer>

                <div className={classes.title}>
                    <img src={titleImg} className={classes.titleImg} alt="Ladbrokes Couch Cricket"/>
                </div>

                {sorted.length > 0 ?

                    <>

                        {sorted.slice(0, 3).map((contest: Contest) => (
                            <FixtureCard setPrevPageForLeaderboard={props.setPrevPageForLeaderboard}
                                         contestId={contest.id}
                                         entryId={contest.entry ? contest.entry.id : undefined}
                            >
                                <CricketFixtureCardWrapper key={contest.id}
                                                           contest={contest}
                                                           entryId={contest.entry ? contest.entry.id : undefined}
                                                           setPrevPageForLeaderboard={props.setPrevPageForLeaderboard}/>
                            </FixtureCard>
                        ))}
                    </>

                    : <NoContests/>}

                <div className={classes.prevContests} onClick={() => {
                    history.push('previous_results/' + history.location.search)
                }}><span>View previously entered contests</span> <span className={classes.chevron}/></div>

            </PlayerLayer>
        );
    }

    return (
        <PlayerLayer>

            <div className={classes.title}>
                <img src={titleImg} className={classes.titleImg} alt="Ladbrokes Couch Cricket"/>
            </div>
            <LoadingScreenBat/>
        </PlayerLayer>
    )


}

export default Homepage;