import { makeStyles, Theme } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { SportsPSGMCardList } from "../../../../entain/components/SportsPSGMCardList/SportsPSGMCardList";
import { useFeatureFlag } from "../../../../entain/hooks/useFeatureFlag";
import { isOfCompetitionName } from "../../../../entain/hooks/useFetchPSGMs";
import { useGameState } from "../../contexts/GameState";
import { useQuestion } from "../../contexts/Question";
import { useNetwork } from "../../hooks/useNetwork";
import AFLKicksBlock from "./afl-kicks-block/AFLKicksBlock";
import GenericBlock from "./generic-block/GenericBlock";
import NoMoreQuestionsForNowBlock from "./no-more-questions-for-now-block/NoMoreQuestionsForNowBlock";
import QuestionBlock from "./question-block/QuestionBlock";
import NoQuestions from "./NoQuestions";
import QuestionBlockWrapper from "./QuestionBlockWrapper";
import QuizEndedBlock from "./quiz-ended-block/QuizEndedBlock";

const styles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    padding: "45px 15px 10px",
  },
  questionsCount: {
    fontSize: 22,
    marginBottom: 25,
    textAlign: "center",
  },
  additionalClass: {
    background: theme.palette.background.default,
    margin: "0px auto",
    padding: "10px 15px 0",
  },
  backToListContainer: {
    margin: "0 auto",
  },
  backToList: {
    display: "inline-flex",
    marginTop: 20,
    fontWeight: 700,
    cursor: "pointer",
  },
  chevron: {
    width: 7,
    height: 7,
    borderBottom: "2px solid " + theme.palette.primary.contrastText,
    borderRight: "2px solid " + theme.palette.primary.contrastText,
    transform: "rotate(135deg)",
    display: "block",
    margin: "6px 10px 0 5px",
  },
  psgmSlider: {
    margin: "0 -15px",
  },
}));

interface Props {
  setPrevPageForLeaderboard: (p: string) => void;
}

const QuestionsWrapper = (props: Props) => {
  const classes = styles();
  const [timeExpired, setTimeExpired] = useState(false);

  const {
    aflKicksGameQuestions,
    currentQuestion,
    currentQuestionNumber,
    lastAnsweredQuestionIndex,
    questionsCount,
    questionsLeft,
  } = useQuestion();
  const { gameState } = useGameState();
  const isLastQuestionAnswered =
    lastAnsweredQuestionIndex + 1 === questionsCount;

  const network = useNetwork();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openInternetConnectionSnackbar, setOpenInternetConnectionSnackbar] =
    React.useState(false);

  const target = (process.env.REACT_APP_BUILD_TARGET ?? "").toUpperCase();
  const competitionName = isOfCompetitionName(target) ? target : undefined;
  const psgmEnabled = useFeatureFlag("pickfans-psgm");

  useEffect(() => {
    setOpenInternetConnectionSnackbar(!network);
  }, [network]);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseInternetConnection = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenInternetConnectionSnackbar(false);
  };

  const renderPSGM = () => {
    return psgmEnabled && competitionName ? (
      <div className={classes.psgmSlider}>
        <SportsPSGMCardList competitionName={competitionName} />
      </div>
    ) : null;
  };

  const renderContent = () => {
    if (
      gameState?.contestStatus === "INPROGRESS" &&
      gameState?.questionStatus === "WAITING"
    ) {
      // Show game is about to begin
      return <NoQuestions />;
    } else if (
      gameState?.contestStatus === "INPROGRESS" &&
      (gameState?.questionStatus === "INPROGRESS" ||
        gameState?.questionStatus === "COMPLETED") &&
      questionsLeft > 0 &&
      currentQuestion
    ) {
      // We have some questions left, questions generation is either in progress or completed, show the current question
      return currentQuestion.questionType === "AFL_KICKS_GAME" ? (
        <>
          <AFLKicksBlock
            question={currentQuestion}
            setOpenSnackbar={setOpenSnackbar}
          />

          <BackLinkComponent maxWidth={400} />
        </>
      ) : (
        <>
          <div className={classes.questionsCount}>
            {currentQuestionNumber ?? "-"} of {questionsCount}
          </div>

          <QuestionBlockWrapper
            additionalClass={classes.additionalClass}
            question={currentQuestion}
            setTimeExpired={setTimeExpired}
            timeExpired={timeExpired}
          >
            <QuestionBlock setOpenSnackbar={setOpenSnackbar} />
          </QuestionBlockWrapper>

          <BackLinkComponent maxWidth={500} />
        </>
      );
    } else if (
      gameState?.contestStatus === "INPROGRESS" &&
      gameState?.questionStatus === "INPROGRESS" &&
      questionsLeft === 0
    ) {
      // We don't have questions left, questions generation is yet to complete, show come back later
      return (
        <>
          <NoMoreQuestionsForNowBlock />
          <BackLinkComponent />
          {renderPSGM()}
        </>
      );
    } else if (
      gameState?.contestStatus === "INPROGRESS" &&
      gameState?.questionStatus === "COMPLETED" &&
      questionsLeft === 0 &&
      isLastQuestionAnswered
    ) {
      // We don't have questions left, questions generation is completed, last question is answered, show no more questions
      return (
        <>
          <QuizEndedBlock aflKicksGameQuestions={aflKicksGameQuestions} />
          <BackLinkComponent />
          {renderPSGM()}
        </>
      );
    } else if (
      gameState?.contestStatus === "INPROGRESS" &&
      gameState?.questionStatus === "COMPLETED" &&
      questionsLeft === 0 &&
      !isLastQuestionAnswered
    ) {
      // We don't have questions left, questions generation is completed, last question is not answered, show out of time
      return (
        <>
          <GenericBlock
            title="You have run out of time."
            description="Keep an eye on your results by checking out your progress on the leaderboard."
          />
          <BackLinkComponent />
          {renderPSGM()}
        </>
      );
    } else if (
      gameState?.contestStatus === "LOCKED" &&
      isLastQuestionAnswered
    ) {
      // Game locked with last question answered
      return (
        <>
          <QuizEndedBlock aflKicksGameQuestions={aflKicksGameQuestions} />
          <BackLinkComponent />
          {renderPSGM()}
        </>
      );
    } else if (
      gameState?.contestStatus === "LOCKED" &&
      !isLastQuestionAnswered
    ) {
      // Game locked with questions left
      return (
        <>
          <GenericBlock
            title="You have run out of time."
            description="Keep an eye on your results by checking out your progress on the leaderboard."
          />
          <BackLinkComponent />
          {renderPSGM()}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div
        className={classes.mainWrapper}
        style={{
          paddingTop:
            !currentQuestion || currentQuestion?.questionType === "AFL_KICKS_GAME"
              ? "15px"
              : "45px",
        }}
      >
        {renderContent()}
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          An error occurred. Please try again.
        </Alert>
      </Snackbar>

      <Snackbar
        open={openInternetConnectionSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseInternetConnection}
      >
        <Alert onClose={handleCloseInternetConnection} severity="warning">
          Internet connection lost.
        </Alert>
      </Snackbar>
    </>
  );
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface BackLinkComponentProps {
  maxWidth?: number;
}

function BackLinkComponent({ maxWidth = 400 }: BackLinkComponentProps) {
  const classes = styles();
  const history = useHistory();

  return (
    <div className={classes.backToListContainer} style={{ maxWidth }}>
      <span
        className={classes.backToList}
        onClick={() => {
          history.push(`/` + history.location.search);
        }}
      >
        <span className={classes.chevron} />
        All Games
      </span>
    </div>
  );
}

export default QuestionsWrapper;
