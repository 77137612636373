import React, {useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {AFL_GET_LEADERBOARD} from "../../../apollo/afl/queries/Leaderboard";
import {NRL_GET_LEADERBOARD} from "../../../apollo/nrl/queries/Leaderboard";
import {useQuery} from "@apollo/client";
import {createArrayWithTiedField} from "../../../utils";
import {AflNrlEntry} from "../../../../../shared/types";
import LoadingScreenSpinner from "../../common/nrlLoaders/LoadingScreenSpinner";
import TiedText from "../../leaderboard/shared/TiedText";
import TopTitles from "../../leaderboard/shared/TopTitles";
import LeaderboardEntry from "./LeaderboardEntry";
import PaginationComponent from "../../leaderboard/nrl_afl/PaginationComponent";
import TextField from '@material-ui/core/TextField';

const styles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        padding: '20px 0'
    },
    emptyText: {
        paddingTop: 20,
        fontSize: 20,
        fontWeight: 700
    },
    overRank: {
        color: '#6BFE00'
    },
    searchRow: {
        width: '100%',
        padding: '10px 0',
    },
    innerRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        height: 50,
        alignItems: 'center'
    },
    searchText: {
        paddingRight: 20
    },
    searchInput: {
        overflowY: 'visible',
        '& .MuiInputLabel-outlined, .MuiInputBase-root': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.contrastText
        },

    }
}))

interface Props {
    sport: string
    activeBlockId: number | null
}

const LeaderboardList = (props: Props) => {
    const classes = styles();
    const {sport, activeBlockId} = props;
    const [page, setPage] = React.useState(1);
    const [searchField, setSearchField] = useState('');

    const query = sport === 'AFL' ? AFL_GET_LEADERBOARD : NRL_GET_LEADERBOARD;

    const {loading, data, error} = useQuery(query, {
        variables: {
            blockId: Number(activeBlockId)
        },
    });

    const pageSize = 20;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const v = value ? value : 1;
        setPage(v);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchLine = event.target.value;

        if (searchLine.length > 2) {
            setSearchField(searchLine)
            setPage(1)
        } else {
            setSearchField('')
            setPage(1)
        }
    }

    if (data && !loading && !error) {
        let correctWay = sport === 'NRL' ? data.nrl.leaderboard.entries : data.afl.leaderboard.entries;

        const creatingNewList = createArrayWithTiedField<AflNrlEntry>(correctWay);
        let newList: (AflNrlEntry & { tied: boolean })[] = creatingNewList.newList;
        let isAnyTie = creatingNewList.isAnyTie;
        const paginationPages = Math.ceil(newList.length / pageSize);

        return (
            <div className={classes.root}>

                {newList.length > 0 ?
                    <>
                        <div className={classes.searchRow}>
                            <div className={classes.innerRow}>

                                <span className={classes.searchText}>Search thru usernames:</span>
                                <TextField id="outlined-search" className={classes.searchInput} onChange={handleSearch}
                                           label="Search" type="search" variant="outlined" size='small'/>
                            </div>
                        </div>


                        {isAnyTie ?
                            <TiedText/>
                            : null}

                        <TopTitles/>

                        <div>
                            {newList
                                .filter((l: AflNrlEntry) => l.username.indexOf(searchField) !== -1)
                                .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
                                .map((entry, i: number) => {
                                    return (
                                        <LeaderboardEntry key={i} entry={entry}
                                                          cashColor={classes.overRank}/>)
                                })}
                        </div>

                        {newList.length > pageSize &&
                            <PaginationComponent paginationPages={paginationPages}
                                                 handlePagination={handleChange}/>}
                    </>
                    : <div className={classes.emptyText}>No entries this quarter/half.</div>}
            </div>
        )
    } else {
        if (!data && !loading) {
            return (
                <div className={classes.emptyText}>No entries this quarter/half.</div>
            )
        }

        return (
            <>
                <div className={classes.emptyText}>Preparing Leaderboard</div>
                <LoadingScreenSpinner/>
            </>
        )
    }
};

export default LeaderboardList;