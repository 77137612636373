import {createTheme} from '@material-ui/core/styles';

export const nrlTheme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
            'sans-serif'
        ].join(','),
    },
    palette: {
        primary: {
            main: '#373848', //CHANGED, same as bg default because of TopBar bg
            contrastText: '#fff', //USING
            light: '#00000029',// shadow color
            dark: '#000' // text color
        },
        secondary: {
            main: '#0000004D',
            contrastText: '#3E4347', //tab button
            light: '#696A76', //CHANGED
            dark: '#8D8F9F' //CHANGED
        },
        info: {
            main: '#00000080',
            contrastText: '#2D2F3195', //divider color
            light: '#eb3638', //USING PLAY BUTTON
            dark: '#7F8090' //CHANGED disabled button
        },
        background: {
            default: '#373848', //CHANGED, fixture card bg
            paper: '#FFCE00' //CHANGED, yellow on leaderboard
        },
        grey: {
            100: '#C44348', //CHANGES loss color
            200: '#22B573', //CHANGED, won color
            300: '#858695', //CHANGED, over tier and dot
            400: '#B8291F',  //CHANGED, tier 5
            500: '#00D76F',  //CHANGED, tier 4
            600: '#0000004E', //card shadow
            700: '#8FD800',  //CHANGED, tier 3
            800: '#00D7D8',  //CHANGED, tier 2
            900: '#00A5D8' //CHANGED, tier 1
        }
    }
});