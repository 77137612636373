import {WebSocketLink} from "@apollo/client/link/ws";
import {ApolloClient, HttpLink, InMemoryCache, split} from "@apollo/client";
import {setContext} from '@apollo/client/link/context';
import {getMainDefinition} from "@apollo/client/utilities";
import {TOKEN_KEY} from "../contexts/Auth";
import ApolloLinkTimeout from 'apollo-link-timeout';

const websocketUri = process.env.REACT_APP_WEBSOCKET_URL ? process.env.REACT_APP_WEBSOCKET_URL : 'ws://localhost:4444/graphql'

const wsLink = new WebSocketLink({
    uri: websocketUri,
    options: {
        reconnect: true
    }
});

const timeoutLink = new ApolloLinkTimeout(100000); // default 100s timeout

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_BASE_URL
});

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(TOKEN_KEY);
    // token is not required for entain admin pages
    const isEntainAdmin = window.location.pathname.includes('entain-admin');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            ...(isEntainAdmin ? {} : { authorization: token ? `Bearer ${token}` : "", }),
        }
    }
});

//@ts-ignore
const timeoutHttpLink = timeoutLink.concat(authLink.concat(httpLink))

const link = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    timeoutHttpLink
);

export const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache()
});