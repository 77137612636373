import { useContext } from 'react';
import { EntainContext } from '../components/core/EntainProvider';

export const useEntain = () => {
    const context = useContext(EntainContext);

    if (context === undefined) {
        throw new Error('useEntain must be used inside an EntainProvider');
    }

    return context;
};
