import { makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import correct from "../../../assets/correct.png";
import unselected from "../../../assets/unselected-tick.png";
import wrong from "../../../assets/wrong.png";

const styles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto auto 15px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: 16,
    fontWeight: 700,
    borderRadius: 4,
    textAlign: "center",
    cursor: "pointer",
    transition: "all 0.25s",
    height: 30,
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#FBFBFC",
    color: "#000",
  },
  button: {
    width: "100%",
    position: "relative",
    textAlign: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  answerText: {
    color: "#000",
    position: "relative",
    zIndex: 2,
  },
  correctBorder: {
    border: "1px solid #29B573",
    borderRadius: 4,
  },
  wrongBorder: {
    border: "1px solid #D13333",
    borderRadius: 4,
  },
  greyBorder: {
    border: "1px solid #8D8F9F",
    borderRadius: 4,
  },
  percentage: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "0%",
    transition: "width 0.2s linear",
  },
  correctPercentage: {
    background: "#29B573",
  },
  wrongPercentage: {
    background: "#D13333",
  },
  greyPercentage: {
    background: theme.palette.secondary.dark,
  },
  percentageAmount: {
    display: "block",
    position: "absolute",
    color: theme.palette.primary.contrastText,
    fontWeight: 800,
    fontSize: 10,
    right: 9,
    top: 7,
  },
  hide: {
    display: "none",
  },
  greyBg: {
    background: "#696A76",
  },
  selected: {
    background: "#7BD2FA",
  },
  rightAlignedText: {
    position: "absolute",
    left: 5,
    fontSize: 10,
    top: 7,
    color: theme.palette.primary.contrastText,
  },
  buttonWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 50px",
  },
  pic: {
    display: "block",
    width: 20,
    height: 20,
    margin: "5px 0 0 10px",
    boxShadow: "#0000005C 0 3px 6px",
    borderRadius: "100%",
  },
  tick: {
    display: "block",
    width: 18,
    height: 14,
    margin: "6px 0 0 10px",
  },
}));

interface Props {
  children: React.ReactNode;
  buttonId: number;
  selected: boolean;
  percentage: number[];
  isPending: boolean;
  isCorrect: boolean | undefined | null;
  notSelected: boolean;
  correctOutcome: boolean | undefined | null;
}

const QuestionButton = (props: Props) => {
  const classes = styles();
  const {
    children,
    buttonId,
    selected,
    percentage,
    isPending,
    isCorrect,
    notSelected,
    correctOutcome,
  } = props;

  return (
    <div className={classes.buttonWrapper}>
      <div
        style={{ boxShadow: isPending ? "#000000CE 0 3px 6px" : "" }}
        className={classNames(classes.root, !isPending ? classes.greyBg : null)}
      >
        <div
          className={classNames(
            classes.button,
            !isPending
              ? selected && isCorrect
                ? classes.correctBorder
                : selected && !isCorrect
                ? classes.wrongBorder
                : classes.greyBorder
              : selected
              ? classes.selected
              : ""
          )}
        >
          <>
            {!isPending && (
              <div
                className={classNames(
                  classes.percentage,
                  selected && isCorrect
                    ? classes.correctPercentage
                    : selected && !isCorrect
                    ? classes.wrongPercentage
                    : classes.greyPercentage
                )}
                style={{
                  width: percentage.length > 0 ? percentage[buttonId] + "%" : 0,
                }}
              />
            )}
            <span
              className={
                percentage.length > 0 ? classes.percentageAmount : classes.hide
              }
              style={{ color: isPending ? "#000" : "#fff" }}
            >
              {percentage[buttonId]}%
            </span>
          </>

          <span
            className={classNames(
              classes.answerText,
              !isPending && percentage.length > 0 && classes.rightAlignedText
            )}
          >
            {children}
          </span>
        </div>
      </div>

      {!isPending && selected ? (
        <img
          src={isCorrect ? correct : wrong}
          alt="question"
          className={classes.pic}
        />
      ) : null}

      {notSelected && correctOutcome && (
        <img src={unselected} alt="unselected" className={classes.tick} />
      )}
    </div>
  );
};

export default QuestionButton;
