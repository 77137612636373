import { useQuery } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import { useEffect } from "react";
import Slider from "react-slick";
import correct from "../../../assets/correct.png";
import wrong from "../../../assets/wrong.png";
import { useContest } from "../../../contexts/Contest";
import {
  GetResultsDocument,
  GetResultsQuery,
  GetResultsQueryVariables,
} from "../../../gql/operations.generated";
import LoadingScreenSpinner from "../../common/LoadingScreenSpinner";
import AnsweredProgressBar from "./AnsweredProgressBar";
import EachQuestion from "./EachQuestion";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    padding: "0px 5px",
    color: theme.palette.primary.contrastText,
  },
  rootInner: {
    background: theme.palette.background.default,
    borderRadius: 12,
    width: "100%",
    padding: "15px 20px",
    boxShadow: "#0000005C 0 3px 6px",
  },
  topInner: {
    background: theme.palette.background.default,
    borderRadius: 6,
    width: "100%",
    padding: "5px 10px",
    boxShadow: "#0000005C 0 3px 6px",
    marginBottom: 15,
  },
  resultSlider: {
    marginBottom: 5,

    "& .slick-list": {
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },

    "& .slick-track": {
      paddingRight: 0,
      width: "100% !important",
    },

    "& .slick-slide": {
      marginLeft: 0,
      marginRight: 10,
      width: "15px !important",
    },
  },
  resultsTitle: {
    color: theme.palette.grey[300],
    textAlign: "left",
    paddingBottom: 5,
  },
  correctAnswers: {
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    paddingLeft: 10,
  },
  answerIcon: {
    display: "block",
    width: "15px !important",
    height: 15,
  },
  pending: {
    display: "block",
    width: "15px !important",
    height: 15,
    borderRadius: "100%",
    background: theme.palette.secondary.light,
  },
  nonchecked: {
    display: "block",
    width: "15px !important",
    height: 15,
    borderRadius: "100%",
    border: "1px solid " + theme.palette.secondary.light,
  },
  emptyText: {
    paddingTop: 20,
    fontSize: 20,
    fontWeight: 700,
  },
}));

interface Props {
  points: number | undefined;
  blockId: string;
  activeEntry: boolean;
  now: number;
}

const Results = (props: Props) => {
  const { contest } = useContest();
  const classes = styles();
  const { points, blockId, activeEntry } = props;

  const { data, loading, error, startPolling, stopPolling } = useQuery<
    GetResultsQuery,
    GetResultsQueryVariables
  >(GetResultsDocument, {
    variables: {
      blockId,
    },
  });

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  let correctPercentage = 0;

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 15,
    slidesToScroll: 1,
    swipe: false,
    touchMove: false,
    swipeToSlide: false,
    className: classes.resultSlider,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (data && !error && !loading) {
    const receivedData = data.afl.questions;
    let correctAnswers = 0;
    let answers: string[] = []; //C, I, P, N //correct, incorrect, pending, notchecked

    receivedData.forEach((q, i) => {
      const selectedOutcome = q.outcomes.filter((o) => o.isSelected);
      const nonChecked = selectedOutcome.length === 0;
      const isPending =
        selectedOutcome.length > 0 && selectedOutcome[0].correct === null;
      const isCorrect =
        selectedOutcome.length > 0 ? selectedOutcome[0].correct : false;

      if (isCorrect) correctAnswers += 1;
      correctPercentage = points
        ? Math.floor((correctAnswers / receivedData.length) * 100)
        : 0;

      if (nonChecked) {
        answers.push("N");
      } else if (isPending) {
        answers.push("P");
      } else if (isCorrect) {
        answers.push("C");
      } else if (!isCorrect) {
        answers.push("I");
      }
    });

    return receivedData.length > 0 ? (
      <div className={classes.root}>
        {activeEntry && (
          <div className={classes.topInner}>
            {answers.length > 0 && (
              <>
                <div className={classes.resultsTitle}>Results Summary</div>

                <Slider {...settings}>
                  {answers.map((a: string, i: number) => {
                    return a === "C" ? (
                      <img
                        src={correct}
                        alt=""
                        key={i}
                        className={classes.answerIcon}
                      />
                    ) : a === "I" ? (
                      <img
                        src={wrong}
                        alt=""
                        key={i}
                        className={classes.answerIcon}
                      />
                    ) : a === "P" ? (
                      <span className={classes.pending} key={i} />
                    ) : a === "N" ? (
                      <span className={classes.nonchecked} key={i} />
                    ) : null;
                  })}
                </Slider>
              </>
            )}

            <div className={classes.resultsTitle}>Your Percentage</div>
            <AnsweredProgressBar percentage={correctPercentage} />

            <div className={classes.resultsTitle}>
              Questions Correct
              <span className={classes.correctAnswers}>
                {correctAnswers} of {receivedData.length}
              </span>
            </div>
          </div>
        )}

        <div className={classes.rootInner}>
          {receivedData.map((q, n) => {
            const selectedOutcome = q.outcomes.filter((o) => o.isSelected);
            const isPending =
              selectedOutcome.length > 0
                ? selectedOutcome[0].correct === null
                : false;
            const isCorrect =
              selectedOutcome.length > 0 ? selectedOutcome[0].correct : false;
            const questionText = q.text;

            const notSelected = q.outcomes.every((element) => element.isSelected === false);
            const contestNotInScheduleOrInprogress = !(
              contest?.status === "SCHEDULED" ||
              contest?.status === "INPROGRESS"
            );

            const hasBeenAnsweredBySomeone = q.outcomes.some((o) => o.count > 0);

            return (
              (contestNotInScheduleOrInprogress || hasBeenAnsweredBySomeone) && (
                <EachQuestion
                  isCorrect={isCorrect}
                  isPending={isPending}
                  outcomes={q.outcomes}
                  pos={n + 1}
                  key={n}
                  questionText={questionText}
                  notSelected={notSelected}
                  points={q.points ?? 0}
                />
              )
            );
          })}
        </div>
      </div>
    ) : (
      <div className={classes.emptyText}>No questions yet.</div>
    );
  } else {
    if (!data && !loading) {
      return <div className={classes.emptyText}>No entries.</div>;
    }
    return (
      <>
        <div className={classes.emptyText}>Preparing Results</div>
        <LoadingScreenSpinner />
      </>
    );
  }
};

export default Results;
