import {gql} from '@apollo/client';

export const NBA_CONTEST_SUBSCRIPTION = gql`
  subscription nbaContest($id: Int!) {
    nbaContest(id: $id) {
        id
        status
        startTime
        isLocked
    }
  }
`;

