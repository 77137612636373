import React, {useState, useEffect} from 'react';
import QuestionBlock from './questions/QuestionBlock';
import NoQuestions from './NoQuestions';
import TopBar from "../TopBar";
import QuestionTopBarWrapper from './QuestionTopBarWrapper';
import {useParams} from "react-router";
import {makeStyles, Theme} from "@material-ui/core";
import {useQuestion} from '../../../../contexts/cricket/Question';
import QuestionBlockWrapper from '../../shared/QuestionBlockWrapper';

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        overflow: 'visible',
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    additionalClass:{
        width: '90% !important',
        padding: '10px 15px',
        maxWidth: 500,
        borderRadius: 8,
        marginBottom: 35,
        display: 'flex !important',
        flexWrap: 'wrap',
        height: 'auto !important',
        transform: 'scale(1.1)',
        overflow: 'visible',
        position: 'relative',
        '@media only screen and (max-width: 1024px)': {
            margin: 'auto',
        },
        '@media only screen and (min-width: 1025px)': {
            margin: '0 auto'
        }
    }
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void
    setOpenBatsmenList: (o: boolean) => void
}

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Live = (props: Props) => {
    const classes = styles();
    const {setPrevPageForLeaderboard, setOpenBatsmenList} = props;
    const [timeExpired, setTimeExpired] = useState(false);

    const {currentQuestion} = useQuestion();

    const {contest_id} = useParams<{ contest_id: string }>();
    const now = new Date();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (currentQuestion && currentQuestion.start.getTime() > now.getTime()) {
            const timeout = setTimeout(() => {
                forceUpdate()
            }, currentQuestion.start.getTime() - now.getTime())

            return () => {
                clearTimeout(timeout)
            }
        }

    }, [currentQuestion]);

    return (
        <>
            <TopBar setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                    setOpenBatsmenList={setOpenBatsmenList}/>
            <div className={classes.wrapper}>
                <QuestionTopBarWrapper/>
            </div>
            {currentQuestion && currentQuestion.start.getTime() < now.getTime() ?

                <QuestionBlockWrapper
                    additionalClass={classes.additionalClass}
                    question={currentQuestion}
                    setTimeExpired={setTimeExpired}
                    timeExpired={timeExpired}>
                    <QuestionBlock/>
                </QuestionBlockWrapper>

                :
                <NoQuestions/>
            }

        </>
    );
};

export default Live;