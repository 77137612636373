import { makeStyles } from '@material-ui/core';
import React, { SVGAttributes } from 'react';

export type Type = 'misc' | 'product' | 'sports';

type IconProps = {
    color?: string;
    height?: number;
    id: string;
    type: Type;
    width?: number;
} & SVGAttributes<SVGElement>;

export const Icon = ({ color, height, id, type, width, ...rest }: IconProps) => {
    const classes = styles();
    // We will refactor as more brand is needed.
    const brand = 'ladbrokes';

    return (
        <svg {...rest} id={id} className={classes.svg} style={{ flexBasis: width }} width={width} height={height} fill={color} version="1.1">
            <use xlinkHref={`/entain/images/${brand}/sprites/svg-${type}-icon.svg#${id}`} />
        </svg>
    );
};

const styles = makeStyles(() => ({
    svg: {
        flexGrow: 0,
        flexShrink: 0,
    },
}));