import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useGameState } from "../../contexts/GameState";
import BackRow from "./BackRow";
import LeaderboardList from "./LeaderboardList";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    boxSizing: "border-box",
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    "@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)":
      {
        maxWidth: "100%",
        overflow: "auto",
      },
    "& > a": {
      textDecoration: "none",
    },
    background: `url('/AFL-Background.jpg')`,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: "100vh",
    "@media only screen and (orientation: landscape)": {
      backgroundImage: `url('/AFL-Background-Landscape.jpg')`,
    },
  },
  rootInner: {
    maxWidth: "100%",
    margin: "auto",
    height: "100vh",
    "@media only screen and (max-width: 650px)": {
      maxWidth: 650,
    },
    "@media only screen and (min-width: 651px) and (max-width: 1024px)": {
      maxWidth: 700,
    },
  },
  topPart: {
    background: theme.palette.background.default,
    height: 55,
  },
}));

const Index = () => {
  const classes = styles();
  const { gameState, isStatusLoaded } = useGameState();
  const [activeBlockId, setBlockId] = useState<string | null>(null);

  useEffect(() => {
    if (isStatusLoaded && gameState) {
      setBlockId(gameState.currentBlock);
    }
  }, [isStatusLoaded, gameState]);

  return (
    <div className={classNames(classes.root)}>
      <div className={classes.rootInner}>
        <div className={classes.topPart}>
          <BackRow />
        </div>

        <LeaderboardList activeBlockId={activeBlockId} />
      </div>
    </div>
  );
};

export default Index;
