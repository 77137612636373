import { formatDistanceToNowStrict } from 'date-fns';

// As per Entain Core Repo > modules > formatting.ts
class Formatting {
    public COUNTDOWN(totalSeconds = 0): string {
        // Return early if 0 seconds or invalid number
        if (!Number(totalSeconds)) return '0s';
        const MILLISECONDS = 1000; // The number of milliseconds in a second.
        const MINUTE = 60;
        const HOUR = MINUTE * 60;
        const DAY = HOUR * 24;
        // Count
        const pastJump = totalSeconds <= 0;
        const prefix = `${pastJump ? '-' : ''}`;
        const timeToJumpInSec = Math.abs(totalSeconds);
    
        // >= 24 hrs
        const days = Math.floor(timeToJumpInSec / DAY);
        if (days) return `${prefix}${formatDistanceToNowStrict(Date.now() + (timeToJumpInSec * MILLISECONDS), { unit: 'day' })}`;
    
        // >=2 hrs
        const hours = Math.floor(timeToJumpInSec / HOUR);
        const hourStringPrefixed = `${prefix}${hours}h`;
        if (hours >= 2) return hourStringPrefixed;
    
        // >= 1 hr
        const minutes = Math.floor(timeToJumpInSec / MINUTE) % 60;
        const minuteString = `${minutes}m`;
        if (hours && minutes) return `${hourStringPrefixed} ${minutes}m`;
        if (hours) return hourStringPrefixed;
    
        // >= 5 mins
        if (minutes >= 5) return `${prefix}${minuteString}`;
    
        // >= 1 min
        const seconds = Math.floor(timeToJumpInSec % MINUTE);
        const secondString = `${seconds}s`;
        if (minutes && seconds) return `${prefix}${minuteString} ${secondString}`;
        if (minutes) return `${prefix}${minuteString}`;
    
        // > 0 seconds
        if (seconds) return `${prefix}${secondString}`;
    
        // 0 seconds
        return `${secondString}`;
    }

    public kebabCase(str?: string): string {
        const strRegex = (str || '').match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
        if (!strRegex) return '';
        return strRegex.map((x) => x.toLowerCase()).join('-');
    }

    public SLUG(str: string): string {
        return this.kebabCase(str);
    }
}

export const formatting = new Formatting();