import { useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
    GetGameStateDocument,
    GetGameStateQuery,
    GetGameStateQueryVariables,
    SubscribeGameStateDocument,
    SubscribeGameStateSubscription,
    SubscribeGameStateSubscriptionVariables,
} from '../gql/operations.generated';
import { GameState } from '../types';

const GameStateContext = createContext<{
    gameState: GameState | null;
    isStatusLoaded: boolean;
}>({
    gameState: null,
    isStatusLoaded: false,
});

interface Props {
    contestId?: string;
    children: React.ReactNode;
}

const GameStateProvider = (props: Props) => {
    const [gameState, setState] = useState<GameState | null>(null);
    const [isStatusLoaded, setIsStatusLoaded] = useState<boolean>(false);
    const { contest_id } = useParams<{ contest_id: string }>();
    const { contestId, children } = props;
    const finalContestId = typeof contestId === 'string' ? contestId : contest_id ?? '';

    const { data, error, loading, subscribeToMore } = useQuery<GetGameStateQuery, GetGameStateQueryVariables>(
        GetGameStateDocument,
        {
            variables: {
                contestId: finalContestId,
            },
            pollInterval: 13000,
        }
    );

    useEffect(() => {
        if (data?.afl && !error && !loading) {
            setState(data.afl.gameState);
            setIsStatusLoaded(true);
        }
    }, [data?.afl, error, loading]);

    useEffect(() => {
        if (loading || !finalContestId || !subscribeToMore) return;

        const unsubscribe = subscribeToMore<SubscribeGameStateSubscription, SubscribeGameStateSubscriptionVariables>({
            document: SubscribeGameStateDocument,
            variables: {
                contestId: finalContestId,
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev;
                }

                setState(subscriptionData.data.aflGameState);

                return {
                    afl: {
                        gameState: subscriptionData.data.aflGameState,
                    },
                };
            },
        });

        return () => unsubscribe?.();
    }, [finalContestId, loading, subscribeToMore]);

    return <GameStateContext.Provider value={{ gameState, isStatusLoaded }}>{children}</GameStateContext.Provider>;
};

const useGameState = () => {
    const context = useContext(GameStateContext);
    if (context === undefined) {
        throw new Error('useGameState must be used within a GameStateProvider');
    }

    return context;
};

export { GameStateProvider, useGameState };
