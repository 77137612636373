import { Theme } from "@material-ui/core";
import { cricketTheme } from "./cricketTheme";
import { nbaTheme } from "./nbaTheme";
import { nrlTheme } from "./nrlTheme";
import { aflTheme } from "./aflTheme";

export function getThemeByName(theme: string): Theme {
    return themeMap[theme];
}

const themeMap: { [key: string]: Theme } = {
    cricketTheme,
    nbaTheme,
    nrlTheme,
    aflTheme
};