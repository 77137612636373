import React from 'react';
import picture from '../../../assets/Batsmen_Image.png'
import {makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        paddingTop: 50
    },
    picture: {
        width: '50%',
        maxWidth: 150,
        margin: 'auto'
    },
    text: {
        paddingTop: 30,
        fontSize: 20,
        fontWeight: 700
    }
}));

const NoAnsweredQuestions = () => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <img src={picture} alt="Batsman" className={classes.picture}/>
            <div className={classes.text}>Your answers will appear here</div>
        </div>
    )
};

export default NoAnsweredQuestions;