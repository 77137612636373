import {gql} from '@apollo/client';

export const ENTRY_SUBSCRIPTION = gql`
    subscription entry($contestId: Int!, $entryId: Int!) {
        entry(contestId: $contestId, entryId: $entryId) {
            id
            currentBatsman {
                id
                key
                name
                teamKey
            }
            selectedBatsmen {
                id
                key
                name
                points
                isOut
                isComplete
                teamKey
            }
            score {
                quizPoints
                runPoints
                rank
                winnings
                points
                wicketPoints
            }
            settlementStatus
            hasSelectedFirst
            hasSelectedSecond
            isFirstComplete
        }
    }
`;

