import { useQuery } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import {
  GetEntainAdminPreviousContestsDocument,
  GetEntainAdminPreviousContestsQuery,
  GetEntainAdminPreviousContestsQueryVariables,
} from "../../gql/operations.generated";
import ListComponent from "./ListComponent";

const styles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    fontSize: 18,
    paddingBottom: 30,
  },
}));

const List = () => {
  const classes = styles();
  const { data, loading, error } = useQuery<
    GetEntainAdminPreviousContestsQuery,
    GetEntainAdminPreviousContestsQueryVariables
  >(GetEntainAdminPreviousContestsDocument);

  if (data && !loading && !error) {
    const contestsList = [...data.afl.previous].sort(
      (a, b) => Number(b.fixture.startTime) - Number(a.fixture.startTime)
    );
    return <ListComponent contestsList={contestsList} />;
  } else {
    return <div className={classes.title}> LOADING....</div>;
  }
};

export default List;
