import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const styles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: `url('/AFL-Background.jpg')`,
    backgroundSize: "cover",
    width: "100%",
    overflow: "auto",
    boxSizing: "border-box",
    color: theme.palette.primary.contrastText,
    height: "100vh",
    "@media only screen and (orientation: landscape)": {
      backgroundImage: `url('/AFL-Background-Landscape.jpg')`,
    },
  },
  transparentBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  won: {
    background: "rgba(0, 199, 110, 0.1)",
  },
  loss: {
    background: "rgba(190, 0, 0, 0.1)",
  },
  rootInner: {
    margin: "auto",
    position: "relative",
    maxWidth: 650,
    // padding: '40px 30px 20px',
  },
}));

interface Props {
  children: React.ReactNode;
  won?: boolean;
  loss?: boolean;
}

const Layout = (props: Props) => {
  const classes = styles();

  return (
    <div className={classes.root}>
      {props.won && (
        <div
          className={classNames(classes.transparentBackground, classes.won)}
        />
      )}
      {props.loss && (
        <div
          className={classNames(classes.transparentBackground, classes.loss)}
        />
      )}

      <div className={classes.rootInner}>{props.children}</div>
    </div>
  );
};

export default Layout;
