import reactStringReplace from 'react-string-replace';
import { makeStyles } from '@material-ui/core';

import { NBA_TEAM_MAP } from '../../../entain/utils/pick-fans';
import { SportsTeamIcon } from '../../../entain/components/core/SportsTeamIcon';

interface Props {
    displayText: string;
    size: number;
}

const TOKEN_REGEX = /\{(.*?)}/;

const TeamIconFormatter = ({ displayText, size }: Props) => {
    const classNames = styles({ size });

    const result = reactStringReplace(displayText, TOKEN_REGEX, (token, index) => {
        const teamIconName = NBA_TEAM_MAP[token]?.iconName || '';
        return (
            <span className={classNames.inlineIcon} key={index}>
                {' '}
                <SportsTeamIcon
                    className={'inline-icon'}
                    competitionName="NBA"
                    teamName={teamIconName}
                    width={size}
                    height={size}
                    border={0}
                />
            </span>
        );
    });
    return <>{result}</>;
};

const styles = makeStyles(() => ({
    inlineIcon: {
        position: 'relative',
        top: ({ size }: { size: number }) => {
            return Math.ceil(size / 5);
        },
    },
}));

export default TeamIconFormatter;
