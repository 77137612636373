import React, {useEffect} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import AflList from './AflList';
import NrlList from './NrlList';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        background: `url('/NRL-BG.jpg')`,
        backgroundSize: 'cover',
        height: '100vh',
        overflowY: 'scroll'
    },
    inner: {
        width: 800,
        margin: "auto",
        padding: 20
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        fontSize: 18,
        paddingBottom: 30
    },
    tabs:{
        width: '100%',
        paddingBottom: 30,
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        fontSize: 18,
        '& .MuiTabs-flexContainer':{
            justifyContent: 'center'
        },
        '& .MuiTabs-indicator':{
            backgroundColor: theme.palette.primary.contrastText,
        },
    }
}))

interface Props {
    setEntainAdminChosenSport: (s: string) => void
}

const Index = (props: Props) => {
    const classes = styles();
    const [value, setValue] = React.useState(0);
    const {setEntainAdminChosenSport} = props;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        setEntainAdminChosenSport(newValue === 0 ? 'AFL' : 'NRL')
    };
    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <div className={classes.title}>Questions list</div>

                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.tabs}>
                    <Tab label="AFL questions list" />
                    <Tab label="NRL questions list" />
                </Tabs>

                {value === 0 &&  <AflList/>}
                {value === 1 &&  <NrlList/>}

            </div>
        </div>
    );
};

export default Index;