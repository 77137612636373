import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { useEntry } from "../../contexts/Entry";
import { useGameState } from "../../contexts/GameState";
import GameStats from "../common/GameStats";
import BackComponent from "./BackComponent";
import WinLossCheck from "./WinLossCheck";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    boxSizing: "border-box",
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    "@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)":
      {
        maxWidth: "100%",
        overflow: "auto",
      },
    "& > a": {
      textDecoration: "none",
    },
    background: `url('/AFL-Background.jpg')`,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: "100vh",
    "@media only screen and (orientation: landscape)": {
      backgroundImage: `url('/AFL-Background-Landscape.jpg')`,
    },
  },
  rootInner: {
    maxWidth: "100%",
    margin: "auto",
    height: "100vh",
  },
  topPart: {
    background: theme.palette.background.default,
    height: 55,
    maxWidth: 650,
    margin: "auto",
  },
  tabs: {
    display: "flex",
    maxWidth: 650,
    margin: `0 auto 15px`,
    background: theme.palette.background.default,
  },
  btn: {
    borderBottom: `3px solid ${theme.palette.background.default}`,
    cursor: "pointer",
    width: "50%",
    padding: 5,
    fontWeight: 400,
  },
  active: {
    borderBottomColor: theme.palette.primary.contrastText,
  },
  mainWrapper: {
    maxWidth: 550,
    margin: "auto",
    width: "100%",
    padding: "0px 15px 15px",
    zIndex: 1,
  },
  won: {
    background: "rgba(0, 199, 110, 0.1)",
  },
  loss: {
    background: "rgba(190, 0, 0, 0.1)",
  },
}));

interface Props {
  blockId: string;
  backToGame: () => void;
  prevPageForLeaderboard: string;
}

const Index = (props: Props) => {
  const classes = styles();
  const { backToGame, prevPageForLeaderboard } = props;
  const [tab, setTab] = useState(0);
  const [isWon, setIsWon] = useState<boolean | null>(null);
  const { gameState, isStatusLoaded } = useGameState();
  const { entry } = useEntry();

  const tabClick = (tab: number) => {
    setTab(tab);
  };

  return (
    <div className={classNames(classes.root)}>
      <div
        className={classNames(
          classes.rootInner,
          isWon !== null && !isWon
            ? classes.loss
            : isWon !== null && isWon
            ? classes.won
            : null
        )}
      >
        <div className={classes.topPart}>
          <BackComponent
            backToGame={backToGame}
            isWon={isWon}
            isLastPeriod={true}
            prevPageForLeaderboard={prevPageForLeaderboard}
          />
        </div>

        <GameStats showShadow={true} />

        <div className={classes.tabs}>
          <div
            className={classNames(
              classes.btn,
              tab === 0 ? classes.active : null
            )}
            onClick={() => tabClick(0)}
          >
            Leaderboard
          </div>
          <div
            className={classNames(
              classes.btn,
              tab === 1 ? classes.active : null
            )}
            onClick={() => tabClick(1)}
          >
            Results
          </div>
        </div>

        {isStatusLoaded && gameState?.currentBlock ? (
          <div className={classNames(classes.mainWrapper)}>
            <WinLossCheck
              setIsWon={setIsWon}
              isWon={isWon}
              tab={tab}
              activeBlockId={gameState?.currentBlock}
              activeEntry={entry}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Index;
