import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import Button from '../../../common/UI/SquareButton';
import {useHistory} from "react-router";
import NotifiedText from "../../../common/UI/NotifiedText";
import NotifyMeButton from "../../../common/UI/NotifyMeButton";
import {AflNrlGameState} from "../../../../../../shared/types";
import {AFL_HAS_REQUESTED_NOTIFICATION} from "../../../../apollo/afl/queries/HasRequestedNotification";
import {NRL_HAS_REQUESTED_NOTIFICATION} from "../../../../apollo/nrl/queries/HasRequestedNotification";
import {useQuery} from "@apollo/client";

const styles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: 14,
        fontWeight: 900,
        textAlign: 'center'
    },
    btnWrapper: {
        margin: '20px 0 10px',
        display: 'flex',
        justifyContent: 'center'
    }
}));

interface Props {
    sport: string
    gameState: AflNrlGameState
    showNotifyMeButton: boolean
}

const PendingResults = (props: Props) => {
    const classes = styles();
    const {gameState, showNotifyMeButton} = props;
    const [pressedNotifiedBtn, setPressedNotifiedBtn] = useState(false);
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const [isBtnStatusLoaded, setIsBtnStatusLoaded] = useState(false);
    const history = useHistory();

    const query = props.sport === 'AFL' ? AFL_HAS_REQUESTED_NOTIFICATION : NRL_HAS_REQUESTED_NOTIFICATION;

    const {loading, error, data} = useQuery(query, {
        variables: {
            blockId: gameState.currentBlock,
            blockNumber: gameState.period + 1,
        }
    });


    useEffect(() => {
        if (data && !loading && !error) {
            const way = props.sport === 'AFL' ? data.afl.hasRequestedNotification : data.nrl.hasRequestedNotification;
            setPressedNotifiedBtn(way);
            setIsBtnStatusLoaded(true)
        }
    }, [data]);

    const pluralSingular = (m: number) => {
        return `${m + (m === 1 ? ' Minute' : ' Minutes')}`
    }

    const notifyClick = () => {
        setPressedNotifiedBtn(true)
    }

    const click = () => {
        if (!buttonWasClicked) {
            setButtonWasClicked(true)
            history.push(`/` + history.location.search)
        }
    }

    return (
        <>
            <div className={classes.title}>All questions have finished</div>
            <div className={classes.btnWrapper}>

                {
                    pressedNotifiedBtn ?
                        <NotifiedText/>
                        :
                        showNotifyMeButton ?
                            <NotifyMeButton click={notifyClick} blockId={gameState.currentBlock}
                                            blockNumber={gameState.period + 1} isListPage={false}/>
                        : <Button buttonWasClicked={buttonWasClicked} onClick={() => click()} boxShadow={true} width='170px'
                                                  notDefaultColors='blue'>More Games</Button>
                }
            </div>
        </>
    )
};

export default PendingResults;