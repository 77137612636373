import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import titleImg from "../../../assets/CC-logo-big.png";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from '../../common/UI/Checkbox';
import {useHistory, useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {ENTER_CONTEST} from "../../../apollo/cricket/mutations/EnterContest";
import {useEntry} from '../../../contexts/cricket/Entry';
import PlayerLayer from '../../common/UI/PlayerLayer';
import Lockout from '../../lockout';
import {CONTEST} from "../../../apollo/cricket/queries/Contest";
import Button from "../../common/UI/SquareButton";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '0 30px',
        '@media only screen and (max-width: 600px)': {
            padding: '30px 0',
        }
    },
    innerRoot: {
        maxWidth: 500,
        width: '90%',
        margin: 'auto',
        color: theme.palette.primary.contrastText
    },
    title1: {
        fontSize: 24,
        fontWeight: 800,
        textAlign: 'center',
        paddingBottom: 20
    },
    row: {
        display: 'flex',
        width: '100%',
        paddingBottom: 25,
        overflow: 'visible'
    },
    list: {
        fontSize: 20,
        fontWeight: 800,
        position: 'relative',
        zIndex: 1,
        paddingLeft: 13,
        '&::before': {
            borderRadius: 100,
            background: theme.palette.grey[100],
            position: 'absolute',
            left: 0,
            top: -3,
            content: '""',
            zIndex: -1,
            width: 36,
            height: 36
        }
    },
    text: {
        fontSize: 18,
        paddingLeft: 25,
        fontWeight: 700
    },
    agreeRow: {
        display: 'flex',
        width: '100%',
        paddingTop: 45
    },
    checkbox: {
        border: 'green'
    },
    link: {
        fontSize: 14,
        color: '#007FFF',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        cursor: 'pointer'
    },
    label: {
        fontSize: 14,
        whiteSpace: 'nowrap'
    },
    wrapper: {
        marginRight: 5
    },
    title: {
        fontWeight: 800,
        fontSize: 25,
        paddingBottom: 15,
        display: 'block',
        textAlign: 'center'
    },
    titleImg: {
        '@media only screen and (max-width: 1024px)': {
            width: 250
        },
        '@media only screen and (min-width: 1025px)': {
            width: '80%',
            maxWidth: 515,
            paddingBottom: 20
        }
    },
    btnWrapper:{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 50
    }
}));

interface Props {
    setAgreedRules: (a: boolean) => void
    agreedRules: boolean
}

const Index = (props: Props) => {
    const classes = styles();
    const history = useHistory();
    const {setAgreedRules, agreedRules} = props;
    const {contest_id} = useParams<{ contest_id: string }>();
    const {entry, loadEntry} = useEntry();
    let timeout: any = null;
    const [isLocked, setIsLocked] = useState(false);
    const [buttonWasClicked, setButtonWasClicked] = useState(false);

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreedRules(event.target.checked);
    };

    const [enterContestMutation, {data, loading, error}] = useMutation(ENTER_CONTEST);

    const {loading: contestLoading, error: contestError, data: contestData} =
        useQuery(CONTEST, {
            variables: {
                id: Number(contest_id)
            }
        });

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (data && !loading && !error) {
            loadEntry(data.enterContest.entry);
            history.push(`question_page` + history.location.search)
        } else if (!loading && error) {
            console.error(error)
        }
    }, [data]);

    useEffect(() => {
        if (!contestLoading && !contestError && contestData) {

            if (contestData.contest.status !== 'INPROGRESS') {
                history.push(`/` + history.location.search)
            }

            if (contestData.contest.entry) {
                history.push(`question_page` + history.location.search)
            }

            if (!contestData.contest.entry) {
                timeout = setInterval(function () {
                    const now = new Date();
                    const lockout = new Date(Number(contestData.contest.lockOutTime) + 1000);

                    if (lockout < now) {
                        clearTimeout(timeout);
                        if (!contestData.contest.entry) {
                            setIsLocked(true)
                        }
                    }
                }, 1000);
            }
        }

    }, [contestData]);


    return (
        <PlayerLayer>

            {isLocked ?
                <Lockout/>
                :
                <>
                    <div className={classes.title}>
                        <img src={titleImg} className={classes.titleImg} alt="Ladbrokes Couch Cricket"/>
                    </div>

                    <div className={classes.root}>
                        <div className={classes.innerRoot}>
                            <div className={classes.title1}>How To Play</div>

                            <div className={classes.row}>
                                <span className={classes.list}>1</span>
                                <span className={classes.text}>Select a batsman</span>
                            </div>

                            <div className={classes.row}>
                                <span className={classes.list}>2</span>
                                <span className={classes.text}>Answer questions</span>
                            </div>

                            <div className={classes.row}>
                                <span className={classes.list}>3</span>
                                <span className={classes.text}>Climb the leaderboard</span>
                            </div>

                            <div className={classes.row}>
                                <span className={classes.list}>4</span>
                                <span className={classes.text}>Finish top 20 and win</span>
                            </div>

                            <div className={classes.agreeRow}>
                                <FormControlLabel
                                    control={
                                        <StyledCheckbox onChange={handleCheckbox}/>
                                    }
                                    label="Agree to"
                                    classes={{label: classes.label, root: classes.wrapper}}
                                />

                                <a className={classes.link}
                                   href="https://www.ladbrokes.com.au/rules-terms-and-conditions" target='_blank'>terms
                                    and conditions</a>
                            </div>

                            <div className={classes.btnWrapper}>
                                <Button width='167px' buttonWasClicked={buttonWasClicked} notDefaultColors='red'
                                        disabled={!agreedRules}
                                        onClick={(e: any) => {
                                            if (!buttonWasClicked && agreedRules) {
                                                setButtonWasClicked(true);
                                                e.preventDefault();

                                                if (entry.id === -1) {
                                                    enterContestMutation({variables: {contestId: Number(contest_id)}});
                                                } else {
                                                    history.push(`question_page` + history.location.search)
                                                }
                                            }
                                        }}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            }

        </PlayerLayer>
    )
};

export default Index;
