import { makeStyles, Theme } from "@material-ui/core";
import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
import loader from "../../assets/animation/loading-spinner.json";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    color: theme.palette.primary.contrastText,
    backgroundSize: "cover",
    "@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)":
      {
        maxWidth: "100%",
        overflow: "hidden",
      },
    "@media only screen and (max-width: 400px)": {
      maxWidth: 400,
      margin: "auto",
    },
  },
  rootInner: {
    padding: "80px 15px",
    maxWidth: 500,
    margin: "auto",
    position: "relative",
  },
  preventClicking: {
    position: "absolute",
    top: 10,
    left: 0,
    width: "100%",
    height: 450,
    zIndex: 100,
  },
  subTitle: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: 700,
    paddingBottom: 20,
  },
}));

interface Props {
  children?: React.ReactNode;
  childrenPicture?: React.ReactNode;
  childrenButtons?: React.ReactNode;
}

const LoadingScreenSpinner = (props: Props) => {
  const classes = styles();
  const lottieRef = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      animationData: JSON.parse(JSON.stringify(loader)),
      loop: true,
      renderer: "svg",
      container: lottieRef.current!,
      autoplay: true,
    });

    return () => {
      anim.stop();
      anim.destroy();
      lottie.stop();
      lottie.destroy();
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <div className={classes.preventClicking} />

        {props.childrenPicture}

        <div className={classes.subTitle}>{props.children}</div>

        <div
          ref={lottieRef}
          style={{ height: 250, width: 250, margin: "auto" }}
        />

        {props.childrenButtons}
      </div>
    </div>
  );
};

export default LoadingScreenSpinner;
