import { makeStyles, Theme } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import GenericButton from "../../../../common/components/GenericButton";

interface Props {
  title: string;
  description: string;
}

const GenericBlock = ({ title, description }: Props) => {
  const classes = styles();
  const history = useHistory();
  const { contest_id: contestId } = useParams<{ contest_id: string }>();

  const onLeaderboardButtonClick = () => {
    history.push(`/${contestId}/leaderboard` + history.location.search);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <strong>{title}</strong>
      </div>
      <div className={classes.description}>{description}</div>

      <div className={classes.buttonContainer}>
        <GenericButton onClick={onLeaderboardButtonClick} small width="150px">
          Leaderboard
        </GenericButton>
      </div>
    </div>
  );
};

const styles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    maxWidth: 400,
    margin: "0 auto",
    padding: "60px 25px 80px",
    position: "relative",
    textAlign: "center",
  },
  title: {
    fontSize: 20,
    marginBottom: 14,
    "@media only screen and (max-width: 450px)": {
      fontSize: 18,
    },
  },
  description: {
    fontSize: 16,
    "@media only screen and (max-width: 450px)": {
      fontSize: 14,
    },
  },
  buttonContainer: {
    bottom: 25,
    left: 0,
    position: "absolute",
    right: 0,
  },
}));

export default GenericBlock;
