import { gql} from '@apollo/client';

export const SELECT_BATSMAN = gql`
  mutation selectBatsman($entryId: Int!, $cricketerId: Int!) {
    selectBatsman(entryId: $entryId, cricketerId: $cricketerId) {
      success
      errorMessage
      entry {
        id
        currentBatsman {
            id
            key
            name
            teamKey
        }
        selectedBatsmen {
            id
            key
            name
            points
            isOut
            isComplete
            teamKey
        }
        score {
            quizPoints
            runPoints
            rank
            winnings
            points
            wicketPoints
        }
        settlementStatus
        hasSelectedFirst
        hasSelectedSecond
        isFirstComplete
      }
    }
  }
`;

