import {gql} from '@apollo/client';


export const GAME_STATUS_QUERY = gql`
    query gameStatus($contestId: Int!) {
        gameStatus(contestId: $contestId) {
            contestStatus
            inningStatus
        }
    }
`;



