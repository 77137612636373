import {gql} from '@apollo/client';

export const CRICKET_STATE_SUBSCRIPTION = gql`
    subscription CricketState($contestId: Int!) {
        cricketState(contestId: $contestId) {
            topTeam {
                code
                name
                runs
                wicketsOut
                isBatting
            }
            bottomTeam {
                code
                name
                runs
                wicketsOut
                isBatting
            }
            over
            ball
            batsmans {
                id
                key
                name
                teamKey
                isReceiver
                runs
                balls
            }
            bowler {
                id
                key
                name
                teamKey
                wickets
                runs
                overs
                balls
            }
        }
    }
`;

