import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import Countdown from "./Countdown";
import {useSubscription} from "@apollo/client";
import {NBA_CONTEST_SUBSCRIPTION} from '../../../apollo/nba/subscriptions/Contest';
import {getDateAndTime} from '../../../utils';
import {NbaContest} from "../../../apollo/nba/queries/GetContests";
import { SportsTeamIcon } from '../../../entain/components/core/SportsTeamIcon';
import { NBA_TEAM_MAP } from '../../../entain/utils/pick-fans';

const styles = makeStyles((theme: Theme) => ({
    teamName: {
        fontSize: 12,
        fontWeight: 700,
        width: '100%',
        textAlign: 'center',
        paddingTop: 10
    },
    columnWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.primary.contrastText,
    },
    venue: {
        fontSize: 10,
        fontWeight: 500,
        paddingBottom: 3,
        textAlign: 'center'
    },
    date: {
        fontSize: 10,
        fontWeight: 700,
        paddingBottom: 3,
    },
    time: {
        fontSize: 14,
        fontWeight: 700,
    },
    whiteText: {
        fontWeight: 700,
        fontSize: 12,
        paddingTop: 10,
        textAlign: 'center'
    },
    live: {
        fontWeight: 700,
        fontSize: 12,
        paddingTop: 10,
        textAlign: 'center',
        color: theme.palette.grey[500],
    }
}));

interface Props {
    contest: NbaContest
    entryId: number | undefined
    setPrevPageForLeaderboard: (p: string) => void
    status: string | null
    setStatus: (s: string) => void
    color: number //0: white, 1: green
}

const FixtureCardTopPart = (props: Props) => {
    const classes = styles();
    const {contest, status, setStatus, color} = props;

    const [estimatedStartTime, setEstimatedStartTime] = useState<number>(0);
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');

    const subscription = NBA_CONTEST_SUBSCRIPTION;

    useEffect(() => {
        setStatus(contest.status);
        setEstimatedStartTime(Number(contest.startTime));

        const getDate = getDateAndTime(Number(contest.fixture.startTime));

        setStartDate(getDate.startDate);
        setStartTime(getDate.startTime);
    }, []);

    const {data, loading, error} = useSubscription(subscription, {
        variables: {id: contest.id},
        skip: contest.status === 'COMPLETED'
    });

    useEffect(() => {
        if (!loading && !error && data) {
            if (data.nbaContest) {
                setStatus(data.nbaContest.status);
            }
        }
    }, [data, error, loading, setStatus]);

    const homeTeamIconName = NBA_TEAM_MAP[contest.fixture.homeTeam.externalId]?.iconName || '';
    const awayTeamIconName = NBA_TEAM_MAP[contest.fixture.awayTeam.externalId]?.iconName || '';

    return (
        <>
            <div className={classes.columnWrapper}>
                <SportsTeamIcon competitionName="NBA" teamName={homeTeamIconName} width={50} height={50} border={0} />
                <div className={classes.teamName}>{contest.fixture.homeTeam.name}</div>

            </div>

            <div className={classes.columnWrapper}>
                {contest.fixture.venue && contest.fixture.venue.name !== 'TBC' ?
                    <div className={classes.venue}>{contest.fixture.venue.name}</div>
                    : null}

                <div className={classes.date}>{startDate}</div>
                <div className={classes.time}>{startTime}</div>

                {
                    status === 'SCHEDULED'
                        ? <Countdown estimatedStartTime={estimatedStartTime} onTop={false} color={color}/>
                        : status === 'INPROGRESS'
                            ? <div className={classes.live}>Open</div>
                            : status === 'LOCKED'
                                ? <div className={classes.whiteText}>Locked</div>
                                : status === 'COMPLETED'
                                    ? <div className={classes.whiteText}>Completed</div>
                                    : null
                }
            </div>

            <div className={classes.columnWrapper}>
                <SportsTeamIcon competitionName="NBA" teamName={awayTeamIconName} width={50} height={50} border={0} />
                <div className={classes.teamName}>{contest.fixture.awayTeam.name}</div>

            </div>
        </>
    )
};

export default FixtureCardTopPart;