import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import NbaList from './NbaList';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        background: `url('/nba-background.png')`,
        backgroundSize: 'cover',
        height: '100vh',
        overflowY: 'scroll',
        '@media only screen and (orientation: landscape)': {
            backgroundImage: `url('/nba-background-landscape.jpg')`
        }
    },
    inner: {
        maxWidth: 800,
        margin: "auto",
        padding: 20
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        fontSize: 18,
        paddingBottom: 30
    },
}))

const Index = () => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <div className={classes.title}>Questions list</div>

                <NbaList/>
            </div>
        </div>
    );
};

export default Index;