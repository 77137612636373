import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { NbaEntry } from "../../../../../shared/types";
import { NBA_EXCLUDE_SA_USER_FROM_NATIONAL } from "../../../constants";
import { useUser } from "../../../contexts/nba/User";
import LeaderboardLadbrokes from "./LeaderboardLadbrokes";
import LeaderboardOverall from "./LeaderboardOverall";

interface Props {
    activeBlockId: number | null
    activeEntry: NbaEntry | null
    isWon: boolean | null
}

type TabName = 'overall' | 'ladbrokes';

const Leaderboards = ({ activeBlockId, activeEntry, isWon }: Props) => {
    const classes = styles();
    const { isLadbrokesUser, user } = useUser();
    const [ tabNames, setTabNames ] = useState<TabName[]>([]);
    const [ selectedTab, setSelectedTab ] = useState<TabName>();

    useEffect(() => {
        const availableTabNames: TabName[] = user?.fromSA && NBA_EXCLUDE_SA_USER_FROM_NATIONAL
            ? isLadbrokesUser
                ? ['ladbrokes']
                : [] // we shouldn't fall into this case, SA user are only from Ladbrokes App
            : isLadbrokesUser
                ? ['overall', 'ladbrokes']
                : ['overall'];
        
        setTabNames(availableTabNames);
        if (availableTabNames.length) setSelectedTab(availableTabNames[0]);
    }, [isLadbrokesUser, user?.fromSA]);

    const tabs = tabNames.map(tabName => (
        <div key={tabName} className={classNames(classes.btn, selectedTab === tabName ? classes.active : null)}
             onClick={() => setSelectedTab(tabName) }>
            {tabName}
        </div>
    ));

    return (
        <>
            { tabs.length > 1
                ? <div className={classes.tabs}>{tabs}</div>
                : null
            }

            { selectedTab === 'overall'
                ? <LeaderboardOverall activeBlockId={activeBlockId} activeEntry={activeEntry} />
                : null
            }
            
            { selectedTab === 'ladbrokes'
                ? <LeaderboardLadbrokes activeBlockId={activeBlockId} activeEntry={activeEntry} isWon={isWon} />
                : null
            }
        </>
    );
};

const styles = makeStyles((theme: Theme) => ({
    tabs: {
        background: theme.palette.background.default,
        borderRadius: 20,
        display: 'flex',
        margin: `15px auto 30px`,
        padding: 3,
    },
    btn: {
        borderRadius: 20,
        cursor: 'pointer',
        fontSize: 16,
        fontWeight: 400,
        padding: 5,
        textTransform: 'capitalize',
        width: '50%',
    },
    active: {
        backgroundColor: '#FFFFFF',
        color: theme.palette.background.default,
    },
}));

export default Leaderboards;