import React from "react";
import {GET_CONTESTS, NbaContest} from '../../../apollo/nba/queries/GetContests';
import {useQuery} from "@apollo/client";
import {makeStyles, Theme} from "@material-ui/core";
import NbaLayout from "../../common/UI/NbaLayout";
import {useAuth} from "../../../contexts/Auth";
import LoadingScreenSpinner from '../../common/nrlLoaders/LoadingScreenSpinner';
import logo from "../../../assets/CQ-logo.png";

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: '0px 30px 20px',
    },
    logo:{
        display: 'block',
        width: 250,
        height: 140,
        margin: 'auto'
    }
}));

interface ChildProps {
    sorted?: NbaContest[];
}

interface Props {
    children: React.ReactElement<ChildProps>
}

function HomepageWrapper(props: Props) {
    const classes = styles();
    const {isAuth} = useAuth();

    const {loading, error, data} = useQuery(GET_CONTESTS, {
        skip: !isAuth
    });

    if (!loading && !error && data) {
        let sorted = [...data.nba.contests].sort((a: NbaContest, b: NbaContest) => {
            if (parseInt(a.startTime, 10) > parseInt(b.startTime, 10)) {
                if (a.status === 'COMPLETED' && b.status === 'COMPLETED') {
                    return 0
                } else if (a.status === 'COMPLETED' && b.status !== 'COMPLETED') {
                    return 1
                } else if (a.status !== 'COMPLETED' && b.status === 'COMPLETED') {
                    return -1
                }
                return 1
            } else {
                if (a.status === 'COMPLETED' && b.status === 'COMPLETED') {
                    return 0
                } else if (a.status === 'COMPLETED' && b.status !== 'COMPLETED') {
                    return 1
                } else if (a.status !== 'COMPLETED' && b.status === 'COMPLETED') {
                    return -1
                }
                return -1
            }
        });

        const childrenWithProps = React.Children.map(props.children, child => {
            if (React.isValidElement(child)) {

                return React.cloneElement(child, {
                    sorted: sorted
                });
            }

            return child;
        });

        return <>{childrenWithProps}</>;
    }

    return (
        <NbaLayout>
            <div className={classes.wrapper}>
                <img src={logo} alt="logo" className={classes.logo}/>
                <LoadingScreenSpinner/>
            </div>
        </NbaLayout>
    )
}

export default HomepageWrapper;