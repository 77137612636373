import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import TopBar from "../../question_page/nrl_afl/TopBar";

const styles = makeStyles((theme: Theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: `url('/NRL-BG.jpg')`,
        width: '100%',
        overflow: 'auto',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        height: '100vh',
    },
    transparentBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    won: {
        background: 'rgba(0, 199, 110, 0.1)'
    },
    loss: {
        background: 'rgba(190, 0, 0, 0.1)'
    },
    rootInner: {
        margin: 'auto',
        height: '100vh',
        position: 'relative',
        maxWidth: 650,
        // padding: '40px 30px 20px',
    }
}));

interface Props {
    children: React.ReactNode
    won?: boolean
    loss?: boolean
    setPrevPageForLeaderboard?: (p: string) => void
}

const NrlAflLayout = (props: Props) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            {props.won && <div className={classNames(classes.transparentBackground, classes.won)}/>}
            {props.loss && <div className={classNames(classes.transparentBackground, classes.loss)}/>}

            <div className={classes.rootInner}>
                {props.setPrevPageForLeaderboard &&
                <TopBar setPrevPageForLeaderboard={props.setPrevPageForLeaderboard}/>}
                {props.children}
            </div>
        </div>
    )
};

export default NrlAflLayout;