import { makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import cashImage from '../../assets/cash.png';
import { LeaderboardEntry } from '../../types';
import { addSuffixToNumber } from '../../utils/utils';

const styles = makeStyles((theme: Theme) => ({
    overallRoot: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43,
    },
    overallUserScore: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px',
        color: theme.palette.background.paper,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43,
    },
    root: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px 90px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43,
    },
    userScore: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px 90px',
        color: theme.palette.background.paper,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43,
    },
    pos: {
        fontSize: 14,
        textAlign: 'center',
    },
    wrap: {
        textAlign: 'left',
        paddingLeft: 3,
    },
    itsYou: {
        fontWeight: 700,
    },
    subTitle: {
        fontSize: 10,
    },
    nameWrapper: {
        textAlign: 'left',
        fontSize: 14,
        overflow: 'hidden',
        paddingRight: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    points: {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'left',
    },
    winning: {
        fontSize: 14,
        fontWeight: 700,
        paddingLeft: 30,
        position: 'relative',
        textAlign: 'left',
    },
    winningType: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
}));

interface Props {
    entry: LeaderboardEntry & { tied: boolean };
    username: string | null;
    userScore: boolean;
    winningStyle?: string;
    type: 'OVERALL' | 'LADBROKES';
    isResulted: boolean;
}

const hashUsername = (username: string) => {
    const numAsterisks = Math.max(0, Math.min(20, username.length - 2));
    const asterisks = new Array(numAsterisks);
    for (let i = 0; i < numAsterisks; i++) {
        asterisks[i] = '*';
    }
    return username[0] + asterisks.join('') + username[username.length - 1];
};

const LeaderboardEntryItem = (props: Props) => {
    const classes = styles();
    const { entry, username, userScore, winningStyle, type } = props;

    return (
        <div
            className={
                type === 'OVERALL'
                    ? !userScore
                        ? classes.overallRoot
                        : classes.overallUserScore
                    : !userScore
                    ? classes.root
                    : classes.userScore
            }
        >
            <span className={classes.pos}>
                {addSuffixToNumber(type === 'OVERALL' ? entry.nationalRank : entry.rank)}
            </span>
            {props.username === entry.username || userScore ? (
                <div className={classes.wrap}>
                    <div className={classNames(classes.nameWrapper, classes.itsYou)}>{username}</div>
                    <div className={classes.subTitle}>You</div>
                </div>
            ) : (
                <div className={classes.nameWrapper}>{hashUsername(entry.username ? entry.username : '')}</div>
            )}

            <span className={classes.points}>
                {entry.tied ? <span>&#65121;</span> : ''}
                {entry.points}
            </span>
            {type !== 'OVERALL' ? (
                <span className={classNames(classes.winning, winningStyle ? winningStyle : null)}>
                    <span className={classes.winningType}>
                        <img src={cashImage} alt="winning-type-icon" />
                    </span>
                    <span>{`$${entry.winnings}`}</span>
                    { props.isResulted ? null : <span style={{ fontSize: 11, verticalAlign: 'super' }}>&nbsp;*</span>}
                </span>
            ) : null}
        </div>
    );
};

export default LeaderboardEntryItem;
