import { makeStyles, Theme } from "@material-ui/core";
import { usePagination } from "@material-ui/lab/Pagination";
import classNames from "classnames";
import React from "react";

const styles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 10,
  },
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    justifyContent: "center",
    "& > li": {
      overflow: "hidden",
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      border: "none",
      margin: "0 1px",
      width: 35,
      height: 35,
      "& > p": {
        cursor: "default",
      },
    },
    "& > li:first-child": {
      borderRadius: "7px 0 0 7px",
      width: 55,
    },
    "& > li:last-child": {
      borderRadius: "0 7px 7px 0",
      width: 55,
    },
  },
  button: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    color: theme.palette.primary.contrastText,
    fontWeight: 900,
    width: 35,
    height: 35,
    fontSize: 14,
    cursor: "pointer",
    "&:disabled": {
      opacity: 0.5,
      cursor: "default",
    },
  },
  last: {
    width: 55,
  },
  selected: {
    backgroundColor: "#D2D2D4",
    color: "#4D4D4D",
  },
}));

interface Props {
  currentPage?: number;
  paginationPages: number;
  handlePagination: (e: React.ChangeEvent<unknown>, v: number) => void;
}

const Pagination = (props: Props) => {
  const classes = styles();
  const { currentPage, paginationPages, handlePagination } = props;

  const { items } = usePagination({
    count: paginationPages,
    siblingCount: 0,
    onChange: handlePagination,
    page: currentPage,
  });

  const types = {
    previous: "Prev",
    first: "Prev",
    last: "Next",
    next: "Next",
  };

  return (
    <nav className={classes.root}>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = <p>...</p>;
          } else if (type === "page") {
            children = (
              <button
                className={classNames(
                  classes.button,
                  selected ? classes.selected : null
                )}
                type="button"
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                className={classNames(classes.button, classes.last)}
                type="button"
                {...item}
              >
                {types[type] as string}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
