import {gql} from "@apollo/client";

export const NBA_ENTRY_SUBSCRIPTION = gql`
    subscription entry($entryId: Int!) {
           nbaEntry(entryId: $entryId) {
                id
                points
                blockId
                rank
                winnings
                nationalRank
                nationalWinnings
                settlementStatus
        }
    }
`;