import React, {useEffect} from 'react';
import classNames from "classnames";
import {addSuffixToNumber} from "../../../utils";
import {makeStyles, Theme} from "@material-ui/core";
import { NBA_MAX_PERIOD } from '../../../constants';

const styles = makeStyles((theme: Theme) => ({
    halfs: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
    },
    quarters: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    eachQtr: {
        paddingBottom: 5
    },
    border: {
        borderBottom: '2px solid ' + theme.palette.primary.contrastText
    }
}));

interface Props {
    period: number
    selectedQtr: number
    qtrClick: (i: number) => void
    setSelectedQtr: (i: number) => void
}

const QtrTabs = (props: Props) => {
    const classes = styles();
    const {selectedQtr, qtrClick, setSelectedQtr, period} = props;

    const qtyOfTabs = NBA_MAX_PERIOD;
    const mainClass = classes.halfs;
    const text = 'Half';

    useEffect(() => {
        setSelectedQtr(period - 1)
    }, [])

    return (
        <div className={mainClass}>
            {[...Array(qtyOfTabs)].map((_, i) => (
                <div key={i}
                     className={classNames(classes.eachQtr, selectedQtr === i ? classes.border : null)}
                     onClick={() => qtrClick(i)}>{addSuffixToNumber(i + 1)} {text}</div>
            ))}
        </div>
    )
};

export default QtrTabs;