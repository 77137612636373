import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Icon } from '../core/Icon';

type SportsPSGMCardToggleButtonProps = {
    isOn: boolean;
    onToggle: () => void;
};

export const SportsPSGMCardToggleButton = ({ isOn, onToggle }: SportsPSGMCardToggleButtonProps) => {
    const classes = styles();
    const text = isOn ? 'Show Less' : 'Show All';
    const icon = isOn ? 'arrow-up' : 'arrow-down';

    return (
        <button className={classes.button} onClick={onToggle}>
            <span className={classes.contentContainer}>
                <span className={classes.text}>{text}</span>
                <Icon id={icon} type="misc" color="#194CA8" width={10} height={10} />
            </span>
        </button>
    );
};

const styles = makeStyles(() => ({
    button: {
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
        outline: 'none',
        padding: '0px 10px',
    },
    contentContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    text: {
        color: '#194CA8',
        fontSize: 13,
        fontWeight: 400,
        paddingRight: 5,
    },
}));