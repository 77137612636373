import React from "react";
import {NRL_PREVIOUS_CONTEST} from '../../../apollo/nrl/queries/PreviousContests';
import {AFL_PREVIOUS_CONTEST} from '../../../apollo/afl/queries/PreviousContests';
import {useQuery} from "@apollo/client";
import {makeStyles, Theme} from "@material-ui/core";
import NrlAflLayout from '../../common/UI/NrlAflLayout';
import {useAuth} from "../../../contexts/Auth";
import LoadingScreenSpinner from '../../common/nrlLoaders/LoadingScreenSpinner';
import {useHistory} from "react-router";
import BackButton from '../../common/UI/BackButton';
import FixtureCardWrapper from '../../homepage/nrl_afl/FixtureCardWrapper';
import FixtureCard from '../../homepage/shared/fixture_card/FixtureCard';
import {AflContest} from "../../../apollo/afl/queries/GetContests";
import NoEnteredContests from './NoEnteredContests';

const styles = makeStyles((theme: Theme) => ({
    title: {
        fontWeight: 700,
        fontSize: 16,
        display: 'block',
        textAlign: 'center',
        justifySelf: 'center',
        position: 'relative',
        top: 12
    },
    back: {
        height: 50,
        position: 'absolute',
        '@media only screen and (min-width: 1025px)': {
            top: 5,
            left: 0,
            width: '100%',
        },
        '@media only screen and (max-width: 1024px)': {
            top: 0,
            left: 0,
            width: '100%',
        }
    },
    inner: {
        height: '100vh',
        padding: '20px 0',
        position: 'relative',
        overflow: 'visible',
        '@media only screen and (min-width: 1025px)': {
            overflow: 'auto',
        },
        '@media only screen and (max-width: 1024px)': {
            padding: '60px 30px 40px',
        },
        '& > $title': {
            top: -48
        }
    }
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void
    sport: string
}

function Index(props: Props) {
    const classes = styles();
    const {isAuth} = useAuth();
    const {setPrevPageForLeaderboard, sport} = props;
    const history = useHistory();

    const query = sport === 'AFL' ? AFL_PREVIOUS_CONTEST : NRL_PREVIOUS_CONTEST;

    const {loading, error, data} = useQuery(query, {
        skip: !isAuth
    });

    if (!loading && !error && data) {

        const dataReceived = sport === 'AFL' ? data.afl.previous : data.nrl.previous;

        return (
            <NrlAflLayout>
                <div className={classes.inner}>
                    <div className={classes.back}>
                        <BackButton onClick={() => history.push(`/` + history.location.search)}>
                            Back</BackButton>
                    </div>
                    <div className={classes.title}>Previous Contests</div>

                    {dataReceived.length > 0 ?
                        <>
                            {dataReceived.map((contest: AflContest) => (
                                <FixtureCard setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                             contestId={contest.id}
                                             key={contest.id}
                                >
                                    <FixtureCardWrapper key={contest.id}
                                                        contest={contest}
                                                        setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                                        sport={sport}/>
                                </FixtureCard>
                            ))}
                        </>
                        : <NoEnteredContests/>}
                </div>
            </NrlAflLayout>
        );
    }

    return (
        <NrlAflLayout>
            <div className={classes.back}>
                <BackButton onClick={() => history.push(`/` + history.location.search)}>
                    Back</BackButton>
            </div>
            <div className={classes.title}>Previous Contests</div>
            <LoadingScreenSpinner/>
        </NrlAflLayout>
    )
}

export default Index;