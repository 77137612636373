import React, {useEffect, useState} from 'react';
import BackButton from '../../common/UI/BackButton';
import {makeStyles, Theme} from "@material-ui/core";
import {useHistory} from "react-router";

const styles = makeStyles((theme: Theme) => ({
    back: {
        position: 'absolute',
        width: '100%',
        height: 50,
        background: theme.palette.primary.main,
        zIndex: 20
    },
    centralText: {
        display: 'block',
        fontSize: 15,
        fontWeight: 700,
        padding: '0px 0 10px',
        boxShadow: '0 3px 6px ' + theme.palette.primary.light,
        position: 'relative',
        zIndex: 2,
        top: -38
    }
}));

interface Props {
    backToGame: () => void
    isWon: boolean | null
    isLastPeriod: boolean
    prevPageForLeaderboard?: string
}

const BackComponent = (props: Props) => {
    const classes = styles();
    const {backToGame, isWon, isLastPeriod, prevPageForLeaderboard} = props;
    const history = useHistory();

    const [isGoHome, setIsGoHome] = useState(false);

    useEffect(() => {
        if (isWon !== null && isLastPeriod && prevPageForLeaderboard !== 'prev_results') {
            setIsGoHome(true)
        } else {
            setIsGoHome(false)
        }
    }, [isWon, isLastPeriod]);

    const handleClick = () => {
        if (!isGoHome) {
            backToGame()
        } else {
            history.push(`/` + history.location.search)
        }
    }

    return (
        <div className={classes.back}>
            <BackButton onClick={() => handleClick()}
                        hideOnDesk={true}>
                {!isGoHome ? 'Back' : 'Home'}
            </BackButton>

            <div className={classes.centralText}>Leaderboard</div>
        </div>
    )
};

export default BackComponent;