import { gql} from '@apollo/client';

export const CONTEST = gql`
  query contest($id: Int!) {
    contest(id: $id) {
      id
      status
      estimatedStartTime
      lockOutTime 
      entry {
        id
      }
    }
  }
`;

