import { gql} from '@apollo/client';

export const SEEN_QUESTION_NRL = gql`
  mutation seenQuestion($entryId: Int!, $questionId: Int!, $timestamp: String!) {
    nrl {
       seen(entryId: $entryId, questionId: $questionId, timestamp: $timestamp) {
            success
            errorMessage
        }
    }
  }
`;

