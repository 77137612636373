import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import { CompetitionName, useFetchPSGMs } from '../../hooks/useFetchPSGMs';
import { SportingPopularSameGameMulti } from '../../types';
import { Icon } from '../core/Icon';
import { SportsPSGMCard } from '../SportsPSGMCard/SportsPSGMCard';
import { useTracking } from '../../hooks/useTracking';

const SLIDE_MARGIN_HORIZONTAL = 4;

type SportsPSGMCardListProps = {
    competitionName: CompetitionName;
    sliderPaddingHorizontal?: number;
};

export const SportsPSGMCardList = ({ competitionName, sliderPaddingHorizontal = 15 }: SportsPSGMCardListProps) => {
    const paddingHorizontal = sliderPaddingHorizontal - SLIDE_MARGIN_HORIZONTAL;
    const classes = styles({
        slickListPaddingHorizontal: paddingHorizontal,
        titlePaddingHorizontal: sliderPaddingHorizontal,
    });
    const tracking = useTracking();
    const { data, error } = useFetchPSGMs(competitionName);
    const { psgmPrices, psgms } = data;

    useEffect(() => {
        if (error) {
            tracking.event('showing psgms error', {
                error,
                competitionName,
            });
            return;
        }

        if ((psgms?.length ?? 0) > 0) {
            tracking.event('showing psgms', {
                psgmsCount: psgms.length,
                competitionName,
            });
        }
    }, [psgms?.length ?? 0, error]);

    const sliderSettings: Settings = {
        arrows: false,
        centerMode: true,
        centerPadding: `${paddingHorizontal}px`,
        className: classes.slider,
        infinite: false,
    };

    const renderCard = (psgm: SportingPopularSameGameMulti) => {
        const psgmPrice = psgmPrices.find((psgmPrice) => psgmPrice.id === psgm.id);

        return (
            <div className={classes.card} key={psgm.id}>
                <SportsPSGMCard psgm={psgm} psgmPrice={psgmPrice} competitionName={competitionName} />
            </div>
        );
    };

    return psgms.length ? (
        <div className={classes.mainContainer}>
            <div className={classes.title}>
                <Icon id="same-game-multi" type="product" color="#ffffff" width={14} height={14} />
                <span className={classes.titleText}>Popular Same Game Multis - {competitionName}</span>
            </div>
            <Slider {...sliderSettings}>{psgms.map(renderCard)}</Slider>
        </div>
    ) : null;
};

type StylesProps = {
    slickListPaddingHorizontal: number;
    titlePaddingHorizontal: number;
};

const styles = makeStyles(() => ({
    mainContainer: {
        paddingBottom: 25,
    },
    title: (props: StylesProps) => ({
        alignItems: 'center',
        borderTop: '1px solid #7F8090',
        display: 'flex',
        fontWeight: 600,
        justifyContent: 'center',
        margin: `15px ${props.titlePaddingHorizontal}px`,
        paddingTop: 15,
    }),
    titleText: {
        paddingLeft: 8,
    },
    card: {
        outline: 'none',
        paddingBottom: 5,
    },
    slider: (props: StylesProps) => ({
        '& .slick-list': {
            /**
             * When the slider contains only 1 item, it does not center the item correctly
             * "!important" is needed here to fix it.
             */
            padding: `0 ${props.slickListPaddingHorizontal}px !important`,
            marginLeft: 0,
            width: '100%',
        },
        '& .slick-track': {
            paddingRight: 0,
        },
        '& .slick-slide': {
            margin: `0 ${SLIDE_MARGIN_HORIZONTAL}px`,
        },
    }),
}));
