import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import {
  GetBlockIdDocument,
  GetBlockIdQuery,
  GetBlockIdQueryVariables,
} from "../../gql/operations.generated";
import LoadingScreen from "../common/leaderboard/LoadingScreen";
import LoadingScreenSpinner from "../common/LoadingScreenSpinner";
import BackComponent from "./BackComponent";
import Leaderboard from "./index";

interface Props {
  prevPageForLeaderboard: string;
}

const Wrapper = (props: Props) => {
  const { prevPageForLeaderboard } = props;
  const { contest_id } = useParams<{ contest_id: string }>();
  const history = useHistory();

  const { loading, error, data } = useQuery<
    GetBlockIdQuery,
    GetBlockIdQueryVariables
  >(GetBlockIdDocument, {
    variables: {
      id: contest_id,
    },
  });

  const backToGame = () => {
    switch (prevPageForLeaderboard) {
      case "homepage": {
        history.push(`/` + history.location.search);
        break;
      }
      case "question": {
        history.push("question_page" + history.location.search);
        break;
      }
      case "prev_results": {
        history.push("/previous_results" + history.location.search);
        break;
      }
      default: {
        history.push(`/` + history.location.search);
        break;
      }
    }
  };

  if (data?.afl.contest?.currentBlock && !error && !loading) {
    return (
      <Leaderboard
        backToGame={backToGame}
        prevPageForLeaderboard={prevPageForLeaderboard}
        blockId={data.afl.contest.currentBlock}
      />
    );
  } else {
    return (
      <LoadingScreen loader={<LoadingScreenSpinner />}>
        <BackComponent
          backToGame={backToGame}
          isWon={null}
          isLastPeriod={false}
        />
      </LoadingScreen>
    );
  }
};

export default Wrapper;
