import React from 'react';
import Leaderboard from "./Leaderboard";
import LoadingScreenSpinner from "../../common/nrlLoaders/LoadingScreenSpinner";
import {useQuery} from "@apollo/client";
import {NBA_GET_LEADERBOARD} from "../../../apollo/nba/queries/Leaderboard";
import {makeStyles, Theme} from "@material-ui/core";
import {createArrayWithTiedFieldForNBA} from "../../../utils";
import {NbaEntry} from "../../../../../shared/types";
import LottieAnimation from "../../common/LottieAnimation";
import animation from "../../../assets/animation/Money-Confetti.json";

const styles = makeStyles((theme: Theme) => ({
    emptyText: {
        paddingTop: 20,
        fontSize: 20,
        fontWeight: 700
    },
    root: {
        position: 'relative'
    },
    preventClicking: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 300,
        zIndex: 10
    },
    notices: {
        fontSize: 12,
        fontWeight: 300,
        textAlign: 'center',
    },
    link: {
        color: theme.palette.primary.contrastText,
        cursor: 'pointer'
    },
}));


interface Props {
    activeBlockId: number | null
    activeEntry: NbaEntry | null
    isWon: boolean | null
}

const LeaderboardLadbrokes = (props: Props) => {
    const classes = styles();

    const {activeBlockId, activeEntry, isWon} = props;
    const query = NBA_GET_LEADERBOARD;

    const {loading, data, error} = useQuery(query, {
        variables: {
            blockId: Number(activeBlockId)
        },
        pollInterval: 5000
    });

    if (data && !loading && !error) {
        const entries = (data.nba?.leaderboard?.entries || [])
            .filter((entry: NbaEntry) => entry.rank > 0);

        const creatingNewList = createArrayWithTiedFieldForNBA<NbaEntry>(entries, 'LADBROKES');
        let newList: (NbaEntry & { tied: boolean })[] = creatingNewList.newList;
        let isAnyTie = creatingNewList.isAnyTie;

        return (
            <>
                <div className={classes.root}>
                    {isWon &&
                        <>
                            <div className={classes.preventClicking}/>
                            <LottieAnimation lotti={animation} height={300} width={100}/>
                        </>
                    }

                    {newList.length > 0 ?
                        <Leaderboard entry={activeEntry}
                                     isAnyTie={isAnyTie}
                                     newList={newList}
                                     type="LADBROKES" />
                        : <div className={classes.emptyText}>No entries.</div>}
                </div>

                {newList.length > 0 &&
                    <div className={classes.notices}>
                        <em>Note:</em> Results are settled as per our data provider.<br />
                        Please refer to our <a className={classes.link} href="https://www.ladbrokes.com.au/rules-terms-and-conditions" target='_blank'>
                            Terms and Conditions
                        </a> for more information.
                    </div>
                }
            </>
    )
    } else {
        if (!data && !loading) {
            return (
                <div className={classes.emptyText}>No entries.</div>
            )
        }

        return (
            <>
                <div className={classes.emptyText}>Preparing Leaderboard</div>
                <LoadingScreenSpinner/>
            </>
        )
    }
};

export default LeaderboardLadbrokes;