import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {CssBaseline} from "@material-ui/core";
import Homepage from './components/homepage/nrl_afl';
import HomepageWrapper from './components/homepage/nrl';
import QuestionPage from './components/question_page/nrl_afl';
import Leaderboard from './components/leaderboard/nrl_afl/Wrapper';
import LeaderboardEntain from './components/entain-admin/leaderboard/Wrapper';
import RulesPage from './components/rules_page/nrl_afl';
import PreviousResults from './components/previous_results/nrl_afl';
import TermsConditions from './components/terms_and_conditions/nrl';
import {EntryNrlAflProvider} from "./contexts/nrl_afl/Entry";
import {AuthProvider} from "./contexts/Auth";
import {GameStateProvider} from "./contexts/nrl_afl/GameState";
import {QuestionManagerProvider} from "./contexts/nrl_afl/Question";
import CustomThemeProvider from './themes/ThemeProvider';
import {PreviousEntryProvider} from "./contexts/nrl_afl/PreviousEntry";
import EntainAdmin from "./components/entain-admin";
import {TimeDriftProvider} from "./contexts/TimeDrift";

function NrlApp() {
    const [prevPageForLeaderboard, setPrevPageForLeaderboard] = useState('');
    const [agreedRules, setAgreedRules] = useState(false);
    const [entainAdminChosenSport, setEntainAdminChosenSport] = useState('AFL');

    return (
        <Router>
            <CustomThemeProvider theme='nrlTheme'>
                <CssBaseline/>
                <Switch>
                    <Route path="/terms_conditions">
                        <AuthProvider>
                            <TermsConditions/>
                        </AuthProvider>
                    </Route>

                    <Route path="/previous_results">
                        <AuthProvider>
                            <PreviousResults setPrevPageForLeaderboard={setPrevPageForLeaderboard} sport='NRL'/>
                        </AuthProvider>
                    </Route>

                    <Route path="/:contest_id/question_page">
                        <AuthProvider>
                            <TimeDriftProvider>
                                <EntryNrlAflProvider sport='NRL'>
                                    <GameStateProvider sport='NRL'>
                                        <QuestionManagerProvider sport='NRL'>
                                            <QuestionPage
                                                sport='NRL'
                                                setPrevPageForLeaderboard={setPrevPageForLeaderboard}/>
                                        </QuestionManagerProvider>
                                    </GameStateProvider>
                                </EntryNrlAflProvider>
                            </TimeDriftProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/:contest_id/home">
                        <AuthProvider>
                            <EntryNrlAflProvider sport='NRL'>
                                <RulesPage setAgreedRules={setAgreedRules} agreedRules={agreedRules} sport='NRL'/>
                            </EntryNrlAflProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/:contest_id/leaderboard">
                        <AuthProvider>
                            <GameStateProvider sport='NRL'>
                                <PreviousEntryProvider sport='NRL'>
                                    <EntryNrlAflProvider sport='NRL'>
                                        <TimeDriftProvider>
                                            <Leaderboard prevPageForLeaderboard={prevPageForLeaderboard} sport='NRL'/>
                                        </TimeDriftProvider>
                                    </EntryNrlAflProvider>
                                </PreviousEntryProvider>
                            </GameStateProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/" exact>
                        <AuthProvider>
                            <TimeDriftProvider>
                                <HomepageWrapper sport='NRL'>
                                    <Homepage setPrevPageForLeaderboard={setPrevPageForLeaderboard} sport='NRL'/>
                                </HomepageWrapper>
                            </TimeDriftProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/entain-admin/:contest_id/leaderboard">
                        <GameStateProvider sport={entainAdminChosenSport}>
                            <PreviousEntryProvider sport={entainAdminChosenSport}>
                                <EntryNrlAflProvider sport={entainAdminChosenSport}>
                                    <LeaderboardEntain sport={entainAdminChosenSport}/>
                                </EntryNrlAflProvider>
                            </PreviousEntryProvider>
                        </GameStateProvider>
                    </Route>

                    <Route path="/entain-admin">
                        <EntainAdmin setEntainAdminChosenSport={setEntainAdminChosenSport}/>
                    </Route>
                </Switch>
            </CustomThemeProvider>
        </Router>
    )
        ;
}

export default NrlApp;
