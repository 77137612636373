import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {AflNrlOutcome, AflNrlQuestion} from '../../../../../../shared/types';
import Outcomes from './Outcomes';
import ProgressBar from './ProgressBar';
import {useMutation} from "@apollo/client";
import {SEEN_QUESTION_NRL} from "../../../../apollo/nrl/mutations/Seen";
import {SEEN_QUESTION_AFL} from "../../../../apollo/afl/mutations/Seen";
import {useNrlAflEntry} from "../../../../contexts/nrl_afl/Entry";
import PointsLine from './PointsLine';
import {useTimeDrift} from "../../../../contexts/TimeDrift";
import {useTracking} from "../../../../entain/hooks/useTracking";


const styles = makeStyles((theme: Theme) => ({
    topLine: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        fontSize: 10,
        paddingBottom: 10,
        '@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)': {
            paddingBottom: 0
        }
    },
    timeWrapper: {
        display: 'flex',
        width: 130
    },
    timeColor: {
        width: 45,
        textAlign: 'end'
    },
    lessTimeColor: {
        color: theme.palette.secondary.light,
        width: 45,
        textAlign: 'end'
    },
    question: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflow: 'visible',
        paddingTop: 10
    },
    questionText: {
        fontSize: 14,
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontWeight: 900,
        paddingBottom: 5
    },
    buttonWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    ballImage: {
        display: 'block',
        width: 15,
        height: 15,
        marginRight: 5
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    '@keyframes wobble': {
        '0%': {
            transform: 'rotate(2deg) scale(1.1)',

        },
        '100%': {
            transform: 'rotate(-2deg) scale(1.1)',
        },
    },
    wobbleAnimation: {
        animation: `$wobble 0.1s cubic-bezier(.36,.07,.19,.97) 2 backwards`,
    },
    timeBar: {
        width: '100%',
        height: 7,
        overflow: 'hidden',
        position: 'relative',
        transform: 'rotate(180deg);'
    },
    timeBarInner: {
        backgroundColor: theme.palette.grey[400],
        height: 7,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    wholeTimerWrapper: {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 20
    },
    fixture: {
        paddingTop: 1,
        width: 100
    },
    dismissButton: {
        color: theme.palette.primary.light,
        fontSize: 12
    },
    countdown: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 'bold'
    },
    topWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0 30px',
        marginTop: 20,
        '@media only screen and (max-width: 1024px)': {
            padding: '10px 0 20px',
        }
    },
    // points: {
    //     fontWeight: 900,
    //     color: theme.palette.primary.contrastText,
    //     fontSize: 18,
    //     textAlign: 'center',
    //     paddingBottom: 15
    // },
    wrapperTopPart: {
        overflow: 'visible'
        // height: 70
    },
    '@keyframes appearing': {
        '0%': {
            transform: 'scale(0.3)',
        },
        '70%': {
            transform: 'scale(1.2)',
        },

        '100%': {
            transform: 'scale(1)',
        },
    },
    title: {
        fontSize: 14,
        fontWeight: 700
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 5,
        paddingBottom: 10,
        fontSize: 14,
        fontWeight: 700,
        color: '#A7A8BA'
    }
}));

interface Props {
    setTimeExpired?: (t: boolean) => void
    timeExpired?: boolean
    question?: AflNrlQuestion
    setLessThanTenSecs?: (l: boolean) => void
    setButtonWasClicked?: (c: boolean) => void
    buttonWasClicked?: boolean
    setOpenSnackbar: (s: boolean) => void
}

const QuestionBlock = (props: Props) => {
    const classes = styles();
    const {
        timeExpired,
        setTimeExpired,
        question,
        setLessThanTenSecs,
        setButtonWasClicked,
        buttonWasClicked,
        setOpenSnackbar
    } = props;
    const tracking = useTracking();
    const [clickedButton, setClickedButton] = useState<number>(-1);

    const diff = useTimeDrift();

    const mutation = process.env.REACT_APP_BUILD_TARGET === 'nrl' ? SEEN_QUESTION_NRL : SEEN_QUESTION_AFL;
    const [seenMutation, {data, loading, error}] = useMutation(mutation);

    const {entry} = useNrlAflEntry();

    useEffect(() => {
        const dateWithDiff = new Date().getTime() + diff;
        const timestamp = new Date(dateWithDiff).toISOString();

        if (entry && question && !question.seen) {
            seenMutation({
                variables: {
                    entryId: entry.id,
                    questionId: question.id,
                    timestamp: String(timestamp)
                }
            });
        }
    }, [])

    const trackAnswer = useCallback((outcome: AflNrlOutcome, timestamp: string, error?: unknown) => {
        tracking.event(`answer clicked${error ? ' error' : ''}`, {
            event_label: entry?.id,
            entryId: entry?.id,
            questionId: question?.id,
            outcomeId: outcome.id,
            outcomeText: outcome.text,
            timestamp: timestamp,
            error,
            sport: process.env.REACT_APP_BUILD_TARGET
        });
    }, [entry?.id, question?.id]);

    return (
        <>
            {entry && question &&
                <>
                    <ProgressBar question={question}
                                 setTimeExpired={setTimeExpired!}
                                 setLessThanTenSecs={setLessThanTenSecs}/>


                    <div className={classes.question}>
                        <PointsLine seen={question.seen}
                                    clickedButton={clickedButton}/>

                        <div
                            className={(classNames(classes.questionText))}>
                            {question.text}
                        </div>

                        <Outcomes question={question!}
                                  setOpenSnackbar={setOpenSnackbar}
                                  setButtonWasClicked={setButtonWasClicked}
                                  setLessThanTenSecs={setLessThanTenSecs}
                                  setTimeExpired={setTimeExpired!}
                                  timeExpired={timeExpired!}
                                  clickedButton={clickedButton}
                                  setClickedButton={setClickedButton}
                                  trackAnswer={trackAnswer}/>
                    </div>
                </>
            }
        </>
    )
};

export default QuestionBlock;