import { gql} from '@apollo/client';

export const ANSWERED_QUESTIONS = gql`
  query previousQuestions($contestId: Int!, $entryId: Int!) {
    previousQuestions(contestId: $contestId, entryId: $entryId) {
        id
        text
        start
        outcomes {
            id
            cricketLiveQuestionId
            correct
            text
            selected
            count
        }
    }
  }
`;

