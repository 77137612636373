import React, {useCallback, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {NBA_GET_LEADERBOARD} from "../../../../apollo/nba/queries/Leaderboard";
import {useQuery} from "@apollo/client";
import {createArrayWithTiedFieldForNBA} from "../../../../utils";
import {NbaEntry} from "../../../../../../shared/types";
import LoadingScreenSpinner from "../../../common/nrlLoaders/LoadingScreenSpinner";
import TiedText from "../../../leaderboard/shared/TiedText";
import LeaderboardEntry from "./LeaderboardEntry";
import PaginationComponent from "../../../leaderboard/nba/PaginationComponent";
import TextField from '@material-ui/core/TextField';
import LeaderboardTabs from '../../../common/nba/LeaderboardTabs';
import LeaderboardTopTitles from '../../../common/nba/LeaderboardTopTitle';

const styles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        padding: '20px 0'
    },
    emptyText: {
        paddingTop: 20,
        fontSize: 20,
        fontWeight: 700
    },
    searchRow: {
        width: '100%',
        padding: '10px 0',
    },
    innerRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        height: 50,
        alignItems: 'center'
    },
    searchText: {
        paddingRight: 20
    },
    searchInput: {
        overflowY: 'visible',
        '& .MuiInputLabel-outlined, .MuiInputBase-root': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.contrastText
        },

    }
}))

type TabName = 'ladbrokes' | 'national';
const tabNames: TabName[] = ['ladbrokes', 'national'];

interface Props {
    activeBlockId: number | null
}

const LeaderboardList = (props: Props) => {
    const classes = styles();
    const {activeBlockId} = props;
    const [page, setPage] = useState(1);
    const [searchField, setSearchField] = useState('');
    const [selectedTab, setSelectedTab] = useState('ladbrokes');

    const onTabClick = useCallback((tabName: string) => {
        setSelectedTab(tabName);
        setPage(1);
    }, []);

    const {loading, data, error} = useQuery(NBA_GET_LEADERBOARD, {
        variables: {
            blockId: Number(activeBlockId)
        },
        skip: !activeBlockId,
    });

    const pageSize = 20;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const v = value ? value : 1;
        setPage(v);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchLine = event.target.value;

        if (searchLine.length > 2) {
            setSearchField(searchLine)
            setPage(1)
        } else {
            setSearchField('')
            setPage(1)
        }
    }

    if (data && !loading && !error) {
        const type = selectedTab === 'ladbrokes' ? 'LADBROKES' : 'OVERALL';
        const creatingNewList = createArrayWithTiedFieldForNBA<NbaEntry>(data.nba.leaderboard.entries, type);
        // we are going to move entry with rank/nationRank 0 to the back of list
        const zeroRankList = creatingNewList.newList.filter((entry) => entry[type === 'LADBROKES' ? 'rank' : 'nationalRank'] === 0);
        const nonZeroRankList = creatingNewList.newList.filter((entry) => entry[type === 'LADBROKES' ? 'rank' : 'nationalRank'] !== 0);
        const newList: (NbaEntry & { tied: boolean })[] = [...nonZeroRankList, ...zeroRankList];
        const isAnyTie = creatingNewList.isAnyTie;
        const paginationPages = Math.ceil(newList.length / pageSize);

        return (
            <div className={classes.root}>

                {newList.length > 0 ?
                    <>
                        <div className={classes.searchRow}>
                            <div className={classes.innerRow}>

                                <span className={classes.searchText}>Search thru usernames:</span>
                                <TextField id="outlined-search" className={classes.searchInput} onChange={handleSearch}
                                           label="Search" type="search" variant="outlined" size='small'/>
                            </div>
                        </div>

                        <LeaderboardTabs onTabClick={onTabClick} selectedTab={selectedTab} tabNames={tabNames}  />

                        {isAnyTie ? <TiedText/> : null}

                        <LeaderboardTopTitles type={type} />

                        <div>
                            {newList
                                .filter((l: NbaEntry) => l.username.indexOf(searchField) !== -1)
                                .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
                                .map((entry, i: number) => {
                                    return (<LeaderboardEntry key={i} entry={entry} type={type} />)
                                })}
                        </div>

                        {newList.length > pageSize &&
                            <PaginationComponent
                                currentPage={page}
                                paginationPages={paginationPages}
                                handlePagination={handleChange}
                            />
                        }
                    </>
                    : <div className={classes.emptyText}>No entries this quarter/half.</div>}
            </div>
        )
    } else {
        if (!data && !loading) {
            return (
                <div className={classes.emptyText}>No entries this quarter/half.</div>
            )
        }

        return (
            <>
                <div className={classes.emptyText}>Preparing Leaderboard</div>
                <LoadingScreenSpinner/>
            </>
        )
    }
};

export default LeaderboardList;