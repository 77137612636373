import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {useTimeDrift} from "../../../../contexts/TimeDrift";
import { NBA_QUESTION_DURATION_MILLISECOND } from '../../../../constants';

const styles = makeStyles((theme: Theme) => ({
    points: {
        fontWeight: 900,
        color: theme.palette.primary.contrastText,
        fontSize: 18,
        textAlign: 'center',
        paddingBottom: 15
    },
}))

interface Props {
    seen: Date | null
    clickedButton: number
}

const PointsLine = (props: Props) => {
    const classes = styles();
    const {seen, clickedButton} = props;
    const [points, setPoints] = useState(-1);
    const diff = useTimeDrift();

    useEffect(() => {
        // Don't start the interval for points countdown when question hasn't been marked as seen
        // or it has been answered
        if (!seen || clickedButton !== -1) return;

        const dateFormat = new Date(seen);
        const seenDate = dateFormat.getTime();

        const pointsCountdown = () => {
            const now = new Date().getTime() + diff;
            const points = Math.max(Math.round(500 - 300 * (now - seenDate) / NBA_QUESTION_DURATION_MILLISECOND), 200);
            setPoints(points)
        };

        // calculate and set the points immediately and start an interval to update it
        pointsCountdown();

        const intervalId = setInterval(() => {
            pointsCountdown();

            if (clickedButton !== -1) {
                clearInterval(intervalId);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }

    }, [clickedButton, diff, seen]);

    return (
        points !== -1 ?
            <span className={classes.points}>{points} Points</span>
            : <span className={classes.points}>- Points</span>
    );
};

export default PointsLine;