import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BatsmenToChoose from './BatsmenToChoose';
import {useCricketState} from "../../../contexts/cricket/CricketState";
import LoadingScreenBall from '../../common/cricketLoaders/LoadingScreeenBall';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        marginBottom: 20
    },
    wrapper: {
        display: 'flex',
        marginBottom: 20,
        position: 'relative',
    },
    rootWrapper: {
        marginLeft: 10,
        width: '100%',
        position: 'relative',
        zIndex: 10,
        height: 82,
        '@media only screen and (max-width: 1024px)': {
            marginLeft: 0
        }
    },
    radio: {
        color: '#D32123',
        padding: 0,
        width: 20,
        height: 20,
        backgroundColor: theme.palette.primary.contrastText,
        '& > span > div > svg': {
            opacity: 0
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
        },
        '&$checked': {
            backgroundColor: theme.palette.primary.contrastText,
            color: '#D32123 !important',
            padding: 0,
            '& > span': {
                marginLeft: -3
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.contrastText,
            },
            '& > span > div > svg': {
                opacity: 1
            },
        },
        '& > span > div':{
            overflowY: 'inherit',
            marginLeft: 2
        }
    },
    checked: {},
    label: {
        width: '100%',
        display: 'block',
        opacity: 0,

    }

}));

interface Props {
    value: string
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const BatsmenWrapper = (props: Props) => {
    const classes = styles();
    const {value, handleChange} = props;

    const {cricketState} = useCricketState();

    if (cricketState) {
        const {batsmans} = cricketState;

        return (
            <div className={classes.root}>
                <RadioGroup aria-label="batsmen" name="batsmen" value={value} onChange={handleChange}>
                    <div className={classes.wrapper}>
                        <FormControlLabel
                            classes={{root: classes.rootWrapper, label: classes.label}}
                            value="first"
                            control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>}
                            label=""/>
                        <BatsmenToChoose checked={value === 'first'} cricketer={batsmans[0]}/>
                    </div>
                    <div className={classes.wrapper}>
                        <FormControlLabel
                            classes={{root: classes.rootWrapper}}
                            value="second"
                            control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>}
                            label=""/>
                        <BatsmenToChoose checked={value === 'second'} cricketer={batsmans[1]}/>
                    </div>
                </RadioGroup>
            </div>
        )
    } else {
        return (
            <LoadingScreenBall>
                Just a minute, we are loading new batsmen
            </LoadingScreenBall>
        )
    }
};

export default BatsmenWrapper;

