import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTimeDrift } from "../../../contexts/TimeDrift";
import {
  GetQuestionsDocument,
  GetQuestionsQuery,
  GetQuestionsQueryVariables,
} from "../gql/operations.generated";
import { QuestionListQuestion } from "../types";
import { useGameState } from "./GameState";

export const QuestionManagerContext = createContext<{
  aflKicksGameQuestions: QuestionListQuestion[];
  currentQuestion: null | QuestionListQuestion;
  currentQuestionNumber: null | number;
  questionsLeft: number;
  questionsCount: number;
  lastAnsweredQuestionIndex: number;
  questionSeen: (time: Date, id: string) => void;
  questionWasAnswered: (id: string) => void;
}>({
  aflKicksGameQuestions: [],
  currentQuestion: null,
  currentQuestionNumber: null,
  questionsLeft: 0,
  questionsCount: 0,
  lastAnsweredQuestionIndex: -1,
  questionSeen: (time: Date, id: string) => {},
  questionWasAnswered: (id: string) => {},
});

interface Props {
  contestId?: number | undefined;
  children: React.ReactNode;
}

const transformQuestions = (
  questions: QuestionListQuestion[]
): QuestionListQuestion[] => {
  return questions.map((question) => {
    return {
      id: question.id,
      blockNumber: question.blockNumber,
      text: question.text,
      isAnswered: question.isAnswered,
      durationSeconds: question.durationSeconds,
      seen: question.seen,
      questionType: question.questionType,
      outcomes: question.outcomes.map((outcome) => {
        return {
          id: outcome.id,
          questionId: outcome.questionId,
          text: outcome.text,
          isSelected: outcome.isSelected,
          correct: outcome.correct,
          minigameScore: outcome.minigameScore,
        };
      }),
    };
  });
};

interface QuestionState {
  aflKicksGameQuestions: QuestionListQuestion[];
  questionsLeft: number;
  currentQuestion: QuestionListQuestion | null;
  currentQuestionNumber: number | null;
  lastAnsweredQuestionIndex: number;
  allQuestions: QuestionListQuestion[];
  questionsCount: number;
}

let seenCache: any = {};

const QuestionManagerProvider = (props: Props) => {
  const [questionState, setQuestionState] = useState<QuestionState>({
    aflKicksGameQuestions: [],
    questionsLeft: -1,
    currentQuestion: null,
    currentQuestionNumber: null,
    lastAnsweredQuestionIndex: -1,
    allQuestions: [],
    questionsCount: 0,
  });

  const { children } = props;

  const { gameState, isStatusLoaded } = useGameState();
  const diff = useTimeDrift();

  const processValidQuestions = (questions: QuestionListQuestion[]) => {
    const now = new Date().getTime() + diff;
    const lastAnsweredQuestionIndex = questions
      .map((q) => q.isAnswered)
      .lastIndexOf(true);

    const aflKicksGameQuestions = questions.filter(
      (question) => question.questionType === "AFL_KICKS_GAME"
    );

    const combined = questions.filter((question) => {
      // AFL_KICKS_GAME doesn't have a time limit
      if (question.questionType !== "AFL_KICKS_GAME" && question.seen) {
        const dateFormat = new Date(question.seen);
        const seenDate = dateFormat.getTime();

        return (
          !question.isAnswered &&
          now - seenDate < question.durationSeconds * 1000
        );
      }

      return !question.isAnswered;
    });

    if (combined.length > 0) {
      const [current, ...rest] = combined;
      const currentQuestionIndex = questions.findIndex(
        (q) => q.id === current.id
      );

      setQuestionState({
        aflKicksGameQuestions,
        currentQuestion: current,
        currentQuestionNumber: currentQuestionIndex + 1,
        questionsLeft: rest.length + 1,
        lastAnsweredQuestionIndex,
        allQuestions: questions,
        questionsCount: questions.length,
      });

      return { current, rest };
    } else {
      setQuestionState({
        aflKicksGameQuestions,
        currentQuestion: null,
        currentQuestionNumber: null,
        questionsLeft: 0,
        lastAnsweredQuestionIndex,
        allQuestions: [],
        questionsCount: questions.length,
      });

      return { current: null, rest: combined };
    }
  };

  let lock = false;
  if (gameState?.isBlockLocked) {
    lock = true;
  }

  const { loading, data } = useQuery<
    GetQuestionsQuery,
    GetQuestionsQueryVariables
  >(GetQuestionsDocument, {
    variables: {
      blockId: gameState?.currentBlock ?? '',
    },
    fetchPolicy: "network-only",
    skip: !isStatusLoaded || lock,
    pollInterval: 3500,
  });

  useEffect(() => {
    let interval: any;
    if (data && data.afl.questions) {
      updateQuestionArray(data.afl.questions);
      interval = setInterval(() => {
        updateQuestionArray(data.afl.questions);
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [data, loading]);

  const updateQuestionArray = (data: QuestionListQuestion[]) => {
    const questions = transformQuestions(data);
    processValidQuestions(questions);
  };

  const questionWasAnswered = (id: string) => {
    const questions = { ...questionState };

    const indexToUpdate = questions.allQuestions.findIndex((q) => q.id === id);
    questions.allQuestions[indexToUpdate].isAnswered = true;

    setQuestionState(questions);
  };

  const questionSeen = (time: Date, id: string) => {
    if (!seenCache[id]) {
      seenCache[id] = time;
    }

    return seenCache;
  };

  return (
    <QuestionManagerContext.Provider
      value={{
        aflKicksGameQuestions: questionState.aflKicksGameQuestions,
        currentQuestion: questionState.currentQuestion,
        currentQuestionNumber: questionState.currentQuestionNumber,
        questionsLeft: questionState.questionsLeft,
        questionsCount: questionState.questionsCount,
        lastAnsweredQuestionIndex: questionState.lastAnsweredQuestionIndex,
        questionSeen: questionSeen,
        questionWasAnswered: questionWasAnswered,
      }}
    >
      {children}
    </QuestionManagerContext.Provider>
  );
};

const useQuestion = () => {
  const context = useContext(QuestionManagerContext);
  if (context === undefined) {
    throw new Error("useQuestion must be used within a QuestionProvider");
  }

  return context;
};

export { QuestionManagerProvider, useQuestion };
