import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import BatsmenWrapper from './components/BatsmenWrapper';
import Button from '../common/UI/SquareButton';
import classNames from "classnames";
import {useAnimation} from "framer-motion";
import {useMutation} from '@apollo/client';
import {SELECT_BATSMAN} from '../../apollo/cricket/mutations/SelectBatsman';
import {useEntry} from "../../contexts/cricket/Entry";
import PlayerLayer from '../common/UI/PlayerLayer';
import {useCricketState} from "../../contexts/cricket/CricketState";
import {Batsman} from '../../../../shared/types';
import LoadingScreenBall from '../common/cricketLoaders/LoadingScreeenBall';

const styles = makeStyles((theme: Theme) => ({
    title: {
        fontWeight: 700,
        fontSize: 31,
        paddingBottom: 70,
        display: 'block',
        textAlign: 'center',
        '@media only screen and (max-width: 1024px)': {
            fontSize: 24,
            paddingBottom: 40
        }
    },
    titleImg: {
        '@media only screen and (max-width: 1024px)': {
            width: 250
        },
        '@media only screen and (min-width: 1025px)': {
            width: '80%',
            maxWidth: 515,
            paddingBottom: 20
        }
    },
    outImage: {
        display: 'block',
        width: 230,
        margin: '40px auto 0'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    link: {
        textDecoration: 'none',
    },
    selectImage: {
        display: 'block',
        margin: '0 auto 20px',
        width: '90%'
    },
    fade: {
        transition: 'all 1s'
    },
    root: {
        width: '100%',
    },
    rootInner: {
        maxWidth: 500,
        width: '100%',
        margin: 'auto',
        color: theme.palette.primary.contrastText
    }
}));

interface Props {
    fade: boolean
    setSelectedBatsman: (b: Batsman) => void
}

const Selection = (props: Props) => {
    const classes = styles();
    const {setSelectedBatsman} = props;
    const {entry, loadEntry} = useEntry();
    const {id} = entry;

    const [value, setValue] = React.useState('first');
    const [buttonWasClicked, setButtonWasClicked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const controls = useAnimation();

    useEffect(() => {
        if (props.fade) {

            controls.start(i => ({
                opacity: 0,
                transition: {default: {duration: 0.5}},
            }))
        }
    }, [props.fade]);

    const [selectBatsmanMutation, {data, loading, error}] = useMutation(SELECT_BATSMAN);

    useEffect(() => {
        if (data && !loading && !error) {
            loadEntry(data.selectBatsman.entry);
        } else if (!loading && error) {
            console.error(error)
        }
    }, [data]);

    const {cricketState} = useCricketState();

    useEffect(() => {
        if (error) {
            setButtonWasClicked(false);
        }
    }, [error]);

    return (
        <PlayerLayer>
            {(cricketState && cricketState.batsmans && cricketState.batsmans.length >= 2) ?
                <div className={classNames(classes.root)}>
                    <div className={classes.rootInner}>

                        <span
                            className={classes.title}>Choose a batter and score points for every run while selected.</span>

                        <BatsmenWrapper value={value} handleChange={handleChange}/>

                        <div className={classes.buttonWrapper}>

                            <Button width='162px'
                                    buttonWasClicked={buttonWasClicked}
                                    notDefaultColors='red'
                                    onClick={() => {
                                        if (!buttonWasClicked) {
                                            setButtonWasClicked(true);

                                            if (value === 'first') {
                                                selectBatsmanMutation({
                                                    variables: {
                                                        entryId: id,
                                                        cricketerId: cricketState.batsmans[0].id
                                                    }
                                                });

                                                setSelectedBatsman(cricketState.batsmans[0])
                                            } else if (value === 'second') {
                                                selectBatsmanMutation({
                                                    variables: {
                                                        entryId: id,
                                                        cricketerId: cricketState.batsmans[1].id
                                                    }
                                                });

                                                setSelectedBatsman(cricketState.batsmans[1])
                                            }
                                        }
                                    }}>Continue
                            </Button>
                        </div>
                    </div>
                </div>
                :
                <LoadingScreenBall>
                    Just a minute, we are loading new batsmen
                </LoadingScreenBall>}
        </PlayerLayer>
    )
};

export default Selection;