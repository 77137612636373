import React, {useEffect, useState} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import {motion} from "framer-motion";

const styles = makeStyles((theme: Theme) => ({
    root: {
        margin: 'auto auto 15px',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 16,
        fontWeight: 700,
        borderRadius: 8,
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'all 0.25s',
        height: 32,
        overflow: 'hidden',
        width: '80%',
        '@media only screen and (max-width: 1024px)': {
            width: '100%',
        },
    },
    bg_grey: {
        backgroundColor: '#FBFBFC',
        color: '#000',
    },
    button: {
        width: '100%',
        position: 'relative',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000',

    },
    answerText: {
        color: '#000',
        position: 'relative',
        zIndex: 2
    },
    selectedAnswer: {
        border: '2px solid #007EB9',
        borderRadius: 8
    },
    percentage: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        background: '#7DD6FF',
        width: '0%',
        transition: 'width 0.2s linear'
    },
    percentageAmount: {
        display: 'block',
        position: 'absolute',
        color: '#007EB9',
        fontWeight: 800,
        fontSize: 14,
        left: 9,
        top: 7
    },
    hide: {
        display: 'none'
    },
    selected: {
        color: '#007EB9',
        fontSize: 8,
        fontWeight: 700,
        position: 'absolute',
        right: 5,
        bottom: 2
    },
}));

interface Props {
    children: React.ReactNode
    onClick: any
    buttonId: number
    clickedButtonId: number
    timeExpired: boolean
    outcome: any | null
    percentage: number[]
    shadow?: boolean
}

const OutcomeButton = (props: Props) => {
    const classes = styles();
    const {children, onClick, buttonId, clickedButtonId, timeExpired, outcome, percentage, shadow} = props;

    return (

        <motion.div
            // animate={(clickedButtonId === buttonId || outcome.selected) && !timeExpired ?
            animate={(clickedButtonId === buttonId ) && !timeExpired ?
                {scale: [1.0, 1.07], background: '#7DD6FF', color: '#fff'} : {
                    scale: 1,
                    background: '#fff',
                    color: '#000'
                }}
            style={{boxShadow: shadow ? '#000000CE 0 3px 6px' : ''}}
            className={classNames(classes.root, classes.bg_grey)}>
            <div onClick={onClick}
                 className={classNames(classes.button, (percentage.length > 0 && clickedButtonId === buttonId) ? classes.selectedAnswer : null)}>

                <>
                    <div className={classes.percentage}
                         style={{width: percentage.length > 0 ? percentage[buttonId] + '%' : 0}}/>
                    <span
                        className={percentage.length > 0 ? classes.percentageAmount : classes.hide}>{percentage[buttonId]}%</span>
                </>

                <span className={classes.answerText}>{children}</span>

                {(percentage.length > 0 && clickedButtonId === buttonId) &&
                <span className={classes.selected}>Selected</span>}

            </div>

        </motion.div>
    )
};

export default OutcomeButton;