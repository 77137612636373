import {gql} from '@apollo/client';


export const ENTRY_QUERY = gql`
  query entry($contestId: Int!) {
    entry(contestId: $contestId) {
        id
        currentBatsman {
            id
            key
            name
            teamKey
        }
        selectedBatsmen {
            id
            key
            name
            points
            isOut
            isComplete
            teamKey
        }
        score {
            quizPoints
            runPoints
            rank
            winnings
            points
            wicketPoints
        }
        settlementStatus
        hasSelectedFirst
        hasSelectedSecond
        isFirstComplete
    }
  }
`;



