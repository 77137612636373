import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";

const styles = makeStyles((theme: Theme) => ({
    topTitles: {
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px 90px',
        fontSize: 12,
        color: theme.palette.primary.contrastText,
        '& > span:nth-child(1)': {
            textAlign: 'center'
        },
        '& > span:nth-child(2)': {
            textAlign: 'left'
        },
        '& > span:nth-child(3)': {
            textAlign: 'left'
        },
        '& > span:nth-child(4)': {
            textAlign: 'left'
        },
    },
    titlesDivider: {
        width: '100%',
        height: 1,
        background: '#FFFFFF2B',
        margin: '5px 0 10px'
    },
    padding:{
        padding: '0 15px',
    }
}));

interface Props {
    withoutPadding?: boolean
    type: 'OVERALL' | 'LADBROKES'
}

const TopTitles = (props: Props) => {
    const classes = styles();

    return (
        <>
            <div className={classNames(classes.topTitles, props.withoutPadding ? null : classes.padding )}>
                <span>POS</span>
                <span>Player</span>
                <span>Pts</span>
                <span>{props.type === 'OVERALL' ? 'Tickets' : 'Bonus Cash'}</span>
            </div>
            <div className={classes.titlesDivider}/>
        </>
    )
};

export default TopTitles;