import {gql} from '@apollo/client';

export const GET_CONTESTS = gql`
  query getContests {
    contests {
        id
        status
        estimatedStartTime
        lockOutTime
        fixture {
            id
            key
            name
            shortName
            subTitle
            startDate
            metricGroup
            status
            playStatus
            winner
            gender
            format
            venue {
               id
               key
               name
               city
            }
            teamA {
                id
                key
                code
                name
            }
            teamB {
                id
                key
                code
                name
            }
            venue {
               id
               key
               name
               city
            }
         }
            entry {
                id
            }
    }
  }
`;

export interface Team {
    id: number
    key: string
    code: string
    name: string
}

export interface Contest {
    id: number
    status: string
    estimatedStartTime: string
    lockOutTime: string
    fixture: Fixture
    entry?: {
        id: number
    }
    __typename: string
}

export interface CricketState {
    id: number
    key: string
    name: string
    city: string
    __typename: string
}

export interface Venue {
    id: number
    key: string
    name: string
    city: string
}

export interface Fixture {
    id: number
    key: string
    name: string
    shortName: string
    subTitle: string
    startDate: string
    metricGroup: string
    status: string
    playStatus: string
    winner?: string
    gender: string
    format: string
    teamA: Team
    teamB: Team
    venue: Venue
    cricketState?: CricketState
    __typename: string
}
