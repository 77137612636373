import { useQuery } from "@apollo/client";
import add from "date-fns/add";
import { useMemo } from "react";
import { useAuth } from "../../../contexts/Auth";
import {
  GetContestsDocument,
  GetContestsQuery,
  GetContestsQueryVariables,
} from "../gql/operations.generated";

export const useGetContests = () => {
  const { isAuth } = useAuth();

  const { data } = useQuery<GetContestsQuery, GetContestsQueryVariables>(
    GetContestsDocument,
    {
      skip: !isAuth,
    }
  );

  const { contests, todayContestsCount } = useMemo(() => {
    if (!data?.afl.contests) {
      return {
        contests: undefined,
        todayContestsCount: 0,
      };
    }

    const sortedContests = [...data.afl.contests].sort((a, b) => {
      if (parseInt(a.startTime, 10) > parseInt(b.startTime, 10)) {
        if (a.status === "COMPLETED" && b.status === "COMPLETED") {
          return 0;
        } else if (a.status === "COMPLETED" && b.status !== "COMPLETED") {
          return 1;
        } else if (a.status !== "COMPLETED" && b.status === "COMPLETED") {
          return -1;
        }
        return 1;
      } else {
        if (a.status === "COMPLETED" && b.status === "COMPLETED") {
          return 0;
        } else if (a.status === "COMPLETED" && b.status !== "COMPLETED") {
          return 1;
        } else if (a.status !== "COMPLETED" && b.status === "COMPLETED") {
          return -1;
        }
        return -1;
      }
    });

    const today = new Date();
    const tomorrow = add(today, { days: 1 });
    const sevenDaysAhead = add(today, { days: 7 });

    const todayContests = sortedContests.filter(
      (contest) => new Date(Number(contest.startTime)) < tomorrow
    );
    const sevenDaysContests = sortedContests.filter(
      (contest) => new Date(Number(contest.startTime)) < sevenDaysAhead
    );

    return {
      contests: sevenDaysContests,
      todayContestsCount: todayContests.length,
    };
  }, [data?.afl.contests]);

  return {
    contests,
    todayContestsCount,
  };
};
