import React from 'react';
import classNames from "classnames";
import {makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    buttonsWrapper: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 5,
        paddingTop: 5,
        justifyItems: 'center',
    },
    fullWidth: {
        gridTemplateColumns: '1fr',
    }
}));

interface Props {
    contestId: number
    entryId?: number
    status: string | null
    playButton: React.ReactNode
    leaderboardButton: React.ReactNode
    resultButton: React.ReactNode
    hasEntered?: boolean
}

const FixtureButtons = (props: Props) => {
    const classes = styles();
    const {contestId, status, playButton, leaderboardButton, resultButton, entryId, hasEntered} = props;

    if (status === 'INPROGRESS') {
        return (
            <div className={classes.buttonsWrapper}>
                {leaderboardButton}
                {contestId && playButton}
            </div>
        );
    } else if (status === 'LOCKED') {
        return (
            <div className={classNames(classes.buttonsWrapper, classes.fullWidth)}>
                {leaderboardButton}
            </div>
        );
    } else if (status === 'COMPLETED') {
        return (
            <div className={classNames(classes.buttonsWrapper, classes.fullWidth)}>
                {!entryId && hasEntered && resultButton}
                {entryId && resultButton}
            </div>
        );
    } else if (status === 'ABANDONED') {
        return (
            <div className={classNames(classes.buttonsWrapper, classes.fullWidth)}>
                {entryId && resultButton}
            </div>
        );
    }

    return null;
};

export default FixtureButtons;