import React, {useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import Button from "../common/UI/SquareButton";
import {useHistory} from "react-router";
import rain from '../../assets/rain.png';
import classNames from "classnames";


const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.contrastText,
    },
    absoluteStyles: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        zIndex: 10000
    },
    limitedWidth:{
        maxWidth: 650,
    },
    inner: {},
    lockout: {
        display: 'block',
        width: 180,
        height: 'auto',
        margin: '20px auto',
        '@media only screen and (max-width: 1024px)': {
            width: 170,
            height: 'auto',
        }
    },
    title: {
        fontSize: 30,
        fontWeight: 800,
        textAlign: 'center',
        paddingTop: 20
    },
    text: {
        width: '70%',
        margin: 'auto',
        padding: '20px 0 0',
        textAlign: 'center',
        fontSize: 18,
        '@media only screen and (max-width: 1024px)': {
            width: '85%',
            fontSize: 14
        }
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30
    }
}));

interface Props {
    isAbsolute: boolean
    isLimitedWidth: boolean
}

const Index = (props: Props) => {
    const classes = styles();
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const history = useHistory();
    const {isAbsolute, isLimitedWidth} = props;

    return (
        <div
            className={classNames(classes.root, isAbsolute ? classes.absoluteStyles : '', isLimitedWidth ? classes.limitedWidth : '')}>
            <div className={classes.inner}>
                <img src={rain} alt="lockout" className={classes.lockout}/>

                <div className={classes.title}>No Result</div>

                <div className={classes.text}>The match has been abandoned. As per the terms and conditions their are no
                    winners for this game.
                </div>

                <div className={classes.buttonWrapper}>
                    <Button width='162px' buttonWasClicked={buttonWasClicked}
                            onClick={(e: any) => {
                                if (!buttonWasClicked) {
                                    setButtonWasClicked(true);
                                    e.preventDefault();

                                    history.push(`/` + history.location.search)
                                }
                            }}>
                        Home
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default Index;