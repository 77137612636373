import { useQuery } from "@apollo/client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { z } from "zod";
import { useAuth } from "../../../contexts/Auth";
import {
  GetContextUserDocument,
  GetContextUserQuery,
  GetContextUserQueryVariables,
} from "../gql/operations.generated";
import { User } from "../types";

export const UserContext = createContext<
  | {
      user: User | null;
      isUserLoaded: boolean;
      isLadbrokesUser: boolean;
    }
  | undefined
>(undefined);

interface Props {
  children: React.ReactNode;
}

const UserProvider = ({ children }: Props) => {
  const { isAuth } = useAuth();
  const [user, setUser] = useState<User | null>(null);
  const [isUserLoaded, setIsUserLoaded] = useState<boolean>(false);
  const [isLadbrokesUser, setIsLadbrokesUser] = useState<boolean>(false);

  const { data, error, loading } = useQuery<
    GetContextUserQuery,
    GetContextUserQueryVariables
  >(GetContextUserDocument, {
    skip: !isAuth,
  });

  useEffect(() => {
    if (data && !error && !loading) {
      // Ladbrokes id is in UUID format
      const { success: isValidUUID } = z
        .string()
        .uuid()
        .safeParse(data.user.externalId);

      setUser(data.user);
      setIsUserLoaded(true);
      setIsLadbrokesUser(isValidUUID);
    }
  }, [data, error, loading]);

  return (
    <UserContext.Provider value={{ user, isUserLoaded, isLadbrokesUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

export { UserProvider, useUser };
