import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import redBall from '../../../../assets/Cricketball.png';
import cricketbat from '../../../../assets/Cricketbat.png';
import classNames from "classnames";
import {useCricketState} from "../../../../contexts/cricket/CricketState";
import {makeNameString} from '../../../../utils';
import {usePrevious} from "../../../../hooks";
import AnimatedCell from '../../../common/AnimatedCell';
import {useGameStatus} from "../../../../contexts/cricket/GameStatus";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '5px 15px',
        boxShadow: '0 3px 6px ' + theme.palette.primary.light,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        overflow: 'visible',
        zIndex: 10,
        '@media only screen and (max-width: 400px)': {
            maxWidth: 400,
            margin: 'auto',
        },
        position: 'relative',
        height: 77,
    },
    rootInner: {
        maxWidth: 500,
        margin: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        overflow: 'visible',
        height: '100%'
    },
    topRow: {
        fontSize: 12,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.secondary.dark,
        paddingBottom: 10
    },
    flagRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 5,
        overflow: 'visible',
        '& > div': {
            overflow: 'visible'
        }
    },
    flag: {
        width: 16,
        height: 16,
        display: 'block',
        marginRight: 5
    },
    teamName: {
        fontSize: 14,
    },
    scores: {
        fontSize: 14,
        transform: 'scale(1)'
    },
    bestScores: {
        color: theme.palette.primary.contrastText,
        paddingBottom: 5,
        '& $alignWrapper + div': {
            overflow: 'visible'
        }
    },
    scoresBraces: {
        fontSize: 12,
        transform: 'scale(1)'
    },
    titleRow: {
        color: theme.palette.secondary.dark,
        fontSize: 10,
        fontWeight: 700,
        paddingBottom: 5,
        paddingLeft: 5,
        display: 'grid',
        gridTemplateColumns: '1fr 30px 30px 40px',
        width: '100%',
        '& > span:not(:first-child)': {
            textAlign: 'center'
        },
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    scoreRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: 23,
        overflow: 'visible',
        '& > span:not(:first-child)': {
            textAlign: 'right',
            // fontWeight: 700
        },
        paddingLeft: 5,
        fontSize: 12,
        color: theme.palette.primary.contrastText,
        '& > span': {
            transform: 'scale(1)',
        },
        '& > $flex': {
            overflow: 'hidden',
            maxWidth: 85
        },
        '& > $flex > span': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%'
        },
        '@media only screen and (min-width: 1025px)': {
            '& > $flex + span + span': {
                display: 'none'
            },
            '& > $flex + span + span + span': {
                display: 'none'
            },
        }
    },
    cricketbat: {
        display: 'block',
        width: 13,
        height: 13,
        marginTop: 2,
        marginRight: 5
    },
    flex: {
        display: 'flex'
    },
    hide: {
        opacity: 0
    },
    scoreRowThin: {
        display: 'flex',
        justifyContent: 'flex-between',
        width: '100%',
        paddingLeft: 5,
        '& > span:not(:first-child)': {},
        paddingBottom: 5,
        fontSize: 12,
        color: theme.palette.primary.contrastText,
        '@media only screen and (min-width: 1025px)': {
            width: 140
        }
    },
    scoresBold: {
        fontWeight: 700,
        paddingLeft: 10,
        transform: 'scale(1)',
        display: 'inline-block',
    },
    blue: {
        color: theme.palette.grey[700]
    },
    red: {
        color: theme.palette.grey[800]
    },
    bigWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1px 1fr',
        width: '100%',
        alignItems: 'center',
        '& > div': {
            overflow: 'visible'
        },
        overflow: 'visible',
    },
    divider: {
        width: 1,
        height: 'calc(100% - 20px)',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        display: 'block',
        '@media only screen and (min-width: 1025px)': {
            height: 'calc(100% - 20px)',
            marginTop: 0,
        }
    },
    alignWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    nowrap: {
        whiteSpace: 'nowrap'
    },
    deskWrapper: {
        overflow: 'visible',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        paddingTop: 5
    },
    bowlerRow: {
        gridTemplateColumns: '1fr 40px',
        '& > div': {
            paddingRight: 5
        },
        '& > span': {
            textAlign: 'right !important',
            fontWeight: '400 !important',

        }
    },
    inningsBreak: {
        fontWeight: 700,
        fontSize: 18,
        paddingLeft: 15
    }
}));

interface Props {}

const QuestionTopBarWrapper = (props: Props) => {
    const classes = styles();
    const {cricketState} = useCricketState();
    const {gameStatus} = useGameStatus();

    const prevCrStateBall = usePrevious(cricketState ? cricketState.ball : null);
    const prevCrStateOver = usePrevious(cricketState ? cricketState.over : null);
    const prevTopTeamRuns = usePrevious(cricketState ? cricketState.topTeam.runs : null);
    const prevTopTeamWicketsOut = usePrevious(cricketState ? cricketState.topTeam.wicketsOut : null);
    const prevBottomTeamRuns = usePrevious(cricketState ? cricketState.bottomTeam.runs : null);
    const prevBottomTeamWicketsOut = usePrevious(cricketState ? cricketState.bottomTeam.wicketsOut : null);
    const prevBowlerRuns = usePrevious(cricketState && cricketState.bowler ? cricketState.bowler.runs : null);
    const prevBowlerWickets = usePrevious(cricketState && cricketState.bowler ? cricketState.bowler.wickets : null);
    const prevBowlerOvers = usePrevious(cricketState && cricketState.bowler ? cricketState.bowler.overs : null);
    const prevBowlerBalls = usePrevious(cricketState && cricketState.bowler ? cricketState.bowler.balls : null);
    const prevBatsmanFirstRuns = usePrevious(cricketState && cricketState.batsmans.length >= 2 ? cricketState.batsmans[0].runs : null);
    const prevBatsmanFirstBalls = usePrevious(cricketState && cricketState.batsmans.length >= 2 ? cricketState.batsmans[0].balls : null);
    const prevBatsmanSecondRuns = usePrevious(cricketState && cricketState.batsmans.length >= 2 ? cricketState.batsmans[1].runs : null);
    const prevBatsmanSecondBals = usePrevious(cricketState && cricketState.batsmans.length >= 2 ? cricketState.batsmans[1].balls : null);


    const topTeamImg = `/swatches/${cricketState ? cricketState.topTeam.code.toLowerCase() : ''}.png`;
    const bottomTeamImg = `/swatches/${cricketState ? cricketState.bottomTeam.code.toLowerCase() : ''}.png`;

    return (
        cricketState ?
            <div className={classes.root}>

                <div className={classes.rootInner}>

                    <div className={classes.bigWrapper}>
                        <div>
                            <div className={classNames(classes.flagRow, classes.bestScores)}>
                                <div className={classes.alignWrapper}>
                                    <img src={topTeamImg} alt="flag" className={classes.flag}/>
                                    <span className={classes.teamName}>{cricketState.topTeam.code}</span>
                                </div>
                                <div>
                                    <AnimatedCell
                                        valueOne={prevTopTeamRuns}
                                        valueTwo={cricketState.topTeam.runs}
                                        classname={classes.scores}
                                    >
                                        {cricketState.topTeam.runs}
                                    </AnimatedCell>


                                    {cricketState.topTeam.isBatting ?
                                        <>
                                            <AnimatedCell
                                                valueOne={prevTopTeamWicketsOut}
                                                valueTwo={cricketState.topTeam.wicketsOut}
                                                classname={classes.scores}
                                            >
                                                {'/' + cricketState.topTeam.wicketsOut}
                                            </AnimatedCell>
                                            &nbsp;
                                            <AnimatedCell
                                                valueOne={prevCrStateOver}
                                                valueTwo={cricketState.over}
                                                classname={classes.scoresBraces}
                                            >
                                                {'(' + cricketState.over + '.'}
                                            </AnimatedCell>

                                            <AnimatedCell
                                                valueOne={prevCrStateBall}
                                                valueTwo={cricketState.ball}
                                                classname={classes.scoresBraces}
                                            >
                                                {cricketState.ball + ')'}
                                            </AnimatedCell>
                                        </>

                                        : null}


                                </div>
                            </div>

                            <div className={classes.flagRow}>
                                <div className={classes.alignWrapper}>
                                    <img src={bottomTeamImg} alt="flag" className={classes.flag}/>
                                    <span className={classes.teamName}>{cricketState.bottomTeam.code}</span>
                                </div>
                                <div>

                                    <AnimatedCell
                                        valueOne={prevBottomTeamRuns}
                                        valueTwo={cricketState.bottomTeam.runs}
                                        classname={classes.scores}
                                    >
                                        {cricketState.bottomTeam.runs}
                                    </AnimatedCell>

                                    {cricketState.bottomTeam.isBatting ?
                                        <>
                                            <AnimatedCell
                                                valueOne={prevBottomTeamWicketsOut}
                                                valueTwo={cricketState.bottomTeam.wicketsOut}
                                                classname={classes.scores}
                                            >
                                                {'/' + cricketState.bottomTeam.wicketsOut}
                                            </AnimatedCell>

                                            &nbsp;
                                            <AnimatedCell
                                                valueOne={prevCrStateOver}
                                                valueTwo={cricketState.over}
                                                classname={classes.scoresBraces}
                                            >
                                                {'(' + cricketState.over + '.'}
                                            </AnimatedCell>

                                            <AnimatedCell
                                                valueOne={prevCrStateBall}
                                                valueTwo={cricketState.ball}
                                                classname={classes.scoresBraces}
                                            >
                                                {cricketState.ball + ')'}
                                            </AnimatedCell>
                                        </>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className={classes.divider}/>

                        {gameStatus.inningStatus === 'BREAK' ?
                            <span className={classes.inningsBreak}>Innings Break</span>
                            :
                            <div className={classes.deskWrapper}>

                                {cricketState.batsmans[0] ?
                                    <div className={classes.scoreRow}>
                                        <div className={classes.flex}>
                                            <img src={cricketbat} alt="cricketbat" className={classNames(
                                                classes.cricketbat, !cricketState.batsmans[0].isReceiver ? classes.hide : null
                                            )}/>
                                            <span>{makeNameString(cricketState.batsmans[0].name, false)}</span>
                                        </div>

                                        <span>
                                    <AnimatedCell
                                        valueOne={prevBatsmanFirstRuns}
                                        valueTwo={cricketState.batsmans[0].runs}
                                        classname={''}
                                    >
                                        {cricketState.batsmans[0].runs}
                                    </AnimatedCell>
                                            &nbsp;
                                            <AnimatedCell
                                                valueOne={prevBatsmanFirstBalls}
                                                valueTwo={cricketState.batsmans[0].balls}
                                                classname={''}
                                            >
                                        ({cricketState.batsmans[0].balls})
                                    </AnimatedCell>
                                    </span>

                                    </div>
                                    : <div className={classes.scoreRow}/>
                                }

                                {cricketState.batsmans[1] ?
                                    <div className={classes.scoreRow}>
                                        <div className={classes.flex}>
                                            <img src={cricketbat} alt="cricketbat" className={classNames(
                                                classes.cricketbat, !cricketState.batsmans[1].isReceiver ? classes.hide : null
                                            )}/>
                                            <span>{makeNameString(cricketState.batsmans[1].name, false)}</span>
                                        </div>
                                        <span>
                                    <AnimatedCell
                                        valueOne={prevBatsmanSecondRuns}
                                        valueTwo={cricketState.batsmans[1].runs}
                                        classname={''}
                                    >
                                        {cricketState.batsmans[1].runs}
                                    </AnimatedCell>
                                            &nbsp;
                                            <AnimatedCell
                                                valueOne={prevBatsmanSecondBals}
                                                valueTwo={cricketState.batsmans[1].balls}
                                                classname={''}
                                            >
                                        ({cricketState.batsmans[1].balls})
                                    </AnimatedCell>
                                    </span>
                                    </div>
                                    : <div className={classes.scoreRow}/>
                                }

                                {cricketState.bowler ?
                                    <div className={classNames(classes.scoreRow, classes.bowlerRow)}>
                                        <div className={classNames(classes.flex, classes.nowrap)}>
                                            <img src={redBall} alt="ball" className={classNames(classes.cricketbat)}/>
                                            <span>{makeNameString(cricketState.bowler.name, true)}</span>
                                        </div>

                                        <span>
                                        <AnimatedCell
                                            valueOne={prevBowlerWickets}
                                            valueTwo={cricketState.bowler.wickets}
                                            classname={classes.scoresBraces}
                                        >
                                            {cricketState.bowler.wickets}/
                                        </AnimatedCell>
                                        <AnimatedCell
                                            valueOne={prevBowlerRuns}
                                            valueTwo={cricketState.bowler.runs}
                                            classname={classes.scoresBraces}
                                        >
                                            {cricketState.bowler.runs}
                                        </AnimatedCell>
                                            &nbsp;
                                            <AnimatedCell
                                                valueOne={prevBowlerOvers}
                                                valueTwo={cricketState.bowler.overs}
                                                classname={classes.scoresBraces}
                                            >
                                            ({cricketState.bowler.overs}
                                        </AnimatedCell>
                                        <AnimatedCell
                                            valueOne={prevBowlerBalls}
                                            valueTwo={cricketState.bowler.balls}
                                            classname={classes.scoresBraces}
                                        >
                                            .{cricketState.bowler.balls})
                                        </AnimatedCell>
                                    </span>
                                    </div>
                                    : <div className={classes.scoreRow}/>
                                }
                            </div>
                        }
                    </div>

                </div>
            </div>
            : <></>
    )
};

export default QuestionTopBarWrapper;