import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {useGameState} from "../../../../contexts/nba/GameState";
import BackRow from "./BackRow";
import QtrTabs from "../../../leaderboard/nba/QtrTabs";
import LeaderboardList from "./LeaderboardList";
import { NBA_MAX_PERIOD } from '../../../../constants';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'auto',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'auto'
        },
        '& > a': {
            textDecoration: 'none'
        },
        background: `url('/nba-background.png')`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100vh',
        '@media only screen and (orientation: landscape)': {
            backgroundImage: `url('/nba-background-landscape.jpg')`
        }
    },
    rootInner: {
        maxWidth: '100%',
        margin: 'auto',
        height: '100vh',
        '@media only screen and (max-width: 650px)': {
            maxWidth: 650,
        },
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
        },
    },
    topPart: {
        background: theme.palette.background.default,
        height: 55,
    },
    innerWrapper: {
        padding: '0 15px',
        background: theme.palette.background.default,
    },
}));

const Index = () => {
    const classes = styles();
    const {gameState, isStatusLoaded} = useGameState();
    const [selectedQtr, setSelectedQtr] = useState(-1);
    const [activeBlockId, setBlockId] = useState<number | null>(null)

    useEffect(() => {
        if (isStatusLoaded && gameState) {
            if (selectedQtr === gameState.period - 1 || selectedQtr === -1) {
                setBlockId(gameState.currentBlock)
            } else {
                setBlockId(gameState.allBlocks[selectedQtr].id)
            }

        }
    }, [isStatusLoaded, gameState])

    const qtrClick = (qtr: number) => {
        if (gameState) {
            setBlockId(gameState.allBlocks[qtr].id)
            setSelectedQtr(qtr)
        }
    };

    return (
        <div className={classNames(classes.root)}>
            <div className={classes.rootInner}>

                <div className={classes.topPart}>
                    <BackRow/>
                </div>

                <div className={classes.innerWrapper}>
                    {gameState && NBA_MAX_PERIOD > 1 &&
                        <QtrTabs qtrClick={qtrClick} selectedQtr={selectedQtr}
                                 setSelectedQtr={setSelectedQtr} period={gameState.period}/>
                    }
                </div>

                <LeaderboardList activeBlockId={activeBlockId}/>
            </div>
        </div>
    )
};

export default Index;