import {gql} from "@apollo/client";

export const NBA_ENTRY_QUERY = gql`
    query entry($contestId: Int!) {
        nba {
           entry(contestId: $contestId) {
                id
                points
                blockId
                rank
                winnings
                nationalRank
                nationalWinnings
                settlementStatus
           }
        }
    }
`;