import React from 'react';
import classNames from "classnames";
import {addSuffixToNumber} from "../../../utils";
import TiedText from '../shared/TiedText';
import {NbaEntry} from "../../../../../shared/types";
import {makeStyles, Theme} from "@material-ui/core";
import {useAuth} from "../../../contexts/Auth";
import TopTitles from './TopTitles';
import LeaderboardEntry from "./LeaderboardEntry";
import PaginationComponent from './PaginationComponent';
import cashImage from '../../../assets/nba-cash.png';
import ticketImage from '../../../assets/nba-ticket.png';

const styles = makeStyles((theme: Theme) => ({
    userData: {
        borderRadius: 7,
        background: theme.palette.background.default,
        padding: '10px',
        marginBottom: 20
    },
    userRow: {
        display: 'grid',
        gridTemplateColumns: '50px 1fr 50px 90px',
        fontSize: 12,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left'
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        }
    },
    userScores: {
        gridTemplateColumns: '50px 1fr 50px 90px',
        alignItems: 'center',
        display: 'grid',
        fontWeight: 900,
        padding: '10px 0',
        borderBottom: '1px solid #2B2C3A',
        '& > span': {
            textAlign: 'left'
        },
        '& > span:nth-child(1)': {
            textAlign: 'center',
        }
    },
    pos: {
        fontSize: 14,
        fontWeight: 400,
    },
    userName: {
        fontSize: 14,
        fontWeight: 400,
        overflow: 'hidden',
        paddingRight: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    points: {
        fontSize: 14,
    },
    winning: {
        fontSize: 14,
        paddingLeft: 30,
        position: 'relative',
    },
    winningType: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
    row: {
        display: 'flex',
        padding: '10px 0 0'
    },
    scores: {
        fontWeight: 400,
    },
    rank: {
        fontWeight: 700,
    },
    listWrapper: {
    },
    cashWinning: {
        color: '#6BFE00'
    },
    ticketWinning: {
        color: '#FFC700'
    },
}));

interface Props {
    entry: NbaEntry | null
    isAnyTie: boolean
    newList: (NbaEntry & {tied: boolean})[]
    type: 'OVERALL' | 'LADBROKES'
}

const Leaderboard = (props: Props) => {
    const classes = styles();
    const {entry, isAnyTie, newList, type} = props;
    const [page, setPage] = React.useState(1);

    const {username} = useAuth();

    const pageSize = 20;
    const paginationPages = Math.ceil(newList.length / pageSize);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const v = value ? value : 1;
        setPage(v);
    };

    return (
        <>
            {entry ?
                <div className={classes.userData}>
                    <div className={classes.userRow}>
                        <span>POS</span>
                        <span>Player</span>
                        <span>Pts</span>
                        <span>{type === 'OVERALL' ? 'Tickets' : 'Bonus Cash'}</span>
                    </div>

                    <div className={classes.userScores}>
                        <span className={classes.pos}>{addSuffixToNumber(type === 'OVERALL' ? entry.nationalRank : entry.rank)}</span>
                        <span className={classNames(classes.userName)}>{username}</span>
                        <span className={classes.points}>{entry.points}</span>
                        <span className={classNames(classes.winning)}>
                            <span className={classes.winningType}><img src={type === 'OVERALL' ? ticketImage : cashImage} alt="winning-type-icon" /></span>
                            <span style={{color: type === 'OVERALL' ? '#FFC700' : '#6BFE00'}}>{type === 'OVERALL' ? entry.nationalWinnings : `$${entry.winnings}`}</span>
                        </span>
                    </div>

                    <div className={classes.row}>
                        <span className={classes.scores}><span className={classes.rank}>{addSuffixToNumber(type === 'OVERALL' ? entry.nationalRank : entry.rank)}</span> of {newList.length}</span>
                    </div>
                </div> : null
            }

            {isAnyTie ?
                <TiedText/>
                : null}

            <TopTitles type={type} />

            <div className={classes.listWrapper}>
                {newList
                    .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
                    .map((entry, i: number) => {
                        return (
                            <LeaderboardEntry key={i} entry={entry} username={username}
                                              userScore={false}
                                              winningStyle={type === 'OVERALL' ? classes.ticketWinning : classes.cashWinning}
                                              type={type} />
                        );
                    })}
            </div>

            {newList.length > pageSize &&
            <PaginationComponent paginationPages={paginationPages}
                                 handlePagination={handleChange}/>}
        </>
    )
};

export default Leaderboard;