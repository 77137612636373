import {gql} from '@apollo/client';

export const NRL_PREVIOUS_CONTEST = gql`
    query getNrlPreviousContest {
        nrl {
           previous {
               id
               currentBlock
               status
               startTime
               isLocked
               fixtureId
               hasEntered
               fixture {
                    id
                    homeTeam {
                        id
                        externalId
                        name
                    }
                    awayTeam {
                        id
                        externalId
                        name
                    }
               }
           }
        }
    }
`;