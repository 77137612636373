import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const styles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[300],
  },
  backRow: {
    position: "relative",
    padding: 7,
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    height: 50,
    cursor: "pointer",
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    width: 70,
    zIndex: 10,
    "& > span": {
      width: 15,
      height: 15,
      borderBottom: "2px solid " + theme.palette.primary.contrastText,
      borderRight: "2px solid " + theme.palette.primary.contrastText,
      transform: "rotate(135deg)",
      display: "block",
      marginLeft: 5,
      // marginRight: 5
    },
  },
  hide: {
    "@media only screen and (min-width: 1025px)": {
      display: "none",
    },
  },
}));

interface Props {
  onClick: any;
  hideOnDesk?: boolean;
  children?: React.ReactNode;
}

const BackButton = (props: Props) => {
  const classes = styles();

  return (
    <div
      className={classNames(
        classes.backRow,
        props.hideOnDesk ? classes.hide : null
      )}
      onClick={() => props.onClick()}
    >
      <span />
      {props.children}
    </div>
  );
};

export default BackButton;
