import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {AflNrlEntry, Score} from "../../../../../shared/types";
import {addSuffixToNumber} from '../../../utils';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '45px 1fr 60px 70px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43
    },
    pos: {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'left'
    },
    nameWrapper: {
        textAlign: 'left',
        fontSize: 14,
        wordBreak: 'break-all'
    },
    points: {
        fontSize: 14,
        textAlign: 'right',
    },
    money: {
        textAlign: 'right',
        fontSize: 14,
        fontWeight: 700,
    }
}));

interface Props {
    entry: AflNrlEntry & { tied: boolean }
    cashColor?: string
}

const LeaderboardEntry = (props: Props) => {
    const classes = styles();
    const {entry, cashColor} = props;

    return (
        <div className={classes.root}>
            <span className={classes.pos}>{addSuffixToNumber(entry.rank)}</span>
            <div className={classes.nameWrapper}>{entry.username}</div>

            <span className={classes.points}>
                {entry.tied ? <span>&#65121;</span> : ''}
                {entry.points}</span>
            <span className={classNames(classes.money, cashColor ? cashColor : null)}>${entry.winnings}</span>
        </div>
    )
};

export default LeaderboardEntry;
