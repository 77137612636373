import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {ContestsList} from "./types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetailsComponent from './AccordionDetails';

const styles = makeStyles((theme: Theme) => ({
    accordion: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
    expandIcon: {
        color: theme.palette.primary.contrastText,
    },

    eachBlock: {
        width: '100%'
    },
    date: {
        fontWeight: 700,
        paddingRight: 10
    },
}))

interface Props {
    contestsList: ContestsList[]
    sport: string
}

const ListComponent = (props: Props) => {
    const classes = styles();
    const [expanded, setExpanded] = React.useState<number | false>(false);
    const {contestsList, sport} = props;

    const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            {contestsList.map((q: ContestsList, i: number) => {

                let date: string | number = q.startTime.getDate();
                date = date > 9 ? date : '0' + date;
                let month: string | number = q.startTime.getMonth();
                month = (month + 1) > 9 ? (month + 1) : '0' + (month + 1);
                const year = q.startTime.getFullYear();
                const fullDate = `${date}.${month}.${year}`

                return (
                    <Accordion key={i} expanded={expanded === i} onChange={handleChange(i)}
                               className={classes.accordion} TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.expandIcon}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div>
                                <span className={classes.date}>{fullDate}</span>
                                {q.fixture.homeTeam.name} VS {q.fixture.awayTeam.name}</div>
                        </AccordionSummary>

                        <AccordionDetailsComponent contestId={q.id} sport={sport}/>
                    </Accordion>
                )
            })}
        </>
    );
};

export default ListComponent;