import { makeStyles, Theme } from '@material-ui/core';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import logo from '../../assets/cq-logo.png';
import { useGetContests } from '../../hooks/useGetContests';
import FixtureCard from '../common/fixture-card/FixtureCard';
import Layout from '../common/Layout';
import LoadingScreenSpinner from '../common/LoadingScreenSpinner';

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: '0px 30px 20px',
    },
    prevContests: {
        fontSize: 13,
        cursor: 'pointer',
        textAlign: 'center',
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
        '& > $chevron': {
            width: 7,
            height: 7,
            borderBottom: '1px solid ' + theme.palette.primary.contrastText,
            borderRight: '1px solid ' + theme.palette.primary.contrastText,
            transform: 'rotate(-45deg)',
            display: 'block',
            marginLeft: 5,
            marginTop: 6,
        },
    },
    chevron: {},
    logo: {
        display: 'block',
        width: 250,
        height: 140,
        margin: 'auto',
    },
    comeBack: {
        width: '100%',
        borderRadius: 5,
        padding: '10px 20px',
        display: 'flex',
        fontSize: 24,
        fontWeight: 700,
        alignItems: 'center',
        background: theme.palette.info.main,
        position: 'relative',
        height: 138,
        textAlign: 'center',
        marginBottom: 10,
    },
    upcomingHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void;
}

function Homepage(props: Props) {
    const { setPrevPageForLeaderboard } = props;
    const classes = styles();
    const history = useHistory();
    const { contests, todayContestsCount } = useGetContests();

    const onPreviousResultClick = useCallback(
        () => history.push('previous_results/' + history.location.search),
        [history]
    );

    if (contests) {
        return (
            <Layout>
                <div className={classes.wrapper}>
                    <img src={logo} alt="logo" className={classes.logo} />

                    {contests ? (
                        <>
                            {todayContestsCount === 0 ? (
                                <div>
                                    <div className={classes.comeBack}>There are no games scheduled for today.</div>
                                    {contests.length > 0 ? (
                                        <div className={classes.upcomingHeader}>
                                            <h3>Upcoming Games</h3>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}

                            {contests.map((contest, n: number) => (
                                <FixtureCard
                                    contest={contest}
                                    key={contest.id}
                                    setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                />
                            ))}
                        </>
                    ) : null}

                    <div className={classes.prevContests} onClick={onPreviousResultClick}>
                        <span>Previous Results</span> <span className={classes.chevron} />
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className={classes.wrapper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <LoadingScreenSpinner />
            </div>
        </Layout>
    );
}

export default Homepage;
