import { useMutation, useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import GenericButton from '../../../common/components/GenericButton';
import logo from '../../assets/cq-logo.png';
import { ENABLE_NATIONAL_COMPETITION, EXCLUDE_SA_USER_FROM_NATIONAL, NATIONAL_TERMS_PDF_PATH } from '../../constants';
import { useEntry } from '../../contexts/Entry';
import { useUser } from '../../contexts/User';
import {
    EnterContestDocument,
    EnterContestMutation,
    EnterContestMutationVariables,
    GetBlockIdDocument,
    GetBlockIdQuery,
    GetBlockIdQueryVariables,
} from '../../gql/operations.generated';
import BackButton from '../common/BackButton';
import StyledCheckbox from '../common/Checkbox';
import Layout from '../common/Layout';
import LoadingScreenSpinner from '../common/LoadingScreenSpinner';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '0 30px',
        '@media only screen and (max-width: 600px)': {
            padding: '0 0 30px',
        },
    },
    innerRoot: {
        maxWidth: 500,
        width: '90%',
        margin: 'auto',
        color: theme.palette.primary.contrastText,
    },
    title1: {
        fontSize: 24,
        fontWeight: 800,
        textAlign: 'center',
        paddingBottom: 20,
    },
    row: {
        display: 'flex',
        width: '100%',
        paddingBottom: 25,
        overflow: 'visible',
        alignItems: 'center',
        '@media only screen and (max-width: 600px)': {
            paddingBottom: 15,
        },
    },
    list: {
        fontSize: 20,
        fontWeight: 800,
        position: 'relative',
        zIndex: 1,
        paddingLeft: 13,
        '&::before': {
            borderRadius: 100,
            background: '#007FFF',
            position: 'absolute',
            left: 0,
            top: -3,
            content: '""',
            zIndex: -1,
            width: 36,
            height: 36,
        },
    },
    text: {
        fontSize: 16,
        paddingLeft: 25,
        fontWeight: 700,
    },
    agreeRow: {
        width: '100%',
    },
    checkbox: {
        left: 0,
        position: 'absolute',
        top: 0,
    },
    link: {
        fontSize: 14,
        color: theme.palette.primary.contrastText,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    },
    label: {
        display: 'block',
        padding: 9,
        paddingLeft: 45,
        position: 'relative',
        '@media only screen and (max-width: 600px)': {
            padding: '5px 0 5px 45px',
        },
    },
    wrapper: {
        marginRight: 5,
    },
    logo: {
        display: 'block',
        width: 250,
        height: 140,
        margin: 'auto',
    },
    btnWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 20,
        paddingTop: 20,
    },
    back: {
        height: 50,
        position: 'absolute',
        '@media only screen and (min-width: 1025px)': {
            top: 5,
            left: 0,
            width: '100%',
        },
        '@media only screen and (max-width: 1024px)': {
            top: 0,
            left: 0,
            width: '100%',
        },
    },
}));

interface Props {
    setAgreedRules: (a: boolean) => void;
    agreedRules: boolean;
}

const Index = (props: Props) => {
    const classes = styles();
    const history = useHistory();
    const { setAgreedRules, agreedRules } = props;
    const { contest_id } = useParams<{ contest_id: string }>();
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const [blockId, setBlockId] = useState('');
    const [contestStatus, setContestStatus] = useState<string | null>(null);
    const [showRulesPage, setShowRulesPage] = useState(false);
    const [agreeLadbrokesTerms, setAgreeLadbrokesTerms] = useState(false);
    const [agreeNationalTerms, setAgreeNationalTerms] = useState(false);
    const { entry, loadEntry, isEntryLoaded } = useEntry();
    const { isLadbrokesUser, user } = useUser();

    const RULES =
        user?.fromSA && EXCLUDE_SA_USER_FROM_NATIONAL
            ? ['Answer at least 5 questions.', 'Climb the leaderboard.']
            : ['Answer at least 5 questions.', 'Climb the leaderboard.'];
    if (isLadbrokesUser) {
        RULES.push('Finish in the top 54 Ladbrokes customers and win a share in $1000 Bonus Cash.');
    }

    const onAgreeLadbrokesTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeLadbrokesTerms(event.target.checked);
    };

    const onAgreeNationalTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeNationalTerms(event.target.checked);
    };

    useEffect(() => {
        let hasAgreed = false;

        if (ENABLE_NATIONAL_COMPETITION) {
            hasAgreed = user?.fromSA && EXCLUDE_SA_USER_FROM_NATIONAL
                ? isLadbrokesUser
                    ? agreeLadbrokesTerms
                    : false // we shouldn't fall into this case, SA user are only from Ladbrokes App
                : isLadbrokesUser
                    ? agreeLadbrokesTerms && agreeNationalTerms
                    : agreeNationalTerms;
        } else {
            hasAgreed = isLadbrokesUser && agreeLadbrokesTerms;
        }

        setAgreedRules(hasAgreed);
    }, [agreeLadbrokesTerms, agreeNationalTerms, isLadbrokesUser, setAgreedRules, user?.fromSA]);

    const [enterContestMutation, { data: enterContestData, loading: enterContestLoading, error: enterContestError }] =
        useMutation<EnterContestMutation, EnterContestMutationVariables>(EnterContestDocument);

    const {
        loading: contestLoading,
        error: contestError,
        data: contestData,
    } = useQuery<GetBlockIdQuery, GetBlockIdQueryVariables>(GetBlockIdDocument, {
        variables: {
            id: contest_id,
        },
    });

    /**
     * On enter contest mutation, update the Entry Context's entry and
     * navigate to the question page.
     */
    useEffect(() => {
        if (enterContestData?.afl.enterContest.entry && !enterContestLoading && !enterContestError) {
            loadEntry(enterContestData.afl.enterContest.entry);
            history.push(`question_page` + history.location.search);
        } else if (!enterContestLoading && enterContestError) {
            console.error(enterContestError);
        }
    }, [enterContestData, enterContestError, enterContestLoading, history, loadEntry]);

    /**
     * On Entry Context's entry loaded, if we have already entered the contest,
     * navigate to question page, otherwise show this page.
     */
    useEffect(() => {
        if (entry && entry.id !== '') {
            history.push(`question_page` + history.location.search);
        }

        if (isEntryLoaded && (!entry || entry.id === '')) {
            setShowRulesPage(true);
        }
    }, [entry, isEntryLoaded, history]);

    /**
     * If the contest to enter is not in progress, navigate to the home page.
     */
    useEffect(() => {
        if (!contestLoading && !contestError && contestData?.afl.contest) {
            const contest = contestData.afl.contest;

            setBlockId(contest.currentBlock);
            setContestStatus(contest.status);
            if (contest.status !== 'INPROGRESS') history.push(`/` + history.location.search);
        }
    }, [contestData?.afl.contest, contestError, contestLoading, history]);

    return (
        <Layout>
            {showRulesPage ? (
                <>
                    <div className={classes.back}>
                        <BackButton onClick={() => history.push(`/` + history.location.search)}>Back</BackButton>
                    </div>
                    <img src={logo} alt="logo" className={classes.logo} />

                    <div className={classes.root}>
                        <div className={classes.innerRoot}>
                            <div className={classes.title1}>How To Play</div>

                            {RULES.map((t: string, i: number) => (
                                <div className={classes.row} key={i}>
                                    <span className={classes.list}>{i + 1}</span>
                                    <span className={classes.text}>{t}</span>
                                </div>
                            ))}

                            {ENABLE_NATIONAL_COMPETITION && !(user?.fromSA && EXCLUDE_SA_USER_FROM_NATIONAL) ? (
                                <div className={classes.agreeRow}>
                                    <label className={classes.label}>
                                        <StyledCheckbox className={classes.checkbox} onChange={onAgreeNationalTerms} />I
                                        agree to the Couch Quiz{' '}
                                        <a
                                            className={classes.link}
                                            href={NATIONAL_TERMS_PDF_PATH}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Terms and Conditions
                                        </a>
                                        .
                                    </label>
                                </div>
                            ) : null}

                            {isLadbrokesUser ? (
                                <div className={classes.agreeRow}>
                                    <label className={classes.label}>
                                        <StyledCheckbox className={classes.checkbox} onChange={onAgreeLadbrokesTerms} />
                                        I agree to the Couch Quiz - Ladbrokes Bonus{' '}
                                        <a
                                            className={classes.link}
                                            href="https://www.ladbrokes.com.au/rules-terms-and-conditions"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Terms and Conditions
                                        </a>
                                        . Bonus Cash is a product, not actual cash.
                                    </label>
                                </div>
                            ) : null}

                            <div className={classes.btnWrapper}>
                                <GenericButton
                                    color="red"
                                    disabled={!agreedRules}
                                    onClick={async (e: any) => {
                                        if (
                                            contestStatus === 'INPROGRESS' &&
                                            !buttonWasClicked &&
                                            agreedRules &&
                                            blockId !== ''
                                        ) {
                                            setButtonWasClicked(true);
                                            e.preventDefault();

                                            if (!entry || entry.id === '') {
                                                enterContestMutation({
                                                    variables: { blockId: blockId },
                                                });
                                            } else {
                                                history.push(`question_page` + history.location.search);
                                            }
                                        }
                                    }}
                                    showLoader={buttonWasClicked}
                                    width="167px"
                                >
                                    Continue
                                </GenericButton>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <LoadingScreenSpinner />
            )}
        </Layout>
    );
};

export default Index;
