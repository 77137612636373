const nextStyles = {
  width: 10,
  height: 10,
  borderBottom: "1px solid white",
  borderRight: "1px solid white",
  transform: "rotate(-45deg)",
  display: "block",
  marginRight: 2,
  marginTop: -8,
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, ...nextStyles }}
      onClick={onClick}
    />
  );
};

export default NextArrow;
