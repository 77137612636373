import React, {useEffect, useState} from 'react';
import locked from '../../../assets/Locked.png';
import {makeStyles, Theme} from "@material-ui/core";
import NotifyMeButton from '../../common/UI/NotifyMeButton';
import NotifiedText from '../../common/UI/NotifiedText';
import {addSuffixToNumber} from '../../../utils';
import {useQuery} from "@apollo/client";
import {AFL_HAS_REQUESTED_NOTIFICATION} from "../../../apollo/afl/queries/HasRequestedNotification";
import {NRL_HAS_REQUESTED_NOTIFICATION} from "../../../apollo/nrl/queries/HasRequestedNotification";
import {USER_QUERY} from '../../../apollo/afl/queries/User';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '100px 30px 30px',
        color: theme.palette.primary.contrastText
    },
    lockedImg: {
        display: 'block',
        width: 77,
        height: 109,
        margin: 'auto auto 40px'
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        paddingBottom: 10
    },
    subTitle: {
        fontSize: 14
    }
}));

interface Props {
    sport: string
    currentPeriod: number
    blockId: number
    setIsWon: (b: boolean | null) => void
}

interface Text {
    NRL: React.ReactNode
    AFL: React.ReactNode

    [key: string]: React.ReactNode
}

const LockedGame = (props: Props) => {
    const classes = styles();
    const [pressedNotifiedBtn, setPressedNotifiedBtn] = useState(false);
    const [isBtnStatusLoaded, setIsBtnStatusLoaded] = useState(false);
    const [showNotifyMeButton, setShowNotifyMeButton] = useState(false);

    const query = props.sport === 'AFL' ? AFL_HAS_REQUESTED_NOTIFICATION : NRL_HAS_REQUESTED_NOTIFICATION;

    const {loading, error, data} = useQuery(query, {
        variables: {
            blockId: props.blockId,
            blockNumber: props.currentPeriod + 1,
        }
    });

    const {loading: userLoading, error: userError, data: userData} = useQuery(USER_QUERY);

    useEffect(() => {
        if (userData) {
            if (userData.user) {
                if (userData.user.notificationStatus === 'ALLOWED' || userData.user.notificationStatus === 'UNKNOWN') {
                    setShowNotifyMeButton(true)
                } else if (userData.user.notificationStatus === 'NOT_ALLOWED') {
                    setShowNotifyMeButton(false)
                }
            }
        }
    }, [userData])

    useEffect(() => {
        props.setIsWon(null)
    }, [])

    useEffect(() => {
        if (data && !loading && !error) {
            const way = props.sport === 'AFL' ? data.afl.hasRequestedNotification : data.nrl.hasRequestedNotification;
            setPressedNotifiedBtn(way);
            setIsBtnStatusLoaded(true)
        }
    }, [data]);

    const text: Text = {
        'NRL': <>The {addSuffixToNumber(props.currentPeriod + 1)} half has not started. <br/>Get notified when this game
            is about to begin.</>,
        'AFL': <>The {addSuffixToNumber(props.currentPeriod + 1)} quarter has not started. <br/>Get notified when this
            game is about to begin.</>,
    };

    const notifyClick = () => {
        setPressedNotifiedBtn(true)
    }

    return (
        <div className={classes.root}>
            <img src={locked} alt="locked" className={classes.lockedImg}/>
            <div className={classes.title}>This game is locked</div>
            <div className={classes.subTitle}>{text[props.sport]} </div>
            {showNotifyMeButton ?
                pressedNotifiedBtn ?
                    <NotifiedText/>
                    :
                    <NotifyMeButton click={notifyClick} blockId={props.blockId} blockNumber={props.currentPeriod + 1} isListPage={false}/>
                : null
            }
        </div>
    )
};

export default LockedGame;