import { makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

type Props = {
    onTabClick: (tabName: string) => void,
    selectedTab: string,
    tabNames: string[],
};

const LeaderboardTabs = ({ onTabClick, selectedTab, tabNames }: Props) => {
    const classes = styles();

    return (
        <div className={classes.tabs}>
            {
                tabNames.map((tabName) => (
                    <div
                        key={tabName}
                        className={classNames(classes.btn, selectedTab === tabName ? classes.active : null)}
                        onClick={() => onTabClick(tabName) }>
                        {tabName}
                    </div>
                ))
            }
        </div>
    );
};

const styles = makeStyles((theme: Theme) => ({
    tabs: {
        background: theme.palette.background.default,
        borderRadius: 20,
        display: 'flex',
        margin: `15px auto 30px`,
        padding: 3,
    },
    btn: {
        borderRadius: 20,
        cursor: 'pointer',
        fontSize: 16,
        fontWeight: 400,
        padding: 5,
        textTransform: 'capitalize',
        width: '50%',
    },
    active: {
        backgroundColor: '#FFFFFF',
        color: theme.palette.background.default,
    },
}));

export default LeaderboardTabs;
