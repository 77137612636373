import { gql} from '@apollo/client';

export const ANSWER_QUESTION_NBA = gql`
  mutation answerQuestion($entryId: Int!, $outcomeId: Int!, $timestamp: String!) {
    nba {
        selectOutcome(entryId: $entryId, outcomeId: $outcomeId, timestamp: $timestamp) {
            success
            errorMessage
        }
    }
  }
`;

