import {gql} from "@apollo/client";

export const NRL_PREVIOUS_ENTRIES_QUERY = gql`
    query previousEntries($contestId: Int!) {
        nrl {
           previousEntries(contestId: $contestId) {
                id
                points
                blockId
                rank
                winnings
                username
                settlementStatus
           }
        }
    }
`;