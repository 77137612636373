import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {Question} from '../../../../../../../../shared/types';
import ProgressBar from './ProgressBar';
import FireballImage from './FireballImage';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        maxWidth: 500,
        height: 6,
        position: 'relative',
        borderRadius: 20,
        marginRight: 10,
        marginBottom: 20,
        overflow: 'visible'
    },
    progress: {
        color: '#E6E6E6',
        textAlign: 'center',
        lineHeight: '55px',
        fontSize: 35,
        animationDirection: 'reverse',
        // position: 'relative',
        borderRadius: 20,
        backgroundColor: '#E6E6E6',
        boxShadow: '0 1px 3px ' + theme.palette.primary.light,
        overflow: 'visible',
        width: '100%',
        height: 7,
        top: 0,
        left: 0,
        zIndex: -1,
    },
}));

interface Props {
    lessThanTenSecs?: boolean
    seconds?: number
    width?: string | undefined
    addClass?: boolean
}


const FireballProgressBar = (props: Props) => {
    const classes = styles();
    const {lessThanTenSecs, width, seconds, addClass} = props;

    return (
        <div className={classes.root}>
            <div className={classes.progress}/>

            {width && seconds && addClass &&
                <>
                    <FireballImage seconds={seconds} addClass={addClass} width={width}/>
                    <ProgressBar addClass={addClass} lessThanTenSecs={lessThanTenSecs} seconds={seconds} width={width}/>
                </>
            }

        </div>
    )

};

export default FireballProgressBar;