import {gql} from "@apollo/client";

export const GET_USER_FOR_CONTEXT_QUERY = gql`
    query getUserForContext {
           user {
                id
                national
                nbaTickets
                notificationStatus
                externalId
                fromSA
           }
    }
`;