import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {AflNrlEntry} from "../../../../../shared/types";
import classNames from "classnames";
import {useNrlAflEntry} from "../../../contexts/nrl_afl/Entry";
import BackComponent from './BackComponent';
import LockedGame from './LockedGame';
import {useGameState} from "../../../contexts/nrl_afl/GameState";
import {usePreviousEntries} from "../../../contexts/nrl_afl/PreviousEntry";
import QtrTabs from './QtrTabs';
import WinLossCheck from './WinLossCheck';
import { SportsPSGMCardList } from '../../../entain/components/SportsPSGMCardList/SportsPSGMCardList';
import { useFeatureFlag } from '../../../entain/hooks/useFeatureFlag';
import { isOfCompetitionName } from '../../../entain/hooks/useFetchPSGMs';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'auto',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'auto'
        },
        '& > a': {
            textDecoration: 'none'
        },
        background: `url('/NRL-BG.jpg')`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100vh',
    },
    rootInner: {
        maxWidth: '100%',
        margin: 'auto',
        height: '100vh',
        '@media only screen and (max-width: 650px)': {
            maxWidth: 650,
        },
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
        },
    },
    topPart: {
        background: theme.palette.background.default,
        height: 43
    },
    innerWrapper: {
        padding: '10px 15px 0px',
        background: theme.palette.background.default,
    },
    tabs: {
        display: 'flex',
        borderRadius: 20,
        padding: 3,
        width: '100%',
        marginTop: 25,
        marginBottom: 15,
        background: theme.palette.background.default,
    },
    btn: {
        width: '50%',
        padding: 5,
        fontWeight: 700
    },
    active: {
        background: theme.palette.primary.contrastText,
        borderRadius: 20,
        color: theme.palette.primary.dark
    },
    mainWrapper: {
        maxWidth: 550,
        margin: 'auto',
        width: '100%',
        padding: '0px 15px 15px',
        zIndex: 1
    },
    won: {
        background: 'rgba(0, 199, 110, 0.1)'
    },
    loss: {
        background: 'rgba(190, 0, 0, 0.1)'
    },
}));

interface Props {
    sport: string
    blockId: number
    backToGame: () => void
    prevPageForLeaderboard: string
}

const Index = (props: Props) => {
    const classes = styles();
    const {sport, backToGame, prevPageForLeaderboard} = props;
    const {gameState, isStatusLoaded} = useGameState();
    const [selectedQtr, setSelectedQtr] = useState(-1);
    const [activeBlockId, setBlockId] = useState<number | null>(null)
    const [activeEntry, setActiveEntry] = useState<AflNrlEntry | null>(null)
    const [previousEntry, setPreviousEntry] = useState<AflNrlEntry | null>(null)
    const [tab, setTab] = useState(0);
    const [isWon, setIsWon] = useState<boolean | null>(null);
    const competitionName = isOfCompetitionName(sport) ? sport : undefined;
    const psgmEnabled = useFeatureFlag('pickfans-psgm');

    const maximumPeriods = sport === 'NRL' ? 2 : sport === 'AFL' ? 4 : 0;

    const {entry} = useNrlAflEntry()
    const {getEntry} = usePreviousEntries()

    useEffect(() => {
        if (isStatusLoaded && gameState) {
            if (selectedQtr === gameState.period - 1 || selectedQtr === -1) {
                setBlockId(gameState.currentBlock)
            } else {
                setBlockId(gameState.allBlocks[selectedQtr].id)
                setPreviousEntry(getEntry(gameState.allBlocks[selectedQtr].id))
            }

        }
    }, [isStatusLoaded, gameState])

    useEffect(() => {
        if (isStatusLoaded && gameState) {
            if (gameState?.currentBlock === activeBlockId) {
                setActiveEntry(entry)
            } else {
                setActiveEntry(previousEntry)
            }
        }
    }, [isStatusLoaded, gameState, activeBlockId, previousEntry])

    const qtrClick = (qtr: number) => {
        if (gameState) {
            setBlockId(gameState.allBlocks[qtr].id)
            setPreviousEntry(getEntry(gameState.allBlocks[qtr].id))
            setSelectedQtr(qtr)
            setTab(0)
        }
    };

    const tabClick = (tab: number) => {
        setTab(tab);
    };

    return (
        <div className={classNames(classes.root)}>
            <div
                className={classNames(classes.rootInner,
                    isWon !== null && !isWon ? classes.loss : isWon !== null && isWon ? classes.won : null
                )}>

                <div className={classes.topPart}>
                    <BackComponent backToGame={backToGame} isWon={isWon}
                                   isLastPeriod={gameState?.period === maximumPeriods}
                                   prevPageForLeaderboard={prevPageForLeaderboard}/>
                </div>

                <>
                    <div className={classes.innerWrapper}>
                        {gameState &&
                            <QtrTabs sport={sport} qtrClick={qtrClick} selectedQtr={selectedQtr}
                                     setSelectedQtr={setSelectedQtr} period={gameState.period}/>
                        }
                    </div>

                    {gameState && selectedQtr > gameState.period - 1 && activeBlockId ?
                        <>
                            <LockedGame sport={sport} currentPeriod={gameState.period} blockId={activeBlockId} setIsWon={setIsWon} />
                            { psgmEnabled && competitionName ? <SportsPSGMCardList competitionName={competitionName} /> : null }
                        </>
                        :
                        <div className={classNames(classes.mainWrapper)}>
                            <div className={classes.tabs}>
                                <div className={classNames(classes.btn, tab === 0 ? classes.active : null)}
                                     onClick={() => tabClick(0)}>Leaderboard
                                </div>
                                <div className={classNames(classes.btn, tab === 1 ? classes.active : null)}
                                     onClick={() => tabClick(1)}>Results
                                </div>
                            </div>

                            {isStatusLoaded && activeBlockId &&
                                <WinLossCheck sport={sport} setIsWon={setIsWon} isWon={isWon} tab={tab}
                                              activeBlockId={activeBlockId} activeEntry={activeEntry}/>}

                        </div>
                    }
                </>
            </div>
        </div>
    )
};

export default Index;