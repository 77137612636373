import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";

const styles = makeStyles((theme: Theme) => ({
    '@keyframes show': {
        '0%': {
            transform: 'scale(1)',
            color: theme.palette.primary.contrastText
        },
        '70%': {
            transform: 'scale(1.4)',
            color: theme.palette.grey[900],
        },
        '100%': {
            transform: 'scale(1)',
            color: theme.palette.primary.contrastText
        },
    },
    '@keyframes showRankStar': {
        '0%': {
            transform: 'translate(-50%, -50%) scale(1)',
            color: theme.palette.primary.contrastText
        },
        '70%': {
            transform: 'translate(-50%, -50%) scale(1.4)',
            color: theme.palette.grey[900],
        },
        '100%': {
            transform: 'translate(-50%, -50%) scale(1)',
            color: theme.palette.primary.contrastText
        },
    },
    '@keyframes positiveScores': {
        '0%': {
            transform: 'scale(1)',
            color: theme.palette.primary.contrastText
        },
        '70%': {
            transform: 'scale(1.4)',
            color: theme.palette.grey[100],
        },
        '100%': {
            transform: 'scale(1)',
            color: theme.palette.primary.contrastText
        },
    },
    '@keyframes negativeScores': {
        '0%': {
            transform: 'scale(1)',
            color: theme.palette.primary.contrastText
        },
        '70%': {
            transform: 'scale(1.4)',
            color: '#FD2C5C',
        },
        '100%': {
            transform: 'scale(1)',
            color: theme.palette.primary.contrastText
        },
    },
    '@keyframes showYellow': {
        '0%': {
            transform: 'scale(1)',
            color: theme.palette.background.paper,
        },
        '70%': {
            transform: 'scale(1.4)',
            color: theme.palette.grey[900],
        },
        '100%': {
            transform: 'scale(1)',
            color: theme.palette.background.paper,
        },
    },
    showAnim: {
        display: 'inline-block',
        animation: `$show 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    showStarRankAnim: {
        display: 'inline-block',
        animation: `$showRankStar 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    positive: {
        display: 'inline-block',
        animation: `$positiveScores 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    negative: {
        display: 'inline-block',
        animation: `$negativeScores 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    yellow: {
        display: 'inline-block',
        animation: `$showYellow 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },

}));


interface Props {
    children: React.ReactNode
    classname: string
    valueOne: number | undefined | null
    valueTwo: number | undefined | null
    isRankStar?: boolean
    isScorePointsPositive?: number //0 negative, 1 positive
    isYellow?: boolean
}

const AnimatedCell = (props: Props) => {
    const classes = styles();
    const {children, classname, isRankStar, isScorePointsPositive, isYellow, valueOne, valueTwo} = props;

    let animClassname = classes.showAnim;
    if (isRankStar) animClassname = classes.showStarRankAnim;

    if (isScorePointsPositive && isScorePointsPositive === 0) animClassname = classes.positive;
    if (isScorePointsPositive && isScorePointsPositive === 1) animClassname = classes.negative;

    if (isYellow) animClassname = classes.yellow;

    const [showAnim, setShowAnim] = useState(false);

    useEffect(() => {
        if (valueOne && valueTwo) {
            if (valueOne !== valueTwo) {
                setShowAnim(true)
            }
        }
    }, [valueOne, valueTwo]);

    return (
        <span className={classNames(showAnim ? animClassname : '', classname)}
              onAnimationEnd={() => setShowAnim(false)}>
            {children}
         </span>
    )
};

export default AnimatedCell;
