import React, {useEffect} from "react";
import {Contest} from '../../../apollo/cricket/queries/GetContests';
import {PREVIOUS_CONTESTS} from '../../../apollo/cricket/queries/PreviousContests';
import {useQuery} from "@apollo/client";
import {makeStyles, Theme} from "@material-ui/core";
import FixtureCardTopPart from '../../homepage/shared/fixture_card/FixtureCardTopPart';
import PlayerLayer from '../../common/UI/PlayerLayer';
import {useAuth} from "../../../contexts/Auth";
import LoadingScreenBat from '../../common/cricketLoaders/LoadingScreenBat';
import {useHistory} from "react-router-dom";
import BackButton from '../../common/UI/BackButton';
import FixtureCard from '../../homepage/shared/fixture_card/FixtureCard';
import CricketFixtureCardWrapper from '../../homepage/cricket/CricketFixtureCardWrapper';

const styles = makeStyles((theme: Theme) => ({
    title: {
        fontWeight: 700,
        fontSize: 16,
        display: 'block',
        textAlign: 'center',
        justifySelf: 'center',
        position: 'relative',
        top: -37
    },
    inner: {
        height: '100vh',
        padding: '80px 0',
        position: 'relative',
        overflow: 'visible',
        '@media only screen and (min-width: 1025px)': {
            overflow: 'auto',
        },
        '@media only screen and (max-width: 1024px)': {
            padding: '40px 0 40px',
        }
    },
    back: {
        position: 'absolute',
        '@media only screen and (min-width: 1025px)': {
            top: 5,
            left: 0,
            width: '100%',
        },
        '@media only screen and (max-width: 1024px)': {
            top: -20,
            left: '-5%',
            width: '110%',
        }
    },
    haveNoContests: {
        textAlign: 'center',
        fontWeight: 800,
        fontSize: 20
    }
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void
}

function Index(props: Props) {
    const classes = styles();
    const {isAuth} = useAuth();
    const history = useHistory();

    const {loading, error, data} = useQuery(PREVIOUS_CONTESTS, {
        skip: !isAuth
    });


    if (!loading && !error && data) {

        return (
            <PlayerLayer>

                <div className={classes.inner}>
                    <div className={classes.back}>
                        <BackButton onClick={() => history.push(`/` + history.location.search)}>
                            Back</BackButton>
                        <div className={classes.title}>Previous Contests</div>

                    </div>


                    {data.previous.length > 0 ?

                        <>
                            {data.previous.map((contest: Contest) => (
                                <FixtureCard setPrevPageForLeaderboard={props.setPrevPageForLeaderboard}
                                             contestId={contest.id}
                                             entryId={contest.entry ? contest.entry.id : undefined}
                                >

                                    <CricketFixtureCardWrapper key={contest.id}
                                                               contest={contest}
                                                               entryId={contest.entry ? contest.entry.id : undefined}
                                                               setPrevPageForLeaderboard={props.setPrevPageForLeaderboard}/>
                                </FixtureCard>
                            ))}
                        </>


                        : <div className={classes.haveNoContests}>YOU HAVE NO CONTESTS</div>}
                </div>

            </PlayerLayer>
        );
    }

    return (
        <PlayerLayer>

            <div className={classes.title}>
                <div className={classes.title}>Previous Contests</div>
            </div>
            <LoadingScreenBat/>
        </PlayerLayer>
    )


}

export default Index;