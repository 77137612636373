import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import BackButton from "../../common/UI/BackButton";
import {useHistory} from "react-router";

const styles = makeStyles((theme: Theme) => ({
    back: {
        position: 'absolute',
        width: '100%',
        height: 50,
        background: theme.palette.primary.main,
        zIndex: 20
    },
    centralText: {
        display: 'block',
        fontSize: 15,
        fontWeight: 700,
        padding: '0px 0 10px',
        boxShadow: '0 3px 6px ' + theme.palette.primary.light,
        position: 'relative',
        zIndex: 2,
        top: -38
    }
}))

interface Props {
}

const BackRow = (props: Props) => {
    const classes = styles();
    const history = useHistory();

    return (
        <div className={classes.back}>
            <BackButton onClick={() =>  history.push(`/entain-admin`)}
                        hideOnDesk={true}>
                Back
            </BackButton>

            <div className={classes.centralText}>Leaderboard</div>
        </div>
    );
};

export default BackRow;