import { makeStyles } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { SportingPopularSameGameMulti, SportingPopularSameGameMultiPrice } from '../../types';
import { SportsPSGMCardAddButton } from './SportsPSGMCardAddButton';
import { SportsPSGMCardHeader } from './SportsPSGMCardHeader';
import { SportsPSGMCardInfo } from './SportsPSGMCardInfo';
import { SportsPSGMCardSelectionList } from './SportsPSGMCardSelectionList';
import { SportsPSGMCardToggleButton } from './SportsPSGMCardToggleButton';

const MIN_NUM_SELECTIONS = 4;

type SportsPSGMCardProps = {
    psgm: SportingPopularSameGameMulti;
    competitionName: string;
    psgmPrice: SportingPopularSameGameMultiPrice | undefined;
};

export const SportsPSGMCard = ({ psgm, psgmPrice, competitionName }: SportsPSGMCardProps) => {
    const classes = styles();
    const { betCount, event, selections } = psgm;
    const [shouldShowAll, setShouldShowAll] = useState(false);
    const shouldShowToggleButton = selections.length > MIN_NUM_SELECTIONS;

    const selectionsToShow = useMemo(
        () => shouldShowAll ? [...selections] : selections.slice(0, MIN_NUM_SELECTIONS),
        [shouldShowAll, selections]
    );

    const onShowAllToggle = () => setShouldShowAll(!shouldShowAll);

    return (
        <div className={classes.mainContainer}>
            <SportsPSGMCardHeader event={event} />
            <div className={classes.contentContainer}>
                    <SportsPSGMCardInfo betCount={betCount} legCount={selections.length} />
                <div className={classes.selectionList}>
                    <SportsPSGMCardSelectionList event={event} selections={selectionsToShow} />
                    { shouldShowToggleButton && (
                        <div className={classes.toggleButtonContainer}>
                            <SportsPSGMCardToggleButton isOn={shouldShowAll} onToggle={onShowAllToggle} />
                        </div>
                    )}
                </div>
                <div className={classes.addButtonContainer}>
                    <SportsPSGMCardAddButton psgm={psgm} psgmPrice={psgmPrice} competitionName={competitionName} />
                </div>
            </div>
        </div>
    );
};

const styles = makeStyles(() => ({
    mainContainer: {
        borderRadius: 5,
        boxShadow: '0px 1px 0px #E8E8E8',
    },
    contentContainer: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #E8E8E8',
        color: '#232323',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 240,
        padding: 10,
    },
    selectionList: {
        flexGrow: 1,
    },
    toggleButtonContainer: {
        marginTop: 'auto',
        textAlign: 'center',
    },
    addButtonContainer: {
        padding: '5px 0px',
        textAlign: 'center',
    },
}));