import React, {useEffect} from "react";
import {AflContest} from '../../../apollo/afl/queries/GetContests';
import {NrlContest} from '../../../apollo/nrl/queries/GetContests';
import {makeStyles, Theme} from "@material-ui/core";
import NrlAflLayout from '../../common/UI/NrlAflLayout';
import {useHistory} from "react-router";
import FixtureCardWrapper from './FixtureCardWrapper';
import NoContests from "../shared/NoContests";
import FixtureCard from '../shared/fixture_card/FixtureCard';
import logo from '../../../assets/CQ-logo.png';

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: '0px 30px 20px',
    },
    prevContests: {
        fontSize: 13,
        cursor: 'pointer',
        textAlign: 'center',
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 700,
        '& > $chevron': {
            width: 7,
            height: 7,
            borderBottom: '1px solid ' + theme.palette.primary.contrastText,
            borderRight: '1px solid ' + theme.palette.primary.contrastText,
            transform: 'rotate(-45deg)',
            display: 'block',
            marginLeft: 5,
            marginTop: 6
        },
    },
    chevron: {},
    logo:{
        display: 'block',
        width: 250,
        height: 140,
        margin: 'auto'
    },
    comeBack: {
        width: '100%',
        borderRadius: 5,
        padding: '10px 20px',
        display: 'flex',
        fontSize: 24,
        fontWeight: 700,
        alignItems: 'center',
        background: theme.palette.info.main,
        position: 'relative',
        height: 138,
        textAlign: 'center',
        marginBottom: 10
    },
    upcomingHeader: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

interface Props {
    sport: string
    setPrevPageForLeaderboard: (p: string) => void
    sorted?: AflContest[] | NrlContest[]
}

function Homepage(props: Props) {
    const allImages = [
        '/GameBackground.jpg',
        logo
    ];
    let timeout1: any = null;
    const classes = styles();
    const history = useHistory();
    const {sorted, setPrevPageForLeaderboard, sport} = props;

    useEffect(() => {
        return () => {
            clearTimeout(timeout1);
        }
    }, []);

    const cacheImages = async (srcArray: string[]) => {
        const promises = await srcArray.map((src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject
            })
        });

        await Promise.all(promises)
    };
    timeout1 = setTimeout(() => {
        cacheImages(allImages).catch(e => console.warn(`caught on main: ${e}`))
    }, 0);

    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const todayCount = sorted ?
        sorted.filter(contest => (new Date(Number(contest.startTime)) < tomorrow)).length : 0

    return (
        <NrlAflLayout>
            <div className={classes.wrapper}>
                <img src={logo} alt="logo" className={classes.logo}/>

                {sorted ?

                    <>
                        {todayCount === 0 ?
                            <div>
                                <div className={classes.comeBack}>
                                    There are no games scheduled today.
                                </div>
                                <div className={classes.upcomingHeader}>
                                    <h3>Upcoming Games</h3>
                                </div>

                            </div> : null}

                        {sorted.slice(0, 3).map((contest: AflContest, n: number) => (
                            <FixtureCard key={n}
                                         setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                         contestId={contest.id}
                            >

                                <FixtureCardWrapper key={contest.id}
                                                    contest={contest}
                                                    setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                                                    sport={sport}/>
                            </FixtureCard>
                        ))}
                    </>

                    : null}

                <div className={classes.prevContests} onClick={() => {
                    history.push('previous_results/' + history.location.search)
                }}><span>Previous Results</span> <span className={classes.chevron}/></div>
            </div>
        </NrlAflLayout>
    );
}

export default Homepage;