import {gql} from '@apollo/client';

export const NRL_GET_BLOCK_ID = gql`
    query getNrlContest($id: Int!) {
        nrl {
           contest(id: $id) {
               id
               currentBlock
               status
           }
        }
    }
`;