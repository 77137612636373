import React, {useState} from 'react';
import Leaderboard from './Leaderboard';
import AnsweredQuestions from './AnsweredQuestions';
import {useHistory, useParams} from "react-router";
import {makeStyles, Theme} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import {LEADERBOARD_QUERY} from "../../../apollo/cricket/queries/Leaderboard";
import {Score} from "../../../../../shared/types";
import classNames from "classnames";
import {useMediaQuery} from "react-responsive";
import {useEntry} from "../../../contexts/cricket/Entry";
import LoadingScreen from '../shared/LoadingScreen';
import BackComponent from './BackComponent';
import LoadingScreenBall from '../../common/cricketLoaders/LoadingScreeenBall';
import {createArrayWithTiedField, differenceInUnits} from '../../../utils';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'auto',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        position: 'relative',
        textAlign: 'center',
        height: 'calc(100vh - 77px)',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'auto'
        },
        '& > a': {
            textDecoration: 'none'
        },
        '@media only screen and (max-width: 1024px)': {
            background: `url('/Home_Background.jpg')`,
            backgroundSize: 'cover',
            height: '100vh'
        },
    },
    rootInner: {
        maxWidth: '100%',
        margin: 'auto',
        position: 'relative',
        height: '100%',
        '@media only screen and (max-width: 650px)': {
            maxWidth: 400,
        },
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
        },
    },
    background: {
        '@media only screen and (min-width: 1025px)': {
            background: `url('/BACKGROUND_DESKTOP.png')`,
            backgroundSize: 'cover',
        }
    },
    loadingPart: {
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    back: {
        position: 'absolute',
        width: '100%',
        background: theme.palette.primary.main,
        height: 50
    },
    mobTitle: {
        display: 'none',
        '@media only screen and (max-width: 1024px)': {
            display: 'block',
            fontSize: 18,
            fontWeight: 700,
            padding: '0px 0 10px',
            boxShadow: '0 3px 6px ' + theme.palette.primary.light,
            position: 'relative',
            zIndex: 2,
            top: -38
        }
    },
    wrapperForBall: {
        position: 'relative',
        paddingTop: 50
    }
}));

interface Props {
    prevPageForLeaderboard: string
}

const Index = (props: Props) => {
    const classes = styles();
    const history = useHistory();
    const {contest_id} = useParams<{ contest_id: string }>();
    const contestId = parseInt(contest_id, 10);
    const {entry: {id}} = useEntry();

    const [showLeaderboard, setShowLeaderboard] = useState(true);

    const {data, loading, error} = useQuery(LEADERBOARD_QUERY, {
        variables: {
            contestId
        },
        pollInterval: 3000,
    });

    const backToGame = () => {
        switch (props.prevPageForLeaderboard) {
            case 'homepage': {
                history.push(`/` + history.location.search);
                break;
            }

            case 'question': {
                history.push('question_page' + history.location.search);
                break;
            }
            case 'prev_results': {
                history.push('/previous_results' + history.location.search);
                break;
            }
            default: {
                history.push(`/` + history.location.search);
                break;

            }
        }
    };

    const isDesktop = useMediaQuery({
        query: '(min-width: 1025px)'
    });

    if (data && !loading && !error) {
        const creatingNewList = createArrayWithTiedField<Score>(data.leaderboard.scores);
        let newList: Score[] = creatingNewList.newList;
        let isAnyTie = creatingNewList.isAnyTie;

        return (
            <div
                className={classNames(classes.root, props.prevPageForLeaderboard === 'homepage' ? classes.background : null, classes.loadingPart)}>
                <div className={classes.rootInner}>
                    {showLeaderboard ?

                        <Leaderboard prevPageForLeaderboard={props.prevPageForLeaderboard} backToGame={backToGame}
                                     setShowLeaderboard={setShowLeaderboard} list={newList} isAnyTie={isAnyTie}
                                     entryCount={data.leaderboard.entryCount} entryId={id}/>

                        : <AnsweredQuestions backToGame={() => setShowLeaderboard(true)} contestId={contestId}
                                             entryId={id}/>}
                </div>
            </div>
        )
    } else {
        return (
            <LoadingScreen loader={<LoadingScreenBall/>}>
                <BackComponent backToGame={backToGame}/>
            </LoadingScreen>
        )
    }
};

export default Index;