import {gql} from "@apollo/client";

export const NRL_ENTRY_QUERY = gql`
    query entry($contestId: Int!) {
        nrl {
           entry(contestId: $contestId) {
                id
                points
                blockId
                rank
                winnings
                settlementStatus
           }
        }
    }
`;