import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {Score} from "../../../../../shared/types";
import {addSuffixToNumber} from '../../../utils';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '40px 1fr 50px 70px',
        color: theme.palette.primary.contrastText,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43
    },
    userScore: {
        width: '100%',
        borderRadius: 23,
        background: theme.palette.background.default,
        padding: '7px 15px',
        display: 'grid',
        gridTemplateColumns: '40px 1fr 50px 70px',
        color: theme.palette.background.paper,
        alignItems: 'center',
        marginBottom: 10,
        minHeight: 43

    },
    pos: {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'left'
    },
    userName: {
        fontSize: 12,
        fontWeight: 800,
    },
    wrap: {
        textAlign: 'left'
    },
    itsYou: {
        fontWeight: 700,
    },
    subTitle: {
        fontSize: 10
    },
    nameWrapper: {
        textAlign: 'left',
        fontSize: 14,
        wordBreak: 'break-all'
    },
    points: {
        fontSize: 14,
        textAlign: 'right',
    },
    money: {
        textAlign: 'right',
        fontSize: 14,
        fontWeight: 700,
    }
}));

interface Props {
    score: Score
    username: string | null
    userScore: boolean
    cashColor?: string
}

const hashUsername = (username: string) => {
    const numAsterisks = Math.max(0, Math.min(20, username.length - 2));
    const asterisks = new Array(numAsterisks);
    for (let i = 0; i < numAsterisks; i++) {
        asterisks[i] = '*';
    }
    return username[0] + asterisks.join('') + username[username.length - 1];
};

const LeaderboardEntry = (props: Props) => {
    const classes = styles();
    const {score, userScore, username, cashColor} = props;

    return (
        <div className={!userScore ? classes.root : classes.userScore}>
            <span className={classes.pos}>{addSuffixToNumber(score.rank)}</span>
            {props.username === score.username || userScore ?
                <div className={classes.wrap}>
                    <div className={classNames(classes.nameWrapper, classes.itsYou)}>{username}</div>
                    <div className={classes.subTitle}>You</div>
                </div>
                : <div className={classes.nameWrapper}>{hashUsername(score.username ? score.username : '')}</div>
            }

            <span className={classes.points}>
                {score.tied ? <span>&#65121;</span> : ''}
                {score.points}</span>
            <span className={classNames(classes.money, cashColor ? cashColor  : null)}>${score.winnings}</span>
        </div>
    )
};

export default LeaderboardEntry;
