import { makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
import loader from "../../../assets/animation/loading-spinner.json";

const styles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto auto 15px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: 16,
    fontWeight: 700,
    borderRadius: 4,
    textAlign: "center",
    cursor: "pointer",
    height: 40,
    overflow: "hidden",
    width: "80%",
    backgroundColor: "#FBFBFC",
    color: "#000",
    "@media only screen and (max-width: 1024px)": {
      width: "100%",
    },
  },
  disabledRoot: {
    background: "#CCC",
    cursor: "not-allowed",
  },
  button: {
    width: "100%",
    position: "relative",
    textAlign: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
  },
  answerText: {
    color: "#000",
    position: "relative",
    zIndex: 2,
  },
  disabledAnswerText: {
    color: "#555",
  },
  selectedAnswer: {
    border: "2px solid #7BD2FA",
    borderRadius: 4,
    background: "#7BD2FA",
  },
  greyBg: {
    background: "#7BD2FA",
  },
  spinner: {
    display: "block",
    width: 45,
    height: 45,
    position: "absolute",
    right: -3,
    top: -13,
    zIndex: 10,
  },
}));

interface Props {
  children: React.ReactNode;
  onClick: any;
  buttonId: number;
  clickedButtonId: number;
  timeExpired: boolean;
  selected: boolean | null;
  shadow?: boolean;
  setClickedButton: (b: number) => void;
  disable: boolean;
}

const OutcomeButton = (props: Props) => {
  const classes = styles();
  const {
    children,
    onClick,
    buttonId,
    clickedButtonId,
    timeExpired,
    selected,
    shadow,
    setClickedButton,
    disable,
  } = props;
  const lottieRef = useRef(null);

  useEffect(() => {
    if (selected) setClickedButton(buttonId);

    const anim = lottie.loadAnimation({
      animationData: JSON.parse(JSON.stringify(loader)),
      loop: true,
      renderer: "svg",
      container: lottieRef.current!,
      autoplay: true,
    });

    return () => {
      anim.stop();
      anim.destroy();
      lottie.stop();
      lottie.destroy();
    };
  }, [clickedButtonId]);

  return (
    <div
      style={{ boxShadow: shadow ? "#000000CE 0 3px 6px" : "" }}
      className={classNames(
        classes.root,
        !timeExpired && clickedButtonId !== -1 && clickedButtonId === buttonId
          ? classes.greyBg
          : null,
        disable ? classes.disabledRoot : null
      )}
      onClick={() => {
        if (!disable) onClick();
      }}
    >
      <div
        className={classNames(
          classes.button,
          clickedButtonId === buttonId ? classes.selectedAnswer : null
        )}
      >
        <span
          className={classNames(
            classes.answerText,
            disable ? classes.disabledAnswerText : null
          )}
        >
          {children}
        </span>

        {clickedButtonId === buttonId && (
          <div
            ref={lottieRef}
            style={{ height: 65, width: 65, margin: "auto" }}
            className={classes.spinner}
          />
        )}
      </div>
    </div>
  );
};

export default OutcomeButton;
