import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import YourBatsmanCard from "./YourBatsmanCard";
import {useEntry} from "../../../../contexts/cricket/Entry";
import BackButton from '../../../common/UI/BackButton';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        color: theme.palette.primary.contrastText
    },
    inner: {
        maxWidth: '100%',
        margin: 'auto',
        position: 'relative',
        '@media only screen and (max-width: 650px)': {
            maxWidth: 400,
        },
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
        },
    },
    title: {
        fontSize: 15,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        display: 'block',
        fontWeight: 700,
        padding: '0px 0 10px',
        // boxShadow: '0 3px 6px ' + cricketTheme.palette.primary.light,
        position: 'relative',
        zIndex: 2,
        top: -36
    },
}));

interface Props {
    setOpenBatsmenList: (b: boolean) => void
}

const BatsmenList = (props: Props) => {
    const classes = styles();

    const {entry} = useEntry();
    const {selectedBatsmen} = entry;

    return (
        <div className={classes.root}>

            <div className={classes.inner}>
                <BackButton onClick={() => props.setOpenBatsmenList(false)}>Back</BackButton>

                <div className={classes.title}>Your batting lineup</div>
                {
                    selectedBatsmen && selectedBatsmen.slice(0).reverse().map((b, i: number) => (
                        <YourBatsmanCard key={i} batsman={b}/>
                    ))}
            </div>
        </div>
    )

};

export default BatsmenList;