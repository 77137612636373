import { useCallback, useEffect, useState } from "react";
import { ENABLE_NATIONAL_COMPETITION, EXCLUDE_SA_USER_FROM_NATIONAL } from "../../constants";
import { useUser } from "../../contexts/User";
import { Entry } from "../../types";
import Tabs from "../common/leaderboard/Tabs";
import LeaderboardLadbrokes from "./LeaderboardLadbrokes";
import LeaderboardOverall from "./LeaderboardOverall";

interface Props {
  activeBlockId: string | null;
  activeEntry: Entry | undefined | null;
  isWon: boolean | null;
}

type TabName = "overall" | "ladbrokes";

const Leaderboards = ({ activeBlockId, activeEntry, isWon }: Props) => {
  const { isLadbrokesUser, user } = useUser();
  const [tabNames, setTabNames] = useState<TabName[]>([]);
  const [selectedTab, setSelectedTab] = useState<TabName>();

  useEffect(() => {
    let availableTabNames: TabName[] = ["ladbrokes"];

    if (ENABLE_NATIONAL_COMPETITION) {
      availableTabNames = user?.fromSA && EXCLUDE_SA_USER_FROM_NATIONAL
        ? isLadbrokesUser
          ? ["ladbrokes"]
          : [] // we shouldn't fall into this case, SA user are only from Ladbrokes App
        : isLadbrokesUser
          ? ["overall", "ladbrokes"]
          : ["overall"];
    }

    setTabNames(availableTabNames);
    if (availableTabNames.length) setSelectedTab(availableTabNames[0]);
  }, [isLadbrokesUser, user?.fromSA]);

  const onTabClick = useCallback((tabName: TabName) => {
    setSelectedTab(tabName);
  }, []);

  return (
    <>
      {tabNames.length > 1 ? (
        <Tabs<TabName>
          onTabClick={onTabClick}
          selectedTab={selectedTab}
          tabNames={tabNames}
        />
      ) : null}

      {selectedTab === "overall" ? (
        <LeaderboardOverall
          activeBlockId={activeBlockId}
          activeEntry={activeEntry}
        />
      ) : null}

      {selectedTab === "ladbrokes" ? (
        <LeaderboardLadbrokes
          activeBlockId={activeBlockId}
          activeEntry={activeEntry}
          isWon={isWon}
        />
      ) : null}
    </>
  );
};

export default Leaderboards;
