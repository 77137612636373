import { makeStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { NBA_HAS_REQUESTED_NOTIFICATION } from "../../../../apollo/nba/queries/HasRequestedNotification";
import { USER_QUERY } from "../../../../apollo/nba/queries/User";
import { useGameState } from "../../../../contexts/nba/GameState";
import NbaNotifyMeButton from "../../../common/UI/NbaNotifyMeButton";
import NotifiedText from "../../../common/UI/NotifiedText";
import { useEntain } from "../../../../entain/hooks/useEntain";
import { NBA_ENABLE_NOTIFICATION } from "../../../../constants";

const NoMoreQuestionsForNowBlock = () => {
    const classes = styles();
    const [ showNotifyMeButton, setShowNotifyMeButton ] = useState(false);
    const [ pressedNotifyMeButton, setPressedNotifyMeButton ] = useState(false);
    const { gameState } = useGameState();
    const { insideLadbrokesApp, insideCouchQuizApp, couchQuizAppPushToken } = useEntain();
    const shouldShowNotification = NBA_ENABLE_NOTIFICATION && (
        insideLadbrokesApp || (insideCouchQuizApp && couchQuizAppPushToken)
    );
    
    const { data: userData, error: userError, loading: userLoading } = useQuery(USER_QUERY, {
        skip: !shouldShowNotification
    });

    const { data: notificationData, error: notificationError, loading: notificationLoading } = useQuery(NBA_HAS_REQUESTED_NOTIFICATION, {
        variables: {
            blockId: gameState?.currentBlock,
            blockNumber: gameState?.period,
        },
        skip: !shouldShowNotification || !gameState
    });

    useEffect(() => {
        if (!shouldShowNotification) {
            setShowNotifyMeButton(false);
            return;
        }

        if (userData && !userError && !userLoading && gameState) {
            if (userData.user?.notificationStatus === 'ALLOWED' || userData.user?.notificationStatus === 'UNKNOWN') {
                const allQuestionsAvailable = gameState.questionStatus === 'COMPLETED';
                setShowNotifyMeButton(!allQuestionsAvailable);
            } else if (userData.user?.notificationStatus === 'NOT_ALLOWED') {
                setShowNotifyMeButton(false);
            }
        }
    }, [shouldShowNotification, userData?.user?.notificationStatus || '', userError, userLoading, gameState?.questionStatus || '']);

    useEffect(() => {
        if (notificationData && !notificationError && !notificationLoading) {
            setPressedNotifyMeButton(notificationData.nba?.hasRequestedNotification);
        }
    }, [notificationData, notificationError, notificationLoading]);

    const onNotifyMeButtonClick = () => {
        setPressedNotifyMeButton(true);
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}><strong>No more questions for now.</strong></div>
            <div className={classes.description}>
                Come back closer to the start of the game. More questions will be available once the starting lineup is announced.
            </div>

            {
                showNotifyMeButton
                    ? <div className={classes.notifyMeContainer}>
                        {
                            pressedNotifyMeButton
                                ? <NotifiedText />
                                : gameState
                                    ? <div className={classes.buttonContainer}><NbaNotifyMeButton click={onNotifyMeButtonClick} blockId={gameState.currentBlock} blockNumber={gameState.period} isListPage={false} /></div>
                                    : null
                        }
                    </div>
                    : null
            }
        </div>
    );
};

const styles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 12,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        maxWidth: 400,
        margin: '0 auto',
        padding: '60px 25px 80px',
        position: 'relative',
        textAlign: 'center',
    },
    title: {
        fontSize: 20,
        marginBottom: 14,
        '@media only screen and (max-width: 450px)': {
            fontSize: 18,
        }
    },
    description: {
        fontSize: 16,
        '@media only screen and (max-width: 450px)': {
            fontSize: 14,
        }
    },
    notifyMeContainer: {
        bottom: 25,
        left: 0,
        position: 'absolute',
        right: 0,
    },
    buttonContainer: {
        margin: '0 auto',
        width: 150,
    }
}));

export default NoMoreQuestionsForNowBlock;