import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { QuestionListQuestion } from "../../types";
import { QUESTION_ANIMATE_IN_SECOND } from "../../constants";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100% !important",
    maxWidth: 500,
    borderRadius: 20,
    marginBottom: 35,
    display: "flex !important",
    flexWrap: "wrap",
    height: "auto !important",
    transform: "scale(1.1)",
    overflow: "visible",
    position: "relative",
    "@media only screen and (max-width: 1024px)": {
      margin: "auto",
    },
    "@media only screen and (min-width: 1025px)": {
      margin: "0 auto",
    },
  },
  inner: {
    width: "100%",
    borderRadius: 8,
    overflow: "visible",
    "@media only screen and (min-width: 1025px)": {
      paddingTop: 40,
    },
  },
  "@keyframes wobble": {
    "0%": {
      transform: "rotate(2deg) scale(1.1)",
    },
    "100%": {
      transform: "rotate(-2deg) scale(1.1)",
    },
  },
  wobbleAnimation: {
    animation: `$wobble 0.1s cubic-bezier(.36,.07,.19,.97) 2 backwards`,
  },
}));

interface ChildProps {
  question?: QuestionListQuestion;
  setTimeExpired?: (t: boolean) => void;
  timeExpired?: boolean;
  lessThanTenSecs?: boolean;
  buttonWasClicked?: boolean;
  setLessThanTenSecs?: (s: boolean) => void;
  setButtonWasClicked?: (b: boolean) => void;
}

interface Props {
  setTimeExpired: (t: boolean) => void;
  timeExpired: boolean;
  question: QuestionListQuestion;
  children: React.ReactElement<ChildProps>;
  additionalClass: any;
}

const QuestionBlockWrapper = (props: Props) => {
  const classes = styles();
  const { timeExpired, setTimeExpired, question, additionalClass, children } =
    props;

  const [lessThanTenSecs, setLessThanTenSecs] = useState<boolean>(false);
  const [buttonWasClicked, setButtonWasClicked] = useState(false);

  useEffect(() => {
    return () => {
      setTimeExpired(false);
    };
  }, []);

  useEffect(() => {
    let timeout: any = null;
    let timeoutSec: any = null;

    if (timeExpired) {
      timeout = setTimeout(() => {
        setLessThanTenSecs(false); //for wobble animation
        setButtonWasClicked(false);
      }, 2500);

      timeoutSec = setTimeout(() => {
        setTimeExpired(false);
      }, 2700);
    }

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutSec);
    };
  }, [timeExpired]);

  useEffect(() => {
    setLessThanTenSecs(false); //for wobble animation
  }, [question]);

  const animated = {
    start: {
      opacity: 0,
      scale: 0.5,
    },
    animation: {
      scale: [0.5, 1.0],
      opacity: [0, 1],
      transition: {
        times: [0, 1],
        default: { duration: QUESTION_ANIMATE_IN_SECOND },
      },
    },
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        question: question,
        setTimeExpired: (t: boolean) => setTimeExpired(t),
        timeExpired: timeExpired,
        lessThanTenSecs: lessThanTenSecs,
        buttonWasClicked: buttonWasClicked,
        setLessThanTenSecs: (s: boolean) => setLessThanTenSecs(s),
        setButtonWasClicked: (b: boolean) => setButtonWasClicked(b),
      });
    }

    return child;
  });

  return (
    <motion.div
      key={question.id}
      initial="start"
      animate="animation"
      variants={animated}
      className={classNames(
        classes.root,
        additionalClass,
        lessThanTenSecs && !buttonWasClicked ? classes.wobbleAnimation : null
      )}
      style={{ position: "relative" }}
    >
      <div className={classes.inner}>{childrenWithProps}</div>
    </motion.div>
  );
};

export default QuestionBlockWrapper;
