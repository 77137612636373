import {gql} from '@apollo/client';

export const AFL_PREVIOUS_CONTEST = gql`
    query getAflPreviousContest {
        afl {
           previous {
               id
               currentBlock
               status
               startTime
               isLocked
               fixtureId
               hasEntered
               fixture {
                    id
                    homeTeam {
                        id
                        externalId
                        name
                    }
                    awayTeam {
                        id
                        externalId
                        name
                    }
               }
           }
        }
    }
`;