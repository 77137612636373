import React, {useEffect, useRef} from 'react';
import loading from '../../../assets/animation/loading_screen.json';
import {makeStyles, Theme} from "@material-ui/core";
import lottie from 'lottie-web';
import {useMediaQuery} from '@material-ui/core';

const styles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        width: '100%',
    },
    preventClicking:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100
    },
    text:{
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center',
        color: theme.palette.primary.contrastText
    }
}));

interface Props {
    text?: boolean
}

const LoadingScreenBat = (props: Props) => {
    const classes = styles();
    const lottieRef = useRef(null);

    const desktopView = useMediaQuery('(min-width:1025px)');
    const tablet = useMediaQuery('(max-width:1024px) and (min-width: 426px)');
    const phone = useMediaQuery('(max-width:425px)');

    let size = 400;

    switch (desktopView || tablet || phone) {
        case phone:
            size = 250;
            break;
        case tablet:
            size = 300;
            break;
        case desktopView:
            size = 400;
            break;
    }

    useEffect(() => {
        const anim = lottie.loadAnimation({
            animationData: JSON.parse(JSON.stringify(loading)),
            loop: true,
            renderer: 'svg',
            container: lottieRef.current!,
            autoplay: true,
        });

        return () => {
            anim.stop();
            anim.destroy();
            lottie.stop();
            lottie.destroy();
        }
    }, []);

    return (

        <div className={classes.root}>
            <div className={classes.preventClicking}/>

            <div ref={lottieRef} style={{height: size, width: size, margin: 'auto'}}/>

            {props.text ?
                <div className={classes.text}>
                    Don't go anywhere,<br/>
                    more questions are coming
                </div> : null}
        </div>
    )
};

export default LoadingScreenBat;