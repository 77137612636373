import React from 'react';
import player from "../../../assets/CRICKET_PLAYER.png";
import {makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'auto',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        minHeight: '100vh',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'auto'
        },
        '@media only screen and (max-width: 400px)': {
            maxWidth: 400,
            margin: 'auto',
        },
        '@media only screen and (min-width: 1025px)': {
            backgroundImage: 'url(/BACKGROUND_DESKTOP.png)',
            backgroundSize: 'cover',
        },
        '@media only screen and (max-width: 1024px)': {
            backgroundImage: `url(/BackgroundGreen.jpg)`,
            backgroundSize: 'cover',
        },
        '@media only screen and (max-width: 320px)': {
            overflow: 'auto'
        },
        '@media only screen and (max-height: 690px)':  {
            overflow: 'auto'
            },
        '@media only screen and (max-height: 750px) and (min-width: 565px) and (max-width: 1000px)': {
            overflow: 'auto'
        }

    },
    rootInner: {
        maxWidth: '100%',
        margin: 'auto',
        height: '100vh',
        position: 'relative',
        '@media only screen and (max-height: 750px)': {
            padding: '10vh 5% 0px',
        },
        '@media (orientation:landscape)  and (max-height: 530px)': {
            padding: '10vh 30px 0px',
        },
        '@media only screen and (max-height: 630px)': {
            padding: '10vh 30px 0px',
        },
        '@media only screen and (max-width: 650px)': {
            maxWidth: 400,
            padding: '40px 30px 20px',
        },
        '@media only screen and (min-width: 1025px)': {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            alignItems: 'flex-end',
            padding: '0px 30px',
            justifyItems: 'center',
        },
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
            padding: '100px 30px 0px',
        },
        '@media only screen and (min-height: 700px) and (min-width: 1000px)': {
            minHeight: '100vh',
            height: 'auto'
        },
        '@media only screen and (min-width: 1025px) and (max-height: 700px)': {
            gridTemplateColumns: '50% 50%',
        }
    },
    player: {
        width: '100%',
        maxWidth: 750,
        display: 'none',
        '@media only screen and (min-width: 1025px)': {
            display: 'block'
        },
        '@media only screen and (min-width: 1025px) and (max-height: 700px)': {
            maxWidth: 500,
        }
    },
    wrapper: {
        width: '100%',
        maxWidth: 550,
        alignSelf: 'center',
        // paddingBottom: 30,
        overflow: 'visible',
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
        },
        '@media only screen and (min-width: 1025px) and (max-height: 700px)': {
            maxWidth: 350,
        }
    }
}));


interface Props {
    children: React.ReactNode
}

const PlayerLayer = (props: Props) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <div className={classes.rootInner}>

                <img src={player} alt="player" className={classes.player}/>

                <div className={classes.wrapper}>
                    {props.children}
                </div>
            </div>
        </div>

    )
};

export default PlayerLayer;