import {gql} from '@apollo/client';

export const AFL_IS_WON_CHECK = gql`
    query ifAflWonCheck($blockId: Int!) {
        afl {
           leaderboard(blockId: $blockId) {
                blockIndex
                entries {
                    id
                    username
                    settlementStatus
                }
           }
        }
    }
`;