import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {useGameState} from "../../../../contexts/nrl_afl/GameState";
import LeaderboardButton from '../../../homepage/nrl_afl/LeaderboardButton';
import {useHistory, useParams} from "react-router";
import {useQuestion} from "../../../../contexts/nrl_afl/Question";
import GameInProgress from './GameInProgress';
import PendingResults from './PendingResults';
import {USER_QUERY} from '../../../../apollo/afl/queries/User';
import {useQuery} from "@apollo/client";
import { SportsPSGMCardList } from '../../../../entain/components/SportsPSGMCardList/SportsPSGMCardList';
import { useFeatureFlag } from '../../../../entain/hooks/useFeatureFlag';
import { isOfCompetitionName } from '../../../../entain/hooks/useFetchPSGMs';


const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        color: theme.palette.primary.contrastText,
        minHeight: 'calc(100vh - 180px)',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-end',
        overflow: 'visible',
    },
    innerRoot: {
        width: '80%',
        margin: 'auto auto 0',
        padding: '20px',
        borderRadius: 20,
        backgroundColor: theme.palette.primary.main,
    },
    divider: {
        width: '100%',
        margin: '30px 0 20px',
        background: theme.palette.info.dark,
        height: 1
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 16
    },
    buttonWrapper: {
        margin: 'auto',
        padding: '30px 0',
        display: 'flex',
        justifyContent: 'center'
    },
    backToList: {
        display: 'inline-flex',
        marginTop: 20,
        fontWeight: 700,
        cursor: 'pointer',
        alignSelf: 'flex-start',
        width: '100%',
        paddingLeft: '10%'
    },
    chevron: {
        width: 7,
        height: 7,
        borderBottom: '2px solid ' + theme.palette.primary.contrastText,
        borderRight: '2px solid ' + theme.palette.primary.contrastText,
        transform: 'rotate(135deg)',
        display: 'block',
        margin: '6px 10px 0 5px'
    },
    psgmSlider: {
        margin: '0 -15px',
    },
}));

interface Props {
    sport: string
    setPrevPageForLeaderboard: (p: string) => void
}

const CompletedBlock = (props: Props) => {
    const classes = styles();

    const {gameState} = useGameState();
    const {questionsLeft} = useQuestion();
    const [leaderBoardButtonWasClicked, setLeaderboardButtonWasClicked] = useState(false);
    const history = useHistory();
    const {contest_id} = useParams<{ contest_id: string }>();
    const [resultsPending, setResultsPending] = useState(false);
    const [showNotifyMeButton, setShowNotifyMeButton] = useState(false);
    const competitionName = isOfCompetitionName(props.sport) ? props.sport : undefined;
    const psgmEnabled = useFeatureFlag('pickfans-psgm');

    const {loading, error, data} = useQuery(USER_QUERY);

    useEffect(() => {
        if (data) {
            if (data.user) {
                if (data.user.notificationStatus === 'ALLOWED' || data.user.notificationStatus === 'UNKNOWN') {
                    setShowNotifyMeButton(true)
                } else if (data.user.notificationStatus === 'NOT_ALLOWED') {
                    setShowNotifyMeButton(false)
                }
            }
        }
    }, [data])

    const leaderboardButtonClickFunc = (e: any) => {
        if (!leaderBoardButtonWasClicked) {
            setLeaderboardButtonWasClicked(true);
            e.preventDefault();

            props.setPrevPageForLeaderboard('question');
            history.push(`/${contest_id}/leaderboard` + history.location.search)
        }
    };

    useEffect(() => {
        const qtyOfPeriods = props.sport === 'NRL' ? 2 : 4;

        if (gameState) {
            if (gameState.period === qtyOfPeriods && (questionsLeft === 0 || gameState.isBlockLocked)) {
                setResultsPending(true)
            }
        }
    }, [questionsLeft, gameState?.isBlockLocked, gameState?.period])

    return (
        <div className={classes.root}>
            <div className={classes.innerRoot}>
                {gameState ? !resultsPending ?
                    <GameInProgress sport={props.sport} gameState={gameState} showNotifyMeButton={showNotifyMeButton}/>
                    : <PendingResults sport={props.sport} gameState={gameState}
                                      showNotifyMeButton={showNotifyMeButton}/> : null
                }
            </div>
            <span className={classes.backToList} onClick={() => {
                history.push(`/` + history.location.search)
            }}>
                                <span className={classes.chevron}/>All Games
                                </span>

            <div>
                <div className={classes.divider}/>
                <div className={classes.subTitle}>View your results in the leaderboard</div>

                <div className={classes.buttonWrapper}>
                    <LeaderboardButton buttonWasClicked={leaderBoardButtonWasClicked}
                                       buttonClickFunc={leaderboardButtonClickFunc}/>
                </div>
            </div>

            { psgmEnabled && competitionName ? <div className={classes.psgmSlider}><SportsPSGMCardList competitionName={competitionName} /></div> : null }
        </div>
    )
};

export default CompletedBlock;