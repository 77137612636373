import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import sadface from '../../../assets/sadfacegrey.png';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '50px 30px 0',
        margin: 'auto'
    },
    image:{
        display: 'block',
        width: 130,
        height: 130,
        margin: 'auto'
    },
    title:{
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 700,
        padding: '40px 0'
    }
}));

const NoEnteredContests = () => {
    const classes = styles();
    return (
        <div className={classes.root}>
            <img src={sadface} alt="sadface" className={classes.image}/>
            <div className={classes.title}>You have not entered any contests</div>
        </div>
    )
};

export default NoEnteredContests;