import { createContext, Dispatch, PropsWithChildren, useEffect, useState } from 'react';
import {
    addMessageListener,
    IncomingMessageTopic,
    OutgoingMessageTopic,
    postMessage,
} from '../../utils/react-native-bridge';

export type EntainConfig = {
    'pickfans-config-interval-ms'?: number;
    'pickfans-psgm'?: boolean;
};
export type Entain = {
    config: EntainConfig;
    setConfig: Dispatch<EntainConfig>;
    insideWebView: boolean;
    insideLadbrokesApp: boolean;
    insideCouchQuizApp: boolean;
    couchQuizAppPushToken: string | null;
};

export const EntainContext = createContext<Entain | undefined>(undefined);
const insideWebView = !!window.ReactNativeWebView;
const insideLadbrokesApp = insideWebView && !window.__COUCH_QUIZ_APP__;
const insideCouchQuizApp = insideWebView && !!window.__COUCH_QUIZ_APP__;
const couchQuizAppPushToken = 
    typeof window.__COUCH_QUIZ_APP_PUSH_TOKEN__ === 'string'
    && window.__COUCH_QUIZ_APP_PUSH_TOKEN__
        ? window.__COUCH_QUIZ_APP_PUSH_TOKEN__
        : null;

export const EntainProvider = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
    const [config, setConfig] = useState<EntainConfig>({});
    const value: Entain = {
        config,
        setConfig,
        insideWebView,
        insideLadbrokesApp,
        insideCouchQuizApp,
        couchQuizAppPushToken,
    };

    // Add listener for config response event triggered by React Native
    useEffect(() => {
        const listener = (data: EntainConfig) => setConfig(data);
        const removeMessageHandler = addMessageListener(IncomingMessageTopic.FetchConfig, listener);

        return () => removeMessageHandler?.();
    }, []);

    // Post message to React Native to fetch config at the given interval
    useEffect(() => {
        if (!value.insideWebView) {
            return;
        }

        const fetchConfig = () => {
            postMessage({ topic: OutgoingMessageTopic.FetchConfig, data: {} });
        };
        const intervalId = setInterval(fetchConfig, config['pickfans-config-interval-ms'] ?? 10_000);
        fetchConfig();

        return () => clearInterval(intervalId);
    }, [value.insideWebView, config['pickfans-config-interval-ms']]);

    return <EntainContext.Provider value={value}>{children}</EntainContext.Provider>;
};
