import { gql} from '@apollo/client';

export const ENTER_CONTEST = gql`
  mutation enterContest($contestId: Int!) {
    enterContest(contestId: $contestId) {
      success
      errorMessage
      entry {
        id
        currentBatsman {
          id
          key
          name
        }
        selectedBatsmen {
          id
          key
          name
          points
          isOut
        }
        score {
          quizPoints
          runPoints
          rank
          winnings
        }
      }
    }
  }
`;

