import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import out from '../../assets/OUT.png';
import {useEntry} from "../../contexts/cricket/Entry";
import SelectBatsmenOut from './SelectBatsmenOut';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        backgroundSize: 'cover',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'hidden'
        },
        '@media only screen and (max-width: 400px)': {
            maxWidth: 400,
            margin: 'auto',
        }
    },
    rootInner: {
        padding: '10px 15px',
        maxWidth: 500,
        margin: 'auto',
    },
    outImage: {
        display: 'block',
        width: 230,
        margin: '40px auto 0'
    },
    title: {
        fontSize: 17,
        fontWeight: 700,
        margin: '20px auto 12px',
        display: 'block',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px',
    },
    link: {
        textDecoration: 'none',
    },
    show: {
        display: 'block'
    },
    hide: {
        display: 'none'
    }
}));


const OutAnnouncement = () => {
    const classes = styles();

    const {entry} = useEntry();
    const {currentBatsman} = entry;

    const loseWicket = !currentBatsman;

    return (

        <div className={classes.root}>
            <div className={classes.rootInner}>

                <div className={classes.show}>
                    <img src={out} alt="out" className={classes.outImage}/>
                    <span className={classes.title}>{loseWicket ? 'You lost a wicket' : 'A wicket was fallen'}</span>

                    <SelectBatsmenOut/>
                </div>

            </div>
        </div>
    )
};

export default OutAnnouncement;