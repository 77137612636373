import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import NotifyMeButton from "../../common/UI/NotifyMeButton";
import NotifiedText from "../../common/UI/NotifiedText";
import {useQuery} from "@apollo/client";
import {
    AFL_HAS_REQUESTED_NOTIFICATION_FOR_CONTEST
} from '../../../apollo/afl/queries/HasRequestedNotificationForContest';
import {
    NRL_HAS_REQUESTED_NOTIFICATION_FOR_CONTEST
} from '../../../apollo/nrl/queries/HasRequestedNotificationForContest';
import {USER_QUERY} from '../../../apollo/afl/queries/User';

const styles = makeStyles((theme: Theme) => ({
    notifyMeWrapper: {
        gridColumnStart: 1,
        gridColumnEnd: 4,
        margin: 'auto',
        '& > div': {
            width: 100,
            margin: '10px auto',
            fontSize: 12,
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 0,
            borderRadius: 5
        },
    },
    textWrapper: {
        width: 200,
        '& > div': {
            margin: '10px auto 4px',
        },
        '& > div > image': {
            width: 20,
            height: 22,
        }
    }
}))

interface Props {
    contestId: number
}

const NotifyMe = (props: Props) => {
    const classes = styles();
    const [pressedNotifiedBtn, setPressedNotifiedBtn] = useState(false);
    const [showNotifyMeButton, setShowNotifyMeButton] = useState(false);

    const query = process.env.REACT_APP_BUILD_TARGET === 'nrl' ?
        NRL_HAS_REQUESTED_NOTIFICATION_FOR_CONTEST :
        AFL_HAS_REQUESTED_NOTIFICATION_FOR_CONTEST;

    const {data, loading, error} = useQuery(query, {variables: {contestId: props.contestId}});
    const {loading: userLoading, error: userError, data: userData} = useQuery(USER_QUERY);

    useEffect(() => {
        if (data && !loading && !error) {
            const path = process.env.REACT_APP_BUILD_TARGET === 'nrl' ?
                data.nrl.hasRequestedNotificationForContest :
                data.afl.hasRequestedNotificationForContest;

            if (path) {
                setPressedNotifiedBtn(true)
            }
        }
    }, [data, loading, error])
    
    useEffect(() => {
        if (userData && !userLoading && !userError) {
            if (userData.user) {
                if (userData.user.notificationStatus === 'ALLOWED' || userData.user.notificationStatus === 'UNKNOWN') {
                    setShowNotifyMeButton(true)
                } else if (userData.user.notificationStatus === 'NOT_ALLOWED') {
                    setShowNotifyMeButton(false)
                }
            }
        }
    }, [userData, userLoading, userError])


    return (
        <div className={classes.notifyMeWrapper}>
            {
                showNotifyMeButton ?
                    pressedNotifiedBtn ?
                        <span className={classes.textWrapper}><NotifiedText/></span>
                        :
                        <NotifyMeButton click={() => setPressedNotifiedBtn(true)}
                                        blockId={1}
                                        blockNumber={1}
                                        contestId={Number(props.contestId)}
                                        isListPage={true}/>
                    : null
            }
        </div>
    )

};

export default NotifyMe;