import { useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useCallback, useState } from 'react';
import {
    GetLeaderboardDocument,
    GetLeaderboardQuery,
    GetLeaderboardQueryVariables,
} from '../../gql/operations.generated';
import { LeaderboardEntry } from '../../types';
import { createArrayWithTiedField } from '../../utils/utils';
import Pagination from '../common/leaderboard/Pagination';
import Tabs from '../common/leaderboard/Tabs';
import TiedText from '../common/leaderboard/TiedText';
import TopTitles from '../common/leaderboard/TopTitles';
import LoadingScreenSpinner from '../common/LoadingScreenSpinner';
import LeaderboardEntryItem from './LeaderboardEntryItem';
import { ENABLE_NATIONAL_COMPETITION } from '../../constants';

const styles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        padding: '20px 0',
    },
    emptyText: {
        paddingTop: 20,
        fontSize: 20,
        fontWeight: 700,
    },
    searchRow: {
        width: '100%',
        padding: '10px 0',
    },
    innerRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        height: 50,
        alignItems: 'center',
    },
    searchText: {
        paddingRight: 20,
    },
    searchInput: {
        overflowY: 'visible',
        '& .MuiInputLabel-outlined, .MuiInputBase-root': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.contrastText,
        },
    },
}));

type TabName = 'ladbrokes' | 'national';
const tabNames: TabName[] = ['ladbrokes', 'national'];

interface Props {
    activeBlockId: string | null;
}

const LeaderboardList = (props: Props) => {
    const classes = styles();
    const { activeBlockId } = props;
    const [page, setPage] = useState(1);
    const [searchField, setSearchField] = useState('');
    const [selectedTab, setSelectedTab] = useState<TabName>('ladbrokes');

    const onTabClick = useCallback((tabName: TabName) => {
        setSelectedTab(tabName);
        setPage(1);
    }, []);

    const { loading, data, error } = useQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(
        GetLeaderboardDocument,
        {
            variables: {
                blockId: activeBlockId ?? '',
            },
            skip: !activeBlockId,
        }
    );

    const pageSize = 20;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const v = value ? value : 1;
        setPage(v);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchLine = event.target.value;

        if (searchLine.length > 2) {
            setSearchField(searchLine);
            setPage(1);
        } else {
            setSearchField('');
            setPage(1);
        }
    };

    if (data && !loading && !error) {
        const type = selectedTab === 'ladbrokes' ? 'LADBROKES' : 'OVERALL';
        const filteredEntries = (data.afl.leaderboard.entries || []).filter(
            (entry): entry is LeaderboardEntry => !!entry
        );
        const creatingNewList = createArrayWithTiedField(filteredEntries, type);
        // we are going to move entry with rank/nationRank 0 to the back of list
        const zeroRankList = creatingNewList.newList.filter(
            (entry) => entry[type === 'LADBROKES' ? 'rank' : 'nationalRank'] === 0
        );
        const nonZeroRankList = creatingNewList.newList.filter(
            (entry) => entry[type === 'LADBROKES' ? 'rank' : 'nationalRank'] !== 0
        );
        const newList = [...nonZeroRankList, ...zeroRankList];
        const isAnyTie = creatingNewList.isAnyTie;
        const paginationPages = Math.ceil(newList.length / pageSize);

        return (
            <div className={classes.root}>
                {newList.length > 0 ? (
                    <>
                        <div className={classes.searchRow}>
                            <div className={classes.innerRow}>
                                <span className={classes.searchText}>Search thru usernames:</span>
                                <TextField
                                    id="outlined-search"
                                    className={classes.searchInput}
                                    onChange={handleSearch}
                                    label="Search"
                                    type="search"
                                    variant="outlined"
                                    size="small"
                                />
                            </div>
                        </div>

                        { ENABLE_NATIONAL_COMPETITION ? <Tabs<TabName> onTabClick={onTabClick} selectedTab={selectedTab} tabNames={tabNames} /> : null }

                        {isAnyTie ? <TiedText /> : null}

                        <TopTitles type={type} />

                        <div>
                            {newList
                                .filter((l) => l.username.indexOf(searchField) !== -1)
                                .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
                                .map((entry, i: number) => {
                                    return <LeaderboardEntryItem key={i} entry={entry} type={type} />;
                                })}
                        </div>

                        {newList.length > pageSize && (
                            <Pagination
                                currentPage={page}
                                paginationPages={paginationPages}
                                handlePagination={handleChange}
                            />
                        )}
                    </>
                ) : (
                    <div className={classes.emptyText}>No entries this quarter/half.</div>
                )}
            </div>
        );
    } else {
        if (!data && !loading) {
            return <div className={classes.emptyText}>No entries this quarter/half.</div>;
        }

        return (
            <>
                <div className={classes.emptyText}>Preparing Leaderboard</div>
                <LoadingScreenSpinner />
            </>
        );
    }
};

export default LeaderboardList;
