import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import CricketApp from './CricketApp';
import AFLApp from './sports/afl/App';
import NbaApp from './NbaApp';
import NrlApp from './NrlApp';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo/connection';
import { EntainProvider } from './entain/components/core/EntainProvider';
import 'react-activity/dist/Spinner.css';

const environment = process.env.NODE_ENV;
if (environment === 'production') {
    Sentry.init({
        dsn: 'https://e862591be53b4731bad802c939842259@o96002.ingest.sentry.io/4504806587564032',
        environment,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

if (process.env.REACT_APP_BUILD_TARGET === 'cricket') {
    ReactDOM.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <EntainProvider>
                    <CricketApp />
                </EntainProvider>
            </ApolloProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else if (process.env.REACT_APP_BUILD_TARGET === 'nrl') {
    ReactDOM.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <EntainProvider>
                    <NrlApp />
                </EntainProvider>
            </ApolloProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else if (process.env.REACT_APP_BUILD_TARGET === 'afl') {
    ReactDOM.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <EntainProvider>
                    <AFLApp />
                </EntainProvider>
            </ApolloProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else if (process.env.REACT_APP_BUILD_TARGET === 'nba') {
    ReactDOM.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <EntainProvider>
                    <NbaApp />
                </EntainProvider>
            </ApolloProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
