import { useMutation } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTimeDrift } from "../../../../../contexts/TimeDrift";
import { useQuestion } from "../../../contexts/Question";
import {
  AnswerQuestionDocument,
  AnswerQuestionMutation,
  AnswerQuestionMutationVariables,
} from "../../../gql/operations.generated";
import {
  QuestionListQuestion,
  QuestionListQuestionOutcome,
} from "../../../types";
import TeamIconFormatter from "../../common/TeamIconFormatter";
import OutcomeButton from "./OutcomeButton";

const styles = makeStyles((theme: Theme) => ({
  flexRoot: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: 5,
    flexDirection: "column",
    overflow: "visible",
    position: "relative",
    paddingTop: 10,
  },
}));

interface Props {
  entryId: string;
  question: QuestionListQuestion;
  timeExpired: boolean;
  setButtonWasClicked: ((b: boolean) => void) | undefined;
  setLessThanTenSecs: ((l: boolean) => void) | undefined;
  setTimeExpired: (t: boolean) => void;
  clickedButton: number;
  setClickedButton: (b: number) => void;
  setOpenSnackbar: (s: boolean) => void;
  trackAnswer: (
    outcome: QuestionListQuestionOutcome,
    timestamp: string,
    err?: unknown
  ) => void;
  disableButtons: boolean;
}

const Outcomes = (props: Props) => {
  const classes = styles();
  const {
    entryId,
    question,
    timeExpired,
    setButtonWasClicked,
    setLessThanTenSecs,
    setTimeExpired,
    clickedButton,
    setClickedButton,
    setOpenSnackbar,
    trackAnswer,
    disableButtons,
  } = props;

  const { questionWasAnswered } = useQuestion();
  const diff = useTimeDrift();

  const [, setError] = useState();

  const [answerQuestion, { data, loading, error }] = useMutation<
    AnswerQuestionMutation,
    AnswerQuestionMutationVariables
  >(AnswerQuestionDocument, {
    context: { timeout: 5000 },
  }); //5 secs timeout for answer mutation, then cancel it

  useEffect(() => {
    if (!loading && !error && data) {
      // questionWasAnswered(question.id)
    } else if (!loading && error) {
      setOpenSnackbar(true);
      setButtonWasClicked && setButtonWasClicked(false);
      setClickedButton(-1);
    }
  }, [loading, data, error]);

  useEffect(() => {
    setOpenSnackbar(false);
    return () => {
      setButtonWasClicked && setButtonWasClicked(false);
      setLessThanTenSecs && setLessThanTenSecs(false);
      setTimeExpired(false);
      setOpenSnackbar(false);
    };
  }, []);

  const onClickGenerator = useCallback(
    (outcome: QuestionListQuestionOutcome, outcomeIdx: number) => {
      return async () => {
        // if (!buttonWasClicked && !timeExpired && !question.isAnswered) { //TODO TIME EXPIRED BROKEN FOR NOW
        if (clickedButton === -1 && !question.isAnswered) {
          setButtonWasClicked && setButtonWasClicked(true);
          setClickedButton(outcomeIdx);
          const dateWithDiff = new Date().getTime() + diff;
          const timestamp = new Date(dateWithDiff).toISOString();

          await answerQuestion({
            variables: {
              entryId: entryId,
              outcomeId: outcome.id,
              timestamp: timestamp,
            },
          })
            .then((result) => {
              const selectOutcome = result.data?.afl.selectOutcome;

              if (selectOutcome?.success && !selectOutcome?.errorMessage) {
                questionWasAnswered(question.id);
                trackAnswer(outcome, timestamp);
              } else {
                setOpenSnackbar(true);
                setButtonWasClicked && setButtonWasClicked(false);
                setClickedButton(-1);
              }
            })
            .catch((e) => {
              setOpenSnackbar(true);
              setButtonWasClicked && setButtonWasClicked(false);
              setClickedButton(-1);
              setError(e);
              trackAnswer(outcome, timestamp, e);
            });
        }
      };
    },
    [
      clickedButton,
      question.isAnswered,
      setButtonWasClicked,
      setClickedButton,
      entryId,
      setOpenSnackbar,
      setError,
      questionWasAnswered,
    ]
  );

  return (
    <div className={classes.flexRoot}>
      {question.outcomes.map((outcome, idx) => {
        return (
          <OutcomeButton
            key={outcome.id}
            onClick={onClickGenerator(outcome, idx)}
            buttonId={idx}
            clickedButtonId={clickedButton}
            timeExpired={timeExpired}
            selected={outcome.isSelected}
            setClickedButton={setClickedButton}
            disable={disableButtons}
          >
            <TeamIconFormatter displayText={outcome.text} size={20} />
          </OutcomeButton>
        );
      })}
    </div>
  );
};

export default Outcomes;
