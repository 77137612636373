import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useContest } from "../../../contexts/nba/Contest";
import { useGameState } from "../../../contexts/nba/GameState";
import { useTimeDrift } from "../../../contexts/TimeDrift";
import { SportsTeamIcon } from "../../../entain/components/core/SportsTeamIcon";
import { NBA_TEAM_MAP } from "../../../entain/utils/pick-fans";
import { calCountdownTime, getDateAndTime } from "../../../utils";

const DEFAULT_COUNTDOWN_TO_DISPLAY = '0h 0m 0s';

interface Props {
    showCountdownOnly?: boolean
    showShadow?: boolean
}

const GameStats = ({ showCountdownOnly, showShadow }: Props) => {
    const classes = styles();
    const { contest } = useContest();
    const { gameState } = useGameState();
    const diff = useTimeDrift();
    const [countdownToDisplay, setCountdownToDisplay] = useState(DEFAULT_COUNTDOWN_TO_DISPLAY);
    const [hasElapsedLockOutTime, setHasElapsedLockOutTime] = useState(false);

    useEffect(() => {
        if (!gameState?.lockOutTime) return;

        const now = new Date().getTime() + diff;
        const lockOutTime = new Date(gameState.lockOutTime).getTime();

        if (lockOutTime - now > 100) {
            const intervalId = setInterval(() => {
                const { countdownTime, distance } = calCountdownTime(lockOutTime, diff);
                setCountdownToDisplay(countdownTime);

                if (distance <= 0) {
                    setHasElapsedLockOutTime(true);
                    setCountdownToDisplay(DEFAULT_COUNTDOWN_TO_DISPLAY);
                    clearInterval(intervalId);
                }
            }, 1000);

            return() => clearInterval(intervalId);
        } else {
            setHasElapsedLockOutTime(true);
        }
    }, [diff, gameState?.lockOutTime]);

    if (!gameState || ! contest) return null;

    const showCountdown = gameState.contestStatus === 'INPROGRESS' || !!showCountdownOnly;
    const homeTeamIconName = NBA_TEAM_MAP[gameState.homeTeam.externalId]?.iconName || '';
    const awayTeamIconName = NBA_TEAM_MAP[gameState.awayTeam.externalId]?.iconName || '';
    const homeTeamCode = NBA_TEAM_MAP[gameState.homeTeam.externalId]?.code || '';
    const awayTeamCode = NBA_TEAM_MAP[gameState.awayTeam.externalId]?.code || '';
    const lockOutTime = new Date(gameState.lockOutTime).getTime();
    const { startDate, startTime } = getDateAndTime(lockOutTime);

    const formatClock = (clockInSeconds: number): string => {
        let mins: number | string = Math.floor(clockInSeconds / 60);
        let secs: number | string = clockInSeconds - mins * 60;

        mins = mins < 10 ? '0' + mins : mins;
        secs = secs < 10 ? '0' + secs : secs;

        return `${mins}:${secs}`;
    };

    return (
        <div className={classes.container}>
            <div className={classNames(classes.innerContainer, showShadow ? classes.innerContainerWithShadow : '' )}>
                <div className={classNames(classes.team, classes.teamHome)}>
                    <div><SportsTeamIcon competitionName="NBA" teamName={homeTeamIconName} width={40} height={40} border={0} /></div>
                    <div className={classes.teamName}>{homeTeamCode}</div>
                    { showCountdown ? null : <div className={classes.teamScore}><strong>{gameState.homeTeamScore}</strong></div> }
                </div>

                {
                    showCountdown
                        ? (
                            <div className={classes.datetime}>
                                <div className={classes.venue}>{contest.fixture.venue?.name}</div>
                                <div className={classes.date}><strong>{startDate}</strong></div>
                                <div className={classes.time}><strong>{startTime}</strong></div>
                                {
                                    hasElapsedLockOutTime
                                        ? gameState.period > 0
                                            ? <div className={classes.period}><strong>Q{gameState.period}</strong></div>
                                            : <div className={classes.countdown}>Starting Soon</div>
                                        : <div className={classes.countdown}>Closes In: <strong className={classes.countdownTime}>{countdownToDisplay}</strong></div>
                                }
                            </div>
                        )
                        : (
                            <div className={classes.game}>
                                <div className={classes.venue}>{contest.fixture.venue?.name}</div>
                                {
                                    gameState.period > 0
                                        ? <div className={classes.period}><strong>Q{gameState.period}</strong></div>
                                        : null
                                }
                                {/* disable clock for now until clockInSeconds can be calculated correctly */}
                                {/* <div className={classes.clock}><strong>{formatClock(gameState.clockInSeconds)}</strong></div> */}
                            </div>
                        )
                }

                <div className={classNames(classes.team, classes.teamAway)}>
                    { showCountdown ? null : <div className={classes.teamScore}><strong>{gameState.awayTeamScore}</strong></div> }
                    <div className={classes.teamName}>{awayTeamCode}</div>
                    <div><SportsTeamIcon competitionName="NBA" teamName={awayTeamIconName} width={40} height={40} border={0} /></div>
                </div>
            </div>
        </div>
    );
};

const styles = makeStyles((theme: Theme) => ({
    container: {
        background: theme.palette.background.default,
        margin: '0 auto',
        maxWidth: 650,
    },
    innerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
    },
    innerContainerWithShadow: {
        boxShadow: '0 3px 6px ' + theme.palette.primary.light,
        marginBottom: 10,
        paddingBottom: 10,
    },
    team: {
        alignItems: 'center',
        display: 'flex',
    },
    teamHome: {
        paddingLeft: 15,
        textAlign: 'left',
    },
    teamAway: {
        paddingRight: 15,
        textAlign: 'right',
    },
    teamName: {
        padding: '0 8px',
    },
    teamScore: {
        fontSize: 20,
        minWidth: 36,
    },
    datetime: {
        textAlign: 'center',
    },
    game: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    venue: {
        color: '#9B9BA3',
        fontSize: 10,
        marginBottom: 7,
    },
    date: {
        fontSize: 12,
    },
    time: {
        fontSize: 16,
        marginBottom: 5,
    },
    countdown: {
        backgroundColor: '#0E0E12',
        borderRadius: 11,
        fontSize: 10,
        padding: '3px 10px',
    },
    countdownTime: {
        color: '#FE2B44',
    },
    period: {
        fontSize: 12,
    },
    clock: {
        fontSize: 16,
    },
}));

export default GameStats;
