import {gql} from '@apollo/client';

export const NBA_ENTER_CONTEST = gql`
  mutation enterContest($blockId: Int!) {
    nba {
        enterContest(blockId: $blockId) {
              success
              entry {
                id
                points
                blockId
                rank
                winnings
              }
              errorMessage
        }
    }
  }
`;
