import React, {createContext, useContext, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {TIME_QUERY} from "../apollo/Time";

const TimeDrift = createContext(0);

interface Props {
    children: React.ReactNode;
}

const TimeDriftProvider = ({children}: Props) => {
    const [timeDrift, setTimeDrift] = useState(0);
    const now = Date.now();
    const {data, loading, error} = useQuery(TIME_QUERY, {fetchPolicy: "network-only"});

    useEffect(() => {
        if (data && !error && !loading) {
            const drift = (Number(data.time) - now);
            if (drift > 5 || drift < -5) {
                setTimeDrift(drift);
            }
        }
    }, [data, loading, error])

    return (
        <TimeDrift.Provider value={timeDrift}>
            {children}
        </TimeDrift.Provider>
    );
};

const useTimeDrift = () => {
    const ctx = useContext(TimeDrift);
    if (ctx === undefined) {
        throw new Error('useTimeDrift must be used within a TimeDriftProvider');
    }
    return ctx;
};

export {TimeDriftProvider, useTimeDrift};
