import {gql} from '@apollo/client';

export const AFL_CONTEST_SUBSCRIPTION = gql`
  subscription aflContest($id: Int!) {
    aflContest(id: $id) {
        id
        status
        startTime
        isLocked
    }
  }
`;

