import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {CssBaseline} from "@material-ui/core";
import Homepage from "./components/homepage/cricket";
import QuestionPage from './components/question_page/cricket';
import Leaderboard from './components/leaderboard/cricket';
import RulesPage from './components/rules_page/cricket';
import PreviousResults from '../src/components/previous_results/cricket';
import TermsConditions from './components/terms_and_conditions/cricket';
import {EntryProvider} from "./contexts/cricket/Entry";
import {AuthProvider} from "./contexts/Auth";
import {GameStatusProvider} from "./contexts/cricket/GameStatus";
import {QuestionManagerProvider} from "./contexts/cricket/Question";
import {CricketStateProvider} from "./contexts/cricket/CricketState";
import CustomThemeProvider from './themes/ThemeProvider';


function CricketApp() {
    const [prevPageForLeaderboard, setPrevPageForLeaderboard] = useState('');
    const [agreedRules, setAgreedRules] = useState(false);

    return (
        <Router>
            <AuthProvider>
                <CustomThemeProvider theme='cricketTheme'>
                    <CssBaseline/>

                    <Switch>
                        <Route path="/terms_conditions">
                            <TermsConditions/>
                        </Route>

                        <Route path="/previous_results">
                            <PreviousResults setPrevPageForLeaderboard={setPrevPageForLeaderboard}/>
                        </Route>

                        <Route path="/:contest_id/question_page">
                            <EntryProvider>
                                <GameStatusProvider>
                                    <CricketStateProvider>
                                        <QuestionManagerProvider>
                                            <QuestionPage
                                                agreedRules={agreedRules}
                                                setPrevPageForLeaderboard={setPrevPageForLeaderboard}/>
                                        </QuestionManagerProvider>
                                    </CricketStateProvider>
                                </GameStatusProvider>
                            </EntryProvider>
                        </Route>

                        <Route path="/:contest_id/home">
                            <EntryProvider>
                                <RulesPage setAgreedRules={setAgreedRules} agreedRules={agreedRules}/>
                            </EntryProvider>
                        </Route>

                        <Route path="/:contest_id/leaderboard">
                            <GameStatusProvider>
                                <EntryProvider>
                                    <Leaderboard prevPageForLeaderboard={prevPageForLeaderboard}/>
                                </EntryProvider>
                            </GameStatusProvider>
                        </Route>


                        <Route path="/" exact>
                            <Homepage
                                setPrevPageForLeaderboard={setPrevPageForLeaderboard}/>
                        </Route>

                        {/*<Redirect from="*" to="/"/>*/}

                    </Switch>
                </CustomThemeProvider>
            </AuthProvider>
        </Router>
    );
}

export default CricketApp;
