import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import OutcomeBlock from "./OutcomeBlock";
import FireballProgressBar from './fireball/FireballProgressBar';
import {Outcome, Question} from '../../../../../../../shared/types';
import ProgressBarWrapper from '../../../shared/ProgressBarWrapper';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '90% !important',
        padding: '10px 15px',
        maxWidth: 500,
        borderRadius: 8,
        marginBottom: 35,
        display: 'flex !important',
        flexWrap: 'wrap',
        height: 'auto !important',
        transform: 'scale(1.1)',
        overflow: 'visible',
        position: 'relative',
        '@media only screen and (max-width: 1024px)': {
            margin: 'auto',
        },
        '@media only screen and (min-width: 1025px)': {
            margin: '0 auto'
        }
    },
    inner: {
        width: '100%',
        borderRadius: 8,
        overflow: 'visible',
        '@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)': {
            // paddingTop: '2%'
        },
        '@media only screen and (min-width: 1025px)': {
            paddingTop: 40,
        }
    },
    topLine: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        fontSize: 10,
        paddingBottom: 10,
        '@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)': {
            paddingBottom: 0
        }
    },
    timeWrapper: {
        display: 'flex',
        width: 130
    },
    timeColor: {
        width: 45,
        textAlign: 'end'
    },
    lessTimeColor: {
        color: theme.palette.secondary.light,
        width: 45,
        textAlign: 'end'
    },
    question: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: 10
    },
    questionText: {
        fontSize: 16,
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontWeight: 800,
        color: theme.palette.background.paper,
        paddingTop: 5,
        paddingBottom: 30
    },
    buttonWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    ballImage: {
        display: 'block',
        width: 15,
        height: 15,
        marginRight: 5
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    '@keyframes wobble': {
        '0%': {
            transform: 'rotate(2deg) scale(1.1)',

        },
        '100%': {
            transform: 'rotate(-2deg) scale(1.1)',
        },
    },
    wobbleAnimation: {
        animation: `$wobble 0.1s cubic-bezier(.36,.07,.19,.97) 2 backwards`,
    },
    timeBar: {
        width: '100%',
        height: 7,
        overflow: 'hidden',
        position: 'relative',
        transform: 'rotate(180deg);'
    },
    timeBarInner: {
        backgroundColor: theme.palette.grey[400],
        height: 7,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    wholeTimerWrapper: {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 20
    },
    fixture: {
        paddingTop: 1,
        width: 100
    },

    dismissButton: {
        color: theme.palette.primary.light,
        fontSize: 12
    },
    countdown: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 'bold'
    },
    topWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0 30px',
        marginTop: 20,
        '@media only screen and (max-width: 1024px)': {
            padding: '10px 0 20px',
        }
    },
    points: {
        padding: '0px 12px',
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
        fontSize: 14,
        fontStyle: 'italic',
        display: 'flex',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center'
    },
    additionalPadding: {
        paddingTop: 20
    },
    timesup: {
        display: 'block',
        width: 'auto',
        height: 50,
        margin: '40px auto 0px',
        transform: 'scale(1)',
        animation: `$appearing 0.4s cubic-bezier(.36,.07,.19,.97) 1 backwards`,
    },
    wrapperTopPart: {
        overflow: 'visible'
        // height: 70
    },
    '@keyframes appearing': {
        '0%': {
            transform: 'scale(0.3)',
        },
        '70%': {
            transform: 'scale(1.2)',
        },

        '100%': {
            transform: 'scale(1)',
        },
    },
}));

interface Props {
    setTimeExpired?: (t: boolean) => void
    timeExpired?: boolean
    question?: Question
    setLessThanTenSecs?: (l: boolean) => void
    percentage?: number[]
    lessThanTenSecs?: boolean
    buttonWasClicked?: boolean
    setButtonWasClicked?: (c: boolean) => void
}

interface Points {
    2: number
    3: number
    4: number

    [key: number]: number
}

const QuestionBlock = (props: Props) => {
    const classes = styles();
    const {timeExpired, setTimeExpired, question, setLessThanTenSecs, percentage, lessThanTenSecs, buttonWasClicked, setButtonWasClicked} = props;

    const points: Points = {
        2: 50,
        3: 100,
        4: 150
    };

    const moreThanThreeOutcomes = question && question.outcomes.length > 3;

    let [kick, setKick] = useState(false);

    useEffect(() => {
        return () => {
            setKick(false)
        }
    }, []);

    return (
        <>
            {question &&
            <>
                <div className={classes.wrapperTopPart}>
                    <div className={classes.topWrapper}>
                        <div className={classes.points}>{points[question.outcomes.length]} POINTS</div>
                    </div>

                    <ProgressBarWrapper question={question} setLessThanTenSecs={setLessThanTenSecs}
                                        setTimeExpired={setTimeExpired!} setKick={setKick}>
                        <FireballProgressBar lessThanTenSecs={lessThanTenSecs}/>
                    </ProgressBarWrapper>


                </div>


                <div className={classes.question}>
                    <div
                        className={(classNames(classes.questionText, moreThanThreeOutcomes ? classes.additionalPadding : ''))}>
                        {question.text}
                    </div>
                </div>

                {setLessThanTenSecs && buttonWasClicked !== undefined && setButtonWasClicked && percentage !== undefined &&

                <OutcomeBlock
                    question={question}
                    timeExpired={timeExpired!}
                    setLessThanTenSecs={setLessThanTenSecs}
                    setTimeExpired={setTimeExpired!}
                    buttonWasClicked={buttonWasClicked}
                    setButtonWasClicked={setButtonWasClicked}
                    percentage={percentage}
                />
                }
            </>
            }
        </>

    )
};

export default QuestionBlock;