import React from 'react';
import Button from '../../common/UI/SquareButton';
import {makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    wrapper: {
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    }
}));

interface Props {
    buttonWasClicked: boolean
    buttonClickFunc: any
}

const LeaderboardButton = (props: Props) => {
    const classes = styles();
    const {buttonWasClicked, buttonClickFunc} = props;

    return (
        <div className={classes.wrapper}>
            <Button width='140px' buttonWasClicked={buttonWasClicked} small={true}
                                  onClick={(e: any) => buttonClickFunc(e)}>
                Leaderboard
            </Button>
        </div>
    )
};

export default LeaderboardButton;