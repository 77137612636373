import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import {useGameState} from "../../../contexts/nrl_afl/GameState";
import BackRow from "./BackRow";
import QtrTabs from "../../leaderboard/nrl_afl/QtrTabs";
import LeaderboardList from "./LeaderboardList";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'auto',
        backgroundSize: 'cover',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'auto'
        },
        '& > a': {
            textDecoration: 'none'
        },
        background: `url('/NRL-BG.jpg')`,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100vh',
    },
    rootInner: {
        maxWidth: '100%',
        margin: 'auto',
        height: '100vh',
        '@media only screen and (max-width: 650px)': {
            maxWidth: 650,
        },
        '@media only screen and (min-width: 651px) and (max-width: 1024px)': {
            maxWidth: 700,
        },
    },
    topPart: {
        height: 43,
        position: 'relative'
    },
    innerWrapper: {
        padding: '10px 15px 0px',
        background: theme.palette.background.default,
    },
}));

interface Props {
    sport: string
}

const Index = (props: Props) => {
    const classes = styles();
    const {sport} = props;
    const {gameState, isStatusLoaded} = useGameState();
    const [selectedQtr, setSelectedQtr] = useState(-1);
    const [activeBlockId, setBlockId] = useState<number | null>(null)

    useEffect(() => {
        if (isStatusLoaded && gameState) {
            if (selectedQtr === gameState.period - 1 || selectedQtr === -1) {
                setBlockId(gameState.currentBlock)
            } else {
                setBlockId(gameState.allBlocks[selectedQtr].id)
            }

        }
    }, [isStatusLoaded, gameState])

    const qtrClick = (qtr: number) => {
        if (gameState) {
            setBlockId(gameState.allBlocks[qtr].id)
            setSelectedQtr(qtr)
        }
    };

    return (
        <div className={classNames(classes.root)}>
            <div className={classes.rootInner}>

                <div className={classes.topPart}>
                    <BackRow/>
                </div>

                <div className={classes.innerWrapper}>
                    {gameState &&
                        <QtrTabs sport={sport} qtrClick={qtrClick} selectedQtr={selectedQtr}
                                 setSelectedQtr={setSelectedQtr} period={gameState.period}/>
                    }
                </div>

                <LeaderboardList sport={sport} activeBlockId={activeBlockId}/>
            </div>
        </div>
    )
};

export default Index;