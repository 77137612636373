import { useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { NBA_ENTAIN_ADMIN } from '../../../apollo/nba/queries/EntainAdmin';
import ListComponent from './ListComponent';
import { AdminContest, AdminTransformedContest } from './types';

const styles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        fontSize: 18,
        paddingBottom: 30,
    },
}))

const NbaList = () => {
    const classes = styles();
    const {data, loading, error} = useQuery(NBA_ENTAIN_ADMIN);

    if (data && !loading && !error) {
        const contestsList = transformAdminContestsForNBA(data.nba.previous);
        return <ListComponent contestsList={contestsList}/>;
    } else {
        return <div className={classes.title}> LOADING....</div>;
    }
};

export const transformAdminContestsForNBA = (contests: AdminContest[]): AdminTransformedContest[] => {
    const result = contests.map((contest) => {
        return {
            id: contest.id,
            status: contest.status,
            startTime: new Date(Number(contest.startTime)),
            fixture: {
                id: contest.fixture.id,
                homeTeam: {
                    id: contest.fixture.homeTeam.id,
                    name: contest.fixture.homeTeam.name,
                },
                awayTeam: {
                    id: contest.fixture.awayTeam.id,
                    name: contest.fixture.awayTeam.name,
                },
                startTime: new Date(Number(contest.fixture.startTime)),
            },
        }
    })

    result.sort((a, b) => b.fixture.startTime.getTime() - a.fixture.startTime.getTime());

    return result;
}

export default NbaList;