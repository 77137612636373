import {gql} from '@apollo/client';

export const NBA_GET_QUESTIONS = gql`
    query getNbaQuestions($blockId: Int!)  {
        nba {
            questions(blockId: $blockId) {
                id
                blockNumber
                text
                outcomes {
                    id
                    questionId
                    correct
                    text
                    isSelected
                }
                isAnswered 
                durationSeconds
                seen
            }   
        }
    }
`;


