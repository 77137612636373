import { makeStyles, Theme } from "@material-ui/core";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import GenericButton from "../../../../common/components/GenericButton";
import { QUESTION_ANIMATE_IN_SECOND } from "../../../constants";
import { QuestionListQuestion } from "../../../types";

const animated = {
  start: {
    opacity: 0,
    scale: 0.5,
  },
  animation: {
    scale: [0.5, 1.0],
    opacity: [0, 1],
    transition: {
      times: [0, 1],
      default: { duration: QUESTION_ANIMATE_IN_SECOND },
    },
  },
};

interface Props {
  aflKicksGameQuestions: QuestionListQuestion[];
}

const QuizEndedBlock = ({ aflKicksGameQuestions }: Props) => {
  const classes = styles();
  const history = useHistory();
  const { contest_id: contestId } = useParams<{ contest_id: string }>();

  const aflKicksGameScore = useMemo(() => {
    if (!aflKicksGameQuestions.length) {
      return 0;
    }

    const selectedScoreOutcome = aflKicksGameQuestions[0].outcomes.find(
      (outcome) => outcome.text === "Score" && outcome.isSelected
    );

    return selectedScoreOutcome ? selectedScoreOutcome.minigameScore ?? 0 : 0;
  }, [aflKicksGameQuestions]);

  const onLeaderboardButtonClick = () => {
    history.push(`/${contestId}/leaderboard` + history.location.search);
  };

  return (
    <motion.div initial="start" animate="animation" variants={animated}>
      <div className={classes.container}>
        <div className={classes.title}>No More Questions</div>
        <div className={classes.description}>
          <span>
            You have scored{" "}
            <span className={classes.score}>{aflKicksGameScore} points</span>{" "}
            from all questions.
          </span>
          <br />
          View all results at the end of the game.
        </div>

        <div className={classes.buttonContainer}>
          <GenericButton onClick={onLeaderboardButtonClick} small width="150px">
            Leaderboard
          </GenericButton>
        </div>
      </div>
    </motion.div>
  );
};

const styles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    maxWidth: 400,
    margin: "0 auto",
    padding: "15px 15px 100px",
    position: "relative",
    textAlign: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    paddingBottom: 15,
    "@media only screen and (max-width: 450px)": {
      fontSize: 20,
    },
  },
  description: {
    fontSize: 16,
    "@media only screen and (max-width: 450px)": {
      fontSize: 14,
    },
  },
  score: {
    color: "#00ff87",
  },
  buttonContainer: {
    bottom: 25,
    left: 0,
    position: "absolute",
    right: 0,
  },
}));

export default QuizEndedBlock;
