import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {NRL_ENTAIN_ADMIN} from "../../apollo/nrl/queries/EntainAdmin";
import {useQuery} from "@apollo/client";
import {transformAdminContests} from "../../utils";
import ListComponent from "./ListComponent";

const styles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        fontSize: 18,
        paddingBottom: 30
    }
}))

interface Props {
}

const AflList = (props: Props) => {
    const classes = styles();
    const {data, loading, error} = useQuery(NRL_ENTAIN_ADMIN);

    if (data && !loading && !error) {
        const contestsList = transformAdminContests(data.nrl.previous)

        return <ListComponent contestsList={contestsList} sport='NRL'/>
    } else {
        return <div className={classes.title}> LOADING....</div>
    }
};

export default AflList;