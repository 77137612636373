import { makeStyles, Theme } from "@material-ui/core";
import ticketImage from '../../../assets/nba-ticket.png';
import winATripImage from '../../../assets/nba-finals-lockup.png';
import { useUser } from "../../../contexts/nba/User";
import { NBA_EXCLUDE_SA_USER_FROM_NATIONAL } from "../../../constants";

const NationalPromotionBanner = () => {
    const classes = styles();
    const { user } = useUser();

    if (!user) return null;
    if (user.fromSA && NBA_EXCLUDE_SA_USER_FROM_NATIONAL) return null;

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div>
                    <img className={classes.image} src={winATripImage} alt="win-a-trip" width={76} height={55} />
                </div>
                <div className={classes.textContainer}>
                    You have <img src={ticketImage} alt="ticket" /> <span className={classes.numOfTickets}>{user.nbaTickets} TICKET{ user.nbaTickets === 1 ? '' : 'S'}</span> into the draw!<br />
                    Play again to earn more tickets.
                </div>
            </div>
        </div>
    );
};

const styles = makeStyles((theme: Theme) => ({
    container: {
        background: '#0E0E12',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
        marginBottom: 10,
        padding: '12px 10px',
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        display: 'block',
        margin: '0 auto',
        '@media only screen and (max-width: 400px)': {
            height: '33px',
            width: '45px',
        }
    },
    textContainer: {
        fontSize: 14,
        fontWeight: 700,
        paddingLeft: 15,
        '@media only screen and (max-width: 400px)': {
            fontSize: 12,
        }
    },
    numOfTickets: {
        color: '#FFC700',
    },
}));

export default NationalPromotionBanner;
