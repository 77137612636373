import { CssBaseline } from "@material-ui/core";
import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "../../contexts/Auth";
import { TimeDriftProvider } from "../../contexts/TimeDrift";
import CustomThemeProvider from "../../themes/ThemeProvider";
import EntainAdmin from "./components/entain-admin";
import EntainAdminLeaderboard from "./components/entain-admin-leaderboard/Wrapper";
import Home from "./components/home/Home";
import Leaderboard from "./components/leaderboard/Wrapper";
import PreviousResults from "./components/previous-results";
import Question from "./components/question";
import Rules from "./components/rules";
import { ContestProvider } from "./contexts/Contest";
import { EntryProvider } from "./contexts/Entry";
import { GameStateProvider } from "./contexts/GameState";
import { QuestionManagerProvider } from "./contexts/Question";
import { UserProvider } from "./contexts/User";

function App() {
  const [prevPageForLeaderboard, setPrevPageForLeaderboard] = useState("");
  const [agreedRules, setAgreedRules] = useState(false);

  return (
    <Router>
      <CustomThemeProvider theme="aflTheme">
        <CssBaseline />
        <Switch>
          <Route path="/previous_results">
            <AuthProvider>
              <PreviousResults
                setPrevPageForLeaderboard={setPrevPageForLeaderboard}
              />
            </AuthProvider>
          </Route>

          <Route path="/:contest_id/question_page">
            <AuthProvider>
              <UserProvider>
                <TimeDriftProvider>
                  <EntryProvider>
                    <GameStateProvider>
                      <QuestionManagerProvider>
                        <ContestProvider>
                          <Question
                            setPrevPageForLeaderboard={setPrevPageForLeaderboard}
                          />
                        </ContestProvider>
                      </QuestionManagerProvider>
                    </GameStateProvider>
                  </EntryProvider>
                </TimeDriftProvider>
              </UserProvider>
            </AuthProvider>
          </Route>

          <Route path="/:contest_id/home">
            <AuthProvider>
              <UserProvider>
                <EntryProvider>
                  <Rules
                    setAgreedRules={setAgreedRules}
                    agreedRules={agreedRules}
                  />
                </EntryProvider>
              </UserProvider>
            </AuthProvider>
          </Route>

          <Route path="/:contest_id/leaderboard">
            <AuthProvider>
              <UserProvider>
                <GameStateProvider>
                  <EntryProvider>
                    <TimeDriftProvider>
                      <ContestProvider>
                        <Leaderboard
                          prevPageForLeaderboard={prevPageForLeaderboard}
                        />
                      </ContestProvider>
                    </TimeDriftProvider>
                  </EntryProvider>
                </GameStateProvider>
              </UserProvider>
            </AuthProvider>
          </Route>

          <Route path="/" exact>
            <AuthProvider>
              <UserProvider>
                <TimeDriftProvider>
                  <Home setPrevPageForLeaderboard={setPrevPageForLeaderboard} />
                </TimeDriftProvider>
              </UserProvider>
            </AuthProvider>
          </Route>

          <Route path="/entain-admin/:contest_id/leaderboard">
            <GameStateProvider>
              <EntainAdminLeaderboard />
            </GameStateProvider>
          </Route>

          <Route path="/entain-admin">
            <EntainAdmin />
          </Route>
        </Switch>
      </CustomThemeProvider>
    </Router>
  );
}

export default App;
