import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import tier1 from '../../../assets/tier1.png';
import tier2 from '../../../assets/tier2.png';
import tier3 from '../../../assets/tier3.png';
import tier4 from '../../../assets/tier4.png';
import tier5 from '../../../assets/tier5.png';
import classNames from "classnames";
import {usePrevious} from '../../../hooks';
import {useHistory} from "react-router";
import {useEntry} from "../../../contexts/cricket/Entry";
import AnimatedCell from '../../common/AnimatedCell';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '10px 15px',
        boxShadow: '0 3px 6px ' + theme.palette.primary.light,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: 16,

        overflow: 'visible',
        '@media only screen and (max-width: 400px)': {
            maxWidth: 400,
            margin: 'auto',
        },
        position: 'relative',
        zIndex: 20,
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    },
    moreHeight: {
        minHeight: 60,
    },
    lessHeight: {
        minHeight: 30,
    },

    rootInner: {
        maxWidth: 500,
        margin: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch'
    },
    topRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    name: {
        width: '100%',
        fontSize: 14,
        fontWeight: 400,
        paddingTop: 2
    },
    batting: {
        fontSize: 14,
        fontWeight: 700,
        cursor: 'pointer'
    },
    link: {
        fontSize: 14,
        fontWeight: 700,
        cursor: 'pointer'
    },
    bottomRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: -2
    },
    leftPart: {
        display: 'flex',
        overflow: 'visible'
    },
    rightPart: {
        display: 'flex',
        alignItems: 'center'
    },
    flex: {
        display: 'flex',
        paddingTop: 5,
        overflow: 'visible',
        alignItems: 'flex-end'
    },
    scoreTitle: {
        fontSize: 14,
        display: 'flex'
    },
    show: {
        animation: `$show 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    '@keyframes show': {
        '0%': {
            opacity: 0,
            transform: 'scale(1)',

        },
        '50%': {
            opacity: 1,
            transform: 'scale(1.7)',
        },
        '100%': {
            opacity: 1,
            transform: 'scale(1.1)',
        },
    },
    '@keyframes hide': {
        '0%': {
            opacity: 1,
            transform: 'scale(1.1)',

        },
        '100%': {
            opacity: 0,
            transform: 'scale(1)',
        },
    },
    hide: {
        animation: `$hide 0.2s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    currentPoints: {
        marginLeft: 5,
        fontWeight: 'bold',
        fontSize: 13,
        opacity: 0,
        width: 25
    },
    positive: {
        color: theme.palette.grey[100]
    },
    negative: {
        color: theme.palette.grey[200]
    },
    bold: {
        fontWeight: 'bold'
    },
    total: {
        minWidth: 25,
        textAlign: 'right',
        fontSize: 13,
        fontWeight: 700,
        paddingLeft: 5
    },
    starWrapper: {
        position: 'relative',
        marginLeft: 5,
        minWidth: 15,
        height: '100%'
    },
    ratingStar: {
        display: 'block',
        width: 25,
        height: 25
    },
    rating: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scale(1)',
        fontSize: 13,
        fontWeight: 800,
        textShadow: '0 3px 6px #000',
    },
    '@keyframes starAnimation': {
        '0%': {
            transform: 'scale(1)',

        },
        '50%': {
            transform: 'scale(1.7)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
    animation: {
        animation: `$starAnimation 0.4s cubic-bezier(.36,.07,.19,.97) forwards`,
    },
    username: {
        fontSize: 14
    },
    scorePic: {
        display: 'block',
        width: 20,
        height: 18,
        marginRight: 5
    },
    starPic: {
        display: 'block',
        width: 18,
        height: 18,
        marginRight: 5
    }
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void
    setOpenBatsmenList?: (l: boolean) => void
}

const TopBar = (props: Props) => {
    const classes = styles();
    const {entry} = useEntry();
    const {currentBatsman, score} = entry;
    const [showDifference, setShowDifference] = useState(false);
    const [savedDifference, setSavedDifference] = useState(0);
    let points = 0;
    let rank = 1;
    let difference: number | null = null;

    if (score) {
        rank = score.rank;
        points = score.points;
        difference = score.difference;
    }

    const [starToDisplay, setStarToDisplay] = useState<string | null>(tier1);
    const history = useHistory();
    const prevRank = usePrevious(rank);

    let timeout: any = null;

    useEffect(() => {
        return () => {
            clearTimeout(timeout)
        }
    }, []);


    useEffect(() => {
        if (difference && difference !== 0) {
            setShowDifference(true);
            setSavedDifference(difference)

            timeout = setTimeout(() => {
                setShowDifference(false);
            }, 2000);
        }
    }, [difference]);

    useEffect(() => {

        switch (true) {
            case (rank <= 2):
                setStarToDisplay(tier1);
                break;
            case (rank > 2 && rank <= 6):
                setStarToDisplay(tier2);
                break;
            case (rank > 6 && rank <= 12):
                setStarToDisplay(tier3);
                break;
            case (rank > 12 && rank <= 20):
                setStarToDisplay(tier4);
                break;
            case (rank > 20 && rank <= 99):
                setStarToDisplay(tier5);
                break;
            case (rank >= 100):
                setStarToDisplay(null);
                break;
        }

    }, [rank]);

    const pointsNode = (
        <>
             <span
                 className={classNames(classes.total)}>{points}</span>

            <span
                className={classNames(classes.currentPoints, savedDifference! > 0 ?
                    classes.positive : classes.negative,
                    showDifference ? classes.show : classes.hide)}>
                                        {difference! > 0 ? '+' + savedDifference : savedDifference}
                            </span>
        </>
    );

    const rankNode = (

        <AnimatedCell
            valueOne={prevRank}
            valueTwo={rank}
            isRankStar={true}
            classname={classNames(classes.rating)}
        >
            {rank}
        </AnimatedCell>
    );

    return (
        <div className={classNames(classes.root, classes.moreHeight)}>
            <div className={classes.rootInner}>

                <div className={classes.topRow}>

                    <div className={classes.batting} onClick={() => props.setOpenBatsmenList!(true)}>
                        Batting Lineup
                    </div>

                    <span className={classes.link} onClick={(e) => {
                        e.preventDefault();
                        props.setPrevPageForLeaderboard('question');

                        history.push(`leaderboard` + history.location.search)
                    }}>View Leaderboard</span>
                </div>

                {currentBatsman ?
                    <div className={classes.name}>
                        {currentBatsman.name}
                    </div>
                    : null
                }
                <div className={classes.bottomRow}>
                    <div className={classes.leftPart}>
                        <div className={classes.flex}>
                            <span className={classes.scoreTitle}>Score</span>
                            {pointsNode}
                        </div>

                    </div>

                    <div className={classes.rightPart}>
                        <span className={classes.scoreTitle}>Your Rank</span>
                        <div className={classNames(classes.starWrapper)}>
                            {starToDisplay ?
                                <img className={classes.ratingStar} src={starToDisplay} alt="star"/>
                                : null}

                            {rankNode}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TopBar;