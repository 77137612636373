import { AdminContestQuestionResult, LeaderboardEntry } from "../types";

export const differenceInUnits = (estimatedStartTime: number, diff: number) => {
  const now = new Date().getTime() + diff;
  const distance = estimatedStartTime - now;

  let days: string | number = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours: string | number = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes: string | number = Math.floor(
    (distance % (1000 * 60 * 60)) / (1000 * 60)
  );
  let seconds: string | number = Math.floor((distance % (1000 * 60)) / 1000);

  hours = hours >= 10 ? hours : "0" + hours;
  minutes = minutes >= 10 ? minutes : "0" + minutes;
  seconds = seconds >= 10 ? seconds : "0" + seconds;

  days = days === 0 ? "" : days + ":";
  hours = hours <= 0 ? "00:" : hours + ":";
  minutes = minutes <= 0 ? "00:" : minutes + ":";
  seconds = seconds <= 0 ? "00" : seconds;

  return {
    countdownTime: days + hours + minutes + seconds,
    distance: distance,
  };
};

export const calCountdownTime = (time: number, diff: number) => {
  const now = new Date().getTime() + diff;
  const distance = time - now;

  let days: string | number = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours: string | number = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes: string | number = Math.floor(
    (distance % (1000 * 60 * 60)) / (1000 * 60)
  );
  let seconds: string | number = Math.floor((distance % (1000 * 60)) / 1000);

  days = days === 0 ? "" : days + "d";
  hours = hours <= 0 ? "0h" : hours + "h";
  minutes = minutes <= 0 ? "0m" : minutes + "m";
  seconds = seconds <= 0 ? "0s" : seconds + "s";

  const result = [days, hours, minutes, seconds].filter(Boolean).join(" ");

  return { countdownTime: result, distance: distance };
};

export const getDateAndTime = (estimatedStartTime: number) => {
  const timeFromApi = estimatedStartTime;
  const a = new Date(timeFromApi);
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const month = months[a.getMonth()];
  const date = a.getDate();
  let hour = a.getHours();
  let min: string | number = a.getMinutes();
  const dow = days[a.getDay()];
  const ampm = hour >= 12 ? "PM" : "AM";
  min = min >= 10 ? min : "0" + min;
  hour = hour % 12;
  hour = hour ? hour : 12;

  return {
    startDate: `${dow} ${date}, ${month}`,
    startTime: `${hour}:${min} ${ampm}`,
  };
};

export const addSuffixToNumber = (n: number) => {
  if (n === 0) return "-";

  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const createArrayWithTiedField = (
  array: LeaderboardEntry[],
  type: "OVERALL" | "LADBROKES" = "LADBROKES"
) => {
  let newList: (LeaderboardEntry & { tied: boolean })[] = [];

  let isAnyTie = false;

  for (let i = 0; i < array.length; i++) {
    if (i !== array.length - 1) {
      if (array[i].points === array[i + 1].points) {
        if (!newList[i]) {
          newList.push({
            ...array[i],
            tied: true,
          });
        } else {
          newList[i].tied = true;
        }
        newList.push({
          ...array[i + 1],
          tied: true,
        });
        if (!isAnyTie) isAnyTie = true;
      } else {
        if (!newList[i]) {
          newList.push({
            ...array[i],
            tied: false,
          });
        }
        newList.push({
          ...array[i + 1],
          tied: false,
        });
      }
    } else {
      if (array.length === 1) {
        newList.push({
          ...array[0],
          tied: false,
        });
      }
    }
  }

  if (type === "OVERALL") {
    //@ts-ignore
    newList.sort((a, b) =>
      a.nationalRank > b.nationalRank
        ? 1
        : b.nationalRank > a.nationalRank
        ? -1
        : 0
    );
  } else {
    //@ts-ignore
    newList.sort((a, b) => (a.rank > b.rank ? 1 : b.rank > a.rank ? -1 : 0));
  }

  return { newList: newList, isAnyTie: isAnyTie };
};

export const transformAdminQuestions = (
  questions: AdminContestQuestionResult[]
) => {
  const questionsList: { [key: string]: [AdminContestQuestionResult] } = {};
  questions.forEach((q) => {
    const eachQuestion = {
      blockNumber: q.blockNumber,
      messages: q.messages,
      questionNumber: q.questionNumber,
      text: q.text,
      outcomes: q.outcomes,
    };

    if (!questionsList[q.blockNumber]) {
      questionsList[q.blockNumber] = [eachQuestion];
    } else {
      questionsList[q.blockNumber].push(eachQuestion);
    }
  });

  return questionsList;
};
