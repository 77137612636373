import { makeStyles } from '@material-ui/core';
import React, { SVGAttributes } from 'react';
import { formatting } from '../../utils/formatting';

export enum COMPETITION_NAME_OVERRIDES {
    'nba' = 'basketball-nba',
    'state-of-origin' = 'nrl-state-of-origin',
};

export enum TEAM_NAME_ICON_OVERRIDES {
    // NBA
    'los-angeles-clippers' = 'lla-clippers',
    'portland-trail-blazers' = 'portland-trailblazers',
    'sacramento-kings' = 'sacremento-kings',
    'philadelphia-76-ers' = 'philidelphia-76ers',
    'philidelphia-76-ers' = 'philidelphia-76ers',
    'minnesota-timberwolves' = 'minasota-timberwolves',
    // NRL
    'canterbury-bulldogs' = 'canterbury-bulldog',
    'st-george-illawara-dragons' = 'st-george-illawara',
    'gold-coast-titans' = 'gold-caost-titans',
    'north-queensland-cowboys' = 'north-qld-cowboys',
    'manly-sea-eagles' = 'manly-seaeagles',
    'nashville-predators' = 'nashville-predator',
    'queensland' = 'qld',
    'new-south-wales' = 'nsw',
    // AFL
    'collingwood' = 'collingwood-magpies',
    'port-adelaide' = 'port-adelaide-power',
    'st-kilda' = 'st-kilda-fc',
    'richmond' = 'richmond-tigers',
    'fremantle' = 'fremantle-dockers',
    'essendon' = 'essendon-bombers',
    'carlton' = 'carlton-fc',
    'melbourne' = 'melbourne-fc',
    'hawthorn' = 'hawthorn-hawks',
    'north-melbourne' = 'north-melbourne-fc',
};

export type CompetitionName = 'AFL' | 'NBA' | 'NRL' | 'State Of Origin';

type SportsTeamIconProps = {
    border?: number;
    competitionName: CompetitionName;
    height?: number;
    teamName: string;
    width?: number;
} & SVGAttributes<SVGElement>;

export const SportsTeamIcon = ({ border = 0, competitionName, height, teamName, width, ...rest }: SportsTeamIconProps) => {
    const classes = styles();
    const competitionNameSlug = formatting.SLUG(competitionName);
    const competitionNameId = (COMPETITION_NAME_OVERRIDES as any)[competitionNameSlug] as string || competitionNameSlug;
    const teamNameSlug = formatting.SLUG(teamName);
    const teamNameId = (TEAM_NAME_ICON_OVERRIDES as any)[teamNameSlug] as string || teamNameSlug;
    // We will refactor as more brand is needed.
    const brand = 'ladbrokes';

    return (
        <svg {...rest} id={teamNameId} className={classes.svg} style={{ borderWidth: border, flexBasis: width }} width={width} height={height} version="1.1">
            <use xlinkHref={`/entain/images/${brand}/sprites/sports-${competitionNameId}.svg#${teamNameId}`} />
        </svg>
    );
};

const styles = makeStyles(() => ({
    svg: {
        backgroundColor: '#232323',
        border: 'solid #FFFFFF',
        borderRadius: '100%',
        flexGrow: 0,
        flexShrink: 0,
    },
}));