import { gql} from '@apollo/client';

export const ANSWER_QUESTION = gql`
  mutation answerQuestion($entryId: Int!, $outcomeId: Int!) {
    selectOutcome(entryId: $entryId, outcomeId: $outcomeId) {
      success
      errorMessage
    }
  }
`;

