import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import {differenceInUnits} from "../../../utils";
import lock from '../../../assets/lock.png'
import greenLck from '../../../assets/Green_Lock.png'
import classNames from "classnames";
import {useTimeDrift} from "../../../contexts/TimeDrift";


const styles = makeStyles((theme: Theme) => ({
    wrap: {
        paddingTop: 10,
        position: 'relative',
        zIndex: 20,
        fontWeight: 700,
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '& > img': {
            marginTop: 2,
            '@media only screen and (max-width: 1024px)': {
                width: 15,
            },
            '@media only screen and (min-width: 1025px)': {
                width: 20
            },
        }
    },
    biggerText:{
        fontSize: 14,
        paddingTop: 0,
        '& > $wrapperWidth' :{
            width: 70
        }
    },
    wrapperWidth:{
        width: 50,
    },
    green:{
        color: theme.palette.grey[500]
    }
}));

interface Props {
    estimatedStartTime: number
    onTop: boolean
    color: number
}

const Countdown = (props: Props) => {
    const classes = styles();
    const {estimatedStartTime, onTop, color} = props;
    let countdown: any = null;
    const diff = useTimeDrift();

    const [countdownToDisplay, setCountdownToDisplay] = useState('00:00:00');

    useEffect(() => {
        return () => {
            clearInterval(countdown);
            setCountdownToDisplay('00:00:00');
        }
    }, []);

    useEffect(() => {
        const now = new Date().getTime() + diff;

        if (estimatedStartTime - now > 100) {
            countdown = setInterval(function () {
                const getCountdownUnits = differenceInUnits(estimatedStartTime, diff);

                setCountdownToDisplay(`${getCountdownUnits.countdownTime}`);

                if (getCountdownUnits.distance <= 0) {
                    setCountdownToDisplay('00:00:00');
                    clearInterval(countdown);
                }
            }, 1000);
        }

        return() => {
            setCountdownToDisplay('00:00:00');
        }

    }, [estimatedStartTime]);

    return (
        <div className={classNames(classes.wrap, onTop ? classes.biggerText : null)}>
            {!onTop &&  <img src={color ? greenLck : lock} alt="lock"/>}
            <span className={classNames(classes.wrapperWidth, color ? classes.green : '')}> {countdownToDisplay}</span>
        </div>
    )
};

export default Countdown;