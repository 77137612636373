import { makeStyles, Theme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ResultQuestionOutcome } from "../../../types";
import TeamIconFormatter from "../../common/TeamIconFormatter";
import QuestionButton from "./QuestionButton";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflow: "visible",
  },
  divider: {
    width: "100%",
    height: 1,
    margin: "10px 0",
    background: theme.palette.grey[300],
  },
  questionWrapper: {
    overflow: "visible",
    alignItems: "flex-end",
    "& > div": {
      overflow: "visible",
    },
  },
  titleLine: {
    display: "flex",
    width: "100%",
    overflow: "visible",
    paddingBottom: 5,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.grey[300],
  },
  questionText: {
    fontSize: 12,
    fontWeight: 700,
    textAlign: "left",
    paddingBottom: 5,
    paddingRight: 50,
  },
  buttonsWrapper: {
    width: "100%",
    overflow: "visible",
    "& > $button:first-child": {
      marginBottom: 10,
    },
  },
  positivePoints: {
    fontSize: 14,
    fontWeight: 900,
    color: "#00FF87",
    textAlign: "right",
    paddingBottom: 1,
    paddingLeft: 10,
  },
}));

interface Props {
  isCorrect: boolean | null | undefined;
  isPending: boolean;
  pos: number;
  questionText: string;
  outcomes: ResultQuestionOutcome[];
  notSelected: boolean;
  points: number;
}

const EachQuestion = (props: Props) => {
  const classes = styles();
  const {
    isCorrect,
    isPending,
    pos,
    questionText,
    outcomes,
    notSelected,
    points,
  } = props;

  const [percentage, setPercentage] = useState<number[]>([]);

  useEffect(() => {
    setPercentage([]);
    return () => {
      setPercentage([]);
    };
  }, []);

  useEffect(() => {
    if (outcomes) {
      const sum = outcomes.reduce(
        (sum: number, cur) => sum + (cur.count ?? 0),
        0
      );

      const arrayOfPercentages = outcomes.map((o) => {
        if (o.count) {
          return Math.round(Number((o.count / sum) * 100));
        } else {
          return 0;
        }
      });

      setPercentage(arrayOfPercentages);
    }
  }, [outcomes]);

  return (
    <div className={classes.root}>
      <div className={classes.questionWrapper}>
        <div>
          <div className={classes.titleLine}>
            <span className={classes.title}>Question {pos}</span>

            {isCorrect !== null && isCorrect && (
              <span className={classes.positivePoints}>+ {points}</span>
            )}
          </div>

          <div className={classes.questionText}>
            <TeamIconFormatter displayText={questionText} size={20} />
          </div>

          <div className={classes.buttonsWrapper}>
            {outcomes.map((outcome, idx) => (
              <QuestionButton
                key={idx}
                percentage={percentage}
                buttonId={idx}
                selected={outcome.isSelected}
                isPending={isPending}
                isCorrect={isCorrect}
                notSelected={notSelected}
                correctOutcome={outcome.correct}
              >
                <TeamIconFormatter
                  displayText={outcome.text}
                  size={isPending ? 20 : 10}
                />
              </QuestionButton>
            ))}
          </div>
        </div>
        <div className={classes.divider} />
      </div>
    </div>
  );
};

export default EachQuestion;
