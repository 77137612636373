export type Team = {
  iconName: string; // map to symbol id in /public/entain/images/ladbrokes/sprites sports-{competition-name}.svg
  code: string;
};

export const TEAM_MAP: { [key: string]: Team } = {
  "25509": { iconName: "adelaide-crows", code: "ADE" },
  "25510": { iconName: "brisbane-lions", code: "BRI" },
  "25511": { iconName: "carlton-fc", code: "CAR" },
  "25512": { iconName: "collingwood-magpies", code: "COL" },
  "25513": { iconName: "essendon-bombers", code: "ESS" },
  "25514": { iconName: "fremantle-dockers", code: "FRE" },
  "25515": { iconName: "gold-coast-suns", code: "GC" },
  "25516": { iconName: "geelong-cats", code: "GEE" },
  "25517": { iconName: "hawthorn-hawks", code: "HAW" },
  "25518": { iconName: "melbourne-fc", code: "MEL" },
  "25519": { iconName: "north-melbourne-fc", code: "NM" },
  "25520": { iconName: "port-adelaide-power", code: "PA" },
  "25521": { iconName: "richmond-tigers", code: "RIC" },
  "25522": { iconName: "st-kilda-fc", code: "STK" },
  "25523": { iconName: "sydney-swans", code: "SYD" },
  "25524": { iconName: "west-coast-eagles", code: "WCE" },
  "25525": { iconName: "western-bulldogs", code: "WBD" },
  "25526": { iconName: "gws-giants", code: "GWS" },
};
