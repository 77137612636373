import { gql} from '@apollo/client';

export const GENERATE_AUTH = gql`
    mutation generateAuth($externalId: String!, $username: String!, $nobonus: Boolean!, $national: Boolean!, $fromSA: Boolean!) {
        generateAuth(externalId: $externalId, username: $username, nobonus: $nobonus, national: $national, fromSA: $fromSA) {
            username
            token
            national
            fromSA
        }
    }
`;

