import { makeStyles, Theme } from "@material-ui/core";
import LoadingScreenSpinner from "../common/LoadingScreenSpinner";

const styles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: "center",
    fontSize: 26,
    fontWeight: 700,
    animation: `$fade 5s linear`,
  },
  "@keyframes fade": {
    "0%": {
      opacity: 0,
    },
    "70%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));
const NoQuestions = () => {
  const classes = styles();

  return (
    <>
      <div className={classes.title}>The game is about to begin.</div>
      <LoadingScreenSpinner />
    </>
  );
};

export default NoQuestions;
