import { makeStyles } from '@material-ui/core'
import {AnimatePresence, motion} from 'framer-motion';
import React, {useMemo} from 'react';
import { MARKET_NAME } from '../../constants';
import { SportingEvent, SportingPopularSameGameMultiSelection } from '../../types';
import { removeTeamName } from '../../utils/x-pack';
import { Icon } from '../core/Icon';
import { SportsTeamIcon } from '../core/SportsTeamIcon';

type SportsPSGMCardSelectionListProps = {
    event: SportingEvent;
    selections: SportingPopularSameGameMultiSelection[];
};

export const SportsPSGMCardSelectionList = ({ event, selections }: SportsPSGMCardSelectionListProps) => {
    const classes = styles();

    const renderSelection = ({ entrant, market }: SportingPopularSameGameMultiSelection) => {
        if (!entrant || !market) return null;

        const entrantName = removeTeamName(entrant.name);
        const entrantHandicap = entrant.handicap;
        const formattedEntrantHandicap = entrantHandicap && market.name === MARKET_NAME.LINE
            ? `(${ entrantHandicap > 0 ? '+' : '' }${entrantHandicap})`
            : entrantHandicap ?? null;
        const competitionName = event.competition?.name;
        const teamName = (event.teams?.find(({ name }) => entrantName.includes(name)) ?? {})?.name ?? '';

        let icon;
        if (competitionName && teamName) {
            icon = <SportsTeamIcon competitionName={competitionName} teamName={teamName} width={16} height={16} />;
        } else {
            const iconId = event.category.toLowerCase().replace('_', '-');
            icon = <Icon id={iconId} type="sports" width={16} height={16} />
        }

        return (
            <motion.div
                className={classes.selection}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.3}}
                key={`entrant.id}-${market.id}`}
                >
                <div className={classes.entrant}>
                    {icon}
                    <span className={classes.entrantText}>{entrantName} {formattedEntrantHandicap}</span>
                </div>
                <div className={classes.market}>{market.name}</div>
            </motion.div>
        );
    };

    return <AnimatePresence initial={false}>{selections.map(renderSelection)}</AnimatePresence>;
};

const styles = makeStyles(() => ({
    selection: {
        display: 'flex',
        gap: 10,
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    entrant: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
    },
    entrantText: {
        fontSize: 12,
        fontWeight: 600,
        marginLeft: 5,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    market: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
        overflow: 'hidden',
        textAlign: 'right',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));