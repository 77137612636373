import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import notify from '../../../assets/notify.png';
import { useMutation } from '@apollo/client';
import { AFL_NOTIFY_ME } from '../../../apollo/afl/mutations/NotifyMe';
import { NRL_NOTIFY_ME } from '../../../apollo/nrl/mutations/NotifyMe';
import { AFL_NOTIFY_ME_FOR_CONTEST } from '../../../apollo/afl/mutations/NotifyMeForContest';
import { NRL_NOTIFY_ME_FOR_CONTEST } from '../../../apollo/nrl/mutations/NotifyMeForContest';
import { useTracking } from '../../../entain/hooks/useTracking';

const styles = makeStyles((theme: Theme) => ({
    button: {
        background: theme.palette.info.light,
        minWidth: 100,
        fontSize: 12,
        height: 30,
        fontWeight: 700,
        textAlign: 'center',
        margin: '40px auto auto',
        position: 'relative',
        cursor: 'pointer',
        paddingLeft: 25,
        paddingRight: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 0,
        borderRadius: 5,
    },
    notify: {
        display: 'block',
        width: 20,
        height: 22,
        position: 'absolute',
        left: 3,
    },
}));

interface Props {
    click: () => void;
    blockId: number;
    blockNumber: number;
    isListPage: boolean;
    contestId?: number;
}

const NotifyMeButton = (props: Props) => {
    const classes = styles();
    const mutation = process.env.REACT_APP_BUILD_TARGET === 'nrl' ? NRL_NOTIFY_ME : AFL_NOTIFY_ME;
    const notifyForContestMutation =
        process.env.REACT_APP_BUILD_TARGET === 'nrl' ? NRL_NOTIFY_ME_FOR_CONTEST : AFL_NOTIFY_ME_FOR_CONTEST;
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const tracking = useTracking();

    const [notifyMe, { data, loading, error }] = useMutation(mutation);
    const [notifyMeForContest, { data: notifyData, loading: notifyLoading, error: notifyError }] =
        useMutation(notifyForContestMutation);

    let text = 'Notify Me';
    if (process.env.REACT_APP_BUILD_TARGET === 'nrl') {
        if (props.blockNumber > 2) {
            text = 'Notify Me Next Game';
        }
    } else if (process.env.REACT_APP_BUILD_TARGET === 'afl') {
        if (props.blockNumber > 4) {
            text = 'Notify Me Next Game';
        }
    }

    useEffect(() => {
        const e = error?.message || notifyError?.message;
        if (e) {
            tracking.event('notify clicked error', {
                event_label: props.contestId,
                contestId: props.contestId,
                blockId: props.blockId,
                blockNumber: props.blockNumber,
                sport: process.env.REACT_APP_BUILD_TARGET,
                error: e,
            });
        }
    }, [error?.message, notifyError?.message]);

    const onClick = useCallback(() => {
        if (!buttonWasClicked) {
            setButtonWasClicked(true);

            if (props.isListPage) {
                notifyMeForContest({ variables: { contestId: props.contestId! } });
            } else {
                notifyMe({ variables: { blockId: props.blockId, blockNumber: props.blockNumber } });
            }

            tracking.event('notify clicked', {
                event_label: props.contestId,
                contestId: props.contestId,
                blockId: props.blockId,
                blockNumber: props.blockNumber,
                sport: process.env.REACT_APP_BUILD_TARGET,
            });
            props.click();
        }
    }, [buttonWasClicked, props.isListPage, props.click]);

    return (
        <div className={classes.button} onClick={onClick}>
            <img src={notify} alt="bell" className={classes.notify} />
            {text}
        </div>
    );
};

export default NotifyMeButton;
