import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import classNames from "classnames";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        borderRadius: 5,
        padding: '10px 20px',
       display: 'flex',
        fontSize: 24,
        fontWeight: 700,
        alignItems: 'center',
        background: theme.palette.info.main,
        position: 'relative',
        height: 138,
        textAlign: 'center'
    },
    wrapper: {
        paddingBottom: 10,
        '@media only screen and (min-width: 1025px)': {
            paddingBottom: 40
        },
        '@media only screen and (min-width: 1025px) and (max-height: 700px)': {
            paddingBottom: 10
        }
    },
}));

const NoContests = () => {
    const classes = styles();

    return(
        <div className={classNames(classes.wrapper)}>
            <div className={classes.root}>
                There are no games scheduled today. Come back tomorrow.
            </div>
      </div>
    )

};

export default NoContests;