const prevStyles = {
  width: 10,
  height: 10,
  borderBottom: "1px solid white",
  borderRight: "1px solid white",
  transform: "rotate(135deg)",
  display: "block",
  marginLeft: 2,
  marginTop: -8,
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, ...prevStyles }}
      onClick={onClick}
    />
  );
};

export default PrevArrow;
