import { makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import { SportingPopularSameGameMulti, SportingPopularSameGameMultiPrice } from '../../types';
import { useTimeout } from '../../hooks/useTimeout';
import { colors } from '../../theme/ladbrokes';
import { OutgoingMessageTopic, postMessage } from '../../utils/react-native-bridge';
import { useTracking } from '../../hooks/useTracking';

type SportsPSGMCardAddButtonProps = {
    psgm: SportingPopularSameGameMulti;
    competitionName: string;
    psgmPrice: SportingPopularSameGameMultiPrice | undefined;
};

const LOADING_TIMEOUT_SECONDS = 10 * 1000;

export const SportsPSGMCardAddButton = ({ psgm, psgmPrice, competitionName }: SportsPSGMCardAddButtonProps) => {
    const classes = styles();
    const [isLoading, setIsLoading] = useState(false);
    const [timeoutDelay, setTimeoutDelay] = useState<number | null>(null);
    const tracking = useTracking();

    const loadingTimeout = useCallback(() => {
        setIsLoading(false);
        setTimeoutDelay(null);
    }, []);

    useTimeout(loadingTimeout, timeoutDelay);

    const onAddToBetslip = useCallback(() => {
        setIsLoading(true);
        setTimeoutDelay(LOADING_TIMEOUT_SECONDS);
        console.log('onAddToBetslip', competitionName);
        postMessage({
            topic: OutgoingMessageTopic.AddPSGMToBetslip,
            data: { psgm, competitionName },
        });
        tracking.event('psgm sent to betslip', {
            psgmNumerator: psgm?.price?.odds?.numerator,
            psgmDenominator: psgm?.price?.odds?.denominator,
            price: psgmPrice?.price,
            eventId: psgm?.event?.id,
            eventCategory: psgm?.event?.category,
        });
    }, [psgm?.id ?? '', psgmPrice?.id ?? '', competitionName]);

    const className = useMemo(
        () => (isLoading ? `${classes.button} ${classes.isLoading}` : classes.button),
        [isLoading]
    );

    return (
        <button className={className} onClick={onAddToBetslip} disabled={isLoading}>
            {isLoading ? (
                <div className={classes.spinnerContainer}>
                    <Spinner color={colors.white} size={13} />
                </div>
            ) : (
                <>
                    Add {competitionName} to Betslip @ <strong>{psgmPrice?.price ?? 'Unknown'}</strong>
                </>
            )}
        </button>
    );
};

const styles = makeStyles(() => ({
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        background: colors.aliceBlue,
        border: `1px solid ${colors.priceButtonBorderColor}`,
        borderRadius: 4,
        boxShadow: `0px 2px 0px ${colors.priceButtonShadowColor}`,
        color: colors.btnPrimaryBase,
        cursor: 'pointer',
        fontSize: 13,
        fontWeight: 400,
        outline: 'none',
        padding: '8px 10px',
        width: 200,
        transition: 'background-color 0.3s, border-color 0.3s, shadow-color 0.3s, color 0.3s',
    },
    isLoading: {
        backgroundColor: colors.btnSecondaryHover,
        borderColor: colors.btnSecondaryHover,
        shadowColor: colors.priceButtonSelectedShadowColor,
        color: colors.white,
    },
}));
