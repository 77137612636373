import { gql} from '@apollo/client';

export const SEEN_QUESTION_AFL = gql`
  mutation seenQuestion($entryId: Int!, $questionId: Int!, $timestamp: String!) {
    afl {
       seen(entryId: $entryId, questionId: $questionId, timestamp: $timestamp) {
            success
            errorMessage
        }
    }
  }
`;

