declare global {
    interface DocumentEventMap {
        message: MessageEvent<string>;
    }

    interface Window {
        ReactNativeWebView?: {
            postMessage?: (message: string) => void;
        };
    }
}

export enum IncomingMessageTopic {
    FetchPSGMs = 'entain:fetchPSGMs',
    FetchConfig = 'entain:fetchConfig',
}

export type IncomingMessage = {
    topic: IncomingMessageTopic;
    data?: Record<string, unknown>;
    error?: string;
};

export enum OutgoingMessageTopic {
    AddPSGMToBetslip = 'pickfans:addPSGMToBetslip',
    FetchPSGMs = 'pickfans:fetchPSGMs',
    FetchConfig = 'pickfans:fetchConfig',
    OpenGroupMode = 'pickfans:openGroupMode',
    Tracking = 'pickfans:tracking',
}

export type OutgoingMessage<T = Record<string, unknown>> = {
    topic: OutgoingMessageTopic;
    data?: T;
    error?: string;
};

export const postMessage = <T = Record<string, unknown>>(message: OutgoingMessage<T>) => {
    if (!window.ReactNativeWebView?.postMessage) {
        console.error('window.ReactNativeWebView.postMessage is not available');
        return;
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

/**
 * Add listener for "message" event dispatched by React Native, note that the listener will only be added
 * when "window.ReactNativeWebView" exist (available when the web app is launched in React Native WebView).
 *
 * @param topic
 * @param messageListener
 * @returns function to remove the added listener when launch in React Native WebView, return undefined otherwise.
 */
export const addMessageListener = <T>(
    topic: IncomingMessageTopic,
    messageListener: (data: T, error?: string) => void
) => {
    if (!window.ReactNativeWebView) {
        return;
    }

    const eventListener = (event: MessageEvent<string>) => {
        let message: IncomingMessage;

        try {
            message = JSON.parse(event.data);
        } catch {
            console.error('Fail to parse message event data');
            return;
        }

        const isValid =
            typeof message.topic === 'string' &&
            (typeof message.error === 'string' || typeof message.data === 'object');
        if (!isValid) return;

        if (message.topic === topic && message.error) {
            console.error(`${topic} error: ${message.error}`);
            messageListener(message.data as T, message.error);
            return;
        }

        if (message.topic === topic) {
            messageListener(message.data as T);
        }
    };

    /**
     * https://github.com/react-native-webview/react-native-webview/issues/356#issuecomment-467430141
     *
     * Depanding on the platform, RN WebView dispatch message event using either `window` or `document`.
     */
    const isAndroid = /android/i.test(navigator.userAgent);

    isAndroid ? document.addEventListener('message', eventListener) : window.addEventListener('message', eventListener);

    return () => {
        isAndroid
            ? document.removeEventListener('message', eventListener)
            : window.removeEventListener('message', eventListener);
    };
};
