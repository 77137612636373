import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { useCountDown } from '../../hooks/useCountDown';
import { SportingEvent } from '../../types';
import { Icon } from '../core/Icon';
import { SportsTeamIcon } from '../core/SportsTeamIcon';

type SportsPSGMCardHeaderProps = {
    event: SportingEvent;
}

export const SportsPSGMCardHeader = ({ event }: SportsPSGMCardHeaderProps) => {
    const classes = styles();
    const iconId = event.category.toLowerCase().replace('_', '-');
    const advertisedStart = new Date(event.advertisedStart);
    const formatedAdvertisedStart = format(advertisedStart, 'd LLL h:mmaaa');
    const { display } = useCountDown(event.advertisedStart);

    const competitionName = event.competition?.name;
    const { name: homeTeamName } = event.teams?.find(team => team.locale === 'HOME') ?? { name: '' };
    const { name: awayTeamName } = event.teams?.find(team => team.locale === 'AWAY') ?? { name: '' };

    return (
        <div className={classes.mainContainer}>
            <div className={classes.dateInfoContainer}>
                <div className={classes.start}>
                    <Icon id={iconId} type="sports" color="#FFF" width={14} height={14} />
                    <span className={classes.startText}>{formatedAdvertisedStart}</span>
                </div>
                <div className={classes.countDown}>{display}</div>
            </div>
            <div className={classes.teamsInfoContainer}>
                { !!competitionName && <SportsTeamIcon competitionName={competitionName} teamName={homeTeamName} width={34} height={34} border={2} /> }
                <div className={classes.title}>
                    {homeTeamName}
                    { homeTeamName && awayTeamName ? <> vs<br /></> : null }
                    {awayTeamName}
                </div>
                { !!competitionName && <SportsTeamIcon competitionName={competitionName} teamName={awayTeamName} width={34} height={34} border={2} /> }
            </div>
        </div>
    );
};

const styles = makeStyles(() => ({
    mainContainer: {
        background: 'linear-gradient(105.06deg, #8E1329 0%, #25518A 100%)',
        padding: '10px 10px 20px',
    },
    dateInfoContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 17
    },
    start: {
        alignItems: 'center',
        display: 'flex',
    },
    startText: {
        fontSize: 11,
        fontWeight: 400,
        marginLeft: 5,
    },
    countDown: {
        backgroundColor: '#232323',
        borderRadius: 5,
        fontSize: 11,
        fontWeight: 600,
        padding: '2px 4px',
    },
    teamsInfoContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
        textAlign: 'center',
    },
}));