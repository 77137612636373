import React, {useState} from 'react';
import classNames from "classnames";
import {makeStyles, Theme} from "@material-ui/core";
import fireball from '../../../../../../assets/animation/Fireball.gif';

const styles = makeStyles<Theme, Props>(theme => ({
    fireball: {
        width: 40,
        position: 'absolute',
        top: -25,
        zIndex: 2,
    },
}));

interface Props {
    seconds: number
    addClass: boolean
    width: string
}

const FireballImage = (props: Props) => {
    const classes = styles(props);
    const {seconds, addClass, width} = props;

    let [kick, setKick] = useState(true)

    setTimeout(() => {
        setKick(false)
    }, 500)

    return (
        <img src={fireball} alt="fireball"
             className={classNames(classes.fireball)}


             style={{
                 transition: 'left ' + seconds + 's linear',
                 left: kick ? `calc(${width} - 20px)` : 'calc(0% - 20px)'
            }}
        />
    )
};

export default FireballImage;