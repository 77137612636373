import { makeStyles, Theme } from "@material-ui/core";
import notify from "../../assets/notify.png";

const styles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    fontWeight: 700,
    fontSize: 12,
    height: 36,
    alignItems: "center",
  },
  notify: {
    display: "block",
    width: 20,
    height: 22,
    marginRight: 5,
  },
}));

const NotifiedText = () => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <img src={notify} alt="bell" className={classes.notify} />
      You will be notified
    </div>
  );
};

export default NotifiedText;
