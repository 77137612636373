import React, {useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import lockout from '../../assets/sadface.png';
import titleImg from "../../assets/CC-Logo.png";
import Button from "../common/UI/SquareButton";
import {useHistory} from "react-router";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.contrastText
    },
    inner: {},
    titleImg: {
        margin: 'auto',
        display: 'block',
        '@media only screen and (max-width: 1024px)': {
            width: 250
        },
        '@media only screen and (min-width: 1025px)': {
            width: '80%',
            maxWidth: 515,
            paddingBottom: 20,
            display: 'none'
        }
    },
    lockout: {
        display: 'block',
        width: 180,
        height: 180,
        margin: '20px auto',
        '@media only screen and (max-width: 1024px)': {
            width: 170,
            height: 170,
        }
    },
    title: {
        fontSize: 30,
        fontWeight: 800,
        textAlign: 'center',
        paddingTop: 20
    },
    text: {
        width: '70%',
        margin: 'auto',
        padding: '20px 0 0',
        textAlign: 'center',
        fontSize: 18,
        '@media only screen and (max-width: 1024px)': {
            width: '100%',
        }
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30
    }
}));


const Index = () => {
    const classes = styles();
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const history = useHistory();

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <img src={titleImg} className={classes.titleImg} alt="Ladbrokes Couch Cricket"/>
                <img src={lockout} alt="lockout" className={classes.lockout}/>

                <div className={classes.title}>You're too late!</div>

                <div className={classes.text}>Unfortunately you are too late for today's contest. Check out the next
                    contests available for your chance to win bonus cash.
                </div>

                <div className={classes.buttonWrapper}>
                    <Button width='162px' buttonWasClicked={buttonWasClicked}
                            onClick={(e: any) => {
                                if (!buttonWasClicked) {
                                    setButtonWasClicked(true);
                                    e.preventDefault();

                                    history.push(`/` + history.location.search)
                                }
                            }}>
                        Home
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default Index;