import classNames from "classnames";
import React from "react";
import {makeStyles, Theme} from "@material-ui/core";
import {useParams} from "react-router-dom";
import DesktopQuestionsView from './live/DesktopQuestionsView';
import BatsmenList from './live/BatsmenList';
import {useMediaQuery} from "react-responsive";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        backgroundImage: `url('/Background-questions.jpg')`,
        boxSizing: 'border-box',
        backgroundSize: 'cover',
        '@media only screen and (max-width: 400px)': {
            maxWidth: 400,
            margin: 'auto',
        },
        '@media only screen and (min-width: 1025px)': {
            background: `url('/BACKGROUND_DESKTOP.png')`,
            backgroundSize: 'cover',
            height: '100%'
        },
        '@media only screen and (max-height: 401px)': {
            minHeight: '100vh',
            paddingBottom: 30
        },
        '@media only screen and (min-height: 402px)': {
            height: '100vh',
        }
    },
    rootInner: {
        margin: 'auto',
        overflow: 'visible'
    },
    mobile: {
        '@media only screen and (min-width: 1025px)': {
            display: 'none'
        }
    }
}));

interface Props {
    children: React.ReactNode
    openBatsmenList: boolean
    setOpenBatsmenList: (o: boolean) => void
}

const GameWrapper = (props: Props) => {
    const classes = styles();
    const {
        children, openBatsmenList, setOpenBatsmenList
    } = props;

    const {contest_id} = useParams<{ contest_id: string }>();

    const isDesktop = useMediaQuery({
        query: '(min-width: 1025px)'
    });

    return (
        <div className={classNames(classes.root)}>
            {!isDesktop ?
                openBatsmenList ?
                    <BatsmenList setOpenBatsmenList={setOpenBatsmenList}/>
                    :
                    <>
                        <div className={classes.rootInner}>
                            {children}
                        </div>
                    </>
                :
                <DesktopQuestionsView>
                    {children}
                </DesktopQuestionsView>
            }
        </div>
    )
};

export default GameWrapper;

