import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import { useEntry } from '../../../../contexts/nba/Entry';
import { NbaQuestion, NbaOutcome } from '../../../../../../shared/types';
import OutcomeButton from './OutcomeButton';
import { ANSWER_QUESTION_NBA } from '../../../../apollo/nba/mutations/AnswerQuestion';
import { useQuestion } from '../../../../contexts/nba/Question';
import { useTimeDrift } from '../../../../contexts/TimeDrift';
import TeamIconFormatter from '../../../common/nba/TeamIconFormatter';

const styles = makeStyles((theme: Theme) => ({
    flexRoot: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 5,
        flexDirection: 'column',
        overflow: 'visible',
        position: 'relative',
        paddingTop: 10
    },
}));

interface Props {
    question: NbaQuestion
    timeExpired: boolean
    setButtonWasClicked: ((b: boolean) => void) | undefined
    setLessThanTenSecs: ((l: boolean) => void) | undefined
    setTimeExpired: (t: boolean) => void
    clickedButton: number
    setClickedButton: (b: number) => void
    setOpenSnackbar: (s: boolean) => void
    trackAnswer: (outcome: NbaOutcome, timestamp: string, err?: unknown) => void
}

const Outcomes = (props: Props) => {
    const classes = styles();
    const {
        question,
        timeExpired,
        setButtonWasClicked,
        setLessThanTenSecs,
        setTimeExpired,
        clickedButton,
        setClickedButton,
        setOpenSnackbar,
        trackAnswer
    } = props;

    const {entry} = useEntry();
    const {questionWasAnswered} = useQuestion();
    const mutation = ANSWER_QUESTION_NBA;
    const diff = useTimeDrift();

    const [errors, setError] = useState()

    const [answerQuestion, {data, loading, error}] = useMutation(mutation, {context: {timeout: 5000}}); //5 secs timeout for answer mutation, then cancel it


    useEffect(() => {
        if (!loading && !error && data) {
            // questionWasAnswered(question.id)
        } else if (!loading && error) {
            setOpenSnackbar(true)
            setButtonWasClicked && setButtonWasClicked(false)
            setClickedButton(-1);
        }
    }, [loading, data, error]);


    useEffect(() => {
        setOpenSnackbar(false)
        return () => {
            setButtonWasClicked && setButtonWasClicked(false)
            setLessThanTenSecs && setLessThanTenSecs(false);
            setTimeExpired(false);
            setOpenSnackbar(false)
        }
    }, []);

    const onClickGenerator = useCallback((outcome: NbaOutcome, outcomeIdx: number) => {
        return async () => {
            // if (!buttonWasClicked && !timeExpired && !question.isAnswered) { //TODO TIME EXPIRED BROKEN FOR NOW
            if (clickedButton === -1 && !question.isAnswered) {
                setButtonWasClicked && setButtonWasClicked(true)
                setClickedButton(outcomeIdx);
                const dateWithDiff = new Date().getTime() + diff;
                const timestamp = new Date(dateWithDiff).toISOString();

                await answerQuestion({variables: {entryId: entry.id, outcomeId: outcome.id, timestamp: timestamp}})
                    .then((data) => {
                        const path = data.data.nba.selectOutcome;

                        if (path.success && !path.errorMessage) {
                            questionWasAnswered(question.id)
                            trackAnswer(outcome, timestamp);
                        } else {
                            setOpenSnackbar(true)
                            setButtonWasClicked && setButtonWasClicked(false)
                            setClickedButton(-1);
                        }
                    })
                    .catch((e) => {
                        setOpenSnackbar(true)
                        setButtonWasClicked && setButtonWasClicked(false)
                        setClickedButton(-1);
                        setError(e)
                        trackAnswer(outcome, timestamp, e);
                    });
            }
        }
    }, [clickedButton, question.isAnswered, setButtonWasClicked, setClickedButton, entry.id, setOpenSnackbar, setError, questionWasAnswered]);


    return (
        <div className={classes.flexRoot}>
            {question.outcomes.map((outcome: NbaOutcome, idx: number) => {
                return (
                    <OutcomeButton
                        key={outcome.id}
                        onClick={onClickGenerator(outcome, idx)}
                        buttonId={idx}
                        clickedButtonId={clickedButton}
                        timeExpired={timeExpired}
                        selected={outcome.isSelected}
                        setClickedButton={setClickedButton}
                    >
                        <TeamIconFormatter displayText={outcome.text} size={20} />
                    </OutcomeButton>
                );
            })}
        </div>
    );
};

export default Outcomes;