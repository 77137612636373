import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import {
  GetBlockIdDocument,
  GetBlockIdQuery,
  GetBlockIdQueryVariables,
} from "../../gql/operations.generated";
import LoadingScreen from "../common/leaderboard/LoadingScreen";
import LoadingScreenSpinner from "../common/LoadingScreenSpinner";
import BackRow from "./BackRow";
import Leaderboard from "./index";

const Wrapper = () => {
  const { contest_id } = useParams<{ contest_id: string }>();

  const { loading, error, data } = useQuery<
    GetBlockIdQuery,
    GetBlockIdQueryVariables
  >(GetBlockIdDocument, {
    variables: {
      id: contest_id,
    },
  });

  if (data && !error && !loading) {
    return <Leaderboard />;
  } else {
    return (
      <LoadingScreen fullWidth loader={<LoadingScreenSpinner />}>
        <BackRow />
      </LoadingScreen>
    );
  }
};

export default Wrapper;
