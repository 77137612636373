import {gql} from '@apollo/client';

export const CRICKET_STATE_QUERY = gql`
    query CricketState($contestId: Int!) {
        contest(id: $contestId) {
            fixture {
                cricketState {
                    topTeam {
                        code
                        name
                        runs
                        wicketsOut
                        isBatting
                    }
                    bottomTeam {
                        code
                        name
                        runs
                        wicketsOut
                        isBatting
                    }
                    over
                    ball
                    batsmans {
                        id
                        key
                        name
                        teamKey
                        isReceiver
                        runs
                        balls
                    }
                    bowler {
                        id
                        key
                        name
                        teamKey
                        wickets
                        runs
                        overs
                        balls
                    }
                }
            }
        }
    }
`;