import React, {useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import LoadingScreenSpinner from "../../common/nrlLoaders/LoadingScreenSpinner";
import Button from "../../common/UI/SquareButton";
import {useHistory} from "react-router";

const styles = makeStyles((theme: Theme) => ({
    pendingresults: {
        width: '80%',
        display: 'block',
        margin: '30px auto'
    },
    buttonsWrapper: {
        marginTop: 30,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 10,
        '@media only screen and (min-width: 1025px)': {
            gridTemplateColumns: '250px',
            justifyContent: 'center',
            '& > div:first-child':{
                display: 'none'
            }
        }
    },
    title: {
        display: 'block',
        textAlign: 'center',
        fontSize: 36,
        fontWeight: 800,
        textTransform: 'uppercase',
        paddingBottom: 20
    }
}));

interface Props {
    setPrevPageForLeaderboard: (p: string) => void
    contestId: number
}

const ResultsPending = (props: Props) => {
    const classes = styles();
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const history = useHistory();

    const buttons = (
        <div className={classes.buttonsWrapper}>
            <Button width='100%' buttonWasClicked={buttonWasClicked} notDefaultColors='blue'
                    onClick={(e: any) => {
                        if (!buttonWasClicked) {
                            setButtonWasClicked(true);
                            e.preventDefault();

                            props.setPrevPageForLeaderboard('question');

                            history.push(`/${props.contestId}/leaderboard` + history.location.search)
                        }
                    }}>
                Leaderboard
            </Button>

            <Button width='100%' buttonWasClicked={buttonWasClicked}
                    onClick={(e: any) => {
                        if (!buttonWasClicked) {
                            setButtonWasClicked(true);
                            e.preventDefault();

                            history.push(`/` + history.location.search)
                        }
                    }}>
                Home
            </Button>
        </div>
    );

    return (
        <LoadingScreenSpinner
            childrenButtons={buttons}>

            <span className={classes.title}>Results pending</span>

            <span>Sit tight, we are calculating</span>
        </LoadingScreenSpinner>
    )
};

export default ResultsPending;