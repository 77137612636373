import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import spinner from "../../../assets/animation/spinner.gif";
import classNames from "classnames";

const styles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        fontSize: 18,
        fontWeight: 700,
        padding: '5px 0px 0',
        height: 35,
        borderRadius: 5,
        display: 'inline-block',
        textDecoration: 'none',
        color: '#232323',
        textAlign: 'center',
        zIndex: 90,
        position: 'relative'
    },
    spinner: {
        display: 'block',
        width: 20,
        height: 20,
        position: 'absolute',
        right: 2,
        top: 8,
    },
    hide: {
        display: 'none'
    },
    red: {
        background: theme.palette.info.light,
        color: theme.palette.primary.contrastText,
    },
    blue: {
        color: theme.palette.primary.contrastText,
        background: '#0072e3',
    },
    grey: {
        color: theme.palette.primary.dark,
        background: theme.palette.grey[300],
    },

    small: {
        fontSize: 12,
        height: 30
    },
    disabled: {
        background: theme.palette.info.dark,
    },
    wrapper: {
        position: 'relative',
        overflow: 'visible'
    }
}));

interface Props {
    children: React.ReactNode
    onClick?: any
    buttonWasClicked?: boolean
    width?: string
    height?: string
    notDefaultColors?: string //so default button is white, not default 'red' 'blue' 'grey'
    small?: boolean //text size, default 18px, 'small' => 12px
    disabled?: boolean
    boxShadow?: boolean
}

const Button = (props: Props) => {
    const classes = styles();
    const {onClick, buttonWasClicked, width, notDefaultColors, disabled, height, boxShadow} = props;

    const addittionalClass = notDefaultColors ?
        notDefaultColors === 'red' ? classes.red
            : notDefaultColors === 'blue' ? classes.blue
            : classes.grey
        : null;

    const disabledClass = disabled ? classes.disabled : '';

    return (
            <div className={classNames(classes.root, addittionalClass, disabledClass, props.small ? classes.small : '')}
                 onClick={onClick} style={{
                width: width ? width : '',
                height: height ? height : '',
                cursor: onClick ? 'pointer' : 'default',
                boxShadow: `${boxShadow ? '#000000CE' : '#00000029'} 0 3px 6px`
            }}>
                {props.children}

                <img src={spinner} alt="spinner"
                     className={buttonWasClicked ? classes.spinner : classes.hide}/>
            </div>
    )
};

export default Button;