import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import BatsmenWrapper from './components/BatsmenWrapper';
import Button from '../common/UI/SquareButton';
import {useMutation} from "@apollo/client";
import {SELECT_BATSMAN} from "../../apollo/cricket/mutations/SelectBatsman";
import {useEntry} from "../../contexts/cricket/Entry";
import {useCricketState} from "../../contexts/cricket/CricketState";
import LoadingScreenBall from '../common/cricketLoaders/LoadingScreeenBall'

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        color: theme.palette.primary.contrastText,
        '@media only screen and (orientation:landscape) and (min-device-width: 375px) and (max-device-width: 812px)': {
            maxWidth: '100%',
            overflow: 'hidden'
        },
        '@media only screen and (max-width: 400px)': {
            maxWidth: 400,
            margin: 'auto',
            paddingTop: 40,
        }
    },
    rootInner: {
        padding: '10px 15px',
        maxWidth: 500,
        margin: 'auto',
    },
    outImage: {
        display: 'block',
        width: 230,
        margin: '40px auto 0'
    },
    title: {
        fontSize: 15,
        fontWeight: 700,
        margin: '20px auto 12px',
        display: 'block',
        textAlign: 'center'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    link: {
        textDecoration: 'none',
    },
    show: {
        display: 'block'
    },
    hide: {
        display: 'none'
    },
    selectTitle: {
        display: 'block',
       textAlign: 'center',
        fontSize: 26,
        fontWeight: 800,
        textTransform: 'uppercase',
        paddingBottom: 20
    }
}));

const SelectBatsmenOut = () => {
    const classes = styles();
    const {entry, loadEntry} = useEntry();
    const {id} = entry;

    const [value, setValue] = React.useState('first');
    const {cricketState} = useCricketState();
    const [buttonWasClicked, setButtonWasClicked] = useState(false);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const [selectBatsmanMutation, {data, loading, error}] = useMutation(SELECT_BATSMAN);


    useEffect(() => {
        if (data && !loading && !error) {
            loadEntry(data.selectBatsman.entry);
        } else if (!loading && error) {
            console.error(error)
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setButtonWasClicked(false);
        }
    }, [error]);

    if (cricketState && cricketState.batsmans && cricketState.batsmans.length >= 2) {

        return (
            <div className={classes.root}>
                <div className={classes.rootInner}>

                    <div className={classes.show}>
                        <span className={classes.selectTitle}>Select new batsman</span>

                        <BatsmenWrapper value={value} handleChange={handleChange}/>
                        <div className={classes.buttonWrapper}>
                            <Button width='162px'
                                    notDefaultColors='red'
                                    buttonWasClicked={buttonWasClicked}
                                    onClick={() => {
                                        if (!buttonWasClicked) {
                                            setButtonWasClicked(true);

                                            if (value === 'first') {
                                                selectBatsmanMutation({
                                                    variables: {
                                                        entryId: id,
                                                        cricketerId: cricketState.batsmans[0].id
                                                    }
                                                })
                                            } else if (value === 'second') {
                                                selectBatsmanMutation({
                                                    variables: {
                                                        entryId: id,
                                                        cricketerId: cricketState.batsmans[1].id
                                                    }
                                                })
                                            }
                                        }
                                    }}>Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <LoadingScreenBall> Just a minute, we are loading new batsmen</LoadingScreenBall>
        )
    }
};

export default SelectBatsmenOut;