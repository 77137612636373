import { useMutation } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import CryptoJS from "crypto-js";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "react-activity";
import { useTracking } from "../../../../../entain/hooks/useTracking";
import GenericButton from "../../../../common/components/GenericButton";
import aflKicksImage from "../../../assets/afl-kicks.jpg";
import {
  GAME_MAX_KICKS,
  GAME_STORAGE_SALT,
  QUESTION_ANIMATE_IN_SECOND,
} from "../../../constants";
import { useEntry } from "../../../contexts/Entry";
import { useUser } from "../../../contexts/User";
import {
  AnswerQuestionDocument,
  AnswerQuestionMutation,
  AnswerQuestionMutationVariables,
} from "../../../gql/operations.generated";
import { QuestionListQuestion } from "../../../types";

const animated = {
  start: {
    opacity: 0,
    scale: 0.5,
  },
  animation: {
    scale: [0.5, 1.0],
    opacity: [0, 1],
    transition: {
      times: [0, 1],
      default: { duration: QUESTION_ANIMATE_IN_SECOND },
    },
  },
};

declare global {
  interface Window {
    CMain: any;
    jQuery: any;
    sizeHandler: any;
  }
}

type KickResultData = {
  stageNum: number;
  ShotNum: number;
  score: number;
  totalScore: number;
  iAreaHit: number;
  iX: number;
  iY: number;
};

type GameOverData = {
  totalScore: number;
};

type LocalStorageGameData = {
  currentShot: number;
  currentTotalScores: number;
};

type Props = {
  question: QuestionListQuestion;
  setOpenSnackbar: (s: boolean) => void;
};

const AFLKicksBlock = ({ question, setOpenSnackbar }: Props) => {
  const classes = styles();
  const canvas = useRef(null);
  const { entry } = useEntry();
  const { user } = useUser();
  const tracking = useTracking();
  const [hasGameStarted, setHasGameStarted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedOutcome = useMemo(() => {
    const scoreOutcome = question.outcomes.find(
      (outcome) => outcome.text === "Score"
    );
    return scoreOutcome;
  }, [question]);

  const [answerQuestion] = useMutation<
    AnswerQuestionMutation,
    AnswerQuestionMutationVariables
  >(AnswerQuestionDocument);

  useEffect(() => {
    if (
      !hasGameStarted ||
      !user?.id ||
      !question.id ||
      !entry?.id ||
      !selectedOutcome?.id
    ) {
      return;
    }

    let currentShot = 1;
    let currentTotalScores = 0;
    const storageKey = `${user.id}-${entry.id}-${question.id}-game-v1`;
    const storageData = localStorage.getItem(storageKey);

    if (typeof storageData === "string") {
      try {
        const decryptedData = CryptoJS.AES.decrypt(
          storageData,
          GAME_STORAGE_SALT
        );
        const paresedData: LocalStorageGameData = JSON.parse(
          decryptedData.toString(CryptoJS.enc.Utf8)
        );
        currentShot = paresedData?.currentShot || 1;
        currentTotalScores = paresedData?.currentTotalScores || 0;
        if (currentShot < 1 || currentShot > GAME_MAX_KICKS) {
          currentShot = 1;
          currentTotalScores = 0;
        }
      } catch {
        // use default value
      }
    }

    let oMain = new window.CMain({
      entain: {
        canvas: canvas.current,
        currentShot: currentShot,
        currentStage: 1,
        currentTotalScores,
      },
      shot_indicator_spd: 2200, //STARTING TIME IT TAKES THE TOKEN TO GO FROM SIDE TO SIDE OF THE KICKING BAR.
      //IT IS EXPRESSED IN MILLISECONDS
      //INCREASE THIS VALUE IF YOU WANT TO SLOW DOWN THE TOKEN
      decrease_shot_indicator_spd: 200, //EVERY NEW LEVEL, THE GAME WILL DECREASE THIS AMOUNT FROM THE STARTING TIME ABOVE
      //DECREAE THIS VALUE TO MAKE THE GAME EASIER

      shots_per_stage: GAME_MAX_KICKS, //NUMBER OF SHOTS PER STAGE

      red_area_points: 100, //POINTS GAINED TO GET THE BALL IN RED AREA
      yellow_area_points: 200, //POINTS GAINED TO GET THE BALL IN YELLOW AREA
      green_area_points: 500, //POINTS GAINED TO GET THE BALL IN GREEN AREA
      audio_enable_on_startup: false, //ENABLE/DISABLE AUDIO WHEN GAME STARTS
      fullscreen: false, //SET THIS TO FALSE IF YOU DON'T WANT TO SHOW FULLSCREEN BUTTON
      check_orientation: false, //SET TO FALSE IF YOU DON'T WANT TO SHOW ORIENTATION ALERT ON MOBILE DEVICES
    });
    window.sizeHandler();

    window
      .jQuery(oMain)
      .on("kick_result", (_: any, kickResultData: KickResultData) => {
        const newStorageData: LocalStorageGameData = {
          currentShot: kickResultData.ShotNum + 1,
          currentTotalScores: kickResultData.totalScore,
        };
        const hash = CryptoJS.AES.encrypt(
          JSON.stringify(newStorageData),
          GAME_STORAGE_SALT
        ).toString();
        localStorage.setItem(storageKey, hash);
      });

    window
      .jQuery(oMain)
      .on("game_over", (_: any, gameOverData: GameOverData) => {
        setIsSubmitting(true);
        const now = new Date();
        const timestampStr = now.getTime().toString();
        const totalScoreStr = gameOverData.totalScore.toString();
        const validationTimestampStr = timestampStr
          .slice(0, timestampStr.length - totalScoreStr.length)
          .concat(totalScoreStr);

        const trackAnswer = (error?: unknown) => {
          tracking.event(`AFL Kicks${error ? " error" : ""}`, {
            event_label: entry.id,
            entryId: entry.id,
            questionId: question.id,
            outcomeId: selectedOutcome.id,
            outcomeText: `Score - ${gameOverData.totalScore}`,
            timestamp: validationTimestampStr,
            error,
            sport: process.env.REACT_APP_BUILD_TARGET,
          });
        };

        answerQuestion({
          variables: {
            entryId: entry.id,
            outcomeId: selectedOutcome.id,
            timestamp: validationTimestampStr,
            score: gameOverData.totalScore,
          },
        })
          .then((result) => {
            const selectOutcome = result.data?.afl.selectOutcome;

            if (selectOutcome?.success && !selectOutcome?.errorMessage) {
              trackAnswer();
              localStorage.removeItem(storageKey);
            } else {
              setOpenSnackbar(true);
              trackAnswer(selectOutcome?.errorMessage);
            }
          })
          .catch((e) => {
            setOpenSnackbar(true);
            trackAnswer(e);
          });
      });

    return () => {
      window.jQuery(oMain).off();
      oMain.unload();
      oMain = null;
    };
  }, [
    answerQuestion,
    entry?.id,
    hasGameStarted,
    selectedOutcome?.id,
    question.id,
    setOpenSnackbar,
    user?.id,
  ]);

  const onStart = useCallback(() => setHasGameStarted(true), []);

  return (
    <motion.div
      key={question.id}
      initial="start"
      animate="animation"
      variants={animated}
    >
      <div className={classes.container}>
        <div className={classes.title}>Play AFL Mini Game</div>
        <div className={classes.content}>
          <div
            className={classes.infoContainer}
            style={{ display: hasGameStarted ? "none" : "block" }}
          >
            <div className={classes.imageContainer}>
              <img src={aflKicksImage} alt="AFL Kicks" />
            </div>
            <ul>
              <li>
                <span className={classes.rulesNum}>1</span>
                <span className={classes.rulesText}>You will have 3 kicks.</span>
              </li>
              <li>
                <span className={classes.rulesNum}>2</span>
                <span className={classes.rulesText}>
                  Stop the ball as close to the centre as possible.
                </span>
              </li>
              <li>
                <span className={classes.rulesNum}>3</span>
                <span className={classes.rulesText}>
                  Maximise your points with accuracy!
                </span>
              </li>
            </ul>
            <div>
              <GenericButton color="red" onClick={onStart} width="167px">
                Continue
              </GenericButton>
            </div>
          </div>
          <div
            className={classes.gameContainer}
            style={{ display: hasGameStarted ? "block" : "none" }}
          >
            <canvas
              ref={canvas}
              id="canvas"
              className="ani_hack"
              width="640"
              height="768"
            ></canvas>
          </div>
          <div
            className={classes.submitContainer}
            style={{ display: isSubmitting ? "flex" : "none" }}
          >
            <Spinner className={classes.spinner} size={50} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const styles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 12,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    margin: "0 auto",
    maxWidth: "430px",
    padding: "15px",
  },
  content: {
    maxHeight: "480px",
    maxWidth: "400px",
    minHeight: "312px",
    minWidth: "260px",
    position: "relative",
  },
  submitContainer: {
    alignItems: "center",
    backgroundColor: "rgba(55,56,72,0.8)",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  spinner: {
    overflow: "visible",
  },
  infoContainer: {
    aspectRatio: "1 / 1.2",
    textAlign: "center",

    "& > ul": {
      listStyle: "none",
      marginBottom: "25px",
      padding: "0 20px",
      textAlign: "left",
      
      "& > li": {
        alignItems: "center",
        display: "flex",
        paddingBottom: "15px",
        width: "100%",

        '@media only screen and (max-width: 450px)': {
          paddingBottom: "10px",
        }
      },
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 800,
    paddingBottom: 15,
    textAlign: 'center',

    '@media only screen and (max-width: 450px)': {
      fontSize: 20,
    }
  },
  imageContainer: {
    margin: "0 auto 15px",
    maxWidth: "150px",

    "& > img": {
      borderRadius: 10,
      maxWidth: "100%",
    },

    '@media only screen and (max-width: 450px)': {
      maxWidth: "100px",
    }
  },
  rulesNum: {
    fontSize: 20,
    fontWeight: 800,
    position: "relative",
    zIndex: 1,
    paddingLeft: 13,

    "&::before": {
      borderRadius: 100,
      background: "#007FFF",
      position: "absolute",
      left: 0,
      top: -3,
      content: '""',
      zIndex: -1,
      width: 36,
      height: 36,
    },

    '@media only screen and (max-width: 450px)': {
      fontSize: 16,
      paddingLeft: 12,

      "&::before": {
        top: -4,
        width: 32,
        height: 32,
      }
    }
  },
  rulesText: {
    fontSize: 16,
    paddingLeft: 25,
    fontWeight: 700,

    '@media only screen and (max-width: 450px)': {
      fontSize: 14,
    }
  },
  gameContainer: {
    aspectRatio: "1 / 1.2",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
}));

export default AFLKicksBlock;
