import React, {useEffect} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import AnsweredProgressBar from './AnsweredProgressBar';
import EachQuestion from './EachQuestion';
import {useQuery} from "@apollo/client";
import {AFL_GET_RESULTS} from "../../../../apollo/afl/queries/GetResults";
import {NRL_GET_RESULTS} from "../../../../apollo/nrl/queries/GetResults";
import LoadingScreenSpinner from '../../../common/nrlLoaders/LoadingScreenSpinner';
import Slider from "react-slick";
import correct from "../../../../assets/Correct.png";
import wrong from "../../../../assets/Wrong.png";
import PrevArrow from './PrevArrow';
import NextArrow from './NextArrow';

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '0px 5px',
        color: theme.palette.primary.contrastText
    },
    rootInner: {
        background: theme.palette.background.default,
        borderRadius: 12,
        width: '100%',
        padding: '15px 20px',
        boxShadow: '#0000005C 0 3px 6px',
    },
    topInner: {
        background: theme.palette.background.default,
        borderRadius: 6,
        width: '100%',
        padding: '5px 10px',
        boxShadow: '#0000005C 0 3px 6px',
        marginBottom: 15
    },
    resultsTitle: {
        color: theme.palette.grey[300],
        textAlign: 'left',
        paddingBottom: 5
    },
    correctAnswers: {
        color: theme.palette.primary.contrastText,
        fontWeight: 700,
        paddingLeft: 10
    },
    answerIcon: {
        display: 'block',
        width: '15px !important',
        height: 15
    },
    pending: {
        display: 'block',
        width: '15px !important',
        height: 15,
        borderRadius: '100%',
        background: theme.palette.secondary.light
    },
    nonchecked: {
        display: 'block',
        width: '15px !important',
        height: 15,
        borderRadius: '100%',
        border: '1px solid ' + theme.palette.secondary.light
    },
    emptyText: {
        paddingTop: 20,
        fontSize: 20,
        fontWeight: 700
    },
}));

interface Props {
    points: number | undefined
    sport: string
    blockId: number
    activeEntry: boolean
    now: number
}

const Results = (props: Props) => {
    const classes = styles();
    const {points, sport, blockId, activeEntry, now} = props;

    const query = sport === 'AFL' ? AFL_GET_RESULTS : NRL_GET_RESULTS;

    const {data, loading, error, startPolling, stopPolling} = useQuery(query, {
        variables: {
            blockId: Number(blockId)
        }
    });

    useEffect(() => {
        startPolling(5000);
        return () => {
            stopPolling();
        }
    }, [startPolling, stopPolling]);

    let maximumQuestions = 0;
    let correctPercentage = 0;
    if (process.env.REACT_APP_BUILD_TARGET === 'nrl') {
        maximumQuestions = 40
    } else if (process.env.REACT_APP_BUILD_TARGET === 'afl') {
        maximumQuestions = 20
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 200,
        slidesToShow: 15,
        slidesToScroll: 1,
        swipe: false,
        touchMove: false,
        swipeToSlide: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
    };

    if (data && !error && !loading) {
        const receivedData = sport === 'NRL' ? data.nrl.questions : data.afl.questions;
        let correctAnswers = 0;
        let answers: string[] = []; //C, I, P, N //correct, incorrect, pending, notchecked

        receivedData.map((q: any, i: number) => {
            const selectedOutcome = q.outcomes.filter((o: any) => o.isSelected);
            const nonChecked = selectedOutcome.length === 0;
            const isPending = selectedOutcome.length > 0 && selectedOutcome[0].correct === null;
            const isCorrect = selectedOutcome.length > 0 ? selectedOutcome[0].correct : false

            if (isCorrect) correctAnswers += 1
            correctPercentage = points ? Math.floor((correctAnswers / maximumQuestions) * 100) : 0;

            if (nonChecked) {
                answers.push('N')
            } else if (isPending) {
                answers.push('P')
            } else if (isCorrect) {
                answers.push('C')
            } else if (!isCorrect) {
                answers.push('I')
            }
        });

        return (
            receivedData.length > 0 ?
                <div className={classes.root}>

                    {activeEntry &&
                        <div className={classes.topInner}>
                            {answers.length > 0 &&
                                <>
                                    <div className={classes.resultsTitle}>Results Summary</div>

                                    <Slider {...settings}>
                                        {answers.map((a: string, i: number) => {
                                            return (
                                                a === 'C' ?
                                                    <img src={correct} alt="" key={i} className={classes.answerIcon}/>
                                                    : a === 'I' ?
                                                        <img src={wrong} alt="" key={i} className={classes.answerIcon}/>
                                                        : a === 'P' ?
                                                            <span className={classes.pending} key={i}/>
                                                            : a === 'N' ?
                                                                <span className={classes.nonchecked} key={i}/>
                                                                : null
                                            )
                                        })}
                                    </Slider>
                                </>}

                            <div className={classes.resultsTitle}>Your Percentage</div>
                            <AnsweredProgressBar percentage={correctPercentage}/>

                            <div className={classes.resultsTitle}>Questions Correct
                                <span
                                    className={classes.correctAnswers}>{correctAnswers} of {receivedData.length}</span>
                            </div>
                        </div>}

                    <div className={classes.rootInner}>
                        {receivedData.map((q: any, n: number) => {
                            const selectedOutcome = q.outcomes.filter((o: any) => o.isSelected);
                            const isPending = selectedOutcome.length > 0 ? selectedOutcome[0].correct === null : false;
                            const isCorrect = selectedOutcome.length > 0 ? selectedOutcome[0].correct : false;
                            const questionText = q.text;
                            const wasntSelected = (element: any, _: number, __: any) => {
                                return element.isSelected === false;
                            }

                            const notSelected = q.outcomes.every(wasntSelected)
                            const difference = q.end - now;

                            return (
                                (difference < 0 || q.isAnswered) &&
                                <EachQuestion isCorrect={isCorrect} isPending={isPending} outcomes={q.outcomes}
                                              pos={n + 1} key={n} questionText={questionText}
                                              notSelected={notSelected} points={q.points}/>
                            )
                        })}
                    </div>
                </div>
                : <div className={classes.emptyText}>No questions yet</div>
        )
    } else {
        if (!data && !loading) {
            return (
                <div className={classes.emptyText}>No entries this quarter/half.</div>
            )
        }
        return (
            <>
                <div className={classes.emptyText}>Preparing Results</div>
                <LoadingScreenSpinner/>
            </>
        )
    }
};

export default Results;