import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => ({
    tiedText: {
        padding: '0px 0 15px',
        textAlign: 'left',
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        '& > span:first-child': {
            fontSize: 14,
            paddingRight: 5
        }
    },
}));

const TiedText = () => {
    const classes = styles();

    return (
        <div className={classes.tiedText}>
            <span>(&#65121;)</span>
            <span>Tie breakers are resulted through average response time.</span>
        </div>
    )
};

export default TiedText;