import React, {useEffect, useState} from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from '../../common/UI/Checkbox';
import {useHistory, useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {NRL_ENTER_CONTEST} from "../../../apollo/nrl/mutations/EnterContest";
import {AFL_ENTER_CONTEST} from "../../../apollo/afl/mutations/EnterContest";
import {NRL_GET_BLOCK_ID} from '../../../apollo/nrl/queries/GetBlockId';
import {AFL_GET_BLOCK_ID} from '../../../apollo/afl/queries/GetBlockId';
import {useNrlAflEntry} from '../../../contexts/nrl_afl/Entry';
import NrlAflLayout from '../../common/UI/NrlAflLayout';
import Button from "../../common/UI/SquareButton";
import BackButton from '../../common/UI/BackButton';
import logo from "../../../assets/CQ-logo.png";
import LoadingScreenSpinner from "../../common/nrlLoaders/LoadingScreenSpinner";

const styles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        padding: '0 30px',
        '@media only screen and (max-width: 600px)': {
            padding: '30px 0',
        }
    },
    innerRoot: {
        maxWidth: 500,
        width: '90%',
        margin: 'auto',
        color: theme.palette.primary.contrastText
    },
    title1: {
        fontSize: 24,
        fontWeight: 800,
        textAlign: 'center',
        paddingBottom: 20
    },
    row: {
        display: 'flex',
        width: '100%',
        paddingBottom: 25,
        overflow: 'visible',
        alignItems: 'center'
    },
    list: {
        fontSize: 20,
        fontWeight: 800,
        position: 'relative',
        zIndex: 1,
        paddingLeft: 13,
        '&::before': {
            borderRadius: 100,
            background: '#007FFF',
            position: 'absolute',
            left: 0,
            top: -3,
            content: '""',
            zIndex: -1,
            width: 36,
            height: 36
        }
    },
    text: {
        fontSize: 16,
        paddingLeft: 25,
        fontWeight: 700
    },
    agreeRow: {
        display: 'flex',
        width: '100%',
        paddingTop: 10,
        justifyContent: 'center'
    },
    checkbox: {
        border: 'green'
    },
    link: {
        fontSize: 14,
        color: theme.palette.primary.contrastText,
        whiteSpace: 'nowrap',
        cursor: 'pointer'
    },
    label: {
        fontSize: 14,
        whiteSpace: 'nowrap'
    },
    wrapper: {
        marginRight: 5
    },
    logo: {
        display: 'block',
        width: 250,
        height: 140,
        margin: 'auto'
    },
    btnWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 50
    },
    back: {
        height: 50,
        position: 'absolute',
        '@media only screen and (min-width: 1025px)': {
            top: 5,
            left: 0,
            width: '100%',
        },
        '@media only screen and (max-width: 1024px)': {
            top: 0,
            left: 0,
            width: '100%',
        }
    },
}));

interface Props {
    setAgreedRules: (a: boolean) => void
    agreedRules: boolean
    sport: string
}

interface Text {
    NRL: string[],
    AFL: string[],

    [key: string]: string[]
}

const Index = (props: Props) => {
    const classes = styles();
    const history = useHistory();
    const {setAgreedRules, agreedRules, sport} = props;
    const {contest_id} = useParams<{ contest_id: string }>();
    const {entry, loadEntry, isEntryLoaded} = useNrlAflEntry();
    let timeout: any = null;
    const [buttonWasClicked, setButtonWasClicked] = useState(false);
    const [blockId, setBlockId] = useState(-1);
    const [showRulesPage, setShowRulesPage] = useState(false);

    const queryBlockId = sport === 'NRL' ? NRL_GET_BLOCK_ID : AFL_GET_BLOCK_ID;

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreedRules(event.target.checked);
    };

    const [enterAflContestMutation, {
        data: aflData,
        loading: aflLoading,
        error: aflError
    }] = useMutation(AFL_ENTER_CONTEST);

    const [enterNrlContestMutation, {
        data: nrlData,
        loading: nrlLoading,
        error: nrlError
    }] = useMutation(NRL_ENTER_CONTEST);

    const {loading: blockIdLoading, error: blockIdError, data: blockIdData} =
        useQuery(queryBlockId, {
            variables: {
                id: Number(contest_id)
            }
        });

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (aflData && !aflLoading && !aflError) {
            loadEntry(aflData.afl.enterContest.entry);
            history.push(`question_page` + history.location.search)
        } else if (!aflLoading && aflError) {
            console.error(aflError)
        }
    }, [aflData]);

    useEffect(() => {
        if (nrlData && !nrlLoading && !nrlError) {
            loadEntry(nrlData.nrl.enterContest.entry);
            history.push(`question_page` + history.location.search)
        } else if (!nrlLoading && nrlError) {
            console.error(nrlError)
        }
    }, [nrlData]);

    useEffect(() => {
        if (entry && entry.id !== -1) {
            history.push(`question_page` + history.location.search)
        }

        if (isEntryLoaded && (!entry || entry.id === -1)) {
            setShowRulesPage(true)
        }
    }, [entry])

    useEffect(() => {
        if (!blockIdLoading && !blockIdError && blockIdData) {
            let current = sport === 'NRL' ? blockIdData.nrl.contest : blockIdData.afl.contest;

            setBlockId(current.currentBlock);
            if (current.status !== 'INPROGRESS') history.push(`/` + history.location.search);
        }
    }, [blockIdData])


    const rulesText: Text = {
        'NRL': [
            'Answer questions for each half',
            'Climb the leaderboard for the half',
            'Finish in the top 10 in either half and win'
        ],
        'AFL': [
            'Answer questions for each quarter',
            'Climb the leaderboard for any quarter',
            'Finish in the top 5 in any quarter and win'
        ]
    };

    return (
        <NrlAflLayout>
            {showRulesPage ?
                <>
                    <div className={classes.back}>
                        <BackButton onClick={() => history.push(`/` + history.location.search)}>
                            Back</BackButton>
                    </div>
                    <img src={logo} alt="logo" className={classes.logo}/>

                    <div className={classes.root}>
                        <div className={classes.innerRoot}>
                            <div className={classes.title1}>How To Play</div>

                            {rulesText[sport].map((t: string, i: number) => (
                                <div className={classes.row} key={i}>
                                    <span className={classes.list}>{i + 1}</span>
                                    <span className={classes.text}>{t}</span>
                                </div>
                            ))}

                            <div className={classes.agreeRow}>
                                <FormControlLabel
                                    control={
                                        <StyledCheckbox onChange={handleCheckbox}/>
                                    }
                                    label="Agree to"
                                    classes={{label: classes.label, root: classes.wrapper}}
                                />

                                <a className={classes.link}
                                   href="https://www.ladbrokes.com.au/rules-terms-and-conditions" target='_blank'>terms
                                    and conditions</a>
                            </div>

                            <div className={classes.btnWrapper}>
                                <Button width='167px' buttonWasClicked={buttonWasClicked} notDefaultColors='red'
                                        disabled={!agreedRules}
                                        onClick={async (e: any) => {
                                            if (!buttonWasClicked && agreedRules && blockId !== -1) {
                                                setButtonWasClicked(true);
                                                e.preventDefault();

                                                if (!entry || entry.id === -1) {
                                                    if (sport === 'AFL') {
                                                        await enterAflContestMutation({variables: {blockId: blockId}})
                                                    } else if (sport === 'NRL') {
                                                        await enterNrlContestMutation({variables: {blockId: blockId}})
                                                    }
                                                } else {
                                                    history.push(`question_page` + history.location.search)
                                                }
                                            }
                                        }}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
                : <LoadingScreenSpinner/>}
        </NrlAflLayout>
    )
};

export default Index;
