import { useMutation } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTimeDrift } from "../../../../../contexts/TimeDrift";
import { useTracking } from "../../../../../entain/hooks/useTracking";
import {
  QUESTION_ANIMATE_IN_SECOND,
  QUESTION_PREVIEW_SECOND,
} from "../../../constants";
import { useEntry } from "../../../contexts/Entry";
import {
  SeenQuestionDocument,
  SeenQuestionMutation,
  SeenQuestionMutationVariables,
} from "../../../gql/operations.generated";
import {
  QuestionListQuestion,
  QuestionListQuestionOutcome,
} from "../../../types";
import TeamIconFormatter from "../../common/TeamIconFormatter";
import Outcomes from "./Outcomes";
import PointsLine from "./PointsLine";
import ProgressBar from "./ProgressBar";

const styles = makeStyles((theme: Theme) => ({
  topLine: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: 10,
    paddingBottom: 10,
    "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)":
      {
        paddingBottom: 0,
      },
  },
  timeWrapper: {
    display: "flex",
    width: 130,
  },
  timeColor: {
    width: 45,
    textAlign: "end",
  },
  lessTimeColor: {
    color: theme.palette.secondary.light,
    width: 45,
    textAlign: "end",
  },
  question: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "visible",
    paddingTop: 10,
  },
  questionText: {
    fontSize: 14,
    display: "block",
    width: "100%",
    textAlign: "center",
    fontWeight: 900,
    paddingBottom: 5,
  },
  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  ballImage: {
    display: "block",
    width: 15,
    height: 15,
    marginRight: 5,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  "@keyframes wobble": {
    "0%": {
      transform: "rotate(2deg) scale(1.1)",
    },
    "100%": {
      transform: "rotate(-2deg) scale(1.1)",
    },
  },
  wobbleAnimation: {
    animation: `$wobble 0.1s cubic-bezier(.36,.07,.19,.97) 2 backwards`,
  },
  timeBar: {
    width: "100%",
    height: 7,
    overflow: "hidden",
    position: "relative",
    transform: "rotate(180deg);",
  },
  timeBarInner: {
    backgroundColor: theme.palette.grey[400],
    height: 7,
    position: "absolute",
    right: 0,
    top: 0,
  },
  wholeTimerWrapper: {
    display: "block",
    width: "100%",
    overflow: "hidden",
    borderRadius: 20,
  },
  fixture: {
    paddingTop: 1,
    width: 100,
  },
  dismissButton: {
    color: theme.palette.primary.light,
    fontSize: 12,
  },
  countdown: {
    display: "flex",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
  topWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0 30px",
    marginTop: 20,
    "@media only screen and (max-width: 1024px)": {
      padding: "10px 0 20px",
    },
  },
  wrapperTopPart: {
    overflow: "visible",
  },
  "@keyframes appearing": {
    "0%": {
      transform: "scale(0.3)",
    },
    "70%": {
      transform: "scale(1.2)",
    },

    "100%": {
      transform: "scale(1)",
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 5,
    paddingBottom: 10,
    fontSize: 14,
    fontWeight: 700,
    color: "#A7A8BA",
  },
  progressBarContainer: {
    position: "relative",
  },
  countdownCircle: {
    fontSize: 14,
    fontWeight: 900,
    left: 0,
    position: "absolute",
    top: 0,
    zIndex: 5,
  },
}));

interface Props {
  setTimeExpired?: (t: boolean) => void;
  timeExpired?: boolean;
  question?: QuestionListQuestion;
  setLessThanTenSecs?: (l: boolean) => void;
  setButtonWasClicked?: (c: boolean) => void;
  buttonWasClicked?: boolean;
  setOpenSnackbar: (s: boolean) => void;
}

/**
 * Notes on initial stages and allowed actions:
 * - when question has not been seen:
 *    - stage 1 - question animate in, answer button is disabled
 *    - stage 2 - question animate in completed, answer button is enabled and can be answered, countdown to points reduction starts
 *    - stage 3 - countdown to points reduction ended, points start to reduce
 *    - when user leave the question on stage 1 and 2 without answering the question, it will mark the question as seen to prevent
 *      user gaining advantage by going in and out of question page
 * - when question has been seen before:
 *    - points start to reduce and question can be answered immediately on mount
 */
const QuestionBlock = (props: Props) => {
  const classes = styles();
  const {
    timeExpired,
    setTimeExpired,
    question,
    setLessThanTenSecs,
    setButtonWasClicked,
    setOpenSnackbar,
  } = props;
  const tracking = useTracking();
  const [clickedButton, setClickedButton] = useState<number>(-1);
  const [questionSeenDate, setQuestionSeenDate] = useState<Date | null>(
    question?.seen ? new Date(question.seen) : null
  );
  const [disableOutcomeButtons, setDisableOutcomeButtons] = useState(
    question?.seen ? false : true
  );
  const [showCountdownCircle, setShowCountdownCircle] = useState(false);
  const refValues = useRef<{
    entryId?: string;
    haveClicked?: boolean;
    haveSeen?: boolean;
    questionId?: string;
    setAsSeen?: (
      entryId: string,
      questionId: string,
      timeDrift: number
    ) => Date;
    timeDrift?: number;
  }>({});
  const diff = useTimeDrift();
  const [seenMutation] = useMutation<
    SeenQuestionMutation,
    SeenQuestionMutationVariables
  >(SeenQuestionDocument);
  const { entry } = useEntry();

  const setQuestionAsSeen = useCallback(
    (entryId: string, questionId: string, timeDrift: number): Date => {
      const dateWithDiff = new Date().getTime() + timeDrift;
      const timestamp = new Date(dateWithDiff);
      const timestampString = timestamp.toISOString();

      seenMutation({
        variables: {
          entryId,
          questionId,
          timestamp: String(timestampString),
        },
      });
      return timestamp;
    },
    [seenMutation]
  );

  useEffect(() => {
    refValues.current = {
      entryId: entry?.id,
      haveClicked: clickedButton > -1,
      haveSeen: !!(question?.seen || questionSeenDate),
      questionId: question?.id,
      setAsSeen: setQuestionAsSeen,
      timeDrift: diff,
    };
  }, [
    clickedButton,
    diff,
    entry?.id,
    question?.id,
    question?.seen,
    questionSeenDate,
    setQuestionAsSeen,
  ]);

  // This is to prevent user from gaining advantage by:
  // - the question has not been marked as seen (it is marked as seen after animating
  //   in and countdown circle completed)
  // - user go back to the home page and come back to the question
  // - since the question has not been marked as seen, the user will have more time for
  //   the question
  // - we will mark the question as seen (if it hasn't and not answered) when unmounted
  useEffect(() => {
    return () => {
      const {
        entryId,
        haveClicked,
        haveSeen,
        questionId,
        setAsSeen,
        timeDrift,
      } = refValues.current;

      if (
        entryId &&
        questionId &&
        !haveClicked &&
        !haveSeen &&
        setAsSeen &&
        timeDrift
      ) {
        setAsSeen(entryId, questionId, timeDrift);
      }
    };
  }, []);

  // If the question hasn't been seen before on mount, we are going to wait for the question to animate
  // in, then enable the answer buttons and show a countdown to when the points will start reducing.
  useEffect(() => {
    if (entry?.id && question?.id && !question.seen) {
      const timeoutId = setTimeout(() => {
        setDisableOutcomeButtons(false);
        setShowCountdownCircle(true);
      }, QUESTION_ANIMATE_IN_SECOND * 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [entry?.id, question?.id, question?.seen]);

  const trackAnswer = useCallback(
    (
      outcome: QuestionListQuestionOutcome,
      timestamp: string,
      error?: unknown
    ) => {
      tracking.event(`answer clicked${error ? " error" : ""}`, {
        event_label: entry?.id,
        entryId: entry?.id,
        questionId: question?.id,
        outcomeId: outcome.id,
        outcomeText: outcome.text,
        timestamp: timestamp,
        error,
        sport: process.env.REACT_APP_BUILD_TARGET,
      });
    },
    [entry?.id, question?.id]
  );

  // When the count down is over, the progress bar and point will start reducing by the
  // seconds (by setting the question seen date).
  const onCountdownCircleComplete = useCallback(() => {
    // Question has already been answered, exit early as question would be marked as
    // seen when question was answered.
    if (clickedButton > -1) {
      return;
    }
    if (!entry?.id || !question?.id) {
      // This scenario should not happen as countdown circle is mounted only when we
      // have both entry and question data
      throw new Error(
        `Invalid entry/question id on countdown circle complete - entry id: ${entry?.id}, question id: ${question?.id}`
      );
    }
    const timestamp = setQuestionAsSeen(entry.id, question.id, diff);
    setShowCountdownCircle(false);
    setQuestionSeenDate(timestamp);
  }, [clickedButton, diff, entry?.id, question?.id, setQuestionAsSeen]);

  return (
    <>
      {entry && question && (
        <>
          <div className={classes.progressBarContainer}>
            {showCountdownCircle ? (
              <div className={classes.countdownCircle}>
                <CountdownCircleTimer
                  isPlaying={clickedButton === -1}
                  duration={QUESTION_PREVIEW_SECOND}
                  size={44}
                  strokeWidth={6}
                  trailStrokeWidth={5}
                  colors="#00FF6C"
                  trailColor="#000"
                  onComplete={onCountdownCircleComplete}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </div>
            ) : null}

            <ProgressBar
              clickedButton={clickedButton}
              question={question}
              seen={questionSeenDate}
              setTimeExpired={setTimeExpired!}
              setLessThanTenSecs={setLessThanTenSecs}
            />
          </div>

          <div className={classes.question}>
            <PointsLine seen={questionSeenDate} clickedButton={clickedButton} />

            <div className={classNames(classes.questionText)}>
              <TeamIconFormatter displayText={question.text} size={20} />
            </div>

            <Outcomes
              entryId={entry.id}
              question={question!}
              setOpenSnackbar={setOpenSnackbar}
              setButtonWasClicked={setButtonWasClicked}
              setLessThanTenSecs={setLessThanTenSecs}
              setTimeExpired={setTimeExpired!}
              timeExpired={timeExpired!}
              clickedButton={clickedButton}
              setClickedButton={setClickedButton}
              trackAnswer={trackAnswer}
              disableButtons={disableOutcomeButtons}
            />
          </div>
        </>
      )}
    </>
  );
};

export default QuestionBlock;
