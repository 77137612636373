import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import HomepageWrapper from './components/homepage/nba';
import Homepage from './components/homepage/nba/Homepage';
import EntainAdmin from './components/entain-admin/nba';
import LeaderboardEntain from './components/entain-admin/nba/leaderboard/Wrapper';
import Leaderboard from './components/leaderboard/nba/Wrapper';
import PreviousResults from './components/previous_results/nba';
import QuestionPage from './components/question_page/nba';
import RulesPage from './components/rules_page/nba';
import { AuthProvider } from './contexts/Auth';
import { EntryProvider } from './contexts/nba/Entry';
import { GameStateProvider } from './contexts/nba/GameState';
import { QuestionManagerProvider } from './contexts/nba/Question';
import { TimeDriftProvider } from './contexts/TimeDrift';
import CustomThemeProvider from './themes/ThemeProvider';
import { ContestProvider } from './contexts/nba/Contest';
import { UserProvider } from './contexts/nba/User';

function NbaApp() {
    const [prevPageForLeaderboard, setPrevPageForLeaderboard] = useState('');
    const [agreedRules, setAgreedRules] = useState(false);

    return (
        <Router>
            <CustomThemeProvider theme="nbaTheme">
                <CssBaseline />
                <Switch>
                    <Route path="/previous_results">
                        <AuthProvider>
                            <PreviousResults setPrevPageForLeaderboard={setPrevPageForLeaderboard}/>
                        </AuthProvider>
                    </Route>

                    <Route path="/:contest_id/question_page">
                        <AuthProvider>
                            <TimeDriftProvider>
                                <EntryProvider>
                                    <GameStateProvider>
                                        <QuestionManagerProvider>
                                            <ContestProvider>
                                                <QuestionPage setPrevPageForLeaderboard={setPrevPageForLeaderboard}/>
                                            </ContestProvider>
                                        </QuestionManagerProvider>
                                    </GameStateProvider>
                                </EntryProvider>
                            </TimeDriftProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/:contest_id/home">
                        <AuthProvider>
                            <UserProvider>
                                <EntryProvider>
                                    <RulesPage setAgreedRules={setAgreedRules} agreedRules={agreedRules}/>
                                </EntryProvider>
                            </UserProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/:contest_id/leaderboard">
                        <AuthProvider>
                            <UserProvider>
                                <GameStateProvider>
                                    <EntryProvider>
                                        <TimeDriftProvider>
                                            <ContestProvider>
                                                <Leaderboard prevPageForLeaderboard={prevPageForLeaderboard}/>
                                            </ContestProvider>
                                        </TimeDriftProvider>
                                    </EntryProvider>
                                </GameStateProvider>
                            </UserProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/" exact>
                        <AuthProvider>
                            <UserProvider>
                                <TimeDriftProvider>
                                    <HomepageWrapper>
                                        <Homepage setPrevPageForLeaderboard={setPrevPageForLeaderboard}/>
                                    </HomepageWrapper>
                                </TimeDriftProvider>
                            </UserProvider>
                        </AuthProvider>
                    </Route>

                    <Route path="/entain-admin/:contest_id/leaderboard">
                        <GameStateProvider>
                            <LeaderboardEntain/>
                        </GameStateProvider>
                    </Route>

                    <Route path="/entain-admin">
                        <EntainAdmin/>
                    </Route>
                </Switch>
            </CustomThemeProvider>
        </Router>
    );
}

export default NbaApp;
