import {gql} from "@apollo/client";

export const AFL_GET_RESULTS = gql`
query getResults($blockId: Int!) {
    afl {
        questions(blockId: $blockId) {
            text
            end
            isAnswered
            points
            outcomes {
                text
                correct
                isSelected
                count
            }
        }
    }
}`;