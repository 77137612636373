import React from 'react';
import {makeStyles, Theme} from "@material-ui/core";
import Leaderboard from '../../../leaderboard/cricket';
import QuestionTopBarWrapper from './QuestionTopBarWrapper';
import YourBatsmanCard from './YourBatsmanCard';
import {useEntry} from "../../../../contexts/cricket/Entry";

const styles = makeStyles((theme: Theme) => ({
    root: {
        display: 'none',
        '@media only screen and (min-width: 1025px)': {
            width: '100%',
            maxWidth: 1256,
            margin: 'auto',
            display: 'grid',
            gridTemplateColumns: '334px 1fr 334px',
        }
    },
    classForOpacityBackground: {
        position: 'relative',
        height: '100vh',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
    },
    background: {
        height: '100vh',
        overflowY: 'auto',
        display: 'block',
        width: '100%',
        background: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 0,
    },
    rightPart: {
        width: '100%',
        position: 'relative',
        height: '100vh',
        overflowY: 'auto',
        scrollBehavior: 'smooth',

    },
    title: {
        fontSize: 21,
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        paddingBottom: 20
    },
    rightInner: {
        padding: 20,
        width: '100%',
        position: 'relative',
        zIndex: 1
    }
}));

interface Props {
    children: React.ReactNode
}

const DesktopQuestionsView = (props: Props) => {
    const classes = styles();
    const {children} = props;

    const {entry} = useEntry();
    const {selectedBatsmen} = entry;

    return (
        <div className={classes.root}>
            <div className={classes.classForOpacityBackground}>
                <div className={classes.background}>
                    <QuestionTopBarWrapper/>
                    <Leaderboard prevPageForLeaderboard='question'/>
                </div>

            </div>

            {children}

            <div className={classes.rightPart}>
                <div className={classes.background}>
                    <div className={classes.rightInner}>
                        <div className={classes.title}>Your batting lineup</div>
                        {
                            selectedBatsmen && selectedBatsmen.slice(0).reverse().map((b, i: number) => (
                                <YourBatsmanCard key={i} batsman={b}/>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DesktopQuestionsView;