import {gql} from '@apollo/client';

export const NRL_GET_LEADERBOARD = gql`
    query getNrlLeaderboard($blockId: Int!) {
        nrl {
           leaderboard(blockId: $blockId) {
                blockIndex
                entryCount
                entries {
                    id
                    points
                    blockId
                    rank
                    winnings
                    username
                    settlementStatus
                }
           }
        }
    }
`;