import React from 'react';
import Leaderboard from "./Leaderboard";
import LoadingScreenSpinner from "../../common/nrlLoaders/LoadingScreenSpinner";
import {useQuery} from "@apollo/client";
import {NBA_GET_LEADERBOARD} from "../../../apollo/nba/queries/Leaderboard";
import {makeStyles, Theme} from "@material-ui/core";
import {createArrayWithTiedFieldForNBA} from "../../../utils";
import {NbaEntry} from "../../../../../shared/types";

const styles = makeStyles((theme: Theme) => ({
    emptyText: {
        paddingTop: 20,
        fontSize: 20,
        fontWeight: 700
    },
    root: {
        position: 'relative'
    },
    notices: {
        fontSize: 12,
        fontWeight: 300,
        textAlign: 'center',
    },
    link: {
        color: theme.palette.primary.contrastText,
        cursor: 'pointer'
    },
}));

interface Props {
    activeBlockId: number | null
    activeEntry: NbaEntry | null
}

const LeaderboardOverall = (props: Props) => {
    const classes = styles();
    const {activeBlockId, activeEntry} = props;

    const {loading, data, error} = useQuery(NBA_GET_LEADERBOARD, {
        variables: {
            blockId: Number(activeBlockId)
        },
        pollInterval: 5000
    });

    if (data && !loading && !error) {
        const entries = (data.nba?.leaderboard?.entries || [])
            .filter((entry: NbaEntry) => entry.nationalRank > 0);

        const creatingNewList = createArrayWithTiedFieldForNBA<NbaEntry>(entries, 'OVERALL');
        let newList: (NbaEntry & { tied: boolean })[] = creatingNewList.newList;
        let isAnyTie = creatingNewList.isAnyTie;

        return (
            <>
                <div className={classes.root}>
                    {newList.length > 0 ?
                        <Leaderboard entry={activeEntry}
                                     isAnyTie={isAnyTie}
                                     newList={newList}
                                     type="OVERALL" />
                        : <div className={classes.emptyText}>No entries.</div>}
                </div>

                {newList.length > 0 &&
                    <div className={classes.notices}>
                        <em>Note:</em> Results are settled as per our data provider.<br />
                        Please refer to our <a className={classes.link} href="/assets/documents/couch-quiz-nba-terms-and-conditions.pdf">
                            Terms and Conditions
                        </a> for more information.
                    </div>
                }
            </>
        );
    } else {
        if (!data && !loading) {
            return (
                <div className={classes.emptyText}>No entries.</div>
            )
        }

        return (
            <>
                <div className={classes.emptyText}>Preparing Leaderboard</div>
                <LoadingScreenSpinner/>
            </>
        );
    }
};

export default LeaderboardOverall;
