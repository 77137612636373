import { useQuery } from "@apollo/client";
import { makeStyles, Theme } from "@material-ui/core";
import animation from "../../assets/animation/money-confetti.json";
import {
  GetLeaderboardDocument,
  GetLeaderboardQuery,
  GetLeaderboardQueryVariables,
} from "../../gql/operations.generated";
import { Entry, LeaderboardEntry } from "../../types";
import { createArrayWithTiedField } from "../../utils/utils";
import LoadingScreenSpinner from "../common/LoadingScreenSpinner";
import LottieAnimation from "../common/LottieAnimation";
import Leaderboard from "./Leaderboard";

const styles = makeStyles((theme: Theme) => ({
  emptyText: {
    paddingTop: 20,
    fontSize: 20,
    fontWeight: 700,
  },
  root: {
    position: "relative",
  },
  preventClicking: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 300,
    zIndex: 10,
  },
  notices: {
    fontSize: 12,
    fontWeight: 300,
    textAlign: "center",
  },
  link: {
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
  },
}));

interface Props {
  activeBlockId: string | null;
  activeEntry: Entry | undefined | null;
  isWon: boolean | null;
}

const LeaderboardLadbrokes = (props: Props) => {
  const classes = styles();
  const { activeBlockId, activeEntry, isWon } = props;
  const { loading, data, error } = useQuery<
    GetLeaderboardQuery,
    GetLeaderboardQueryVariables
  >(GetLeaderboardDocument, {
    variables: {
      blockId: activeBlockId ?? '',
    },
    pollInterval: 5000,
  });

  if (data && !loading && !error) {
    const entries = (data.afl.leaderboard.entries || [])
      .filter((entry): entry is LeaderboardEntry => !!entry)
      .filter((entry) => entry.rank > 0);
    const isResulted = !!data.afl.leaderboard.entries?.find((e) => e && (e.settlementStatus === 'WON' || e.settlementStatus === 'LOSS'));

    const creatingNewList = createArrayWithTiedField(entries, "LADBROKES");
    let newList = creatingNewList.newList;
    let isAnyTie = creatingNewList.isAnyTie;

    return (
      <>
        <div className={classes.root}>
          {isWon && (
            <>
              <div className={classes.preventClicking} />
              <LottieAnimation lotti={animation} height={300} width={100} />
            </>
          )}

          {newList.length > 0 ? (
              <>
                { !isResulted ? <div style={{paddingBottom: 20}}><span style={{verticalAlign: 'super'}}>*</span> This game has not yet resulted. Ranks are subject to change.</div> : null}
                <Leaderboard
                    entry={activeEntry}
                    isAnyTie={isAnyTie}
                    newList={newList}
                    type="LADBROKES"
                    isResulted={isResulted}
                />
              </>
          ) : (
            <div className={classes.emptyText}>No entries.</div>
          )}
        </div>

        {newList.length > 0 && (
          <div className={classes.notices}>
            <em>Note:</em> Results are settled as per our data provider.
            <br />
            Please refer to our{" "}
            <a
              className={classes.link}
              href="https://www.ladbrokes.com.au/rules-terms-and-conditions"
              rel="noreferrer"
              target="_blank"
            >
              Terms and Conditions
            </a>{" "}
            for more information.
          </div>
        )}
      </>
    );
  } else {
    if (!data && !loading) {
      return <div className={classes.emptyText}>No entries.</div>;
    }

    return (
      <>
        <div className={classes.emptyText}>Preparing Leaderboard</div>
        <LoadingScreenSpinner />
      </>
    );
  }
};

export default LeaderboardLadbrokes;
